<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view v-if="!$route.meta.link" :key="key" />
      </keep-alive>
    </transition>
    <iframe-toggle />
  </section>
</template>

<script>
import iframeToggle from "./IframeToggle/index"

export default {
  name: 'AppMain',
  components: { iframeToggle },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh - 90px);
  min-width: 900px;
  // width: calc(100% - 40px);
  // margin: 20px;
  // background-color: #fff;
  position: relative;
  overflow: hidden;
}

.fixed-header+.app-main {
  padding: 100px 0px 0px 0px;
}

// 聚合聊天时，高度调整
.isWechat {
  .app-main {
    padding: 65px 0px 0px 0px;
    min-height: calc(100vh) !important;
  }
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 124px);
    overflow: hidden;
  }

  .fixed-header+.app-main {
    // padding-top: 84px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    // padding-right: 17px;
  }
}
</style>
