<template>
  <p class="advanced-filter-dialog__header">
    <span>高级筛选</span>
    <i class="el-icon-close" @click="$emit('close')"></i>
  </p>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {},
})
</script>

<style scoped lang="scss">
.advanced-filter-dialog__header {
  margin-top: 6px;
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 14px;
    color: #333333;
    font-weight: 500;
  }
  i {
    cursor: pointer;
  }
}
</style>
