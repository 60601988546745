import request from "@/utils/request";
// 获取素材列表
export function getMaterial(query) {
  return request({
    url: "/material/manager/page",
    method: "get",
    params: query,
  });
}

//获取类型列表
export function getMaterialType() {
  return request({
    url: "/material/manager/type/list",
    method: "get",
  });
}

// 创建素材
export function createMaterial(data) {
  return request({
    url: "/material/manager/create",
    method: "POST",
    data: data,
  });
}

//删除素材
export function getDeleteMaterial(query) {
  return request({
    url: "/material/manager/delete",
    method: "get",
    params: query,
  });
}

//获取素材详情
export function getMaterialDetail(query) {
  return request({
    url: "/material/manager/detail",
    method: "get",
    params: query,
  });
}

// 编辑素材
export function editMaterial(data) {
  return request({
    url: "/material/manager/edit",
    method: "POST",
    data: data,
  });
}

//自动采集信息
export function getMaterialDetailAuto(query) {
  return request({
    url: "/material/collect/detail",
    method: "get",
    params: query,
  });
}
// 创建 - 素材自动采集
export function createMaterialAuto(data) {
  return request({
    url: "/material/collect/create",
    method: "POST",
    data: data,
  });
}

// 编辑 - 素材自动采集
export function editMaterialAuto(data) {
  return request({
    url: "/material/collect/edit",
    method: "POST",
    data: data,
  });
}
// 获取素材自动采集分页
export function pageMaterialAuto(query) {
  return request({
    url: "/material/collect/page",
    method: "get",
    params: query,
  });
}
// 开启素材自动采集
export function openMaterialAuto(query) {
  return request({
    url: `/material/collect/open`,
    method: "get",
    params: query,
  });
}

// 关闭素材自动采集
export function closeMaterialAuto(query) {
  return request({
    url: `/material/collect/close`,
    method: "get",
    params: query,
  });
}

// 删除素材自动采集
export function deleteMaterialAuto(query) {
  return request({
    url: `/material/collect/delete`,
    method: "get",
    params: query,
  });
}

// 获取自动采集的元素分页 -> 就是自动采集主页面的查看功能
export function lookMaterialAuto(query) {
  return request({
    url: `/material/element/page`,
    method: "get",
    params: query,
  });
}

// 已托管群分页查询 --- 弹窗
export function getGroupPageMaterialAuto(query) {
  return request({
    url: `/wechat/client/entrustedGroupPage`,
    method: "get",
    params: query,
  });
}

// 已托管好友分页查询 --- 弹窗
export function getFriendPageMaterialAuto(query) {
  return request({
    url: `/wechat/client/entrustedFriendPage`,
    method: "get",
    params: query,
  });
}

// 群成员查询 - 二级弹窗
export function getGroupMembers(query) {
  return request({
    url: `/wechat/client/groupMembers`,
    method: "get",
    params: query,
  });
}

// 群成员查询-分页
export function groupMembersPage(query) {
  return request({
    url: `/wechat/client/groupMembersPage`,
    method: "get",
    params: query,
  });
}
/**
 *  选择人/群弹框接口
 */
// AI员工好友列表分页查询
export function friendPage(query) {
  return request({
    url: `/wechat/friend/page`,
    method: "get",
    params: query,
  });
}

// AI员工群列表分页查询
export function groupPage(query) {
  return request({
    url: `/wechat/group/page`,
    method: "get",
    params: query,
  });
}

// AI员工群成员列表分页查询
export function groupMemberPage(query) {
  return request({
    url: `/wechat/groupMember/page`,
    method: "get",
    params: query,
  });
}

export function groupCustomerMemberPage(query) {
  return request({
    url: `/wechat/customer/groupMemberPage`,
    method: "get",
    params: query,
  });
}
// 获取素材支持的参数 
export function getManagerParma(query) {
  return request({
    url: `/material/manager/parma`,
    method: "get",
    params: query,
  });
}
// 获取sop素材支持的参数
export function getSopManagerParma(query) {
  return request({
    url: `/material/manager/parmaBySop`,
    method: "get",
    params: query,
  });
}
// 获取素材引用参数的值 
export function getManagerParmaValues(query) {
  return request({
    url: `/material/manager/parma/values`,
    method: "get",
    params: query,
  });
}


