<template>
    <span>
        <template>
            <svg-icon v-if="type == '2'" icon-class="Enterprise"></svg-icon>
            <img :class="size == 'mini' ? 'image' : 'large'" style="vertical-align: middle;" v-else
                src="../../assets/images/wechathollow.png" alt="">
        </template>
    </span>
</template>

<script>
export default {
    name: "wechatImg",
    props: {
        type: [String, Number],
        size: {
            type: String,
            default: 'mini'
        },
    },
};
</script>
<style scoped>
.image {
    width: 16px;
}

.large {
    width: 27px;
    height: auto;
}
</style>