var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "right-start",
        "popper-class": "importInfo-popover",
        width: "150",
        trigger: "manual",
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "pointer padding-right-xs",
          staticStyle: { color: "#778CA2" },
          attrs: { slot: "reference" },
          on: {
            click: function ($event) {
              _vm.visible = !_vm.visible
            },
          },
          slot: "reference",
        },
        [
          _c("i", { staticClass: "el-icon-circle-plus" }),
          _vm._v(
            " 引用客户" +
              _vm._s(_vm.$route.query.sendType == 1 ? "" : "群") +
              "信息 "
          ),
        ]
      ),
      _c("div", { staticClass: "importInfo padding-top-xs padding-left-xs" }),
      _vm._l(_vm.clientInfoList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "dropdown-item" },
          [
            item.paramType == 1
              ? _c(
                  "div",
                  {
                    staticClass: "flex-center pointer padding-left-sm",
                    staticStyle: { width: "100%" },
                    on: {
                      click: function ($event) {
                        return _vm.clickChild(item)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              : _vm._e(),
            item.paramType == 2
              ? _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "right-start",
                      "popper-class": "importInfo-popover",
                      "visible-arrow": false,
                      width: "180",
                      trigger: "manual",
                    },
                    model: {
                      value: item.visible,
                      callback: function ($$v) {
                        _vm.$set(item, "visible", $$v)
                      },
                      expression: "item.visible",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "dropdown-item flex-center",
                        attrs: { slot: "reference" },
                        on: {
                          click: function ($event) {
                            return _vm.clickChild(item)
                          },
                        },
                        slot: "reference",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-center pointer padding-left-sm",
                            staticStyle: { width: "100%" },
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _c("i", {
                          staticClass: "el-icon-arrow-right padding-right-xs",
                        }),
                      ]
                    ),
                    _vm._l(item.childList, function (i) {
                      return _c(
                        "div",
                        {
                          key: i.id,
                          staticClass: "dropdown-item",
                          on: {
                            click: function ($event) {
                              return _vm.clientInfoCommand(i, item)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-center pointer padding-left-sm",
                              staticStyle: { width: "100%" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    i.paramValue.map((v) => v.show).join("/")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }