<template>
  <div class="table-list" v-height-adaptive:[adaptiveArg]>
    <div class="table-list__search">
      <slot name="search">
        <json-schema v-if="schema" :schema="schema" v-bind="$attrs" />
      </slot>
    </div>
    <div
      class="table-list__content"
      :style="{ paddingBottom: hasTableHeight ? '60px' : '0px' }"
    >
      <slot name="toolbar"></slot>
      <el-table
        ref="tableRef"
        style="width: 100%"
        :height="tableHeight ?? '500px'"
        :data="tableData"
        v-loading="loading"
        v-height-adaptive:[adaptiveArg]="{ bottomOffset: 60 }"
        class="table-list__table"
      >
        <el-table-column v-if="showCheckbox" width="50">
          <template slot="header">
            <span></span>
          </template>
          <template slot-scope="{ row }">
            <el-checkbox
              v-model="row.__checked__"
              @change="(bool) => handleChecked(bool, row)"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="showIndex" label="序号" type="index" width="60">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column
          v-for="column in columns"
          :key="column.prop"
          :label="column.label"
          :renderCell="column.renderCell"
          v-bind="column"
        >
        </el-table-column>

        <template slot="empty">
          <div
            class="table-list__empty"
            style="line-height: 35px; margin: 28px 0 300px 0"
          >
            <p style="color: #3d3d3d; font-size: 14px; margin: 0">暂无数据</p>
          </div>
        </template>
      </el-table>

      <BottomView v-if="tableData.length" class="absolute-footer1">
        <div class="table-list__pagination">
          <div>
            <el-checkbox
              v-if="showCheckbox"
              :indeterminate="isIndeterminate"
              v-model="checkedAll"
              @change="handleCheckedAll"
              class="table-list__pagination-checkbox"
            >
              全部选中
            </el-checkbox>
            <span class="table-list__pagination-records">
              共 {{ total }} 条记录
            </span>
          </div>
          <div class="flex">
            <div style="position: relative; top: -5px">
              共 {{ Math.ceil(total / pageSize) }} 页
            </div>
            <el-pagination
              :current-page="pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              layout="sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </BottomView>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, computed } from 'vue'
import BottomView from '@/components/bottomView/bottomView'
import DraggableTable from '@/base/draggable-table/index'
import { JsonSchema } from '@/formily/base/index'

const isDef = (v) => v !== undefined && v !== null

export default defineComponent({
  name: 'TableList',
  components: {
    BottomView,
    ElTable: DraggableTable,
    ElTableColumn: DraggableTable.TableColumn,
    JsonSchema,
  },
  props: {
    httpRequest: {
      type: Function,
      required: true,
    },
    columns: {
      type: Array,
      default: () => [], // [{prop, label, width, renderCell}]
    },
    schema: {
      type: Object,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    showIndex: {
      type: Boolean,
      default: true,
    },
    defaultPageSize: {
      type: Number,
      default: 20,
    },
    tableHeight: {
      type: [String, Number],
    },
  },
  setup(props, { emit }) {
    const { defaultPageSize, tableHeight } = props
    const data = reactive({
      pageNo: 1,
      pageSize: defaultPageSize,
      loading: false,
      total: 0,
      tableData: [],
      checkedAll: false,
    })

    const isIndeterminate = computed(() => {
      return (
        data.tableData.some((_) => _.__checked__) &&
        !data.tableData.every((_) => _.__checked__)
      )
    })
    const hasTableHeight = computed(() => !!isDef(tableHeight))
    const adaptiveArg = computed(() =>
      hasTableHeight.value ? 'invalid' : 'valid'
    )

    // methods
    const getList = async (params = {}) => {
      return new Promise((resolve) => {
        data.loading = true
        props.httpRequest({
          params: {
            pageNo: data.pageNo,
            pageSize: data.pageSize,
            ...params,
          },
          resolve: ({ list, total }) => {
            data.tableData = list.map((row) => {
              row.__checked__ = false
              return row
            })
            data.total = total ?? 0
            data.loading = false
            emit('on-refresh-list', data.tableData)
            return resolve()
          },
        })
      })
    }
    getList()

    const handleSizeChange = (val) => {
      data.pageSize = val
      getList()
    }
    const handleCurrentChange = (val) => {
      data.pageNo = val
      getList()
    }
    const handleChecked = (bool, row) => {
      emit('on-checked', bool, [row])
    }
    const handleCheckedAll = (bool) => {
      data.tableData.forEach((row) => {
        row.__checked__ = bool
      })
      emit('on-checked', bool, data.tableData)
    }

    // public api
    const request = ({ pageNo = 1, pageSize = defaultPageSize, ...args }) => {
      data.pageNo = pageNo
      data.pageSize = pageSize
      return getList(args)
    }
    const getSelectedTableData = () => {
      return data.tableData.filter((row) => row.__checked__)
    }
    const getTableData = () => {
      return [...data.tableData]
    }

    return {
      ...toRefs(data),
      isIndeterminate,
      handleSizeChange,
      handleCurrentChange,
      handleChecked,
      handleCheckedAll,
      request,
      getSelectedTableData,
      getTableData,
      adaptiveArg,
      hasTableHeight,
    }
  },
})
</script>

<style lang="scss" scoped>
.table-list {
  position: relative;
  padding: 0 16px;
  &__search {
    padding-top: 24px;
  }
  &__pagination {
    display: flex;
    justify-content: space-between;
    &-checkbox {
      margin-right: 12px;
    }
    &-records {
      color: #999999;
    }
  }
}
.table-list__search ::v-deep {
  .formily-element-form-item {
    display: inline-flex;
  }
}
.table-list__search ::v-deep form {
  display: flex;
  flex-wrap: wrap;
}
</style>
