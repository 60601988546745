/**
 * 如何使用:
 *
 * 1. 关于页面含有table组件的使用 - height必须设置（这是el-table的bug, 可以随便给个默认值，该指令初始化时会自动修正）
 *   <el-table height="500px" v-height-adaptive="{ bottomOffset: 60 }">...</el-table>
 *
 * 2. 关于页面自适应可视区高度 - 这里el-card容器会自适应当前屏幕的可见区域
 *   <el-card v-height-adaptive>
 *     <div v-for="num in 20" :key="num">{{ num }}</div>
 *   </el-card>
 *
 * 3. 关于页面含有底部fixed布局的元素 - 根据BottomView组件的高度可以设置bottomOffset的大小
 *   <div
 *     v-height-adaptive="{
 *       bottomOffset: 50,
 *     }"
 *    >
 *     <div>
 *       <div v-for="num in 200" :key="num">内容{{ num }}</div>
 *     </div>
 *     <BottomView> 固定底部 </BottomView>
 *   </div>
 *
 * 总结: 只要页面使用了固定底部元素，就得给该指令设置 bottomOffset 属性值
 */

import adaptive from './adaptive'

const install = function (Vue) {
  Vue.directive('height-adaptive', adaptive)
}

if (window.Vue) {
  window['height-adaptive'] = adaptive
  Vue.use(install)
}

adaptive.install = install
export default adaptive
