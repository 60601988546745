import { defineComponent } from 'vue'

export default defineComponent({
  functional: true,
  name: 'Fragment',
  props: {
    tag: {
      type: null,
      default: 'div'
    }
  },
  render(h, context) {
    return h(context.props.tag ?? 'div', context.data, context.children)
  },
})
