<template>
    <el-dialog title="选择小程序" class="mini-dialog" append-to-body @close="resetForm" :close-on-click-modal="false"
        :visible.sync="dialogVisible" width="920px">
        <div class="chooseContent">
            <div class="top-step margin-top-sm">
                <el-steps class="padding-tb-sm sop-step" :active="activeTab">
                    <el-step v-for="(item, index) in stepList" :key="index">
                        <div class="step-item" slot="icon">
                            <div class="icon-circle">{{ index + 1 }}</div>
                            <span class="text-black text-cut2 padding-left-xs">{{ item.name }}</span>
                        </div>
                    </el-step>
                </el-steps>
            </div>
            <div v-show="activeTab == 1">
                <div class="margin-top-sm search-view">
                    <!-- <el-input clearable placeholder="请输入要搜索的小程序标题/AppID" @keyup.enter.native="getList"
                        v-model="keyword">
                        <div slot="append">
                            <el-button  @click="getList" icon="el-icon-search"></el-button>
                        </div>
                    </el-input> -->
                    <el-input clearable placeholder="请输入要搜索的小程序标题/AppID" v-model="keyword"  @keyup.enter.native="getList">
                        <i slot="suffix" style="cursor: pointer" class="el-input__icon el-icon-search" @click="getList"></i>
                    </el-input>

                    <div class="margin-top-xs">我的小程序
                        <!-- <el-button type="text" icon="el-icon-plus" @click="next(true)">新增</el-button> -->
                    </div>
                </div>
                <el-scrollbar class="margin-top-xs" v-loadmore="getMaterialMpPage">
                    <div class="miniProgramList">
                        <div class="miniProgramItem pointer solid radius-8" @click="setCurrentMiniProgram(item)"
                            v-for="item in tableData" :key="item.id">
                            <el-image :src="item.mpLogo ? item.mpLogo : defaultLogo" class="image"></el-image>
                            <div class="flex-sub">
                                <div class="text-cut2">{{ item.title }}</div>
                                <div class="text-xs text-gray text-cut2">
                                </div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
            <div class="margin-top" style="padding-bottom: 14px;" v-show="activeTab == 2">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px">
                    <el-form-item label="小程序标题" prop="title">
                        <el-input v-model="ruleForm.title" class="inputvalue uni-input" placeholder="请输入小程序标题" maxlength="30"
                            show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item class="flex-label" prop="mpUsername">
                        <div class="flex-center" slot="label">
                            账号原始ID
                            <question-tooltip popperClass="tooltip-view-1 popper-editMaterial" class="margin-left-xs"
                                effect="light" placement="bottom-start">
                                <div class="tooltip-image" slot="content">
                                    <div class="flex margin-top justify-between margin-bottom-sm padding-lr text-group">
                                        <div>1、请前往微信小程序右上角，点击「小程序名称」</div>
                                        <div>2、进入「详情页」，点击「更多资料」</div>
                                        <div>3、复制「账号原始ID」后，粘贴到此处</div>
                                    </div>
                                    <el-image style="width: 810px;height: 250px;margin-left: -1px;" fit="contain"
                                        src="https://oss.isuiyou.cn/6bf2b96508c9c49397f9e4ff1ec201941507c28f6c6b67588e1d17c227c3ea24.png"></el-image>
                                </div>
                            </question-tooltip>
                        </div>
                        <el-input :disabled="!isAdd" placeholder="请输入账号原始ID" maxlength="30"
                            oninput="value=value.replace(/[\u4e00-\u9fa5]/g, '')"
                            v-model="ruleForm.mpUsername"></el-input>
                    </el-form-item>
                    <el-form-item class="flex-label" prop="appId">
                        <div class="flex-center" slot="label">
                            小程序AppID
                            <question-tooltip class="margin-left-xs" effect="light" placement="bottom">
                                <div slot="content">
                                    APPID获取方式请参见：<br>
                                    <a style="text-decoration: underline;" class="text-edit"
                                        href="http://www.mpgcw.com/9765.html" target="_blank"
                                        rel="noopener noreferrer">http://www.mpgcw.com/9765.html</a>
                                </div>
                            </question-tooltip>
                        </div>
                        <el-input :disabled="!isAdd" placeholder="请输入小程序AppID"
                            onkeyup="value=value.replace(/[^\w]/ig,'')" maxlength="30"
                            v-model="ruleForm.appId"></el-input>
                    </el-form-item>

                    <el-form-item class="flex-label" prop="path">
                        <div slot="label">
                            小程序路径
                            <question-tooltip popperClass="tooltip-view popper-editMaterial" class="margin-left-xs"
                                effect="light" placement="bottom">
                                <div class="tooltip-image" slot="content">
                                    <!-- 请前往微信小程序右上角，复制链接后粘贴<br>到此处<br> -->
                                    <el-image style="width: 308px;height: 355px;" fit="cover"
                                        src="https://oss.isuiyou.cn/433d85e0c2635ce43e8b4f7aba45dd611dcd4e713905731cb96e7145a771441c.png"></el-image>
                                </div>
                            </question-tooltip>
                        </div>
                        <el-input :disabled="!isAdd" maxlength="50" placeholder="微信打开小程序，右上角复制链接后粘贴到此处"
                            v-model="ruleForm.path"></el-input>
                    </el-form-item>

                    <el-form-item label="小程序封面" prop="url">
                        <el-upload list-type="picture-card" accept=".png,.jpg" :action="uploadFileUrl"
                            style="width: 192px" :show-file-list="false" :file-list="fileList" :headers="headers"
                            :on-success="handleUploadSuccess" :limit="1" :before-upload="beforeUpload"
                            :on-error="onError">
                            <el-image v-if="ruleForm.url" @mouseover="reupload(true)" @mouseleave="reupload(false)"
                                fit="scale-down" :src="ruleForm.url" class="avatar" />
                            <div class="reupload" v-if="ruleForm.url && showReupload">重新上传</div>
                            <div  v-if="!ruleForm.url" class="margin-top-lg" style="line-height: 2;">
                                <i class="el-icon-plus text-38"></i>
                                <div>上传图片</div>
                            </div>
                            <!-- <div class="reupload" v-if="showReupload">重新上传</div>
                            <div v-else class="margin-top-lg" style="line-height: 2;">
                                <i class="el-icon-plus text-38"></i>
                                <div>上传图片</div>
                            </div> -->
                        </el-upload>
                        <div class="text-gray margin-top-xs">建议图片尺寸为5:4，大小不超过5M，暂不支持动图</div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <template slot="footer">
            <div class="flex-center justify-between">
                <div>
                    <el-button size="mini" v-if="activeTab == 2" @click="prev">上一步</el-button>
                </div>
                <div>
                    <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
                    <!-- <el-button size="mini" v-if="activeTab == 1" type="primary" @click="next(true)">下一步</el-button> -->
                    <el-button size="mini" v-if="activeTab == 2" type="primary" @click="saveFieldOption">确 定</el-button>
                </div>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import { getAccessToken } from '@/utils/auth'
import { getMaterialMpPage, createMaterialMp } from "@/api/robot/robot.js"
import questionTooltip from '../questionTooltip/questionTooltip.vue'
import { uploadFile } from "@/api/index.js";
export default {
    components: { questionTooltip },
    provide() {
        return {
            elFormItem: ''
        }
    },
    data() {
        return {
            dialogVisible: false,
            activeTab: 1,
            stepList: [{ name: "选择小程序" }, { name: "填写详细信息" }],
            ruleForm: {
                type: 10,
                title: "",
                appId: "",
                path: "",
                url: "",
                mpUsername: "",
                mpLogo: ""
            },
            rules: {
                title: [{ required: true, message: "请输入小程序标题", trigger: "blur" }],
                appId: [{ required: true, message: "请输入正确的小程序名称/APPID/账号原始ID，并确保小程序允许搜索", trigger: "blur" }],
                path: [{ required: true, message: "请输入小程序路径", trigger: "blur" }],
                url: [{ required: true, message: "请上传小程序封面", trigger: ["blur", "change"] }],
                mpUsername: [{ required: true, message: "请输入小程序用户名称", trigger: ["blur", "change"] }]
            },
            isAdd: false,
            uploadFileUrl: process.env.VUE_APP_BASE_API + "/admin-api/infra/file/upload", // 请求地址
            headers: { Authorization: "Bearer " + getAccessToken() }, // 设置上传的请求头部
            keyword: null,
            pageNo: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            loading: false,
            defaultLogo: "https://oss.isuiyou.cn/c170293e5ab39a8aa129f69bf97a9905cade3d4006a9e2ebcbbb1af6218ffb7a.png",
            immediate: false,
            fileList: [],
            showReupload: false
        }
    },
    computed: {
        noMore() {
            return this.total < 10
        },
        disabled() {
            return this.loading || this.noMore
        }
    },
    methods: {
        open() {
            this.dialogVisible = true;
        },
        prev() {
            this.activeTab--;
        },
        next(isAdd) {
            this.isAdd = isAdd;
            this.activeTab++;
            this.$nextTick(() => {
                this.isAdd && this.$refs.ruleForm.resetFields();
            })
        },
        handleUploadSuccess(res) {
            this.loading.close();
            this.fileList = [];
            this.ruleForm.url = res.data;
            this.$message.success("上传成功");
        },
        onError() {
            this.fileList = [];
            this.loading.close();
        },
        saveFieldOption() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.$emit("setMiniProgram", JSON.parse(JSON.stringify(this.ruleForm)));
                    this.ruleForm.mpLogo = "";
                    this.dialogVisible = false;
                }
            })
        },
        getList() {
            this.pageNo = 1;
            this.tableData = [];
            this.getMaterialMpPage();
        },
        getMaterialMpPage() {
            this.loading = true;
            this.immediate = true;
            getMaterialMpPage({ keyword: this.keyword, pageNo: this.pageNo++, pageSize: this.pageSize, searchCount: true }).then(res => {
                this.tableData = this.tableData.concat(res.data.list);
                this.total = res.data.total;
                this.loading = false;
                this.immediate = false;
            })
        },
        // 选中小程序
        setCurrentMiniProgram(item) {
            this.activeTab++;
            this.isAdd = false;
            this.$nextTick(() => {
                this.ruleForm = Object.assign(this.ruleForm, item);
                this.keyword = "";
                this.getList();
            })
        },
        // 重置表单
        resetForm() {
            this.ruleForm.id = null;
            this.activeTab = 1;
            this.$refs.ruleForm && this.$refs.ruleForm.resetFields();
            this.keyword = "";
            this.getList();
        },
        handleInput(value) {
            const pattern = /^[\u4e00-\u9fa5]*$/;
            if (pattern.test(value)) {
                this.ruleForm.mpUsername = value.replace(/\u4e00-\u9fa5/g, '')
            }
        },
        // 上传文件之前
        beforeUpload(file) {
            this.loading = this.$loading({
                lock: true,
                text: "上传中,请稍候",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.3)",
            });
            if (file.size / 1024 / 1024 > 5) {
                this.$message.error("图片大小不能超过5M");
                this.loading.close();
                return false;
            }
            // 图片大小大于2M进行图片压缩
            if (file.size / 1024 / 1024 > 0.2) {
                this.$util.compressImage(file, 300).then((result) => {
                    let fileObj = new File([result.compressFile], file.name, { type: file.type })
                    console.log(fileObj, 'fileObj');
                    this.uploadFile(fileObj);
                })
                return false;
            } else {
                const imageTypeList = ["image/jpeg", "image/png"];
                if (
                    imageTypeList.indexOf(file.type) == -1 &&
                    file.type.indexOf("image/") != -1
                ) {
                    this.$message.error("只能上传jpg/png格式图片");
                    this.loading.close();
                    return false;
                }
                return true;
            }
        },
        uploadFile(file) {
            this.dialogVisible = true;
            // if (!progressCallback) {
            //   this.dialogVisible = false;
            // }
            const formData = new FormData();
            formData.append("file", file);
            // if (file.type.indexOf("image") == -1) {
            // formData.append("path", `scrm/${uuidv4()}/${file.name}`);
            // }
            uploadFile(formData)
                .then((res) => {
                    // this.sendNumber++;
                    this.handleUploadSuccess(res);
                    // this.uploadCallback();
                })
                .catch((err) => {
                    this.onError(err);
                    this.dialogVisible = false;
                    // this.failNumber++; // 失败数量
                    // this.uploadCallback();
                });
        },
        reupload(params) {
            this.showReupload = params;
        }
    },
    created() {
        this.getList();
    },
}
</script>

<style>
    .uni-input .el-input__validateIcon {
        display: none !important;
    }
</style>

<style lang="scss" scoped>
.top-step ::v-deep {
    padding: 0 50px;
}

.text-group {
    div {
        font-size: 12px;
        width: 215px;
    }
}

.chooseContent ::v-deep {
    padding: 0px 160px;



    .el-upload {
        width: 100%;
        position: relative;
    }

    .reupload {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #fff;
        background: rgba(0, 0, 0, .3);
    }

    .search-view {
        .el-icon-circle-check {
            display: none;
        }

        .el-input-group__append,
        .el-input-group__prepend {
            background: transparent;
            background-color: transparent;
            border-left-color: transparent;
        }

        .el-input__inner {
            &:focus+.el-input-group__append {
                outline: none;
                border-color: #307EF2;
            }
        }

        // .el-input__inner {
        //    border-right: 0 none;
        // }
    }

    .flex-label {
        .el-form-item__label {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .avatar {
        width: 100%;
        height: 100%;
    }

    .inputvalue .el-input__suffix {
        right: -50px;
    }

    .miniProgramList {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-between;
        height: 300px;

        .miniProgramItem {
            padding: 15px;
            width: 48%;
            height: 100px;
            display: flex;
            align-items: center;
            gap: 12px;

            .image {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }

            .text-cut2 {
                line-height: 1.5;
            }
        }
    }

    .el-form-item__label {
        float: left;
    }
}
</style>