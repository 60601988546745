var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "Submit",
        {
          attrs: {
            type: "primary",
            onSubmit: _vm.handleSubmit,
            icon: _vm.submitIcon,
          },
        },
        [_vm._v(" " + _vm._s(_vm.submitText) + " ")]
      ),
      _c(
        "Reset",
        {
          attrs: { icon: _vm.resetIcon },
          on: { resetValidateSuccess: _vm.handleReset },
        },
        [_vm._v(" " + _vm._s(_vm.resetText) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }