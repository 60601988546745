var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "outerWrapper", class: _vm.wrapperClasses }, [
    _vm.isHorizontal
      ? _c("div", { class: `${_vm.prefix}-horizontal` }, [
          _c(
            "div",
            {
              staticClass: "left-pane",
              class: _vm.paneClasses,
              style: { right: `${_vm.anotherOffset}%` },
            },
            [_vm._t("left")],
            2
          ),
          _c(
            "div",
            {
              class: `${_vm.prefix}-trigger-con`,
              style: { left: `${_vm.offset}%` },
              on: { mousedown: _vm.handleMousedown },
            },
            [
              _vm._t("trigger", function () {
                return [_c("trigger", { attrs: { mode: "vertical" } })]
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "right-pane",
              class: _vm.paneClasses,
              style: { left: `${_vm.offset}%` },
            },
            [_vm._t("right")],
            2
          ),
        ])
      : _c("div", { class: `${_vm.prefix}-vertical` }, [
          _c(
            "div",
            {
              staticClass: "top-pane",
              class: _vm.paneClasses,
              style: { bottom: `${_vm.anotherOffset}%` },
            },
            [_vm._t("top")],
            2
          ),
          _c(
            "div",
            {
              class: `${_vm.prefix}-trigger-con`,
              style: { top: `${_vm.offset}%` },
              on: { mousedown: _vm.handleMousedown },
            },
            [
              _vm._t("trigger", function () {
                return [_c("trigger", { attrs: { mode: "horizontal" } })]
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "bottom-pane",
              class: _vm.paneClasses,
              style: { top: `${_vm.offset}%` },
            },
            [_vm._t("bottom")],
            2
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }