import {
  Input_Type,
  Textarea_Type,
  Date_Type,
  Daterange_Type,
  Number_Type,
  Select_Type,
} from './constants'
import { Loading } from 'element-ui'
let util = {}
// 分转化为元 - 正则解决精度

util.regFenToYuan = (fen) => {
  if (!fen) return null
  var num = fen
  num = fen * 0.01
  num += ''
  var reg =
    num.indexOf('.') > -1
      ? /(\d{1,3})(?=(?:\d{3})+\.)/g
      : /(\d{1,3})(?=(?:\d{3})+$)/g
  num = num.replace(reg, '$1')
  num = util.toDecimal2(num)
  return num
}

// 元转分 - 解决精度问题 yuan:要转换的钱，单位元； digit：转换倍数

util.regYuanToFen = (yuan, digit) => {
  if (!yuan) return null
  var m = 0,
    s1 = yuan.toString(),
    s2 = digit.toString()
  try {
    m += s1.split('.')[1].length
  } catch (e) {}
  try {
    m += s2.split('.')[1].length
  } catch (e) {}
  return (
    (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
    Math.pow(10, m)
  )
}

// 强制保留2位小数，如：2，会在2后面补上00.即2.00
util.toDecimal2 = (x) => {
  var f = parseFloat(x)
  if (isNaN(f)) {
    return false
  }
  var f = Math.round(x * 100) / 100
  var s = f.toString()
  var rs = s.indexOf('.')
  if (rs < 0) {
    rs = s.length
    s += '.'
  }
  while (s.length <= rs + 2) {
    s += '0'
  }
  return s
}

//根据身份证号获取出生年月，性别，年龄
util.getInfo = (idCard) => {
  let sex = null
  let birth = null
  let myDate = new Date()
  let month = myDate.getMonth() + 1
  let day = myDate.getDate()
  let age = 0

  if (idCard.length === 18) {
    age = myDate.getFullYear() - idCard.substring(6, 10) - 1
    sex = idCard.substring(16, 17)
    birth =
      idCard.substring(6, 10) +
      '-' +
      idCard.substring(10, 12) +
      '-' +
      idCard.substring(12, 14)
    if (
      idCard.substring(10, 12) < month ||
      (idCard.substring(10, 12) === month && idCard.substring(12, 14) <= day)
    )
      age++
  }
  if (idCard.length === 15) {
    age = myDate.getFullYear() - idCard.substring(6, 8) - 1901
    sex = idCard.substring(13, 14)
    birth =
      '19' +
      idCard.substring(6, 8) +
      '-' +
      idCard.substring(8, 10) +
      '-' +
      idCard.substring(10, 12)
    if (
      idCard.substring(8, 10) < month ||
      (idCard.substring(8, 10) === month && idCard.substring(10, 12) <= day)
    )
      age++
  }

  if (sex % 2 === 0)
    sex = '0' // 性别代码 1代表男，0代表女，暂时不涉及其他类型性别
  else sex = '1'
  return { age, sex, birth }
}

//复制
util.copy = (val) => {
  let item = val //拿到想要复制的值
  let copyInput = document.createElement('input') //创建input元素
  document.body.appendChild(copyInput) //向页面底部追加输入框
  copyInput.setAttribute('value', item) //添加属性，将url赋值给input元素的value属性
  copyInput.select() //选择input元素
  document.execCommand('Copy') //执行复制命令
  //复制之后再删除元素，否则无法成功赋值
  copyInput.remove() //删除动态创建的节点
}

// formatDate 接受俩个参数, oldDate 类型可以是 Date，String，Number。因为现在用时间戳传递时间真的是蛮多，
//也蛮方便的，而JS 是一门弱类型语言，所以我将 String 和 Number 类型的数据统一当做时间戳来处理。 fmt 是格式化的类型：yyyy-MM-dd hh:mm，其中的 yyyy | MM | dd | hh | mm 是分别匹配 年 | 月 | 日 | 时 | 分 的关键字。其中的连字符是可以随意替换的，只展示年月将其他关键字去掉即可。举几个例子：
// yyyy年MM月dd -> 2019年09月7日
// hh分mm秒 -> 16分53秒
util.formatDate = (oldDate, fmt) => {
  if (oldDate == null) return ''
  let date = new Date()
  if (typeof oldDate === 'string' || typeof oldDate === 'number') {
    date = new Date(+oldDate)
  } else {
    date = oldDate
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length),
    )
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  }

  function padLeftZero(str) {
    return ('00' + str).substr(str.length)
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str),
      )
    }
  }
  return fmt
}

util.exFileName = (fileName) => {
  let nowtime = util.formatDate(new Date().getTime(), 'yyyyMMddhhmmss')
  let num = Math.floor(Math.random() * 900 + 100)
  return `${fileName}_${nowtime}${num}.xlsx`
}
// 图片压缩
util.compressImage = (file, fileSize) => {
  fileSize = fileSize ? fileSize : 800
  const loading = Loading.service({
    lock: true,
    text: '压缩中,请稍候',
    background: 'rgba(255, 255, 255, 0.3)',
  })
  // 参数file,是通过input 选择本地文件获取的
  return new Promise((resolve, reject) => {
    const { type, name, size } = file?.raw || file
    let img = new Image()
    // 创建一个reader实例
    let reader = new FileReader()
    // 读取拿到的文件
    reader.readAsDataURL(file?.raw || file)
    reader.onload = (e) => {
      img.src = e.target.result
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        // 获取文件宽高比例
        const { width: originWidth, height: originHeight } = img
        console.log(originWidth, 'originWidth')
        const scale = +(originWidth / originHeight).toFixed(2) // 比例取小数点后两位
        const targetWidth = originWidth > fileSize ? fileSize : originWidth // 固定宽
        const targetHeight = Math.round(targetWidth / scale) // 等比例缩放高度自适应

        canvas.width = targetWidth
        canvas.height = targetHeight
        context.clearRect(0, 0, targetWidth, targetHeight)
        // canvas重新绘制图片
        context.drawImage(img, 0, 0, targetWidth, targetHeight)
        // canvas转二进制对象转文件对象，返回
        const type = 'image/png'
        canvas.toBlob(
          (blob) => {
            // blob 格式的图片 转成file对象，这里主要看接口支不支持blob格式的文件上传，如果支持就没有必要转
            let fileData = new File([blob], name, { type: type, size: size })
            loading.close()
            resolve({ type: type, compressFile: fileData })
          },
          `image/${type.split('/')[1]}`,
          0.3,
        ) // 1 是文件压缩程度
      }
    }
  })
}

// 图片压缩
util.isEmptyString = (s) => {
  if (
    s == undefined ||
    s == null ||
    s === '' ||
    s == 'undefined' ||
    s == 'null'
  ) {
    return true
  }
  return false
}
//图片下载
util.downloadImage = (imgPath) => {
  const image = new Image()
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous')
  image.src = imgPath
  image.onload = function () {
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    const context = canvas.getContext('2d')
    context.drawImage(image, 0, 0, image.width, image.height)
    const url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
    const a = document.createElement('a') // 生成一个a元素
    const event = new MouseEvent('click') // 创建一个单击事件
    a.download = 'img.png' || 'photo' // 设置图片名称
    a.href = url // 将生成的URL设置为a.href属性
    a.dispatchEvent(event) // 触发a的单击事件
  }
}

// 直接下载文档
util.downFile = ({ url, name }) => {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.onload = () => {
    if (xhr.status === 200) {
      console.log(xhr.response)
      const blob = new Blob([xhr.response], { type: xhr.response.type })
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = name
      link.click()

      URL.revokeObjectURL(url)
    }
  }

  xhr.send()
}

util.downFilePromise = ({ url, name }) => {
  return new Promise((resolve, reject) => {
    try {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      xhr.onload = () => {
        if (xhr.status === 200) {
          console.log(xhr.response)
          const blob = new Blob([xhr.response], { type: xhr.response.type })
          const url = URL.createObjectURL(blob)

          const link = document.createElement('a')
          link.href = url
          link.download = name
          link.click()

          URL.revokeObjectURL(url)
          return resolve()
        } else {
          return reject()
        }
      }

      xhr.onerror = () => {
        return reject()
      }

      xhr.send()
    } catch (e) {
      return reject(e)
    }
  })
}

// 新建 - 编辑 页面特殊情况下清除页面数据
import store from '@/store'
import router from '@/router'
util.redirectTo = async (name, query, params) => {
  params = params ? params : {}
  const route = store.state.tagsView.visitedViews.find((v) => v.name == name)
  if (store.state.tagsView.cachedViews.includes(name)) {
    await store.dispatch('tagsView/delView', route)
  }
  router.push({
    name: name,
    query: query,
    ...params,
  })
}

//上传文件地址
util.uploadUrl =
  process.env.VUE_APP_BASE_API +
  '/organization-admin-api/recreation/file/upload'

util.debounce = (fn, delay) => {
  let time = null
  return function () {
    if (time !== null) {
      clearTimeout(time)
    }
    time = setTimeout(() => {
      fn.call(this)
    }, delay)
  }
}
//获取素材文件信息

util.getMaterialInfo = (file) => {
  const fileType = file.raw.type
  const fileName = file.name.split('.').at(-1)
  const arr = [
    {
      type: '1',
      name: '图片',
      accept: 'image/* ',
      notes: 'image',
      fileType: fileType,
    },
    {
      type: '2',
      name: '视频',
      accept: 'video/*',
      notes: 'video',
      fileType: fileType,
    },
    {
      type: '3',
      name: 'Word文档',
      accept: '.doc,.docx',
      notes: '.doc,.docx',
      img: 'img-word',
      fileType: fileType,
    },
    {
      type: '4',
      name: 'Excel文档',
      accept: '.xls,.xlsx',
      notes: '.xls,.xlsx',
      img: 'img-excel',
      fileType: fileType,
    },
    {
      type: '5',
      name: 'PDF文档',
      accept: 'application/pdf',
      notes: '.pdf',
      img: 'img-pdf',
      fileType: fileType,
    },
  ]
  return arr.find(
    (v) => fileType.startsWith(v.notes) || v.notes.includes(fileName),
  )
}

// 格式化文件大小

util.formatFileSize = (size) => {
  size = size ? size : 0
  const unitArr = ['B', 'KB', 'MB', 'GB']
  const index = Math.floor(Math.log(size) / Math.log(1024))
  const fileSize = (size / Math.pow(1024, index)).toFixed(2)
  return fileSize + unitArr[index]
}

//url转file对象
util.urlToFile = async (url, callback) => {
  let DownUrl = url
  let data = await fetch(DownUrl)
    .then((response) => response.blob())
    .then((res) => {
      //获取文件格式
      var index = DownUrl.lastIndexOf('.')
      //获取文件后缀判断文件格式
      var fileType = DownUrl.substr(index + 1)
      let blod = new Blob([res])
      if (typeof callback == 'function') {
        const file = new File([blod], new URL(url).pathname.split('/').at(-1), {
          type: fileType,
        })
        callback(file)
      }
    })
  return data
}
//删除对象中空值
util.delEmptyQueryNodes = (obj) => {
  Object.keys(obj).forEach((key) => {
    let value = obj[key]
    value && typeof value === 'object' && util.delEmptyQueryNodes(value)
    ;(value === '' ||
      value === null ||
      value === undefined ||
      value.length === 0 ||
      (typeof value == 'object' && Object.keys(value).length === 0)) &&
      delete obj[key]
  })
  return obj
}

util.toEmotion = (text, isNoGif) => {
  const list = [
    '微笑',
    '撇嘴',
    '色',
    '发呆',
    '得意',
    '流泪',
    '害羞',
    '闭嘴',
    '睡',
    '大哭',
    '尴尬',
    '发怒',
    '调皮',
    '呲牙',
    '惊讶',
    '难过',
    '酷',
    '冷汗',
    '抓狂',
    '吐',
    '偷笑',
    '愉快',
    '白眼',
    '傲慢',
    '饥饿',
    '困',
    '惊恐',
    '流汗',
    '憨笑',
    '大兵',
    '奋斗',
    '咒骂',
    '疑问',
    '嘘',
    '晕',
    '折磨',
    '衰',
    '骷髅',
    '敲打',
    '再见',
    '擦汗',
    '抠鼻',
    '鼓掌',
    '糗大了',
    '坏笑',
    '左哼哼',
    '右哼哼',
    '哈欠',
    '鄙视',
    '委屈',
    '快哭了',
    '阴险',
    '亲亲',
    '吓',
    '可怜',
    '菜刀',
    '西瓜',
    '啤酒',
    '篮球',
    '乒乓',
    '咖啡',
    '饭',
    '猪头',
    '玫瑰',
    '凋谢',
    '示爱',
    '爱心',
    '心碎',
    '蛋糕',
    '闪电',
    '炸弹',
    '刀',
    '足球',
    '瓢虫',
    '便便',
    '月亮',
    '太阳',
    '礼物',
    '拥抱',
    '强',
    '弱',
    '握手',
    '胜利',
    '抱拳',
    '勾引',
    '拳头',
    '差劲',
    '爱你',
    'NO',
    'OK',
    '爱情',
    '飞吻',
    '跳跳',
    '发抖',
    '怄火',
    '转圈',
    '磕头',
    '回头',
    '跳绳',
    '挥手',
    '激动',
    '街舞',
    '献吻',
    '左太极',
    '右太极',
    '嘿哈',
    '捂脸',
    '奸笑',
    '机智',
    '皱眉',
    '耶',
    '红包',
    '鸡',
  ]

  if (!text) {
    return text
  }

  text = text.replace(/\[[\u4E00-\u9FA5]{1,3}\]/gi, function (word) {
    const newWord = word.replace(/\[|\]/gi, '')
    const index = list.indexOf(newWord)
    const backgroundPositionX = -index * 24
    let imgHTML = ''
    if (index < 0) {
      return word
    }

    if (isNoGif) {
      if (index > 104) {
        return word
      }
      imgHTML = `<i class="static-emotion" style="background:url(https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/default218877.gif) ${backgroundPositionX}px 0;"></i>`
    } else {
      // var path =
      //   index > 104
      //     ? "/img"
      //     : "https://res.wx.qq.com/mpres/htmledition/images/icon";
      if (index > 104) {
        return word
      }
      const path = 'https://res.wx.qq.com/mpres/htmledition/images/icon'
      imgHTML = `<img class="static-emotion-gif" style="vertical-align: middle" src="${path}/emotion/${index}.gif" />`
    }
    return imgHTML
  })
  return text
}

// 格式化数据 form-gen-parser
util.formatParserData = (dataList, formOptions) => {
  const defaultConfig = {
    // 默认配置
    layout: 'colFormItem',
    labelWidth: null,
    showLabel: true,
    changeTag: true,
  }
  // 表单类型与标签对应
  const labelType = {
    [Input_Type]: { tag: 'XrInput' },
    [Textarea_Type]: { tag: 'XrInput' },
    [Date_Type]: { tag: 'el-date-picker' },
    [Daterange_Type]: { tag: 'el-date-picker' },
    [Number_Type]: { tag: 'XrInput' },
    [Select_Type]: { tag: 'el-select' },
  }

  // 生成单个fields数据
  const initFieldsConfig = (formItem) => {
    let formObj = {
      style: { width: '100%', ...formItem.style },
      __config__: initLabelConfig(formItem),
      __vModel__: String(formItem.id),
      placeholder: formItem.inFont || '请输入',
      clearable: formItem.clearable ?? true,
      maxlength: formItem.length,
      'show-word-limit': formItem.showLength,
      readonly: false,
      disabled: false,
      'label-position': 'top',
      unit: formItem.unit ? `单位:${formItem.unit}` : '',
      resize: formItem.resize,
    }
    if ([Date_Type, Daterange_Type].includes(formItem.storageType)) {
      // 日期格式化
      formObj = {
        ...formObj,
        // type: formItem.storageType == 103 ? 'datetime' : 'datetimerange',
        type: formItem.type,
        format: formItem.dateStartCustomerFormat,
        'value-format': 'timestamp',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期',
        clearable: true,
        'popper-class': 'data-popper__mm-dd',
      }
    }
    if (formItem.unit) {
      // 单位
      formObj.__slot__ = { append: `单位: ${formItem.unit}` }
    }
    if (formItem.storageType == Number_Type) {
      //数字
      formObj.type = 'number'
      formObj.min = 0
      formObj['controls-position'] = 'right'
      formObj.step = 1
    }
    if (formItem.storageType == Textarea_Type) {
      //多行文本
      formObj.type = 'textarea'
      formObj.rows = 2
      formObj.autosize = { minRows: 2, maxRows: 6 }
    }
    if (formItem.storageType == Select_Type) {
      formObj.__slot__ = { options: formItem.options }
    }
    return formObj
  }
  // 初始化默认配置校验规则
  const initLabelConfig = (formItem) => {
    formItem.rules = formItem.rules ?? []
    let obj = {
      label: formItem.label ?? formItem.name,
      required: formItem.isRequire,
      ...defaultConfig, // 默认配置
      ...labelType[formItem.storageType], // 标签类型
      defaultValue: formItem.defaultValue,
      regList: [...formItem.rules],
      span: formItem.span ?? 24,
      gutter: formItem.gutter ?? 16,
      storageType: formItem.storageType,
      id: formItem.id,
      responsive: formItem.responsive,
      append: formItem.append,
      prepend: formItem.prepend,
    }
    return obj
  }
  // 生成from表单数据 dataList:后台返回数据列表
  const initformConfig = (dataList, formOptions = {}) => {
    return {
      fields: dataList.map((v) => {
        return initFieldsConfig(v)
      }),
      labelWidth: 180, // 表单label默认宽度
      formRef: 'elForm',
      disabled: false,
      formRules: 'rules',
      'label-position': 'top',
      ...formOptions,
    }
  }
  return initformConfig(dataList, formOptions)
}

export const isUndef = (v) => v === undefined || v === null
export const isDef = (v) => v !== undefined && v !== null

export const downloadFile = (blob, name) => {
  const url = window.URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()

  setTimeout(() => {
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }, 100)
}

export function getStr(str) {
  return str?.replace(/^#/, '')
}

export default util
