<template>
    <div class="fixed-footer">
        <slot></slot>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/mixin.scss";
@import "~@/assets/styles/variables.scss";
.fixed-footer {
    position: fixed;
    bottom: 0;
    right: 0px;
    z-index: 9;
    padding: 16px;
    transition: width 0.28s;
    background-color: #fff;
    width: calc(100% - #{$base-sidebar-width} - 10px);
    box-shadow: 0px -3px 8px 0px rgba(202, 202, 202, 0.16);
}

.hideSidebar .fixed-footer {
    width: calc(100% - 74px);
}

.sidebarHide .fixed-footer {
    width: calc(100%);
}

.mobile .fixed-footer {
    width: 100%;
}
</style>