<template>
  <div>
    <Submit type="primary" :onSubmit="handleSubmit" :icon="submitIcon">
      {{ submitText }}
    </Submit>
    <Reset @resetValidateSuccess="handleReset" :icon="resetIcon">
      {{ resetText }}
    </Reset>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Submit, Reset } from '@/formily/index'
import { useForm } from '@formily/vue'

const Noop = () => {}

const isDef = (v) => v !== undefined && v !== null && v !== ''

export default defineComponent({
  name: 'Actions',
  components: {
    Submit,
    Reset,
  },
  props: {
    onSubmit: Function,
    onReset: Function,
    submitText: {
      type: String,
      default: '查询',
    },
    resetText: {
      type: String,
      default: '重置',
    },
    submitIcon: {
      type: String,
      default: 'el-icon-search',
    },
    resetIcon: {
      type: String,
      default: 'el-icon-refresh-right',
    },
  },
  setup(props, { listeners }) {
    const formRef = useForm()
    const form = formRef.value

    const {
      onSubmit = listeners?.submit ?? Noop,
      onReset = listeners?.reset ?? Noop,
    } = props

    const handleReset = () => onReset({})

    const handleSubmit = async (values) => {
      values = Object.keys(values).reduce((pre, key) => {
        const value = values[key]
        if (isDef(value)) {
          pre = {
            ...pre,
            [key]: value,
          }
        }
        return pre
      }, {})
      form.submitting = true
      await onSubmit(values)
      form.submitting = false
    }

    return {
      handleSubmit,
      handleReset,
    }
  },
})
</script>
