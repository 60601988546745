var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.classes }, [
    _c("div", { class: _vm.barConClasses }, _vm._m(0), 0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _vm._l(8, function (i) {
      return _c("i", { key: `trigger-${i}`, class: `${_vm.prefix}-bar` })
    })
  },
]
render._withStripped = true

export { render, staticRenderFns }