var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "materail-file" },
    [
      _c(
        "el-card",
        { staticClass: "file-tabs", attrs: { shadow: "never" } },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "小程序", name: "miniProgram" } },
                [
                  _c(
                    "div",
                    { staticClass: "search-view" },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请输入要搜索的小程序标题/AppID",
                          },
                          on: { change: _vm.getList },
                          model: {
                            value: _vm.queryParams.keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "keyword", $$v)
                            },
                            expression: "queryParams.keyword",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-input__icon el-icon-search",
                            staticStyle: { cursor: "pointer" },
                            attrs: { slot: "suffix" },
                            on: { click: _vm.getList },
                            slot: "suffix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-scrollbar",
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          staticClass: "file-content-view margin-tb-sm",
                          model: {
                            value: _vm.queryParams.checkList,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "checkList", $$v)
                            },
                            expression: "queryParams.checkList",
                          },
                        },
                        _vm._l(_vm.queryParams.tableData, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.appId,
                              staticClass:
                                "solid text-sm program-view radius-8 pointer padding-xs",
                              on: {
                                click: function ($event) {
                                  return _vm.selectMiniProgram(item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-center justify-between" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-center text-xs flex-sub",
                                      staticStyle: { "max-width": "90%" },
                                    },
                                    [
                                      !item.mpLogo
                                        ? _c("i", {
                                            staticClass:
                                              "icon-top-logo margin-right-xs",
                                          })
                                        : _c("el-image", {
                                            staticClass:
                                              "icon-top-logo margin-right-xs",
                                            attrs: { src: item.mpLogo },
                                          }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-cut",
                                          staticStyle: { "max-width": "95%" },
                                        },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("el-checkbox", { attrs: { label: item } }),
                                ],
                                1
                              ),
                              _c("div", {
                                staticClass: "text-cut2 margin-tb-xs",
                                staticStyle: { "line-height": "1.5" },
                              }),
                              _c("el-image", {
                                staticClass: "image",
                                attrs: { src: item.url, fit: "contain" },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-center text-xs solid-top margin-top-xs padding-top-xs",
                                  staticStyle: { "line-height": "1" },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "icon-bottom-logo margin-right-xs",
                                  }),
                                  _vm._v("小程序 "),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "padding-top-xs" },
            [
              _c("pagination", {
                attrs: {
                  total: _vm.queryParams.total,
                  pageSizes: [12, 24, 36, 48, 60],
                  page: _vm.queryParams.pageNo,
                  limit: _vm.queryParams.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNo", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("preview-video", { ref: "previewVideo", attrs: { url: _vm.playUrl } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }