<template>
  <div class="content conent-draggable">
    <el-scrollbar ref="scrollbar">
      <el-form :model="queryParams" class="fileList" @submit.native.prevent
        :style="{ 'max-height': `calc(100vh - ${isNotice ? '650' : '450'}px)` }" :rules="rules" ref="queryParams">
        <div class="item flex-center margin-bottom-sm" :class="[{ textView: isText(item) }]"
          v-for="(item, index) in queryParams.materialElements.filter(v => v.type != 8)" :key="index">
          <div class="content-view flex" style="width: 85%">
            <div class="padding-left-xs left-view">{{ formatFileType(item) }}</div>
            <el-form-item :ref="`formItem${index}`" v-if="isText(item)"
              :prop="`materialElements.${isNotice ? index + 1 : index}.contentBack`"
              :rules="[{ required: true, message: '请输入文本内容', trigger: ['blur', 'change'] }]">
              <!-- 文本 -->
              <tinymce :ref="`tinymce${index}`" v-if="showTinymce" @blur="$refs.queryParams.validate();"
                @getContent="tinymceInput(arguments, item, index)" :height="178" linmitNum="1000" textarea selectEmoji
                v-model="item.contentBack">
                <div class="clientInfo flex-center" v-if="showClientInfo && !isRule" slot="footer">
                  <client-params @command="clientInfoCommand(arguments, item, index)"></client-params>
                  <client-custom v-if="showClientCustom" :isSopShow="isSopShow" @command="clientInfoCommand(arguments, item, index)"></client-custom>
                </div>
              </tinymce>
            </el-form-item>
            <!-- 文件 -->
            <el-form-item v-if="isFile(item)" :prop="`materialElements.${isNotice ? index + 1 : index}.fileUrl`"
              :rules="[{ required: true, message: '请上传文件', trigger: ['blur', 'change'] }]">
              <el-upload :key="uploadKey" :disabled="isEditFile" :show-file-list="false" drag
                :class="[{ active: item.fileUrl, 'upload-demo flex-sub': true }]" :on-change="onChange"
                :action="upload.action" :http-request="uploadFile" :accept="item.accept" :headers="upload.headers"
                :on-success="onSuccess" :on-error="onError" :limit="1" :before-upload="beforeUpload">
                <div v-if="!item.fileUrl" @click="editMaterialData(item)">
                  <el-progress v-if="item.ext.process" :percentage="item.ext.process"></el-progress>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="margin-bottom-sm">支持上传文件为图片、视频、pdf、word、excel</div>
                </div>
                <div v-else @click="editMaterialData(item)">
                  <el-progress v-if="item.ext.process" :percentage="item.ext.process"></el-progress>
                  <el-image v-if="item.type == 1" fit="scale-down" :src="item.fileUrl" class="avatar" />
                  <div class="avatar solid" style="position: relative;" v-if="item.type == 2">
                    <video :src="item.fileUrl" class="avatar"></video>
                    <div class="icon-play"></div>
                  </div>
                  <div v-if="item.type > 2 && item.type < 6" class="content-text radius content-office">
                    <div class="flex-center">
                      <div class="img-icon margin-right-xs" :class="[item?.ext?.img]"></div>
                      <div>
                        <div>{{ item.fileName }}</div>
                        <div class="text-left margin-top-xs">{{ item.ext.size }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item v-if="item.type == 7" :prop="`materialElements.${isNotice ? index + 1 : index}.title`"
              :rules="[{ required: true, message: '请编辑产品笔记', trigger: ['blur', 'change'] }]">
              <!-- 产品笔记 -->
              <div class="content-text radius flex justify-between pointer padding-sm" @click="editMaterialData(item)"
                style="width: 100%">
                <div style="max-width: 80%;">
                  <div class="text-cut text-bold" style="max-width: 500px;">
                    {{ item.title }}
                  </div>
                  <div class="text-cut2 margin-top-xs">{{ item.description }}</div>
                </div>
                <el-image style="width:92px;height:92px;" :src="item.url"></el-image>
              </div>
            </el-form-item>
            <el-form-item v-if="item.type == 10" :prop="`materialElements.${isNotice ? index + 1 : index}.title`"
              :rules="[{ required: true, message: '请选择小程序', trigger: ['blur', 'change'] }]">
              <!-- 小程序 -->
              <div class="solid program-view bg-white radius-8 pointer padding-xs" @click="editMaterialData(item)">
                <div class="flex-center">
                  <i v-if="!item.mpLogo" class="icon-top-logo margin-right-xs"></i>
                  <el-image v-else :src="item.mpLogo" class="icon-top-logo margin-right-xs"></el-image>
                  <div class="text-cut" style="max-width: 90%;">{{ item.title }}</div>
                </div>
                <div class="text-cut2" style="line-height: 1.5;margin-bottom: 5px;">

                </div>
                <el-image :src="item.url" fit="contain" class="image"></el-image>
                <div class="flex-center solid-top margin-top-xs padding-top-xs" style="line-height: 1;">
                  <i class="icon-bottom-logo margin-right-xs"></i>小程序
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="bottom-btns margin-lr-sm">
            <el-tooltip content="上移一层" popper-class="popper-class" placement="top">
              <el-button @click="upClick(item)" :disabled="index === 0 || disabled" class="move-class">
                <i class="el-icon-up"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip content="下移一层" popper-class="popper-class" placement="top">
              <el-button @click="downClick(item)"
                :disabled="index === (queryParams.materialElements.length - 1) || disabled" class="move-class">
                <i class="el-icon-down"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip content="删除" popper-class="popper-class" placement="top">
              <el-button @click="deleteItem(item)" :disabled="disabled" class="move-class">
                <i class="el-icon-delete text-red text-xl text-bold"></i>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="flex dropdown align-center justify-between padding-bottom" style="width: 280px;"
          :style="{ 'margin-left': queryParams.materialElements.filter(v => v.type != 8).length > 0 ? '100px' : '' }">
          <el-dropdown :disabled="$route.query && $route.query.type == 'visible' || disabled" placement="right-start"
            trigger="click" @command="handleCommand">
            <el-button type="primary" style="width: 132px;">添加</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="dropdown-item"
                v-for="(item, index) in materialTypeList.filter(v => isRule ? v.type < 7 : v)" :key="index"
                :command="item.type">
                <div class="flex-center padding-left-sm">{{ item.name }}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button v-if="!isNewAdd && !isRule" :disabled="disabled" @click="openMaterialDialog"
            style="width: 132px;">引用素材库</el-button>
        </div>
      </el-form>
    </el-scrollbar>
    <!-- 产品笔记 -->
    <el-dialog v-if="dialogVisible" title="产品笔记" top="9vh" custom-class="tinymce-dialog" :visible.sync="dialogVisible"
      width="760px" :before-close="handleClose" :close-on-click-modal="false" append-to-body>
      <tinymce ref="tinymce" placeholder="请输入产品笔记内容…" :height="580" :id="id" v-model="contentBack"></tinymce>
      <span slot="footer" class="dialog-footer">
        <el-button style="width: 70px;" @click="handleClose">取消</el-button>
        <el-button style="width: 70px;" type="primary" @click="configHClick">保存</el-button>
      </span>
    </el-dialog>
    <choose-mini-program @setMiniProgram="setMiniProgram" ref="chooseMiniProgram"></choose-mini-program>
  </div>
</template>

<script>
import { getBaseHeader } from "@/utils/request";
import { createHFive } from "@/api/robot/robot.js";
import { uploadFile } from "@/api/index.js";
import { v4 as uuidv4 } from "uuid"
import _ from "lodash";
import SelectEmoji from '../../../components/selectEmoji/selectEmoji.vue';
import ClientParams from '../../components/clientParams/clientParams.vue';
import ChooseMiniProgram from '../../../components/chooseMiniProgram/chooseMiniProgram.vue';
import ClientCustom from '../../components/clientCustom/clientCustom.vue';

export default {
  components: { SelectEmoji, ClientParams, ChooseMiniProgram, ClientCustom },
  props: {
    imported: {
      type: [Number, String],
      default: 1,
    },
    isRule: {
      type: Boolean,
      default: false,
    },
    isSopShow:{
      type: Boolean,
      default: false,
    },
    isNewAdd: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: [Boolean, String],
      default: false,
    },
    queryParams: {},
    hideCustom:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dialogVisible: false,
      showClientInfo: true,
      contentBack: "",
      id: "1000",
      upload: {
        // 设置上传的请求头部
        headers: getBaseHeader(),
        action: process.env.VUE_APP_BASE_API + "/admin-api/infra/file/upload", // 请求地址
        path: ""
      },
      materialTypeList: [
        { type: "0", name: "文本", icon: "icon-text" },
        { type: "1", name: "文件", icon: "icon-image" },
        { type: "7", name: "产品笔记", icon: "", img: "" },
        { type: "10", name: "小程序", icon: "", img: "" }
      ],
      rules: {},
      currentData: {}, // 当前数据
      currentType: "", // 当前文件类型
      currentFile: {}, // 当前文件
      uploadKey: "",
      loading: null,
      showPopover: false,
      process: "",
      key: "",
      showTinymce: true
    };
  },
  computed: {
    isNotice() {
      return this.$route.query.sendType == 3; // 群公告
    },
    isEditFile() {
      return this.$route?.query?.visibleStatus == 2 || this.disabled // 编辑限制
    },
    showClientCustom(){
      return this.$route?.query?.sendType == 1 && !this.hideCustom;
    }
  },
  methods: {
    handleClose() {
      this.$confirm('关闭后所有新建内容将会清空，确定关闭吗？', '提示!', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.dialogVisible = false;
      })
    },
    configHClick() {
      let tinymceObj = this.$refs.tinymce.getParams();
      createHFive({ ...tinymceObj, content: this.contentBack }).then((res) => {
        this.dialogVisible = false;
        let obj = {
          ...tinymceObj,
          url: tinymceObj.logo,
          path: process.env.VUE_APP_H + res.data, // url
          contentBack: this.contentBack,
          ext: { process: 0 },
          id: uuidv4(),
          type: 7
        };
        this.currentType = 7;
        this.onSuccess(obj);
      });
    },
    // 保存小程序
    setMiniProgram(data) {
      data.id = data.id ? data.id : uuidv4();
      data.mpLogo = data.mpLogo ? data.mpLogo : 'https://oss.isuiyou.cn/162a06ac42441132c166b031a8282528f5bb69dc89d17fb82ba5745b68d894c3.png';
      const index = this.queryParams.materialElements.findIndex(v => v.id == data?.id);
      if (index == -1) {
        this.queryParams.materialElements.push(data);
      } else {
        this.queryParams.materialElements.map(v => {
          if (v.id == data.id) {
            v = Object.assign(v, data);
          }
        })
      }
    },
    handleCommand(e) {
      this.currentData = {};
      if (e == "7") { // 创建产品笔记
        this.dialogVisible = true;
        this.contentBack = "";
        return;
      }
      if (e == "10") { // 选择小程序
        this.$refs.chooseMiniProgram.open();
        return;
      }
      this.queryParams.materialElements.push({
        currentType: e,
        contentBack: "",
        fileUrl: "",
        type: e != 1 ? e : '',
        ext: { process: 0 },
        text: "",
        id: uuidv4()
      });
      this.$emit('valueRules')
      this.currentData = this.queryParams.materialElements.at(-1); // 点击新建,默认当前数据是最后一条
    },
    // 选择客户信息
    clientInfoCommand([command], item, index) {
      this.$refs[`tinymce${index}`][0].insertContent(command.name, command.id, command.paramValueId);
    },
    deleteItem(item) {
      // const index = this.queryParams.materialElements.findIndex(v => v.id != item.id)
      // this.$refs[`tinymce${index}`][0].setTinymce(1);
      this.$confirm('确定要删除此内容吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.queryParams.materialElements = this.queryParams.materialElements.filter(v => v.id != item.id);
        this.currentData = {};
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    editMaterialData(item) {
      if (this.$route.query.visible == 3 || this.disabled) return;
      this.currentData = item;
      if (item.type == 7) {
        this.contentBack = item.contentBack;
        this.dialogVisible = true;
      }
      if (item.type == 10) {
        this.$refs.chooseMiniProgram.open();
        this.$refs.chooseMiniProgram.getList();
        this.$refs.chooseMiniProgram.isAdd = false;
        this.$refs.chooseMiniProgram.setCurrentMiniProgram(item);
      }
    },
    onChange(file, item) {
      this.currentFile = file;
      this.upload.path = file.name;
    },
    onSuccess(res) {
      const currentId = _.cloneDeep(this.currentData).id;
      const data = res?.type != 7 ? { ...this.$util.getMaterialInfo(this.currentFile), size: this.$util.formatFileSize(this.currentFile?.size), process: 0 } : { process: 0 };
      const fileData = { fileUrl: res.data, fileName: this.currentFile?.name, ext: data, type: data?.type, content: data.content ? data.content : res.data };
      const addData = res.contentBack ? res : fileData;
      if (currentId) {
        this.queryParams.materialElements.map((v) => {
          if (v?.id == currentId) {
            v = Object.assign(v, addData);
          }
        });
      } else {
        this.queryParams.materialElements.push({
          type: this.currentType,
          ...addData
        });
      }
      this.$refs.queryParams.validate();
      this.loading && this.loading.close();
      this.currentType != 7 && this.$message.success("上传成功");
      this.currentType = "";
      this.process = "";
      this.currentData = {};
      this.uploadKey = new Date().getTime();
    },
    onError() {
      this.$message.error("上传失败");
      this.loading.close();
    },
    // 引用自动采集素材
    openMaterialDialog() {
      this.$emit("openMaterialDialog");
    },
    // 上传文件之前
    beforeUpload(file) {
      this.upload.path = file.name;
      this.loading = this.$loading({
        lock: true,
        text: "上传中,请稍候",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
      });
      let fileType = file.type;
      let fileSuffix = file.name.split(".").at(-1);
      const fileTypeList = ["image", "video", "application/pdf"];
      const wordTypeList = ["xls", "xlsx", "doc", "docx"];
      if (fileTypeList.findIndex(v => fileType.indexOf(v) != -1) == -1 && wordTypeList.indexOf(fileSuffix) == -1) {
        this.$message.error("请上传正确的文件类型");
        this.loading.close();
        return false;
      }
      // 图片大小大于2M进行图片压缩
      if (file.size / 1024 / 1024 > 10) {
        this.$message.error("文件大小不能超过10M");
        this.loading.close();
        return false;
      } else {
        const imageTypeList = ["image/jpeg", "image/png"];
        if (
          imageTypeList.indexOf(file.type) == -1 &&
          file.type.indexOf("image/") != -1
        ) {
          this.$message.error("只能上传jpg/png格式图片");
          this.loading.close();
          return false;
        }
        return true;
      }
    },
    uploadFile(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("path", `scrm/${uuidv4()}/${file.file.name}`);
      uploadFile(formData, (e) => {
        this.queryParams.materialElements.map(v => {
          if (v?.id == this.currentData?.id) {
            v.ext.process = Number(((e.loaded / e.total) * 100).toFixed(2)) || 0;
          }
        })
      }).then((res) => {
        this.onSuccess(res);
      }).catch((e) => {
        this.loading.close();
      })
    },
    formatFileType(item) {
      const arr = ["文本", "图片", "视频", "文档", "文档", "文档", "", "产品笔记", "群公告", "语音", "小程序"];
      const obj = { text: "文本", image: "图片", video: "视频", application: '文档' };
      const fileType = item.ext?.fileType && item.ext?.fileType.split("/")[0];
      const text = item.currentType == 1 ? '文件' : arr[item.type];
      return item.ext?.fileType ? obj[fileType] : text;
    },
    //上移
    upClick(item) {
      const index = this.queryParams.materialElements.findIndex(v => v.id == item.id);
      if (index != -1) {
        this.$set(this.queryParams.materialElements, index, this.queryParams.materialElements.splice(index - 1, 1, this.queryParams.materialElements[index])[0])
      }
      setTimeout(() => {
        this.$refs[`tinymce${index - 1}`][0].setPlaceholder();
      }, 200)
    },
    //下移
    downClick(item) {
      const index = this.queryParams.materialElements.findIndex(v => v.id == item.id);
      if (index != -1) {
        this.$set(this.queryParams.materialElements, index, this.queryParams.materialElements.splice(index + 1, 1, this.queryParams.materialElements[index])[0])
      }
      setTimeout(() => {
        this.$refs[`tinymce${index + 1}`][0].setPlaceholder();
      }, 200)
    },
    // 文本消息获取id/纯文字
    tinymceInput([data], item) {
      item.text = data.str;
      item.content = data.str;
      item.materialParams = data.ids;
    },
    //判断文字类型消息
    isText(item) {
      const currentType = item.currentType ? item.currentType : item.type;
      return currentType === 0 || (item.type && Number(item.type) === 0);
    },
    //判断文件类型消息
    isFile(item) {
      return item.currentType == 1 || (item.type > 0 && item.type < 6)
    },
    resetEditor() {
      this.queryParams.materialElements.map(v => {
        if (v.type == 0 && v.ext) {
          v.ext.process = 0;
        }
      })
    },
  },
  activated() {
    this.showTinymce = true;
  },
  deactivated() {
    this.showTinymce = false;
  }
};
</script>

<style lang="scss" scoped>
.content ::v-deep {
  // max-height: 54vh;
  // overflow: auto;
  border-radius: 4px;

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 20px;
  }

  .delete {
    height: 22px;
  }

  .popper__arrow {
    top: 150px !important;
  }
}

.scroll-container {
  max-height: 60vh;
}

/*被拖拽对象的样式*/
.item {
  align-items: stretch;
}


.upload-demo ::v-deep {

  .el-upload,
  .el-upload-dragger {
    width: 100% !important;
    height: auto;
  }

  &.active {

    .el-upload,
    .el-upload-dragger {
      border: 0 none;
      background: transparent;
    }
  }

  .el-upload-dragger .el-icon-upload {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 32px;
    height: 24px;
    line-height: 24px;
  }

  img {
    vertical-align: top;
  }
}

.bottom-btns {
  display: flex;
  justify-content: space-between;
  width: 15%;
  max-width: 120px;
  align-items: flex-start;


  .el-button {
    margin: 0 5px;
  }

  .el-button {
    padding: 5px;
    border: 0 none;
    background-color: transparent;

    &:hover,
    &:focus,
    &active {
      background-color: #e0e5f7;
    }
  }
}

.content-text ::v-deep {
  white-space: break-spaces;
  word-break: break-all;
  width: 100%;
  background-color: #F6F6FA;
  line-height: 1.2;

  .el-scrollbar__view {
    max-height: 60px;
  }
}

.content-view ::v-deep {
  .el-form-item {
    flex: 1;
  }

  .textarea-editor,
  .el-textarea__inner {
    height: 120px;
    border-radius: 2px;
  }

  .el-textarea__inner {
    padding-top: 10px;
    padding-left: 10px;
    font-size: 14px;
  }
}


.icon-text {
  background: url("../../../assets/images/text.png") center center no-repeat;
  background-size: contain;
}

.icon-image {
  background: url("../../../assets/images/image.png") center center no-repeat;
  background-size: contain;
}

.icon-video {
  background: url("../../../assets/images/video.png") center center no-repeat;
  background-size: contain;
}

.icon-word {
  background: url("../../../assets/images/word.png") center center no-repeat;
  background-size: contain;
}

.icon-excel {
  background: url("../../../assets/images/excel.png") center center no-repeat;
  background-size: contain;
}

.icon-pdf {
  background: url("../../../assets/images/pdf.png") center center no-repeat;
  background-size: contain;
}

.icon-play {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  background: url(../../../assets/images/play.png) center center no-repeat;
  background-size: contain;
}

.content-office {
  display: inline-block;
  min-width: 400px;
  padding: 15px;
}

.img-icon {
  width: 27px;
  height: 32px;
}

.img-word {
  background: url("../../../assets/images/img-word.png") center center no-repeat;
  background-size: contain;
}

.img-excel {
  background: url("../../../assets/images/img-excel.png") center center no-repeat;
  background-size: contain;
}

.img-pdf {
  background: url("../../../assets/images/img-pdf.png") center center no-repeat;
  background-size: contain;
}


.disabled {
  color: #999;
  cursor: no-drop;
}

.context-text-line {
  line-height: normal;
}

.el-icon-up {
  width: 18px;
  height: 17px;
  margin: 1px;
  background: url(../../../assets/images/up.png) center center no-repeat;
  background-size: contain;
}

.el-icon-down {
  width: 18px;
  height: 17px;
  margin: 1px;
  background: url(../../../assets/images/down.png) center center no-repeat;
  background-size: contain;
}

.left-view {
  min-width: 100px;
  line-height: 1;
}

.avatar {
  width: 160px;
  height: 160px;
  display: block;
}

.clientInfo ::v-deep {
  padding: 0px 16px;
  border: 0.5px solid #C0CCDA;
  background: #F5F8FD;
  border-top: 0 none;
  border-radius: 0 0 4px 4px;

  .el-button {
    color: #778CA2;
  }

  .el-icon-plus {
    font-size: 16px;
    vertical-align: text-bottom;
  }
}

.icon-view {
  display: inline-flex;
  width: 46px;
  height: 32px;
  border: 0.5px solid #C0CCDA;
  border-radius: 2px;
  margin-bottom: 3px;
}

.icon {
  display: inline-block;
  width: 18px;
  height: 18px;
}

.icon-emoji {
  background: url(../../../assets/images/emoji.png) center center no-repeat;
  background-size: contain;
}

.editor-view {
  height: 207px;
}

.program-view {
  width: 240px;

  // height: 316px;
  .margin-right-xs {
    margin-right: 5px;
  }

  .image {
    width: 220px;
    height: 176px;
    vertical-align: top;
  }

  .icon-top-logo {
    width: 20px;
    height: 20px;
    min-width: 20px;
    background: url(https://oss.isuiyou.cn/162a06ac42441132c166b031a8282528f5bb69dc89d17fb82ba5745b68d894c3.png) center center no-repeat;
    background-size: contain;
    border-radius: 50%;
  }

  .icon-bottom-logo {
    width: 12px;
    height: 12px;
    background: url(https://oss.isuiyou.cn/570de39153493035369ea04f58ab79c1c848f95c376cab6557e8efff6b98d617.png) center center no-repeat;
    background-size: contain;
  }
}
</style>
