<template>
  <div>
    <el-dialog :title="title" width="555px" :modal-append-to-body="false" append-to-body class="dialog-box"
            :visible.sync="dialogVisible" @close="handleClose">
      <div v-if="stageType == 100 || stageType == 200">
        <el-input @enter="search" class="criteria-input" @change="search" ref="form" v-model.trim="name"
          placeholder="请输入参数名称" />
        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
        <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        <div class="criteria-content">
            <el-checkbox-group v-if="multiple" :max="maxlength - selectCriteriaObjList.length"
                v-model="selectCriteriaObjList">
                <el-checkbox :label="item.id" class="tags-item" v-for="(item, index) in criteriaList"
                    :key="index" border>
                    {{ item.name }}
                </el-checkbox>
            </el-checkbox-group>
            <el-radio-group v-else v-model="selectCriteriaObj" size="small">
              <el-radio :label="item.id" class="tags-item" v-for="(item, index) in criteriaList"
              :key="index" border>{{item.name}}</el-radio>
            </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="flex justify-end">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="determineClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getDynamicAll } from "@/api/custom"
  import _ from "lodash"
  export default {
    props:{
      title: { // 弹框标题
        type: String,
        default: ""
      },
      maxlength:{ // multiple为true时,最大选择数量
        type: Number,
        default: 10,
      },
      taskInfo: { // 是否sop
        type: Boolean,
        default: false
      },
      stageType:{ // 节点类型
        type:Number 
      },
      multiple:{ // 是否多选
        type: Boolean,
        default: false
      },
      paramsType:{ // 1: 动态 2: 全部
        type:[String,Number],
        default: 1
      },
      dataModule: { // 1: 客户，2: 客户群
        type: [String,Number],
        default: 1
      }
    },
    data() {
      return {
        name:"",
        dialogVisible: false,
        criteriaList: [],//动态自定义参数列表
        selectCriteriaObjList: [], // 已选择动态自定义参数列表
        selectCriteriaObj: null,//单选已选择数据
      }
    },
    methods: {
      //打开选择弹框
      open(){
        this.dialogVisible = true;
        this.getCustomList();
      },
      // 重置查询条件
      reset(){
        this.name = "";
        this.search();
      },
      // 关闭弹框回调
      handleClose(){
        this.reset();
        this.selectCriteriaObj = null;
      },
      //点击查询
      search(){
        this.getCustomList();
      },
      // 获取自定义参数列表
      getCustomList() {
        getDynamicAll({
          paramsType: this.paramsType, // 1: 动态 2: 全部
          dataModule: this.dataModule, // 1: 客户，2: 客户群
          name: this.name,
          isMessageReference: 1 // 客户群发/客户sop发消息 添加自定义参数
        }).then(res => {
          res.data.map(v => {
            const id = ['staticParamId', 'dynamicParamId'][v.paramsType];
            v.dynamicParam = v.paramsType == 1;
            v[id] = v.id;
            v.paramType = v.type;
            v.paramName = v.name;
          })
          this.criteriaList = res.data;
        })
      },
      // id数组转对象数组
      formatSelectList(idList){
        return _.filter(this.criteriaList, function(v) { return idList.includes(v.id) });
      },
      // 点击确定按钮
      determineClick(){
        const idList = this.multiple ? this.selectCriteriaObjList : [this.selectCriteriaObj];
        if(idList.length == 0 || !idList[0]){
          this.$message.error("请选择参数");
          return;
        }
        const objList = this.formatSelectList(idList); // 已选择对象数组
        objList.map(v=>{
          this.$emit("command", v);
        })
        this.dialogVisible = false;
      }
    },
  }
</script>

<style lang="scss" scoped>
.dialog-box ::v-deep {
    .tags-item {
        background: #F9F9F9;
        border-color: #F9F9F9;

        &.is-checked {
            background: rgba(48, 126, 242, 0.1);
            border: 1px solid #307EF2;
        }

        &.is-disabled {
            // background: rgba(48, 126, 242, 0.1);
            border: 1px solid transparent;
        }
    }

    .criteria-input {
        width: 180px;
        margin-right: 20px;
    }

    .criteria-content {
        margin-top: 16px;
        height: 250px;
        overflow: auto;

        .el-checkbox__input,.el-radio__input {
            display: none;
        }
        
        .el-checkbox__label,.el-radio__label {
            display: inline-block;
            line-height: 22px;
            min-width: 53px;
            padding: 0;
            text-align: center;
        }

        .el-checkbox-group,.el-radio-group {
            padding: 16px 0;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }

        .el-checkbox,.el-radio {
            margin: 0;
            padding: 5px 10px;
        }
    }


}
</style>