import request from "@/utils/request";
// ----------------列表----------------------
// 获取SOP分组
export function getSopPage(params) {
  return request({
    url: "/sop/sopGroupConfig/page",
    method: "get",
    params,
  });
}

// sop分组详情
export function getSopGroupConfigDetail(params) {
  return request({
    url: "/sop/sopGroupConfig/getSopGroupConfigDetail",
    method: "get",
    params,
  });
}

// sop删除分组
export function postSopGroupDelete(data) {
  return request({
    url: "/sop/sopGroupConfig/delete",
    method: "POST",
    data,
  });
}
// sop分组启用
// 
export function postSopGroupEnable(data) {
  return request({
    url: "/sop/sopGroupConfig/enable",
    method: "POST",
    data,
  });
}

// sop分组禁用

export function postSopGroupDisable(data) {
  return request({
    url: "/sop/sopGroupConfig/disable",
    method: "POST",
    data,
  });
}

// sop分组启用/禁用
// export function postSopGroupUpdateStatus(data) {
//   return request({
//     url: "/sop/sopGroupConfig/updateStatus",
//     method: "POST",
//     data,
//   });
// }
// sop分组复制
export function postSopGroupConfigCopy(data) {
  return request({
    url: "/sop/sopGroupConfig/copy",
    method: "POST",
    data,
    timeout: 60000
  });
}

// -------------------sop-分组-------------------
// 发布
export function postSopStageRelease(data) {
  return request({
    url: "/sop/sopGroupConfig/release",
    method: "POST",
    data,
  });
}
// sop分组-阶段-信息-新增
export function postSopStageCreate(data) {
  return request({
    url: "/sop/sopStage/create",
    method: "POST",
    data,
  });
}
// sop分组-阶段-信息-编辑
export function postSopSageEdit(data) {
  return request({
    url: "/sop/sopStage/edit",
    method: "POST",
    data,
  });
}

// sop分组-阶段-任务-新增
export function postSopStateAddTask(data) {
  return request({
    url: "/sop/sopStage/addTask",
    method: "POST",
    data,
  });
}

// sop分组-列表
export function getSopStageList(params) {
  return request({
    url: "/sop/sopStage/getSopStageList",
    method: "get",
    params,
  });
}

// sop分组-创建
export function postSopGroupCreate(data) {
  return request({
    url: "/sop/sopGroupConfig/create",
    method: "POST",
    data,
  });
}
// sop分组-编辑
export function postSopGroupEdit(data) {
  return request({
    url: "/sop/sopGroupConfig/edit",
    method: "POST",
    data,
  });
}
// 查询SOP分组阶段默认任务列表
export function getSopStageDefaultTask(params) {
  return request({
    url: "/sop/sopStage/getSopStageDefaultTask",
    method: "get",
    params,
  });
}

// 客户列表分页(根据静态参数tag)

export function getCustomerTagPage(params) {
  return request({
    url: "/wechat/customer/page",
    method: "get",
    params,
  });
}
// 客户群列表(tag)
export function getGroupListByTag(params) {
  return request({
    url: "/wechat/customer/groupListByTag",
    method: "get",
    params,
  });
}

// sop分组-阶段-复制

export function postSopStageCopy(data) {
  return request({
    url: "/sop/sopStage/copy",
    method: "POST",
    data,
  });
}

// sop分组-阶段-排序

export function postSopStageMoveIndexSort(data) {
  return request({
    url: "/sop/sopStage/moveIndexSort",
    method: "POST",
    data,
  });
}
// 删除SOP分组阶段
export function postSopStageDelete(data) {
  return request({
    url: "/sop/sopStage/delete",
    method: "POST",
    data,
  });
}
// 查询SOP分组阶段详情
export function getSopStageDetail(params) {
  return request({
    url: "/sop/sopStage/detail",
    method: "get",
    params,
  });
}

// ---------------------sop-阶段-任务------------------------
// 新增SOP分组阶段任务
export function postSopTaskNodeCreate(data) {
  return request({
    url: "/sop/sopTaskNode/create",
    method: "POST",
    data,
  });
}
// 插入SOP分组阶段任务
export function postSopTaskNodeInsert(data) {
  return request({
    url: "/sop/sopTaskNode/insert",
    method: "POST",
    data,
  });
}
// 粘贴SOP分组阶段任务
export function postSopTaskNodePaste(data) {
  return request({
    url: "/sop/sopTaskNode/paste",
    method: "POST",
    data,
  });
}

// 编辑SOP分组任务阶段
export function postSopTaskNodeEdit(data) {
  return request({
    url: "/sop/sopTaskNode/edit",
    method: "POST",
    data,
  });
}

// 删除SOP分组阶段任务
export function postSopTaskNodeDelete(data) {
  return request({
    url: "/sop/sopTaskNode/delete",
    method: "POST",
    data,
  });
}
// 删除SOP分组阶段任务
export function postSopTaskNodeDeleteAndSon(data) {
  return request({
    url: "/sop/sopTaskNode/deleteAndSon",
    method: "POST",
    data,
  });
}
// 查询SOP分组阶段任务列表
export function getSopTaskList(params) {
  return request({
    url: "/sop/sopTaskNode/getSopTaskList",
    method: "get",
    params,
  });
}
// 查询SOP分组阶段任务详情

export function getSopTaskNodeDetail(params) {
  return request({
    url: "/sop/sopTaskNode/detail",
    method: "get",
    params,
  });
}

// ---------------------sop-执行-任务------------------------
//获取SOP任务节点执行记录
export function getSopPerformTaskPage(params) {
  return request({
    url: "/sop/sopTaskNodeExecuteRecord/page",
    method: "get",
    params,
  });
}

// 暂停.

export function getSopPerformTaskStop(params) {
  return request({
    url: "/sop/sopTaskNodeExecuteRecord/stop",
    method: "get",
    params,
  });
}

// 开始

export function getSopPerformTaskStart(params) {
  return request({
    url: "/sop/sopTaskNodeExecuteRecord/start",
    method: "get",
    params,
  });
}

// 发送记录

export function getSendRecordPage(params) {
  return request({
    url: "/sop/sopTaskNodeExecuteRecord/sendRecordPage",
    method: "get",
    params,
  });
}

// 获得自定义参数关联关系分页
export function getParamValuePage(params) {
  return request({
    url: "/wechat/param-value/page",
    method: "get",
    params,
  });
}
// SOP设置AI员工
export function setStaffAccount(data) {
  return request({
    url: "/sop/sopGroupConfig/setStaffAccount",
    method: "POST",
    data,
  });
}


