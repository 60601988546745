var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      [
        _vm.type == "2"
          ? _c("svg-icon", { attrs: { "icon-class": "Enterprise" } })
          : _c("img", {
              class: _vm.size == "mini" ? "image" : "large",
              staticStyle: { "vertical-align": "middle" },
              attrs: {
                src: require("../../assets/images/wechathollow.png"),
                alt: "",
              },
            }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }