var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sop-add" },
    [
      _c(
        "el-drawer",
        {
          staticClass: "sop-add-drawer",
          attrs: {
            title: "任务节点配置",
            visible: _vm.sopDrawer,
            "modal-append-to-body": false,
            "append-to-body": "",
            wrapperClosable: false,
            size: 880,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.sopDrawer = $event
            },
          },
        },
        [
          _c("div", { staticClass: "line" }),
          _c(
            "div",
            { staticClass: "top-step margin-top-sm" },
            [
              _c(
                "el-steps",
                {
                  staticClass: "padding-tb-sm sop-step",
                  attrs: { active: _vm.activeTab },
                },
                _vm._l(_vm.stepList, function (item, index) {
                  return _c("el-step", { key: index }, [
                    _c(
                      "div",
                      {
                        staticClass: "step-item",
                        attrs: { slot: "icon" },
                        slot: "icon",
                      },
                      [
                        _c("div", { staticClass: "icon-circle" }, [
                          _vm._v(_vm._s(index + 1)),
                        ]),
                        _c(
                          "span",
                          { staticClass: "text-black padding-left-xs" },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeTab == 1,
                  expression: "activeTab == 1",
                },
              ],
              staticClass: "stage-settings-frists",
            },
            [
              _c(
                "el-form",
                {
                  ref: "stageFormFrist",
                  attrs: {
                    disabled: _vm.visible || _vm.visibleStatus,
                    rules: _vm.rules,
                    model: _vm.sopTaskNodeReqVO,
                    "label-width": "100px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "节点名称", prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "width380",
                        attrs: { placeholder: `请输入`, maxlength: "20" },
                        model: {
                          value: _vm.sopTaskNodeReqVO.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.sopTaskNodeReqVO,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "sopTaskNodeReqVO.name",
                        },
                      }),
                      _c("span", { staticClass: "limit-text" }, [
                        _vm._v(
                          _vm._s(_vm.sopTaskNodeReqVO.name?.length) + "/20"
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "用户满足条件" } }),
                  _c("div", { staticClass: "stage-tip" }, [
                    _c("i", { staticClass: "el-icon-warning" }),
                    _vm._v(
                      " 主要用于执行任务的触发的条件，满足条件才可进入群发,转人工的动作 "
                    ),
                  ]),
                  _vm.$route.query.isTask == 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "收集情况", prop: "selectCondition" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sopTaskNodeReqVO.selectCondition,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.sopTaskNodeReqVO,
                                    "selectCondition",
                                    $$v
                                  )
                                },
                                expression: "sopTaskNodeReqVO.selectCondition",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: 104 } },
                                [
                                  _vm._v(" 信息收集成功"),
                                  _c(
                                    "question-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _vm._v(
                                            " 指需要收集的客户信息已全部补全"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " （不区分收集前有还是收集后有） "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-radio",
                                { attrs: { label: 105 } },
                                [
                                  _vm._v(" 信息收集失败"),
                                  _c(
                                    "question-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [_vm._v("指需要收集的客户信息未补全")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("el-radio", { attrs: { label: 103 } }, [
                                _vm._v(" 客户不回复 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-form-item",
                        {
                          attrs: { label: "回复情况", prop: "selectCondition" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.checkSelectCondition },
                              model: {
                                value: _vm.sopTaskNodeReqVO.selectCondition,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.sopTaskNodeReqVO,
                                    "selectCondition",
                                    $$v
                                  )
                                },
                                expression: "sopTaskNodeReqVO.selectCondition",
                              },
                            },
                            [
                              _vm.sopType == "clientSOP"
                                ? [
                                    _c("el-radio", { attrs: { label: 103 } }, [
                                      _vm._v("客户不回复"),
                                    ]),
                                    _c("el-radio", { attrs: { label: 102 } }, [
                                      _vm._v("客户回复"),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.sopType == "clientGroupSOP"
                                ? [
                                    _c("el-radio", { attrs: { label: 202 } }, [
                                      _vm._v("客户群回复"),
                                    ]),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                  _c(
                    "div",
                    _vm._l(
                      _vm.sopTaskNodeReqVO.sopConditionReqVOs,
                      function (v, i) {
                        return _c(
                          "div",
                          { key: i },
                          _vm._l(v[_vm.customerReplyList], function (m, n) {
                            return _c(
                              "div",
                              { key: n },
                              [
                                _vm.sopTaskNodeReqVO.selectCondition == 103 ||
                                _vm.sopTaskNodeReqVO.selectCondition == 203
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: `sopConditionReqVOs.${i}.${_vm.customerReplyList}.${n}.minute`,
                                          rules: [
                                            {
                                              required: true,
                                              message: "请输入",
                                              trigger: "blur",
                                            },
                                            {
                                              type: "integer",
                                              min: 10,
                                              message: "不能小于10分钟",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c("span", [_vm._v("超过")]),
                                        _c("el-input", {
                                          staticStyle: {
                                            width: "100px",
                                            margin: "0 4px",
                                          },
                                          attrs: {
                                            type: "number",
                                            step: "10",
                                            oninput:
                                              "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                                          },
                                          model: {
                                            value: m.minute,
                                            callback: function ($$v) {
                                              _vm.$set(m, "minute", _vm._n($$v))
                                            },
                                            expression: "m.minute",
                                          },
                                        }),
                                        _vm._v(" 分钟，执行该动作 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.sopTaskNodeReqVO.selectCondition == 105
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-center justify-center",
                                      },
                                      [
                                        _vm._v(" 超过 "),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: { margin: "0" },
                                            attrs: {
                                              "label-width": "0",
                                              prop: `sopConditionReqVOs.${i}.${_vm.customerReplyList}.${n}.num`,
                                              rules: [
                                                {
                                                  required: true,
                                                  validator: _vm.validTime,
                                                  trigger: "blur",
                                                  m,
                                                },
                                              ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: {
                                                width: "110px",
                                                margin: "0 4px",
                                              },
                                              attrs: {
                                                type: "number",
                                                step: "10",
                                                max: "60",
                                                oninput:
                                                  "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                                              },
                                              model: {
                                                value: m.num,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    m,
                                                    "num",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "m.num",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: { margin: "0" },
                                            attrs: {
                                              "label-width": "0",
                                              prop: `sopConditionReqVOs.${i}.${_vm.customerReplyList}.${n}.type`,
                                              rules: [
                                                {
                                                  required: true,
                                                  message: "请选择",
                                                  trigger: "blur",
                                                },
                                              ],
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: {
                                                  width: "110px",
                                                  margin: "0 4px",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.$refs.stageFormFrist.validateField(
                                                      `sopConditionReqVOs.${i}.${_vm.customerReplyList}.${n}.num`
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: m.type,
                                                  callback: function ($$v) {
                                                    _vm.$set(m, "type", $$v)
                                                  },
                                                  expression: "m.type",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "分钟",
                                                    value: 2,
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "小时",
                                                    value: 1,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" ，执行该动作 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.sopTaskNodeReqVO.selectCondition == 102 ||
                                _vm.sopTaskNodeReqVO.selectCondition == 202
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "回复内容",
                                          rules: [
                                            {
                                              required: true,
                                              message: "请选择",
                                              trigger: ["blur", "change"],
                                            },
                                          ],
                                          prop: `sopConditionReqVOs.${i}.${_vm.customerReplyList}.${n}.anyContent`,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value: m.anyContent,
                                              callback: function ($$v) {
                                                _vm.$set(m, "anyContent", $$v)
                                              },
                                              expression: "m.anyContent",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 0 } },
                                              [_vm._v("具体内容")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 1 } },
                                              [_vm._v("任意内容")]
                                            ),
                                          ],
                                          1
                                        ),
                                        m.anyContent == 0
                                          ? _c(
                                              "div",
                                              { staticClass: "add-reply" },
                                              [
                                                _vm._l(
                                                  m.specificContentList || [{}],
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "add-reply-item margin-bottom-xs flex-center justify-between",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "flex",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "当用户表达 "
                                                            ),
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                staticStyle: {
                                                                  margin: "0",
                                                                },
                                                                attrs: {
                                                                  prop: `sopConditionReqVOs.${i}.${_vm.customerReplyList}.${n}.specificContentList.${index}.specificContent`,
                                                                  rules: [
                                                                    {
                                                                      required: true,
                                                                      message:
                                                                        "请输入",
                                                                      trigger:
                                                                        "blur",
                                                                    },
                                                                  ],
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  staticClass:
                                                                    "add-input-reply",
                                                                  attrs: {
                                                                    resize:
                                                                      "none",
                                                                    type: "textarea",
                                                                    maxlength:
                                                                      "150",
                                                                    placeholder:
                                                                      "示例：请给我下商品的链接",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.specificContent,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "specificContent",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.specificContent",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(
                                                              " 的意思时，执行该动作。 "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        m.specificContentList
                                                          ?.length > 1
                                                          ? _c("i", {
                                                              staticClass:
                                                                "el-icon-error pointer",
                                                              staticStyle: {
                                                                color:
                                                                  "#D3D2D2",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeReply(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          type: "text",
                                                          icon: "el-icon-circle-plus",
                                                        },
                                                        on: {
                                                          click: _vm.addReply,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" 添加 "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-red",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "【单次最多添加5条问题】"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeTab == 2,
                  expression: "activeTab == 2",
                },
              ],
            },
            [
              _c("stage-second-set", {
                ref: "stageSecondSet",
                attrs: {
                  visible: _vm.visible || _vm.visibleStatus,
                  selectCondition: _vm.sopTaskNodeReqVO.selectCondition,
                  id: _vm.sopItemId,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "foot" },
            [
              _vm.activeTab == 2
                ? _c(
                    "el-button",
                    { staticClass: "prev", on: { click: _vm.prev } },
                    [_vm._v("上一步")]
                  )
                : _vm._e(),
              _vm.activeTab == 1
                ? _c(
                    "el-button",
                    {
                      staticClass: "next",
                      attrs: { type: "primary" },
                      on: { click: _vm.next },
                    },
                    [_vm._v("下一步")]
                  )
                : _vm._e(),
              _vm.activeTab == 2
                ? _c(
                    "el-button",
                    {
                      directives: [
                        { name: "no-more-click", rawName: "v-no-more-click" },
                      ],
                      staticClass: "finish",
                      attrs: { disabled: _vm.visible, type: "primary" },
                      on: { click: _vm.finishClick },
                    },
                    [_vm._v("完成")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                { staticClass: "cancelClose", on: { click: _vm.handleClose } },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }