var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        slot: "suffix",
        placement: "bottom",
        "popper-class": "emoji-popover",
        title: "表情",
        trigger: "manual",
      },
      slot: "suffix",
      model: {
        value: _vm.popover,
        callback: function ($$v) {
          _vm.popover = $$v
        },
        expression: "popover",
      },
    },
    [
      _c("picker", {
        attrs: {
          showSearch: false,
          showPreview: false,
          i18n: _vm.I18N,
          showCategories: false,
          backgroundImageFn: _vm.backgroundImageFn,
        },
        on: {
          select: function ($event) {
            return _vm.addEmoji(arguments)
          },
        },
      }),
      _c(
        "div",
        {
          staticClass:
            "flex-center justify-center margin-top-xs icon-view pointer",
          attrs: { slot: "reference" },
          on: { click: _vm.handleClick },
          slot: "reference",
        },
        [
          _vm._t("reference", function () {
            return [_c("i", { staticClass: "icon icon-emoji text-xl" })]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }