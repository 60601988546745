var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "stage-second-set" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "task-form",
          attrs: {
            disabled: _vm.visibleStatus || _vm.visible,
            rules: _vm.rules,
            model: _vm.ruleForm,
            "label-width": "110px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "执行任务", prop: "performType" } },
            [
              _c(
                "el-checkbox-group",
                {
                  attrs: { size: "medium" },
                  on: { change: _vm.selectPerformType },
                  model: {
                    value: _vm.ruleForm.performType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "performType", $$v)
                    },
                    expression: "ruleForm.performType",
                  },
                },
                _vm._l(_vm.taskList, function (item, index) {
                  return _c(
                    "el-checkbox",
                    { key: index, attrs: { label: item.id, border: "" } },
                    [
                      _c("i", {
                        class: [
                          _vm.ruleForm.performType.indexOf(item.id) != -1
                            ? item.icon
                            : item.iconNo,
                          "icon",
                        ],
                      }),
                      _vm._v(" " + _vm._s(item.name) + " "),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "task-all" },
        [
          _c("taskMessage", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.ruleForm.performType.indexOf(1) != -1,
                expression: "ruleForm.performType.indexOf(1) != -1",
              },
            ],
            ref: "taskMessages",
            on: { taskMessage: _vm.stageSecondSetData },
          }),
          _c("taskForward", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.ruleForm.performType.indexOf(2) != -1,
                expression: "ruleForm.performType.indexOf(2) != -1",
              },
            ],
            ref: "taskForwards",
            on: { taskForward: _vm.stageSecondSetData },
          }),
          _c("taskLabel", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.ruleForm.performType.indexOf(3) != -1,
                expression: "ruleForm.performType.indexOf(3) != -1",
              },
            ],
            ref: "taskLabels",
            attrs: { stageDrawer: _vm.stageDrawer },
            on: { taskLabel: _vm.stageSecondSetData },
          }),
          _c("taskNotice", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.ruleForm.performType.indexOf(4) != -1,
                expression: "ruleForm.performType.indexOf(4) != -1",
              },
            ],
            ref: "taskNotice",
            on: { taskNotice: _vm.stageSecondSetData },
          }),
          _c("task-info", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.ruleForm.performType.indexOf(5) != -1,
                expression: "ruleForm.performType.indexOf(5) != -1",
              },
            ],
            ref: "taskInfo",
            on: { taskInfo: _vm.stageSecondSetData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }