/* eslint-disable max-len */
export const plugins = [
  'print preview searchreplace autolink directionality visualblocks image visualchars fullscreen link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons bdmap indent2em autoresize formatpainter axupimgs wordcount wordlimit attachment paste atwho',
]
export const toolbar = [
  'undo redo restoredraft | forecolor backcolor bold italic underline strikethrough link| alignleft aligncenter alignright alignjustify outdent indent | \
  styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
  table image media attachment charmap emoticons insertdatetime print preview | fullscreen',
]
export const toolbar1 = [
  'undo redo backcolor bold italic underline hr bullist numlist emoticons attachment',
]
export const liteToolbar = [
  'undo redo backcolor bold italic underline hr bullist numlist emoticons attachment',
]
