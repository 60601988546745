var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showAddTag
        ? _vm._t("addTag")
        : _c(
            "div",
            { staticClass: "table-tags" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "top",
                    trigger: _vm.currentTags.length > 0 ? "hover" : null,
                  },
                },
                [
                  _c("el-scrollbar", [
                    _c(
                      "div",
                      {
                        staticClass: "table-tags",
                        staticStyle: {
                          "max-width": "300px",
                          "max-height": "200px",
                        },
                      },
                      _vm._l(_vm.currentTags, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "tag-item text-cut",
                            staticStyle: { "max-width": "inherit" },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "table-tags pointer",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    _vm._l(_vm.viewTags, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "tag-item text-cut" },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "el-tag",
                {
                  staticClass: "cursor",
                  attrs: { type: "info" },
                  on: { click: _vm.open },
                },
                [
                  _vm.currentTags.length < 2
                    ? _c("i", { staticClass: "el-icon-plus" })
                    : _c("i", { staticClass: "el-icon-more" }),
                ]
              ),
            ],
            1
          ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择标签",
            "custom-class": "tagDialog",
            "close-on-click-modal": "",
            "append-to-body": "",
            visible: _vm.dialogVisible,
            width: "800px",
          },
          on: {
            close: _vm.resetTagData,
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex justify-between" }, [
            _c(
              "div",
              {
                staticClass: "flex-treble",
                staticStyle: { "border-right": "1px solid #eaeaea" },
              },
              [
                _c(
                  "el-tabs",
                  {
                    staticStyle: { height: "450px", "margin-top": "12px" },
                    attrs: { "tab-position": "left" },
                    model: {
                      value: _vm.activeMode,
                      callback: function ($$v) {
                        _vm.activeMode = $$v
                      },
                      expression: "activeMode",
                    },
                  },
                  [
                    _vm.showDynamicTag
                      ? _c(
                          "el-tab-pane",
                          { attrs: { label: "动态标签", name: "1" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "0px" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: { width: "200px" },
                                    attrs: { placeholder: "请输入关键词" },
                                    on: { change: _vm.getDynamicTags },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.getDynamicTags.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.dynamicSearchName,
                                      callback: function ($$v) {
                                        _vm.dynamicSearchName = $$v
                                      },
                                      expression: "dynamicSearchName",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-input__icon el-icon-search",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix",
                                    }),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSearch(1)
                                      },
                                    },
                                  },
                                  [_vm._v(" 查询 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { icon: "el-icon-refresh-right" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onReset(1)
                                      },
                                    },
                                  },
                                  [_vm._v(" 重置 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tags-view padding-tb-sm padding-right-sm gap_sm1",
                                staticStyle: {
                                  height: "390px",
                                  overflow: "auto",
                                },
                              },
                              _vm._l(_vm.dynamicTags, function (tag) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: tag.id,
                                    staticClass: "cursor tags-item",
                                    staticStyle: { "user-select": "none" },
                                    attrs: {
                                      effect:
                                        _vm.currentTags &&
                                        _vm.currentTags.findIndex(
                                          (v) => v.id === tag.id
                                        ) === -1
                                          ? "plain"
                                          : "light",
                                      "disable-transitions": false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addCurrentTags(tag, 1)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(tag.name) + " ")]
                                )
                              }),
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showStaticTag
                      ? _c(
                          "el-tab-pane",
                          { attrs: { label: "静态标签", name: "2" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "0px" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: { width: "200px" },
                                    attrs: { placeholder: "请输入关键词" },
                                    on: { change: _vm.getStaticTags },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.getStaticTags.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.staticSearchName,
                                      callback: function ($$v) {
                                        _vm.staticSearchName = $$v
                                      },
                                      expression: "staticSearchName",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-input__icon el-icon-search",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix",
                                    }),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-search",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSearch(2)
                                      },
                                    },
                                  },
                                  [_vm._v(" 查询 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { icon: "el-icon-refresh-right" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onReset(2)
                                      },
                                    },
                                  },
                                  [_vm._v(" 重置 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tags-view padding-tb-sm padding-right-sm gap_sm1",
                                staticStyle: {
                                  height: "390px",
                                  overflow: "auto",
                                },
                              },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    ref: "popoverRef",
                                    staticStyle: {
                                      height: "32px",
                                      "margin-right": "8px",
                                    },
                                    attrs: {
                                      placement: "bottom-start",
                                      width: "380",
                                      trigger: "click",
                                    },
                                    model: {
                                      value: _vm.show,
                                      callback: function ($$v) {
                                        _vm.show = $$v
                                      },
                                      expression: "show",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "el-form",
                                          {
                                            ref: "ruleForm",
                                            staticClass: "demo-ruleForm",
                                            attrs: {
                                              model: _vm.ruleForm,
                                              rules: _vm.rules,
                                              "label-width": "90px",
                                            },
                                            nativeOn: {
                                              submit: function ($event) {
                                                $event.preventDefault()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "标签名称",
                                                  prop: "name",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder:
                                                      "请输入标签名称",
                                                    maxlength: "20",
                                                    showWordLimit: "",
                                                  },
                                                  nativeOn: {
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.sure.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.ruleForm.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ruleForm,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "ruleForm.name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "dialog-footer dialog-footer-end",
                                            attrs: { slot: "footer" },
                                            slot: "footer",
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              { on: { click: _vm.cancel } },
                                              [_vm._v("取 消")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  loading: _vm.loading,
                                                },
                                                on: { click: _vm.sure },
                                              },
                                              [_vm._v(" 确 定 ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    false
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              plain: "",
                                              effect: "plain",
                                            },
                                            slot: "reference",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-plus text-bold",
                                            }),
                                            _vm._v(" 新建标签 "),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._l(_vm.staticTags, function (tag) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: tag.id,
                                      staticClass: "cursor tags-item",
                                      staticStyle: { "user-select": "none" },
                                      attrs: {
                                        effect:
                                          _vm.currentTags &&
                                          _vm.currentTags.findIndex(
                                            (v) => v.id === tag.id
                                          ) === -1
                                            ? "plain"
                                            : "light",
                                        "disable-transitions": false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addCurrentTags(tag, 2)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(tag.name) + " ")]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex-sub padding-lr-sm" },
              [
                _c(
                  "div",
                  { staticClass: "flex-center padding-top-sm justify-between" },
                  [
                    _c("div", [
                      _vm._v(
                        "已选" +
                          _vm._s(_vm.currentTags && _vm.currentTags.length) +
                          "项"
                      ),
                    ]),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary", underline: false },
                        on: { click: _vm.clearTags },
                      },
                      [_vm._v("清空")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-scrollbar-v2",
                  {
                    ref: "scrollContainer",
                    staticClass: "scroll-container padding-top-xs",
                    attrs: { vertical: false },
                  },
                  _vm._l(_vm.currentTags, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass:
                          "flex-center padding-bottom-xs justify-between",
                      },
                      [
                        _c("div", { staticClass: "text-black" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        !item.mode ||
                        (item.mode == 1 && !_vm.disableDynamicTag) ||
                        (item.mode == 2 && !_vm.disableStaticTag)
                          ? _c(
                              "div",
                              {
                                staticClass: "pointer padding-lr-xs",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteTag(item)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-error text-ccc",
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer padding-top-sm footer",
              staticStyle: { "padding-top": "10px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.signLabel } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }