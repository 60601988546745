<template>
  <div class="tabledata-select-dialog">
    <div @click="dialogVisible = true">
      <slot name="trigger">触发器</slot>
    </div>
    <el-dialog
      append-to-body
      :width="width"
      :title="title"
      :visible.sync="dialogVisible"
      @open="handleOpen"
      custom-class="tabledata-select-dialog__custom-class"
    >
      <div class="tabledata-select-dialog__wrapper">
        <div
          class="tabledata-select-dialog__content"
          :style="{ height: contentHeight }"
        >
          <div class="tabledata-select-dialog__left">
            <table-list
              ref="tableListRef"
              style="padding: 0"
              show-checkbox
              v-bind="$attrs"
              :table-height="tableHeight"
              @on-checked="handleCheckedAll"
              @on-refresh-list="updateTableDataStatus"
            />
          </div>
          <div class="tabledata-select-dialog__right">
            <selected-list
              :selected-list="selectedList"
              :real-label="realLabel"
              :real-id="realId"
              @on-clear="clear"
              @on-delete="handleDel"
            />
          </div>
        </div>
        <dialog-footer
          class="tabledata-select-dialog__footer"
          @on-cancel="handleCancel"
          @on-sure="handleSure"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableList from '@/base/table-list/index'
import SelectedList from './selected-list.vue'
import DialogFooter from './dialog-footer.vue'

export default {
  name: 'TabledataSelectDialog',
  components: {
    TableList,
    SelectedList,
    DialogFooter,
  },
  props: {
    value: {
      type: Array,
      default: () => [], // [id, id] | [{id}, {id}]
    },
    tableHeight: {
      type: String,
      default: '800px',
    },
    width: {
      type: String,
      default: '1200px',
    },
    title: {
      type: String,
      default: '请选择',
    },
    props: {
      type: Object,
      default: () => {
        return {
          label: 'label',
          id: 'id',
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      selectedList: [],
    }
  },
  computed: {
    realId() {
      return this.props.id ?? 'id'
    },
    realLabel() {
      return this.props.label ?? 'label'
    },
    contentHeight() {
      return parseInt(this.tableHeight) + 60 + 'px'
    },
    modelValue: {
      get() {
        return this.value
      },
      set(values) {
        this.$emit('input', values)
      },
    },
  },
  methods: {
    handleOpen() {
      this.selectedList = [...this.modelValue]
    },
    handleCancel() {
      this.dialogVisible = false
    },
    handleSure() {
      this.dialogVisible = false
      this.$emit('on-change', this.selectedList)
      this.modelValue = this.selectedList
    },
    clear() {
      this.selectedList = []
      this.$nextTick(this.updateTableDataStatus)
      this.$refs.tableListRef.checkedAll = false
    },
    has(id) {
      const { realId } = this
      return !!this.selectedList.find((_) => _?.[realId] === id)
    },
    del(id) {
      const { realId } = this
      const index = this.selectedList.findIndex((_) => _?.[realId] === id)
      if (index > -1) {
        this.selectedList.splice(index, 1)
      }
    },
    add(row) {
      this.selectedList.push(row)
    },
    handleCheckedAll(bool, rows) {
      rows.forEach((row) => {
        this.handleChecked(bool, row)
      })
    },
    handleChecked(bool, row) {
      const { realId } = this
      if (bool && !this.has(row?.[realId])) {
        this.add(row)
      } else if (!bool) {
        this.del(row?.[realId])
      }
    },
    handleDel(row) {
      const { realId } = this
      this.del(row?.[realId])
      this.updateTableDataStatus()
    },
    correctSelectedList(tableData = []) {
      const { realId } = this
      this.selectedList = this.selectedList.map((item) => {
        let id =
          typeof item === 'string' || typeof item === 'number'
            ? item
            : item?.[realId]
        const data = tableData.find((_) => _?.[realId] === id)
        return data
      })
    },
    updateTableDataStatus() {
      const { realId } = this
      const tableListRef = this.$refs?.tableListRef
      const tableData = tableListRef?.tableData ?? []
      this.correctSelectedList(tableData)
      this.$nextTick(() => {
        tableData?.forEach((row) => {
          row.__checked__ = this.has(row?.[realId]) ? true : false
        })
      })
    },
  },
}
</script>

<style lang="scss">
.tabledata-select-dialog__custom-class {
  .tabledata-select-dialog__content {
    display: flex;
    overflow: hidden;
  }
  .tabledata-select-dialog__left {
    flex: 1;
  }
  .tabledata-select-dialog__right {
    width: 325px;
    flex: none;
    border-left: 0.5px solid #e1e4ea;
  }
  .tabledata-select-dialog__footer {
    border-top: 0.5px solid #e1e4ea;
  }
  .el-dialog__body {
    padding: 15px 14px 0px 20px !important;
  }
}
</style>
