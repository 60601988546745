// import { login, logout, getInfo, socialLogin, smsLogin } from '@/api/login'
// import { setToken, removeToken } from '@/utils/auth'
import { 
	getAllAccountData, 
	getContactInfo, 
	getWChatDialog, 
	topContact, 
	getContactInfos, 
	getGroupInfos,
	getChatBarInfo,
	trusteeship,
	cancelTrusteeship,
	msgAlreadyRead,
	coversationChoose,
	updateHeadImg,
} from '@/api/wechat/index'

const wechat = {
	namespaced: true,
	state: {
		isMoreAccountState: false, // 单-多账号状态
		chatRoutePage: '', // 当前展示=页面路由
		tabType: 0, // 当前tab标签的状态
		msgHighLightType: 0,
		isShowChat: false, // 是否显示聊天室
		// timer
		accountTimer: null,
		contactTimer: null, // 当前联系人的轮询器
		chatHeadTimer: null, // 聊天室头部
		// account
		currentAccount: {}, // 当前使用的单个社交账号的信息
		allAccount: [], // 社交账号信息
		currentHighLightAccountId: '',
		// contact
		currentContact: {}, // 当前选中的联系人信息
		// allContactInfo: [], // 存储所有联系人
		firstContactData: {},
		currentHighLightContactId: '',
		enableUpdate: false,
		topHighlightType: 0,
	},

	mutations: {
		// router
		SET_CUR_PAGEROUTER: ( state, chatRoutePage ) => {
			state.chatRoutePage = chatRoutePage;
		},
		SET_MORE_ACCOUNT_STATE: ( state, isMoreAccountState ) => { // 单多账号状态
			state.isMoreAccountState = isMoreAccountState;
		},
		SET_CUR_TABTYPE: ( state, tabType ) => {
			state.tabType = tabType;
		},
		SET_CUR_HIGHLIGHT_TYPE: ( state, msgHighLightType ) => {
			state.msgHighLightType = msgHighLightType;
		},
		SET_TOP_HIGHLIGHT: ( state, topHighlightType ) => {
			state.topHighlightType = topHighlightType;
		},
		SET_CHAT_SHOW: ( state, isShowChat ) => {
			state.isShowChat = isShowChat;
		},
		// timer
		SET_ACCOUNT_TIMER: ( state, accountTimer ) => {
			state.accountTimer = accountTimer;
		},
		CLEAR_ACCOUNT_TIMER: ( state ) => {
			clearInterval(state.accountTimer);
			state.accountTimer = null;
		},
		SET_CONTACT_TIMER: ( state, contactTimer ) => {
			state.contactTimer = contactTimer;
		},
		CLEAR_CONTACT_TIMER: ( state ) => {
			clearInterval(state.contactTimer);
			state.contactTimer = null;
		},
		SET_CHAT_HEAD_TIMER: ( state, chatHeadTimer ) => {
			state.chatHeadTimer = chatHeadTimer;
		},
		CLEAR_CHAT_HEAD_TIMER: ( state ) => {
			clearInterval(state.chatHeadTimer);
			state.chatHeadTimer = null;
		},
		//
		SET_ENABLE_UPDATE: (state, enableUpdate ) => {
			state.enableUpdate = enableUpdate;
		},
		// account
		SET_CUR_ACCOUNT: (state, currentAccount ) => {
			state.currentAccount = currentAccount;
		},
		SET_ALL_ACCOUNTINFO: ( state, allAccount ) => {
			state.allAccount = allAccount;
		},
		SET_CUR_ACCOUNT_HIGHLIGHT: ( state, currentHighLightAccountId ) => {
			state.currentHighLightAccountId = currentHighLightAccountId;
		},
		// contact
		SET_CUR_CONTACT: ( state, currentContact ) => {
			state.currentContact = currentContact;
		},
		// SET_ALL_CONTACTINFO: ( state,  allContactInfo) => {
		// 	state.allContactInfo = allContactInfo;
		// },
		SET_CUR_CONTACT_HIGHLIGHT: ( state, currentHighLightContactId ) => {
			state.currentHighLightContactId = currentHighLightContactId;
		},
		SET_FIRST_CONTACT_INFO: ( state, firstContactData ) => {
			state.firstContactData = firstContactData;
		},
		
		
		
		



		
		
	},

	actions: {
        // 获取社交账号
        getAllAccountAction ( { commit },params ) {
			// const username = userInfo.username.trim(
			return new Promise( ( resolve, reject ) => {
				getAllAccountData(params).then( res => {
					resolve(res)
				} ).catch( error => {
					reject( error )
				} )
			} )
		},
		// 获取联系人信息
		getContactInfoAction ( { commit }, payload ) {
			// console.log('payload', payload);
			// const username = userInfo.username.trim(
			return new Promise( ( resolve, reject ) => {
				getContactInfo(payload).then( res => {
					// console.log('联系人数据', res);
					// commit( 'SET_ID', user.id )
					// res = res.data;
					// 设置 token
					// console.log( res.tenantId, 'res.tenantId' )
					// setToken( res )
					resolve(res)
				} ).catch( error => {
					reject( error )
				} )
			} )
		},
		getWChatDialogAction ( { commit }, payload ) {
			console.log('payload', payload);
			return new Promise( ( resolve, reject ) => {
				getWChatDialog(payload).then( res => {
					console.log('聊天数据', res);
					resolve(res)
				} ).catch( error => {
					reject( error )
				} )
			} )
		},
		// 置顶联系人
		topContactAction ( { commit }, payload ) {
			return new Promise( ( resolve, reject ) => {
				topContact(payload).then( res => {
					resolve(res)
				} ).catch( error => {
					reject( error )
				} )
			} )
		},
		// 搜索联系人
		getContactInfosActions ( { commit }, payload ) {
			return new Promise( ( resolve, reject ) => {
				getContactInfos(payload).then( res => {
					resolve(res)
				} ).catch( error => {
					reject( error )
				} )
			} )
		},
		// 搜索群聊
		getContactGroupActions ( { commit }, payload ) {
			return new Promise( ( resolve, reject ) => {
				getGroupInfos(payload).then( res => {
					resolve(res)
				} ).catch( error => {
					reject( error )
				} )
			} )
		},
		// 聊天室头部信息
		getChatBarInfoAction ( { commit }, payload ) {
			return new Promise( ( resolve, reject ) => {
				getChatBarInfo(payload).then( res => {
					resolve(res)
				} ).catch( error => {
					reject( error )
				} )
			} )
		},
		// 托管
		trusteeshipAction ( { commit }, payload ) {
			return new Promise( ( resolve, reject ) => {
				trusteeship(payload).then( res => {
					resolve(res)
				} ).catch( error => {
					reject( error )
				} )
			} )
		},
		// 取消托管
		cancelTrusteeshipAction ( { commit }, payload ) {
			return new Promise( ( resolve, reject ) => {
				cancelTrusteeship(payload).then( res => {
					resolve(res)
				} ).catch( error => {
					reject( error )
				} )
			} )
		},
		// 消息已读
		msgAlreadyReadActions ( { commit }, payload ) {
			return new Promise( ( resolve, reject ) => {
				msgAlreadyRead(payload).then( res => {
					resolve(res)
				} ).catch( error => {
					reject( error )
				} )
			} )
		},
		// 搜索联系人或群选中
		coversationChooseAction ( { commit }, payload ) {
			return new Promise( ( resolve, reject ) => {
				coversationChoose(payload).then( res => {
					resolve(res)
				} ).catch( error => {
					reject( error )
				} )
			} )
		},
		updateHeadImgAction ( { commit }, payload ) {
			return new Promise( ( resolve, reject ) => {
				updateHeadImg(payload).then( res => {
					resolve(res)
				} ).catch( error => {
					reject( error )
				} )
			} )
		},
	}
}

export default wechat
