<template>
  <div
    inset
    v-bind="$attrs"
    :class="['mb-collapse', transition ? 'transition' : '']"
    ref="cellGroupRef"
    :style="[isCollapse ? { overflow: 'hidden' } : {}]"
  >
    <div class="mb-collapse__content" ref="contentRef">
      <slot />
      <div
        center
        @click="handleCollapse()"
        class="mb-collapse__trigger-wrapper"
        :class="!isExpand ? 'mb-collapse__button--expand' : ''"
        ref="triggerRef"
        v-if="showTrigger"
      >
        <slot name="trigger" v-bind="{ isCollapse }">
          <div class="mb-collapse__button">
            <span>
              {{ !isExpand ? '查看更多' : '收起' }}
            </span>
            <i
              :class="isExpand ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
              :style="{
                fontSize: '16px',
              }"
            />
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Collapse',
  props: {
    height: {
      type: String,
      default: '176px', // 4 * van-cell height(44px)
    },
    transition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCollapse: false, // 是否折叠
      showTrigger: true,
    }
  },
  computed: {
    isExpand() {
      return !this.isCollapse
    },
  },
  watch: {
    isCollapse(bool) {
      this.onChange(bool)
    },
  },
  methods: {
    handleCollapse(bool) {
      this.isCollapse = bool ?? !this.isCollapse
      this.initRootElementHeight()
    },
    toggle() {
      this.handleCollapse()
    },
    open() {
      this.handleCollapse(false)
    },
    close() {
      this.handleCollapse(true)
    },
    canSetHeight() {
      const { height, getContentElementHeight } = this
      const userHeight = parseFloat(height)
      const eleHeight = parseFloat(getContentElementHeight())
      if (!isNaN(userHeight) && !isNaN(eleHeight)) {
        return eleHeight > userHeight
      } else {
        return true
      }
    },
    getContentElementHeight() {
      const el = this.$refs.contentRef
      return el ? el.scrollHeight + 'px' : ''
    },
    initRootElementHeight() {
      this.$nextTick(() => {
        const content = this.$refs.cellGroupRef
        const canSet = this.canSetHeight()
        this.$emit('on-has-trigger', canSet)
        this.showTrigger = canSet ? true : false
        if (canSet) {
          content.style.height = this.isExpand
            ? this.getContentElementHeight()
            : this.height
        } else {
          content.style.height = 'auto'
        }
      })
    },
    handleResize() {
      this.initRootElementHeight()
    },
  },
  beforeCreate() {
    const createEmitter = (eventName) => (event) => this.$emit(eventName, event)
    this.onChange = createEmitter('change')
  },
  mounted() {
    this.initRootElementHeight()
    this.handleCollapse()

    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  updated() {
    this.initRootElementHeight()
  },
}
</script>
