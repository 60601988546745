<template>
    <div class="contailner" v-if="dialogVisible">
        <el-dialog class="dialog select-dialog" top="10vh" :modal-append-to-body="false" append-to-body
            :title="`选择${formData.selectTitle}`" :visible.sync="dialogVisible" width="1220px" :destroy-on-close="true"
            @close="close" close-on-click-modal>
            <div class="flex">
                <div class="flex-sub content-left">
                    <div>
                        <el-form :model="dialogParams" @submit.native.prevent align="left" label-width="120px"
                            ref="dialogParams" class="flex flex-wrap">
                            <el-form-item :label="formData.selectTitle" prop="value">
                                <el-input @change="getList('inquire')" v-model.trim="dialogParams.value" placeholder="请输入" clearable />
                            </el-form-item>
                            <!-- <el-form-item v-if="formData.type != 2" label="昵称" prop="nickName">
                                <el-input v-model.trim="dialogParams.nickName" placeholder="请输入" clearable />
                            </el-form-item> -->
                            <!-- <el-form-item label="群主昵称" prop="wechatName">
                                <el-input v-model.trim="dialogParams.wechatName" placeholder="请输入" clearable />
                            </el-form-item>
                            <el-form-item label="群标签" prop="wechatName">
                                <el-input v-model.trim="dialogParams.wechatName" placeholder="请输入" clearable />
                            </el-form-item>
                            <el-form-item label="职员姓名" prop="wechatName">
                                <el-input v-model.trim="dialogParams.wechatName" placeholder="请输入" clearable />
                            </el-form-item> -->

                        </el-form>
                        <div class="submit-reset">
                            <el-button @click="getList('inquire')" type="primary" icon="el-icon-search">查询</el-button>
                            <el-button class="reset" @click="resetForm()" icon="el-icon-refresh-right">重置</el-button>
                            <AdvancedFilter v-if="formData.type == 1 && !disabledSomeColumn" ref="advancedFilterRef" @change="handleChange" />
                        </div>
                        <!-- <el-form v-if="formData.type == 1" :model="dialogParams" @submit.native.prevent align="left"
                            label-width="70px" ref="dialogParams" class="flex flex-wrap">
                            <el-form-item label="微信号" prop="groupName">
                                <el-input v-model.trim="dialogParams.groupName" placeholder="请输入" clearable />
                            </el-form-item>
                            <el-form-item label="昵称" prop="nickname">
                                <el-input v-model.trim="dialogParams.nickname" placeholder="请输入" clearable />
                            </el-form-item>
                            <el-form-item label="客户表示" prop="wechatName">
                                <el-input v-model.trim="dialogParams.wechatName" placeholder="请输入" clearable />
                            </el-form-item>
                            <el-form-item label="跟进人" prop="wechatName">
                                <el-input v-model.trim="dialogParams.wechatName" placeholder="请输入" clearable />
                            </el-form-item>
                            <el-form-item label="职员姓名" prop="wechatName">
                                <el-input v-model.trim="dialogParams.wechatName" placeholder="请输入" clearable />
                            </el-form-item>
                        </el-form>
                        <div class="submit-reset">
                            <el-button @click="getList('inquire')" type="primary" icon="el-icon-search">查询</el-button>
                            <el-button class="reset" @click="resetForm()" icon="el-icon-refresh-right">重置</el-button>
                        </div> -->

                    </div>
                    <div>
                        <div class="margin-bottom-sm" v-if="formData?.masterWechatId && formData.type == 2">
                            <el-alert title="当选择发送群公告时，只能往自己为群主的客户群发送" :closable="false" type="warning" show-icon>
                            </el-alert>
                        </div>
                        <el-table :data="list" ref="multipleTable" height="calc(100vh - 500px)" :row-key="getRowKeys"
                            @selection-change="handleSelect">
                            <el-table-column type="selection" :reserve-selection="true" width="60" align="center"
                                v-if="!isRadio"></el-table-column>
                            <el-table-column v-else type="selection" :reserve-selection="true" width="55"></el-table-column>
                            <el-table-column label="序号" type="index" align="left" prop="title" width="60" />
                            <el-table-column :label="formData.selectTitle" align="left" prop="wechatName">
                                <template slot-scope="scope">
                                    <div>{{ scope.row.groupName || scope.row.nickname || scope.row.value }}</div>
                                    <channelType :type="DICT_TYPE.ROBOT_CHANNEL_TYPE" :value="scope.row.accountType">
                                    </channelType>
                                </template>
                            </el-table-column>

                            <template v-if="formData.type == 1 && !disabledSomeColumn">
                                <el-table-column label="社交账号" align="left" prop="externalOpenId">
                                    <template slot-scope="scope">
                                        <div>{{ scope.row.externalOpenId || '--' }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="姓名" align="left" prop="name">
                                    <template slot-scope="scope">
                                        <div>{{ scope.row.name || '--' }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="手机号" align="left" prop="mobile">
                                    <template slot-scope="scope">
                                        <div>{{ scope.row.mobile || '--' }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="称呼" align="left" prop="notes">
                                    <template slot-scope="scope">
                                        <div>{{ scope.row.notes || '--' }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="跟进员工" align="left" prop="followEmployees">
                                    <template slot-scope="scope">
                                        <div>{{ scope.row.followEmployees || '--' }}</div>
                                    </template>
                                </el-table-column>
                            </template>
                            
                            <!-- <el-table-column label="群主昵称" align="left" prop="wechatName">
                                <template slot-scope="scope">
                                    <div>{{ scope.row.groupName }}</div>
                                </template>
                            </el-table-column> -->
                            <!-- <el-table-column label="成员数" align="left" prop="wechatName">
                                <template slot-scope="scope">
                                    <div>{{ scope.row.groupName }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column label="群标签" align="left" prop="wechatName">
                                <template slot-scope="scope">
                                    <div>{{ scope.row.groupName }}</div>
                                </template>
                            </el-table-column> -->
                        </el-table>
                        <div class="page-list">
                            <!-- <el-checkbox class="checkout-all" v-model="checked">全部选中</el-checkbox> -->
                            <pagination :total="total" :pagerCount="pagerCount" :page.sync="dialogParams.pageNo"
                                :limit.sync="dialogParams.pageSize" @pagination="getList" />

                        </div>

                    </div>
                </div>
                <div class="content-right">
                    <div class="flex justify-between">
                        <div style="color: #999999">已选{{ selectData.length }}项</div>
                        <div style="color: #1c53d9; cursor: pointer" @click="reset">
                            清空
                        </div>
                    </div>
                    <el-scrollbar ref="scrollContainer" :vertical="false" class="scroll-container padding-top-xs">
                        <div class="tip flex justify-between margin-top-xs align-center padding-right-sm"
                            v-for="(item, index) in selectData" :key="index">
                            <div class="flex-center">
                                <div style="max-width: 180px;" class="desc margin-right-xs text-cut">
                                    {{ item.groupName || item.nickname || item.value }}
                                </div>
                                <channelType :type="DICT_TYPE.ROBOT_CHANNEL_TYPE" :value="item.accountType"></channelType>
                            </div>
                            <i @click="deleteTo(item)" style="color: #D3D2D2;width: 16px;height: 16px;"
                                class="el-icon-error pointer icons margin-left-sm"></i>
                        </div>
                    </el-scrollbar>
                </div>
            </div>
            <div slot="footer" class="select-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="submitData">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    friendPage, groupPage, groupMemberPage
} from "@/api/material/material";
import { getCustomerTagPage, getGroupListByTag, getParamValuePage } from '@/api/sop/index.js'
import _ from "lodash";
import AdvancedFilter from './advanced-filter'

export default {
    components: {
        AdvancedFilter
    },
    props: {
        formData: {
            default: () => {
                return {};
            },
        },
        isRadio: { // 是否单选
            type: Boolean,
            default: false
        },
        resetEchoId: { // 根据传的字段，回显，删除数据
            type: String,
            default: ''
        },
        maxNum: {
            type: Number,
            default: 100000
        },
        disabledSomeColumn: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            pagerCount: 5,
            pageSizeList: [10, 20, 30, 50],
            dialogVisible: false,
            checked: false,
            dialogParams: {
                value: "",
                groupName: '',
                nickName: '',
                pageNo: 1,
                pageSize: 30,
            },
            list: [],
            total: 0,
            radioId: '',
            echoId: 'value',
            selectData: [], // 当前选中数据
            advancedFilterParams: {}
        }
    },

    watch: {
        radioId: {
            handler: function (val) {
                let item = this.list.find(e => e[this.echoId] == val)
                if (item) {
                    this.selectData = []
                    this.selectData.push(item)
                }
            },
        }
    },
    methods: {
        handleChange(values) {
            this.advancedFilterParams = values
            this.getList("inquire", values);
        },
        getRowKeys(row) {
            return row[this.echoId];
        },
        // 确定
        submitData() {
            this.$emit("getCrowPeople", this.selectData);
            this.close();
        },
        // 打开
        open() {
            this.radioId = '';
            this.dialogVisible = true;
            this.selectData = _.cloneDeep(this.formData.selectData || [])
            this.dialogParams = {
                value: "",
                pageNo: 1,
                pageSize: 30,
                paramId: this.formData?.paramId ? this.formData.paramId : '',
                tag: this.formData.tag || '',
                type: this.formData.type == 3 ? 2 : this.formData.type
            }
            this.getList("inquire");
            this.$nextTick(() => {
                this.exhoClick();
            })
        },
        // 关闭
        close() {
            this.dialogVisible = false;
            this.$refs.dialogParams.resetFields();
        },
        // 重置
        resetForm() {
            this.dialogParams.pageNo = 1;
            this.dialogParams.pageSize = 30;
            this.$refs.dialogParams.resetFields();
             this.$refs.advancedFilterRef.reset()
            this.advancedFilterParams = {}
            this.getList("inquire");
        },
        // 列表
        async getList(dom) {
            if (dom === "inquire") {
                this.dialogParams.pageNo = 1;
            }
            let requestArr = [getCustomerTagPage, getGroupListByTag, getParamValuePage]; // 1:好友，2:群，3，群成员
            let url = requestArr[this.formData.type - 1];
            const res = await url({...this.dialogParams, ...this.advancedFilterParams});
            this.total = res.data.total;
            this.list = res.data.list;
            this.$nextTick(() => {
                this.list.map(v => {
                    let index = this.selectData.findIndex((item) => v[this.echoId] == item[this.echoId]);
                    if (index == -1) {
                        this.$refs.multipleTable.toggleRowSelection(v, false);
                    }
                })
            })
        },
        // 全选/单选
        handleSelect(selection, row) {
            this.selectData = selection;
        },
        // 勾选 / 回显
        exhoClick() {
            this.selectData.map(v => {
                this.$refs.multipleTable.toggleRowSelection(v, true);
            })
        },
        // 全部清除
        reset() {
            this.$refs.multipleTable.clearSelection();
            this.selectData = [];
            this.radioId = '';
        },
        // 单个删除
        deleteTo(ids) {
            this.radioId = '';
            let index = this.selectData.findIndex((item) => item == ids);
            let item = this.list.find((v) => v[this.echoId] == ids.value);
            this.selectData.splice(index, 1);
            this.$refs.multipleTable.toggleRowSelection(item, false);
            if (this.selectData.length == 0) {
                this.reset();
            }
        },


    }
}


</script>

<style lang="scss" scoped>
.select-dialog ::v-deep {

    .el-dialog {
        border-radius: 0 !important;

        .el-dialog__header {
            padding: 24px 24px 24px 40px !important;
            background-color: #fff;
            border: 0.5px solid #F5F4F7;

            .el-dialog__title {
                font-family: 思源黑体;
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                color: #333333;
            }

            .el-dialog__headerbtn {
                top: 26px !important;

                .el-dialog__close {
                    color: #333;
                }

            }
        }

        .el-dialog__body {
            padding: 25px 24px 0px 40px !important;

        }

        .el-dialog__footer {
            padding: 10px 24px;
            border-top: 0.5px solid #F5F4F7;

        }
    }

    .el-form {
        .el-form-item {
            margin-bottom: 16px;
        }

        .el-form-item__label {
            font-size: 14px;
            font-weight: normal;
            color: #333333;
            padding-right: 8px;
        }

        .el-input {
            .el-input__inner {
                width: 240px;
                height: 32px;
                border-radius: 2px;
            }
        }

        .el-form-item:nth-child(even) {
            margin-left: 40px;
        }
    }

    .el-table {
        // margin-top: 16px;

        .el-table__header-wrapper th.el-table__cell {
            background-color: #F5F5F5 !important;
            height: 55px;
            border: 0 none;
        }
    }

    .content-left {}

    .content-right {
        width: 332px;
        padding-left: 24px;
        border-left: 0.5px solid #E1E4EA;
        // max-height: 300px;
        // overflow: auto;
    }

    .page-list {
        position: relative;

        .checkout-all {
            position: absolute;
            top: 5px;
        }
    }

    .submit-reset {
        padding-left: 70px;
        margin-bottom: 16px;

        .el-button {
            padding: 10px 16px;
        }

        .reset {
            margin-left: 15px;
        }

    }

    .scroll-container {
        height: 62vh;
        max-height: 650px;
    }

}
</style>


