import './micro-app.js'
import Vue from 'vue'
import Element from 'element-ui'
// import '@/formily/element.css'
import '@formily/element/dist/element.css'
import './assets/styles/element-variables.scss'
import '@/components/split/split.css'
import '@/components/collapse/style.scss'
import '@/components/tabs/style.scss'
import '@/components/scrollbar-v2/style.scss'

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import App from './App'
import store from './store'
import router from './router'
import directive from './directive' // directive
import plugins from './plugins' // plugins
import util from './utils/util.js'
import './utils/update.js'
import './assets/icons' // icon
import './permission' // permission control
import './tongji' // 百度统计
import { getDicts } from '@/api/system/dict/data'
import { getConfigKey } from '@/api/infra/config'
import func from '@/utils/preload.js'
import loadTinymce from '@/utils/loadTinymce'
import {
  parseTime,
  resetForm,
  handleTree,
  addBeginAndEndTime,
  divide,
} from '@/utils/ruoyi'
import Pagination from '@/components/Pagination'
// 自定义表格工具扩展
import RightToolbar from '@/components/RightToolbar'
// 代码高亮插件
// import hljs from 'highlight.js'
// import 'highlight.js/styles/github-gist.css'
import {
  DICT_TYPE,
  getDictDataLabel,
  getDictDatas,
  getDictDatas2,
} from '@/utils/dict'
// import './utils/rem.js'

import nodeWrap from '@/views/sop/components/nodeWrap.vue'
import '@/base/index'

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.getDictDatas = getDictDatas
Vue.prototype.getDictDatas2 = getDictDatas2
Vue.prototype.getDictDataLabel = getDictDataLabel
Vue.prototype.DICT_TYPE = DICT_TYPE
Vue.prototype.handleTree = handleTree
Vue.prototype.addBeginAndEndTime = addBeginAndEndTime
Vue.prototype.divide = divide
Vue.prototype.$util = util
Vue.prototype.$func = func
// 全局组件挂载
Vue.component('nodeWrap', nodeWrap) //初始化组件
Vue.component('DictTag', DictTag)
Vue.component('channelType', channelType)
Vue.component('wechatImg', wechatImg)
Vue.component('DocAlert', DocAlert)
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
// 字典标签组件
import DictTag from '@/components/DictTag'
import DocAlert from '@/components/DocAlert'
import channelType from '@/components/channelType/channelType.vue'
import wechatImg from '@/components/wechatImg/wechatImg.vue'
// 头部标签插件
import VueMeta from 'vue-meta'

Vue.use(directive)
Vue.use(plugins)
Vue.use(VueMeta)
// Vue.use(hljs.vuePlugin);

// bpmnProcessDesigner 需要引入
import MyPD from '@/components/bpmnProcessDesigner/package/index.js'
Vue.use(MyPD)
import '@/components/bpmnProcessDesigner/package/theme/index.scss'
import 'bpmn-js/dist/assets/diagram-js.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css'

// Form Generator 组件需要使用到 tinymce
import Tinymce from '@/components/tinymce/index.vue'
Vue.component('tinymce', Tinymce)

import editorInput from '@/components/div-editor-input/div-editor-input.vue'
Vue.component('editorInput', editorInput)

import Split from '@/components/split/index'
Vue.component('Split', Split)
import Collapse from '@/components/collapse/index'
Vue.component('Collapse', Collapse)
import Image from '@/components/image/index'
Vue.component('CsImage', Image)
import VueTinymce from '@/components/vue-tinymce/index'
Vue.component('VueTinymce', VueTinymce)
import XrInput from '@/components/input'
Vue.component('XrInput', XrInput)
import Adaptive from '@/directive/adaptive'
Vue.use(Adaptive)
import ElScrollbarV2 from '@/components/scrollbar-v2/index'
Vue.use(ElScrollbarV2)

import '@/assets/icons'
import request from '@/utils/request' // 实现 form generator 使用自己定义的 axios request 对象
// console.log(request)
Vue.prototype.$axios = request
import '@/styles/index.scss'

// 默认点击背景不关闭弹窗
import ElementUI from 'element-ui'
ElementUI.Dialog.props.closeOnClickModal.default = true

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  size: localStorage.getItem('size') || 'small', // set element-ui default size
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
})

// loadTinymce(tinymce => {
//   require("@/components/tinymce/zh_CN");
//   require("@/components/tinymce/plugins/ax_wordlimit/plugin");
//   require("@/components/tinymce/plugins/attachment/plugin.min");
//   require("@/components/tinymce/plugins/atwho/plugin");
// });
