
import request from "@/utils/request";
import vala from "highlight.js/lib/languages/vala";
// 自定义列表
export function getCustomList(query) {
    return request({
        url: "/wechat/dynamic/page",
        method: "get",
        params: query,
    });
}

// 创建自定义参数
export function postCustomCreate(query) {
    return request({
        url: "/wechat/dynamic/create",
        method: "post",
        data: query,
    });
}

//获取最大的排序数
export function getMaxOrderNum(query) {
    return request({
        url: "/wechat/dynamic/getMaxOrderNum",
        method: "get",
        params: query,
    });
}

//删除自定义动态参数
export function getDeleteCustom(query) {
    return request({
        url: "/wechat/dynamic/delete",
        method: "get",
        params: query,
    });
}

// 更新自定义动态参数
export function postCustomUpdate(query) {
    return request({
        url: "/wechat/dynamic/update",
        method: "post",
        data: query,
    });
}

// 获取自定义动态参数详情
export function getDetailCustom(query) {
    return request({
        url: "/wechat/dynamic/get",
        method: "get",
        params: query,
    });
}
//获得静态和动态全部列表
export function getDynamicAll(query) {
    return request({
        url: "/wechat/dynamic/all",
        method: "get",
        params: query,
    });
}



