var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "send-notice margin-bottom-sm" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: 1 } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "header-icon el-icon-message-path" }),
                _vm._v(" 群公告 "),
                _c("span", { staticClass: "expand" }, [
                  _vm._v(_vm._s(_vm.activeNames.length > 0 ? "收起" : "展开")),
                ]),
              ]),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "send-form",
                  attrs: {
                    disabled: _vm.visible,
                    rules: _vm.rules,
                    model: _vm.taskNoticeForm,
                    "label-width": "110px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发送方式", prop: "name" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.taskNoticeForm.sendType,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskNoticeForm, "sendType", $$v)
                            },
                            expression: "taskNoticeForm.sendType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("立即发送"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("定时发送"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.taskNoticeForm.sendType == 2
                ? _c(
                    "div",
                    { staticClass: "add-btn", on: { click: _vm.addTask } },
                    [_vm._v("新建")]
                  )
                : _vm._e(),
              _vm.taskNoticeForm.sendNoticeReqVOList?.length > 0 &&
              _vm.taskNoticeForm.sendType == 2
                ? _c(
                    "div",
                    { staticClass: "add-main" },
                    [
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.activeItemNames,
                            callback: function ($$v) {
                              _vm.activeItemNames = $$v
                            },
                            expression: "activeItemNames",
                          },
                        },
                        _vm._l(
                          _vm.taskNoticeForm.sendNoticeReqVOList,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "add-item" },
                              [
                                _c(
                                  "el-collapse-item",
                                  {
                                    class: [
                                      _vm.taskNoticeForm.sendType == 1
                                        ? "add-item-container"
                                        : "",
                                    ],
                                    attrs: { name: index },
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.stopHaneldle.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-remove",
                                            staticStyle: { color: "#D3D2D2" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delectItem(index)
                                              },
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "title-text" },
                                            [_vm._v("进入后,  第")]
                                          ),
                                          _c("el-input", {
                                            staticStyle: { width: "60px" },
                                            attrs: {
                                              disabled: _vm.visible,
                                              type: "number",
                                            },
                                            model: {
                                              value: item.day,
                                              callback: function ($$v) {
                                                _vm.$set(item, "day", $$v)
                                              },
                                              expression: "item.day",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "title-text" },
                                            [_vm._v("天,")]
                                          ),
                                          _c("el-time-picker", {
                                            attrs: {
                                              disabled: _vm.visible,
                                              "value-format": _vm.dataFormat,
                                              placeholder: "请选择开始时间",
                                            },
                                            model: {
                                              value: item.sendTime,
                                              callback: function ($$v) {
                                                _vm.$set(item, "sendTime", $$v)
                                              },
                                              expression: "item.sendTime",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "title-text" },
                                            [_vm._v("发送")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("span", { staticClass: "expand" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.activeItemNames.length > 0
                                              ? "收起"
                                              : "展开"
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "item_notice" },
                                      [
                                        _c(
                                          "el-form",
                                          {
                                            ref: "ruleForm",
                                            refInFor: true,
                                            attrs: {
                                              rules: _vm.rules,
                                              disabled: _vm.visible,
                                              model: _vm.taskNoticeForm,
                                              "label-width": "110px",
                                              "label-position": "left",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "公告内容",
                                                  "label-position": "left",
                                                  prop: "text",
                                                },
                                              },
                                              _vm._l(
                                                item.materialElements,
                                                function (items) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: items.id,
                                                      staticStyle: {
                                                        position: "relative",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          resize: "none",
                                                          maxlength: "1000",
                                                          "show-word-limit": "",
                                                          placeholder:
                                                            "请输入公告内容...",
                                                          size: "medium",
                                                          type: "textarea",
                                                          autosize: {
                                                            minRows: 3,
                                                            maxRows: 3,
                                                          },
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.setNoticeData,
                                                        },
                                                        model: {
                                                          value: items.text,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              items,
                                                              "text",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "items.text",
                                                        },
                                                      }),
                                                      _c("select-emoji", {
                                                        on: {
                                                          addEmoji: function (
                                                            $event
                                                          ) {
                                                            return _vm.addEmoji(
                                                              $event,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }