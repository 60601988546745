export const plugins = `link image fullscreen link media table lists hr emoticons attachment paste atwho`
export const toolbar = `undo redo backcolor bold italic underline hr bullist numlist emoticons attachment`

export const getDefaultConfig = (textarea, tinymceId) => {
  let content = ''
  const pasteContent = (args, content) => {
    const tinymce = window.tinymce.get(tinymceId)
    let str = ''
    content.map((v, i) => {
      str += `${i > 0 ? '<br>' : ''}${v}`
    })
    tinymce.insertContent(str)
  }
  return {
    language: 'zh_CN',
    menubar: false,
    plugins,
    toolbar: textarea ? '' : toolbar,
    resize: textarea ? false : true,
    statusbar: textarea ? false : true,
    min_height: 240,
    max_height: 500,
    branding: false, // 禁用 "Powered by Tiny"
    object_resizing: false, // 禁用所有调整图像/表格大小的功能
    powerpaste_word_import: 'clean', // 控制如何筛选从Microsoft Word粘贴的内容
    paste_data_images: textarea ? false : true, // 是否允许粘贴图像
    default_link_target: '_blank',
    images_upload_url:
      process.env.VUE_APP_BASE_API + '/admin-api/infra/file/upload',
    paste_preprocess: (plugin, args) => {
      console.log(1, plugin, args)
      // if (args.content.startsWith('<img src=')) return
      // args.content = ''
      // let content = content.split(/[(\r\n)\r\n]/)
      // pasteContent(args, content)
    },
    setup(editor) {
      editor.on('paste', (e) => {
        content = e.clipboardData.getData('text/plain')
        console.log(2, content)
      })
    },
  }
}
