var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频播放",
            visible: _vm.dialogVideo,
            width: "40%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVideo = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _vm.playerOptions.sources[0].src
            ? _c("video-player", {
                ref: "videoPlayer",
                staticClass: "video-player vjs-custom-skin",
                attrs: { playsinline: true, options: _vm.playerOptions },
                on: {
                  play: function ($event) {
                    return _vm.onPlayerPlay($event)
                  },
                  pause: function ($event) {
                    return _vm.onPlayerPause($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }