var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "inline-block", "margin-left": "5px" } },
    [
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: {
            "popper-class": _vm.popperClass,
            effect: _vm.effect,
            placement: _vm.placement,
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "line-height": "1.5" },
              attrs: { slot: "content" },
              slot: "content",
            },
            [_vm._t("content")],
            2
          ),
          _c("i", { staticClass: "icon-question" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }