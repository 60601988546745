<template>
  <div>
    <div class="flex-center justify-between">
      <div class="text-999">社交账号</div>
      <div>{{ message.sendTime }}</div>
    </div>
    <div class="text-black padding-tb-xs">
      <img style="vertical-align: middle;" :src="message.accountType == '2' ? qwImg : wxImg" alt="" />
      {{ message.nickname }}
    </div>
    <div class="padding-bottom-xs text-999">通知内容</div>
    <div style="color: #718096;"><span class="text-black" style="min-width: 30px;">{{ message.sendName }}</span>
      {{ message.content }}</div>
  </div>
</template>

<script>
import wxImg from "@/assets/images/wechathollow.png";
import qwImg from "@/assets/images/qw.png";
export default {
  name: "message",
  props: {
    message: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      wxImg,
      qwImg
    }
  },
}
</script>

<style lang="scss" scoped></style>