var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        click: function ($event) {
          $event.stopPropagation()
          _vm.isFocused = false
        },
      },
    },
    [
      _c("div", { staticClass: "editor-input-view solids" }, [
        _c("div", {
          ref: "editor",
          staticClass: "editor",
          style: { height: `${_vm.height}px` },
          attrs: { contenteditable: "true", id: "editor" },
          domProps: { innerHTML: _vm._s(_vm.innerHTML) },
          on: { focus: _vm.focus, blur: _vm.blur },
        }),
        _c(
          "div",
          {
            staticClass: "solids-top",
            on: {
              mouseenter: function ($event) {
                _vm.isFocused = true
              },
            },
          },
          [_vm._t("footer")],
          2
        ),
      ]),
      _c("div", { staticClass: "flex-center justify-between" }, [
        _c(
          "div",
          { on: { click: _vm.clickEmoji, mouseenter: _vm.mouseenter } },
          [
            _vm.selectEmoji
              ? _c("select-emoji", { on: { addEmoji: _vm.addEmoji } })
              : _vm._e(),
          ],
          1
        ),
        _vm.linmitNum
          ? _c("div", { staticClass: "wordCount bg-white" }, [
              _vm._v(_vm._s(_vm.wordCount) + " / " + _vm._s(_vm.linmitNum)),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }