<template>
    <div class="task-forward margin-bottom-sm">
        <el-collapse v-model="activeNames">
            <el-collapse-item :name="1">
                <template slot="title">
                    <i class="header-icon el-icon-forward-path"></i>
                    转发
                    <span class="expand">{{ activeNames.length > 0 ? '收起' : '展开' }}</span>
                </template>
                <el-form class="forward-form" ref="ruleForm" :rules="rules" :disabled="visible || visibleStatus"
                    :model="taskForwardForm" label-width="110px" label-position="left">
                    <el-form-item label="转发对象" prop="taskTransferCustomerReqVOList">
                        <div>
                            <span class="margin-right-xs">选择人</span>
                            <el-button type="text" class="margin-left-xs" size="medium"
                                :disabled="visible || visibleStatus" @click="addPeople('1')">
                                <i class="el-icon-circle-plus add-icon"></i> 添加
                            </el-button>
                        </div>
                        <div class="people-select" v-if="taskForwardForm.taskTransferCustomerReqVOList?.length">
                            <span @click="addPeople('1')" class="people-select-title">{{ friendDataLimit }}</span>
                            等<span>{{ taskForwardForm.taskTransferCustomerReqVOList?.length }}</span>个客户
                        </div>

                        <div class="line-add"></div>
                        <div>
                            <span class="margin-right-xs">选择群</span>
                            <el-button type="text" size="medium" :disabled="visible || visibleStatus"
                                @click="addPeople('2')">
                                <i class="el-icon-circle-plus add-icon"></i> 添加
                            </el-button>
                        </div>
                        <div class="people-select" v-if="taskForwardForm.taskTransferCustomerGroupReqVOList?.length">
                            <span @click="addPeople('2')" class="people-select-title">{{ groupDataLimit }}</span>
                            等<span>{{ taskForwardForm.taskTransferCustomerGroupReqVOList?.length }}</span>个客户群
                        </div>
                    </el-form-item>
                    <el-form-item class="taskForwardView" label="转发说明" prop="taskTransferMaterialElements"
                        :rules="[{ required: true, message: '请输入转发说明', trigger: 'blur' }]">
                        <edit-material ref="editMaterial" :disabled="visible || visibleStatus"
                            :materialElements.sync="taskForwardForm.taskTransferMaterialElements"
                            :isSopShow="true" :hideCustom="true"></edit-material>
                    </el-form-item>

                </el-form>
            </el-collapse-item>
        </el-collapse>
        <select-dialog ref="selectDialog" @getCrowPeople="getCrowPeople" :formData="{
            type,
            selectData: type == 1 ? taskForwardForm.taskTransferCustomerReqVOList : taskForwardForm.taskTransferCustomerGroupReqVOList,
            staffAccountId: taskForwardForm.staffAccountId
        }"></select-dialog>
    </div>
</template>

<script>
import SelectDialog from "@/views/components/selectDialog/selectDialog.vue";
import { getSopManagerParma } from "@/api/material/material.js";
import ClientParams from '../../components/clientParams/clientParams.vue';
import EditMaterial from '../../robot/components/editMaterial.vue';
export default {
    components: {
        SelectDialog,
        ClientParams,
        EditMaterial,
    },
    provide() {
        return {
            flag: () => {
                return this.$route.query.isStage == 0 ? 2 : 1
            }
        }
    },
    data() {
        const validateSendObject = (rule, value, callback) => {
            if (this.taskForwardForm.taskTransferCustomerReqVOList?.length == 0 && this.taskForwardForm.taskTransferCustomerGroupReqVOList?.length == 0) {
                callback(new Error("请选择发送对象"));
            } else {
                callback();
            }
        };
        return {
            activeNames: [1],
            rules: {
                name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                taskTransferCustomerReqVOList: [{ required: true, validator: validateSendObject, trigger: 'blur' }]
            },
            taskForwardForm: {
                actionType: 2,
                sendType: 1,
                forwardToDesc: '',
                taskTransferCustomerReqVOList: [],
                taskTransferCustomerGroupReqVOList: [],
                taskTransferMaterialElements: []
            },
            parameterType: '',
            parameterList: [],
            type: 1,
            clientInfoList: [],
            visible: this.$route.query?.visible == 3,
            visibleStatus: this.$route.query?.visibleStatus == 2,
        }
    },
    computed: {
        friendDataLimit() {
            return this.taskForwardForm.taskTransferCustomerReqVOList.filter((v, i) => i < 2).map(v => v.nickname || v.targetAccountName).join("、")
        },
        groupDataLimit() {
            return this.taskForwardForm.taskTransferCustomerGroupReqVOList.filter((v, i) => i < 2).map(v => v.groupName || v.targetAccountName).join("、")
        },
        isGroup() {
            return this.$route.query.sopType == 'clientSOP' ? false : true;
        }
    },
    methods: {
        addPeople(val) {
            this.type = val;
            this.$nextTick(() => {
                this.$refs.selectDialog.open();
            });
        },
        handleClick() { },
        // 选择人/群
        getCrowPeople(data) {
            const name = ["nickname", "groupName"][this.type - 1];
            const id = ["friendAccountId", "groupAccountId"][this.type - 1];
            data.map(v => {
                v.objType = this.type;
                v.targetAccountName = v[name];
                v.targetAccountId = v[id];
            })
            this.type == 1 ? this.taskForwardForm.taskTransferCustomerReqVOList = data : this.taskForwardForm.taskTransferCustomerGroupReqVOList = data;
        },
        // 回显taskForwards数据
        setTaskForwards(data) {
            if (!data) return;
            const messageList = ["taskTransferCustomerGroupReqVOList", "taskTransferCustomerReqVOList"];
            messageList.map(message => {
                const name = ["nickname", "groupName"][message == 'taskTransferCustomerGroupReqVOList' ? 1 : 0];
                const id = ["friendAccountId", "groupAccountId"][message == 'taskTransferCustomerGroupReqVOList' ? 1 : 0];
                data[message]?.map(v => {
                    v[name] = v.targetAccountName;
                    v[id] = v.targetAccountId;
                })
                this.taskForwardForm[message] = data[message];
            })
            this.taskForwardForm.taskTransferMaterialElements = data.taskTransferMaterialElements.map(v => {
                v.ext = v.ext && typeof v.ext == 'string' ? JSON.parse(v.ext) : {};
                // console.log(JSON.parse(v.ext), typeof v.ext == 'string', "typeof v.ext == 'string'");

                return v;
            })
            // console.log(data.taskTransferMaterialElements, 'data.taskTransferMaterialElements');
            // this.taskForwardForm.taskTransferMaterialElements = data.taskTransferMaterialElements;
        },
        //获取素材支持的参数
        getManagerParma() {
            getSopManagerParma({ type: this.isGroup ? 2 : 1, businessType: this.$route.query.isStage == 1 ? 1 : 2 }).then(res => {
                this.clientInfoList = res.data.filter(v => v.flag == 2);
            })
        },
        // 选择客户信息
        clientInfoCommand(data, item, index) {
            console.log(data, item, index, "data, item, index");
            const command = this.clientInfoList.find(v => v.id == data[1].command);
            this.$refs[`tinymce${index}`][0].insertContent(command.name, command.id, command.paramValueId);
        },
    },
    created() {
        this.getManagerParma();
    },
    watch: {
        taskForwardForm: {
            handler: function (val) {
                let taskForwardForm = JSON.parse(JSON.stringify(this.taskForwardForm));
                taskForwardForm.taskTransferMaterialElements.map(v => {
                    if (v.ext && typeof v.ext == 'object') {
                        v.ext = JSON.stringify(v.ext);
                    }
                })
                this.$emit('taskForward', taskForwardForm)
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" scoped>
.task-forward ::v-deep {
    border-radius: 2px;

    .el-collapse-item__content .el-form {
        padding: 0 48px;
    }

    .el-icon-forward-path {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        background: url(../../../assets/images/forwardPath.png) center center no-repeat;
        background-size: contain;
    }

    .el-collapse-item__wrap,
    .el-collapse,
    .el-collapse-item__header {
        border-bottom: none;
        border-top: none;
    }

    .el-collapse-item__header {
        padding: 0 24px 0 40px;
        height: 40px;
        opacity: 1;
        line-height: 40px;
        background: #FDF8EE;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        position: relative;
    }

    .el-icon-arrow-right {
        color: #307EF2;
    }

    .expand {
        position: absolute;
        font-size: 14px;
        font-weight: normal;
        color: #307EF2;
        right: 50px;
    }

    .add-icon-text {
        color: #1c53d9;
        font-size: 14px;
        cursor: pointer;

        .add-icon {
            color: #1c53d9;
            width: 15px;
            height: 15px;
            margin-left: 8px;
        }
    }

    .line-add {
        margin: 30px 0 15px 0;
        height: 1px;
        background: rgba(216, 216, 216, 0.5);
    }

    .people-select {
        height: 98px;
        width: 400px;
        padding: 20px 16px;
        background: #F9F9F9;
        border-radius: 2px;
        font-weight: 400;
        font-size: 14px;
        color: #333;

        .people-select-title {
            color: #307EF2;
            cursor: pointer;
        }
    }

    .people-illustrate {
        // width: 383px;
        // border: 1px solid #d8d8d8;
        position: relative;
        height: 220px;

        .wordCount {
            bottom: -35px;
            position: relative;
            background-color: transparent;
            z-index: 99;
        }
    }


    .bottom-dropdown {
        position: relative;
        bottom: 32px;
        border: 0.5px solid #C0CCDA;
        border-top: 0 none;
        padding: 0 10px;
        background: #F5F8FD;
    }

    .scroll-container {
        max-height: 200px;

        .popover-item {
            padding: 5px;

            &:hover {
                color: #307ef2;
                cursor: pointer;
            }
        }
    }

}
</style>