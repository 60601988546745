import { h } from '@formily/vue'
import { Popover } from 'element-ui'
import JsonSchema from '@/formily/base/json-schema'
import { defineComponent, h as hInVue2, getCurrentInstance } from 'vue'
import Reference from '@/formily/components/advanced-filter/reference'
import Header from '@/formily/components/advanced-filter/header'

export default defineComponent({
  name: 'JsonSchemaPopover',
  setup(_props, { slots, attrs, expose }) {
    const vm = getCurrentInstance()?.proxy

    const closePopover = () => {
      vm.$refs.popoverRef.doClose()
    }

    const getForm = () => {
      return vm.$refs.jsonSchemaRef?.form
    }

    expose({ closePopover, getForm })

    return () => {
      return h(
        Popover,
        {
          attrs: {
            width: '700',
            placement: 'bottom',
            trigger: 'click',
            ...attrs,
          },
          ref: 'popoverRef',
        },
        {
          reference: () => {
            return hInVue2(Reference)
          },
          default: () => {
            return hInVue2('div', {}, [
              hInVue2(Header, {
                on: {
                  close: closePopover,
                },
              }),
              hInVue2(JsonSchema, {
                props: { schema: attrs.schema },
                ref: 'jsonSchemaRef',
                attrs,
              }),
            ])
          },
          ...slots,
        },
      )
    }
  },
})
