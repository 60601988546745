var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("p", { staticClass: "advanced-filter-dialog__header" }, [
    _c("span", [_vm._v("高级筛选")]),
    _c("i", {
      staticClass: "el-icon-close",
      on: {
        click: function ($event) {
          return _vm.$emit("close")
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }