<template>
    <el-popover placement="right-start" popper-class="importInfo-popover" v-model="visible" width="150"
        trigger="manual">
        <span slot="reference" class="pointer padding-right-xs" style="color: #778CA2;" @click="visible = !visible">
            <i class="el-icon-circle-plus"></i>
            引用客户{{ $route.query.sendType == 1 ? '' : '群' }}信息
        </span>
        <div class="importInfo padding-top-xs padding-left-xs"></div>

        <div class="dropdown-item" v-for="(item, index) in clientInfoList" :key="index">
            <div style="width: 100%;" @click="clickChild(item)" v-if="item.paramType == 1"
                class="flex-center pointer padding-left-sm">
                {{ item.name }}
            </div>
            <el-popover v-if="item.paramType == 2" placement="right-start" popper-class="importInfo-popover"
                :visible-arrow="false" width="180" v-model="item.visible" trigger="manual">
                <div slot="reference" class="dropdown-item flex-center" @click="clickChild(item)">
                    <div style="width: 100%;" class="flex-center pointer padding-left-sm">{{ item.name }}</div>
                    <i class="el-icon-arrow-right padding-right-xs"></i>
                </div>

                <div @click="clientInfoCommand(i, item)" v-for="i in item.childList" :key="i.id" class="dropdown-item">
                    <div style="width: 100%;" class="flex-center pointer padding-left-sm">
                        {{ i.paramValue.map(v => v.show).join("/") }}
                    </div>
                </div>
            </el-popover>
        </div>
    </el-popover>
</template>

<script>
import { getManagerParma, getSopManagerParma, getManagerParmaValues } from "@/api/material/material.js";
export default {
    inject: ['flag'],
    data() {
        return {
            clientInfoList: [], // 素材支持的参数
            visible: false,
        }
    },
    methods: {
        //获取素材/SOP支持的参数
        getManagerParma() {
            // type 1 客户sop 2 客群sop
            // businessId 2 返回1个昵称 4全部返回
            // flag 阶段发消息、阶段转发、任务发消息取1 任务转发取2
            const isSop = this.$route.name == 'sopCustomAdd';
            const sopParams = { type: this.$route.query.sendType == 1 ? 1 : 2, businessType: this.$route.query.isStage == 1 ? 1 : 2 };
            const sendMessageParams = { type: this.$route.query.sendType == 1 ? 1 : 2, businessId: 2 };
            const requestUrl = [getSopManagerParma, getManagerParma][isSop ? 0 : 1];
            requestUrl(isSop ? sopParams : sendMessageParams).then(res => {
                const flag = this.flag() ? this.flag() : 1;
                let requestData = isSop ? res.data.filter(v => v.flag == flag) : res.data;
                requestData.map(v => {
                    v.visible = false;
                    if (v.paramType == 2) {
                        getManagerParmaValues({ materialParamId: v.id }).then(response => {
                            response.data.map(v => {
                                v.paramValue = JSON.parse(v.paramValue)
                            })
                            v.childList = response.data;
                        })
                    }
                })
                this.clientInfoList = requestData;
            })
        },
        // 点击选项
        clickChild(item) {
            this.clientInfoList.map(v => {
                if (v.id != item.id) {
                    v.visible = false;
                } else {
                    item.visible = true;
                }
            })
            if (item.paramType == 1) {
                this.clientInfoCommand(item);
            }
        },
        // 点击选项时传递父元素数据
        clientInfoCommand(data, item) {
            if (item && item.paramType == 2) {
                item.paramValueId = data.id
            }
            this.$emit("command", data.paramType == 1 ? data : item)
            this.hidePopover();
        },
        // 隐藏popover
        hidePopover() {
            this.visible = false;
            this.clientInfoList.map(v => {
                v.visible = false;
            })
        }
    },
    mounted() {
        document.addEventListener("click", (e) => {
            if (!e.target.closest(".el-popover__reference-wrapper")) {
                this.hidePopover();
            }
        })
    },
    created() {
        if (this.clientInfoList.length == 0) {
            this.getManagerParma();
        }
    },
}
</script>

<style lang="scss" scoped>
.dropdown-item {
    &:hover {
        background: #E6EDFE;
    }
}

::v-deep .popover {
    padding: 0;
}
</style>