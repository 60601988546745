var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content",
      style: {
        background: "url(" + _vm.imgUrl + ") center center no-repeat",
        "background-size": "contain",
      },
    },
    [
      Object.keys(_vm.noticeInfo).length > 0
        ? _c(
            "div",
            {
              staticClass:
                "text-cut bg-white margin-lr-sm padding-xs radius margin-top-xs",
            },
            [
              _c("i", {
                staticClass: "icon-notice",
                staticStyle: { "vertical-align": "middle" },
              }),
              _vm._v(
                " " +
                  _vm._s(_vm.noticeInfo.contentBack || _vm.noticeInfo.text) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-scrollbar",
        {
          ref: "scrollContainer",
          staticClass: "scroll-container",
          style: {
            height: Object.keys(_vm.noticeInfo).length > 0 ? "440px" : "",
          },
        },
        [
          _c(
            "div",
            {
              ref: "container",
              staticClass: "padding-bottom-sm padding-left-sm",
            },
            _vm._l(_vm.materialData, function (item, index) {
              return _c(
                "div",
                {
                  key: JSON.stringify(item) || index,
                  ref: `material${index}`,
                  refInFor: true,
                  staticClass: "flex margin-top-xs",
                },
                [
                  _c("div", { staticClass: "icon-avatar" }),
                  _c("div", { staticClass: "preview-content margin-left-xs" }, [
                    item.type == "0"
                      ? _c(
                          "div",
                          {
                            staticClass: "padding-text",
                            class: [item.isSuccess ? "preview-max" : ""],
                          },
                          [
                            _c("el-scrollbar", [
                              _c("div", {
                                staticStyle: { "line-height": "32px" },
                                domProps: {
                                  innerHTML: _vm._s(item.contentBack),
                                },
                              }),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.type == "1"
                      ? _c(
                          "div",
                          {
                            staticClass: "preview-view pointer",
                            class: [item.isSuccess ? "preview-max" : ""],
                          },
                          [
                            _c("el-image", {
                              staticStyle: { "border-radius": "8px" },
                              attrs: {
                                src: item.fileUrl,
                                "preview-src-list": [item.fileUrl],
                              },
                            }),
                            Number(item.isSuccess) === 2
                              ? _c("i", { staticClass: "el-icon-warning" })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.type == "2"
                      ? _c(
                          "div",
                          {
                            staticClass: "preview-view pointer",
                            class: [item.isSuccess ? "preview-max" : ""],
                          },
                          [
                            _c("video", {
                              staticStyle: {
                                width: "100%",
                                "border-radius": "8px",
                              },
                              attrs: { src: item.fileUrl },
                            }),
                            _c("div", { staticClass: "icon-play" }),
                            Number(item.isSuccess) === 2
                              ? _c("i", { staticClass: "el-icon-warning" })
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm.officeArr.indexOf(String(item.type)) != -1
                      ? _c(
                          "div",
                          {
                            staticClass: "preview-view pointer",
                            class: [item.isSuccess ? "preview-max" : ""],
                          },
                          [
                            _vm._v(" " + _vm._s(item.fileName) + " "),
                            Number(item.isSuccess) === 2
                              ? _c("i", { staticClass: "el-icon-warning" })
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    item.type == "7"
                      ? _c(
                          "div",
                          {
                            staticClass: "preview-view pointer",
                            class: [item.isSuccess ? "preview-max" : ""],
                          },
                          [
                            _c("div", { staticClass: "item-h5" }, [
                              _c("div", { staticClass: "h-title" }, [
                                _vm._v(" " + _vm._s(item.title) + " "),
                              ]),
                              _c("div", { staticClass: "h-content" }, [
                                _vm._v(" " + _vm._s(item.description) + " "),
                              ]),
                              _c("img", {
                                staticClass: "h-img",
                                attrs: { src: item.url, alt: "" },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    item.type == "8"
                      ? _c(
                          "div",
                          {
                            staticClass: "preview-text",
                            class: [item.isSuccess ? "preview-max" : ""],
                          },
                          [
                            _c("div", [_vm._v("群公告")]),
                            _c(
                              "el-scrollbar",
                              { staticClass: "margin-top-xs" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "word-break": "break-all",
                                      "max-height": "150px",
                                      "white-space": "pre-line",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.contentBack || item.text)
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            Number(item.isSuccess) === 2
                              ? _c("i", { staticClass: "el-icon-warning" })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.type == "10"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "solid program-view margin-top-xs bg-white radius-8 pointer padding-xs",
                            staticStyle: { width: "200px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-center" },
                              [
                                !item.mpLogo
                                  ? _c("i", {
                                      staticClass:
                                        "icon-top-logo margin-right-xs",
                                    })
                                  : _c("el-image", {
                                      staticClass:
                                        "icon-top-logo margin-right-xs",
                                      attrs: { src: item.mpLogo },
                                    }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-cut",
                                    staticStyle: { "max-width": "170px" },
                                  },
                                  [_vm._v(_vm._s(item.title))]
                                ),
                              ],
                              1
                            ),
                            _c("div", {
                              staticClass: "text-cut2 margin-top-xs",
                              staticStyle: { "line-height": "1.5" },
                            }),
                            _c("el-image", {
                              staticClass: "image",
                              attrs: { src: item.url, fit: "contain" },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-center solid-top padding-top-xs margin-top-xs",
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "icon-bottom-logo margin-right-xs",
                                }),
                                _vm._v("小程序 "),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    ![0, 7, 8, 10, "0"].includes(item.type)
                      ? _c(
                          "div",
                          { staticClass: "download" },
                          [
                            _vm.officeArr.indexOf(String(item.type)) == -1
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { padding: "5px 10px" },
                                    attrs: {
                                      size: "small",
                                      type: "primary",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.previewFile(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "icon-preview" }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text-sm" }, [
                                      _vm._v("预览"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                staticStyle: { padding: "5px 10px" },
                                attrs: { type: "primary", plain: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFile(item)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "icon-down" }),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-sm" }, [
                                  _vm._v("下载"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _c("preview-video", { ref: "previewVideo", attrs: { url: _vm.playUrl } }),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: {
              "z-index": "99999",
              onClose: () => {
                _vm.showViewer = false
              },
              "url-list": _vm.previewUrlList,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }