import request from "@/utils/request";

export function fileUpload(data) {
  return request({
    url: "/infra/file/upload",
    method: "POST",
    data,
  });
}
// 微信机器人分页查询
export function getWechatPage(params) {
  return request({
    url: "/wechat/client/page",
    method: "get",
    params,
  });
}
//获取微信二维码(点击登陆后1秒轮询获取最新的二维码，收到二维码状态=2-确认登录，关闭二维码窗口、刷新列表)
export function getQrcode(params) {
  return request({
    url: "/wechat/client/getQrcode",
    method: "get",
    params,
  });
}
//企业微信登录,弹框输入验证码校验
export function reportLoginCode(data) {
  return request({
    url: "/wechat/client/reportLoginCode",
    method: "post",
    data,
  });
}


// 使用记录
export function getWechatRecords(params) {
  return request({
    url: "/wechat/client/records",
    method: "get",
    params,
  });
}
//分配机器人
export function wechatAllocate(data) {
  return request({
    url: "/wechat/client/allocate",
    method: "post",
    data,
  });
}
//取消分配机器人
export function wechatCancelAllocate(data) {
  return request({
    url: "/wechat/client/cancelAllocate",
    method: "post",
    data,
  });
}
//登录
export function wechatLogin(data) {
  return request({
    url: "/wechat/client/login",
    method: "post",
    data,
  });
}
//登出
export function wechatLogout(data) {
  return request({
    url: "/wechat/client/logout",
    method: "post",
    data,
  });
}
//休眠
export function wechatSleep(data) {
  return request({
    url: "/wechat/client/sleep",
    method: "post",
    data,
  });
}
//唤醒
export function wechatWakeup(data) {
  return request({
    url: "/wechat/client/wakeup",
    method: "post",
    data,
  });
}
//解绑
export function wechatUnbind(data) {
  return request({
    url: "/wechat/client/unbind",
    method: "post",
    data,
  });
}


//托管
export function wechatEntrust(data) {
  return request({
    url: "/wechat/entrust/customerAndGroup",
    method: "post",
    data,
  });
}
// 取消托管
export function wechatCancelEntrust(data) {
  return request({
    url: "/wechat/entrust/cancel",
    method: "post",
    data,
  });
}
// 已托管群分页查询
export function entrustedGroupPage(params) {
  return request({
    url: "/wechat/client/entrustedGroupPage",
    method: "get",
    params,
  });
}
// 已托管好友分页查询
export function entrustedFriendPage(params) {
  return request({
    url: "/wechat/client/entrustedFriendPage",
    method: "get",
    params,
  });
}

/*
* 群发
*/

// 群发分页查询
export function wechatSendPage(params) {
  return request({
    url: "/send/group/page",
    method: "get",
    params,
  });
}
// 群发记录明细分页
export function wechatDetailSendPage(params) {
  return request({
    url: "/send/group/record/detail/page",
    method: "get",
    params,
  });
}
// 群发记录开始
export function wechatDetailStart(params) {
  return request({
    url: "/send/group/record/start",
    method: "get",
    params,
  });
}
// 群发记录暂停
export function wechatDetailPaused(params) {
  return request({
    url: "/send/group/record/stop",
    method: "get",
    params,
  });
}
// 发送消息详情
export function wechatSendDetail(params) {
  return request({
    url: "/send/group/detail",
    method: "get",
    params,
  });
}
// 待发送消息创建
export function wechatSendCreate(data) {
  return request({
    url: "/send/group/create",
    method: "post",
    data,
  });
}

// 评估时间

export function postGroupAssessment(data) {
  return request({
    url: "/send/group/assessment",
    method: "post",
    data,
  });
}
// 启用群发任务
export function wechatChangeEnable(data) {
  return request({
    url: "/send/group/enable",
    method: "post",
    data,
  });
}

// 禁用群发任务
export function wechatChangeDisable(data) {
  return request({
    url: "/send/group/disable",
    method: "post",
    data,
  });
}

// 删除 未发送消息
export function wechatSendDelete(data) {
  return request({
    url: "/send/group/delete",
    method: "post",
    data,
  });
}
// 编辑群发任务
export function wechatSendUpdate(data) {
  return request({
    url: "/send/group/update",
    method: "post",
    data,
  });
}


// 已托管群列表查询
export function entrustedGroupList(params) {
  return request({
    url: "/wechat/client/entrustedGroupList",
    method: "get",
    params,
  });
}
// 已托管群列表查询
export function entrustedFriendList(params) {
  return request({
    url: "/wechat/client/entrustedFriendList",
    method: "get",
    params,
  });
}

// 查看已执行任务详情
export function getTaskSendDetail(params) {
  return request({
    url: "/wechat/send/taskSendDetail",
    method: "get",
    params,
  });
}

// 来源微信列表
export function getWechatList(params) {
  return request({
    url: "/wechat/client/staffAccount/List",
    method: "get",
    params,
  });
}

// 来源微信列表
export function createHFive(data) {
  return request({
    url: "/material/element/h5/content/create",
    method: "post",
    data,
  });
}

// 获取微信登录信息
export function getWechatLoginInfo(params) {
  return request({
    url: "/wechat/client/login/info",
    method: "get",
    params,
  });
}

// 回复设置(群)
export function aitrustSetGroup(data) {
  return request({
    url: "/wechat/entrust/setGroupWhiteList",
    method: "post",
    data,
  });
}

// 查看回复设置(群)
export function aitrustQuery(data) {
  return request({
    url: "/wechat/entrust/queryGroupWhiteList",
    method: "post",
    data,
  });
}

// 默认启用开启
export function talkEnabled(data) {
  return request({
    url: "/wechat/talk/enabled",
    method: "post",
    data,
  });
}

// 默认启用关闭
export function talkClose(data) {
  return request({
    url: "/wechat/talk/close",
    method: "post",
    data,
  });
}

// 默认启用关闭
export function talkQuery(params) {
  return request({
    url: "/wechat/talk/query",
    method: "get",
    params,
  });
}

// 对话范围 客户群列表查询
export function customerGroupPage(params) {
  return request({
    url: "/wechat/conversation/customerGroupPage",
    method: "get",
    params,
  });
}

// 对话范围 客户群列表查询
export function customerPage(params) {
  return request({
    url: "/wechat/conversation/customerPage",
    method: "get",
    params,
  });
}
export function targetDetail(params) {
  return request({
    url: "/wechat/send/targetDetail",
    method: "get",
    params,
  });
}
// 群发记录分页 
export function groupRecordPage(params) {
  return request({
    url: "/send/group/record/page",
    method: "get",
    params,
  });
}
// 免打扰

// 获取免打扰配置
export function getDisturb(params) {
  return request({
    url: "/send/not/disturb/get",
    method: "get",
    params,
  });
}
// 免打扰创建
export function creatDisturb(data) {
  return request({
    url: "/send/not/disturb/create",
    method: "post",
    data,
  });
}
// 更新免打扰配置
export function updateDisturb(data) {
  return request({
    url: "/send/not/disturb/update",
    method: "post",
    data,
  });
}
//对话范围刷新群成员
export function refreshGroupMember(data) {
  return request({
    url: "/wechat/conversation/refreshGroupMember",
    method: "post",
    data,
  });
}

// 获取SOP AI员工集合
export function queryStaffAccountIdList(query) {
  return request({
    url: `/sop/sopGroupConfig/queryStaffAccountIdList`,
    method: "get",
    params: query,
  });
}

// 获取小程序素材列表
export function getMaterialMpPage(query) {
  return request({
    url: `/material/mp/page`,
    method: "get",
    params: query,
  });
}
// 创建小程序素材
export function createMaterialMp(data) {
  return request({
    url: "/material/mp/create",
    method: "post",
    data,
  });
}

// 免登录上报全量好友和群列表
export function noLoginRefresh(data) {
  return request({
    url: `/wechat/client/noLoginRefresh`,
    method: "post",
    data,
  });
}
