var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "send-message margin-bottom-sm" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: 1 } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "header-icon el-icon-message-path" }),
                _c("span", { staticClass: "text-black" }, [_vm._v("发消息")]),
                _c("span", { staticClass: "expand" }, [
                  _vm._v(_vm._s(_vm.activeNames.length > 0 ? "收起" : "展开")),
                ]),
              ]),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "send-form",
                  attrs: {
                    disabled: _vm.visibleStatus || _vm.visible,
                    rules: _vm.rules,
                    model: _vm.taskMessageForm,
                    "label-width": "130px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发送方式",
                        prop: "sendType",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.changeSendType },
                          model: {
                            value: _vm.taskMessageForm.sendType,
                            callback: function ($$v) {
                              _vm.$set(_vm.taskMessageForm, "sendType", $$v)
                            },
                            expression: "taskMessageForm.sendType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("立即发送"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("定时发送"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.taskMessageForm.sendType == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "crossDayExecution", required: "" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "flex-center",
                              attrs: { slot: "label" },
                              slot: "label",
                            },
                            [
                              _vm._v(" 按顺序发送 "),
                              _c(
                                "question-tooltip",
                                { attrs: { placement: "top" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        " 不按顺序，指消息超过24点后则不再发送"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " 按顺序，指消息超过24点会继续发送 "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.taskMessageForm.crossDayExecution,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.taskMessageForm,
                                    "crossDayExecution",
                                    $$v
                                  )
                                },
                                expression: "taskMessageForm.crossDayExecution",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是"),
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.taskMessageForm.sendType == 2
                    ? _c(
                        "div",
                        { staticClass: "calendar margin-bottom-sm" },
                        [
                          _c(
                            "el-collapse",
                            {
                              model: {
                                value: _vm.openCollapse,
                                callback: function ($$v) {
                                  _vm.openCollapse = $$v
                                },
                                expression: "openCollapse",
                              },
                            },
                            [
                              _c(
                                "el-collapse-item",
                                { attrs: { name: "1" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-center text-black text-bold justify-between",
                                      },
                                      [
                                        _c("div", [_vm._v("发消息时间日历")]),
                                        _c("span", { staticClass: "expand" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.openCollapse ? "收起" : "展开"
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "solids-top flex-center align-end flex-wrap padding-tb-xs margin-lr-sm",
                                      staticStyle: { gap: "16px" },
                                    },
                                    [
                                      _vm._l(_vm.dayList, function (item) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.id,
                                            staticClass:
                                              "text-center dayItem pointer",
                                            class: [
                                              {
                                                active:
                                                  _vm.currentDay == item.day,
                                              },
                                            ],
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectCurrentDay(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "text-black" },
                                              [
                                                _vm._v(
                                                  "第" + _vm._s(item.day) + "天"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-link",
                                                  {
                                                    staticClass:
                                                      "solids-bottom lines-blue",
                                                    attrs: {
                                                      type: "primary",
                                                      underline: false,
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-time",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.sendNow
                                                            ? "立即发送"
                                                            : item.sendTime
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }),
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.visibleStatus || _vm.visible,
                                            type: "primary",
                                            underline: false,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addTask(null)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-circle-plus text-df",
                                          }),
                                          _vm._v(" 添加消息"),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "add-main" },
                    [
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.activeItemNames,
                            callback: function ($$v) {
                              _vm.activeItemNames = $$v
                            },
                            expression: "activeItemNames",
                          },
                        },
                        _vm._l(
                          _vm.taskMessageForm.sendMessageReqVOList,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.originDay == _vm.currentDay,
                                    expression: "item.originDay == currentDay",
                                  },
                                ],
                                key: item.id,
                                staticClass: "add-item",
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  {
                                    class: [
                                      _vm.taskMessageForm.sendType == 1
                                        ? "add-item-container"
                                        : "",
                                    ],
                                    attrs: { name: index },
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _vm.taskMessageForm.sendType == 2
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "flex-center",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.stopHaneldle.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-remove",
                                                staticStyle: {
                                                  color: "#D3D2D2",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delectItem(item)
                                                  },
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "title-text" },
                                                [_vm._v("进入后,  第")]
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass: "form-day",
                                                  staticStyle: { margin: "0" },
                                                  attrs: {
                                                    disabled:
                                                      _vm.visibleStatus ||
                                                      _vm.visible,
                                                    "label-width": "0",
                                                    prop: `sendMessageReqVOList.${index}.day`,
                                                    rules: [
                                                      {
                                                        required: true,
                                                        validator: _vm.validDay,
                                                        trigger: ["blur"],
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "80px",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.disabledInput(item),
                                                      "controls-position":
                                                        "right",
                                                      min: 1,
                                                      max: 100,
                                                      size: "mini",
                                                      oninput:
                                                        "if(value){value=value.replace(/[^\\d]/g,'')}",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.inputDay(
                                                          item,
                                                          index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: item.day,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "day",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.day",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "title-text" },
                                                [_vm._v("天")]
                                              ),
                                              _c(
                                                "el-radio-group",
                                                {
                                                  model: {
                                                    value: item.sendNow,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "sendNow",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.sendNow",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          _vm.currentDay != 1,
                                                        label: 1,
                                                      },
                                                    },
                                                    [_vm._v("立即发送")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: null } },
                                                    [_vm._v("定时发送")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              !item.sendNow
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      staticClass:
                                                        "margin-left-xs",
                                                      staticStyle: {
                                                        "margin-bottom": "0",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          _vm.visibleStatus ||
                                                          _vm.visible,
                                                        "label-width": "0",
                                                        prop: `sendMessageReqVOList.${index}.sendTime`,
                                                        rules: [
                                                          {
                                                            required: true,
                                                            message: "请选择",
                                                            trigger: "blur",
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    [
                                                      _c("el-time-picker", {
                                                        attrs: {
                                                          size: "mini",
                                                          "value-format":
                                                            "HH:mm:ss",
                                                          placeholder:
                                                            "请选择开始时间(时分秒)",
                                                        },
                                                        model: {
                                                          value: item.sendTime,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "sendTime",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.sendTime",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                { staticClass: "title-text" },
                                                [
                                                  _c(
                                                    "question-tooltip",
                                                    {
                                                      attrs: {
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          attrs: {
                                                            slot: "content",
                                                          },
                                                          slot: "content",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                "注: 第一天指当天"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v("? "),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "expand margin-right-xs",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                      }),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "sendMessageReqVOList" },
                                      [
                                        _vm.taskMessageForm.sendType == 2
                                          ? _c("div", {
                                              staticClass: "add-line",
                                            })
                                          : _vm._e(),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              "label-width": "0",
                                              prop: `sendMessageReqVOList.${index}.materialElements`,
                                              rules: [
                                                {
                                                  required: true,
                                                  validator:
                                                    _vm.validateMaterialElements,
                                                  trigger: ["blur", "change"],
                                                },
                                              ],
                                            },
                                          },
                                          [
                                            _c("edit-material", {
                                              ref: `editMaterial${index}`,
                                              refInFor: true,
                                              staticClass: "sopEditMaterial",
                                              attrs: {
                                                disabled:
                                                  _vm.visible ||
                                                  _vm.visibleStatus,
                                                materialElements:
                                                  item.materialElements,
                                                isSopShow: true,
                                              },
                                              on: {
                                                "update:materialElements":
                                                  function ($event) {
                                                    return _vm.$set(
                                                      item,
                                                      "materialElements",
                                                      $event
                                                    )
                                                  },
                                                "update:material-elements":
                                                  function ($event) {
                                                    return _vm.$set(
                                                      item,
                                                      "materialElements",
                                                      $event
                                                    )
                                                  },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                      _vm.taskMessageForm.sendType == 2 &&
                      _vm.visibleStatus != true &&
                      _vm.visible != true
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { width: "135px" },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.addTask(_vm.currentDay)
                                },
                              },
                            },
                            [_vm._v(" 新建 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }