var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm._l(this.getDictDatas2(_vm.type, _vm.value), function (dict, index) {
        return [
          dict.cssClass
            ? _c("span", { key: index, class: [dict.cssClass] }, [
                _vm._v(" @" + _vm._s(dict.label) + " "),
              ])
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }