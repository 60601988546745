import { Table as ElTable, TableColumn as ElTableColumn } from 'element-ui'
import draggable from 'vuedraggable'

// ElTableColumn组件新增 renderCell 属性
const setColumnRenders = ElTableColumn.methods.setColumnRenders
const TableColumn = {
  ...ElTableColumn,
  name: 'TableColumn',
  props: {
    ...ElTableColumn.props,
    renderCell: {
      type: Function,
    },
  },
  methods: {
    ...ElTableColumn.methods,
    setColumnRenders: function (column) {
      column.renderCell = this.renderCell
      return setColumnRenders.call(this, column)
    },
  },
}

// 重写 TableBody render 函数
const TableBody = {
  ...ElTable.components.TableBody,
  render(h) {
    const _this = this
    const data = this.data || []
    const isDraggable = this.$parent.draggable
    const handleClassName = this.$parent.handleClassName

    const tbodyProps = {
      value: data,
      tag: 'tbody',
      options: {
        // handle: `.${handleClassName}`,
        // ghostClass: "el-table__ghost-class-dragging",
      },
    }
    if (handleClassName) {
      tbodyProps.options = {
        handle: `.${handleClassName}`,
        ghostClass: 'el-table__ghost-class-dragging',
      }
    }
    return h(
      'table',
      {
        class: 'el-table__body',
        attrs: { cellspacing: '0', cellpadding: '0', border: '0' },
      },
      [
        h(
          'colgroup',
          {},
          this.columns.map((column) =>
            h('col', { attrs: { name: column.id }, key: column.id }),
          ),
        ),
        h(
          'tbody',
          {
            props: tbodyProps,
            is: isDraggable ? draggable : 'tbody',
            attrs: { animation: 500, forceFallback: false },
            on: {
              input(data) {
                if (isDraggable) {
                  _this.store.commit('setData', data)
                  _this.$parent.$emit('sort-data', data)
                }
              },
            },
          },
          [
            ...data.reduce((acc, row) => {
              return acc.concat(this.wrappedRowRender(row, acc.length))
            }, []),
            h('el-tooltip', {
              props: {
                effect: this.table.tooltipEffect,
                placement: 'top',
                content: this.tooltipContent,
              },
              ref: 'tooltip',
            }),
          ],
        ),
      ],
    )
  },
}

const Table = {
  ...ElTable,
  name: 'DraggableTable',
  _Ctor: null,
  components: { ...ElTable.components, TableBody, draggable },
  props: {
    ...ElTable.props,
    // 新增如下属性
    draggable: {
      type: Boolean,
      default: false,
    },
    handleClassName: {
      type: String,
    },
  },
}

Table.TableColumn = TableColumn

export default Table
