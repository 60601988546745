import ElInput from 'element-ui/lib/input'

function isUndef(v) {
  return v === undefined || v === null || v === ''
}

const colorStyle = {
  color: '#B3B3B3',
}

const colorStyle2 = {
  color: '#333333',
}

const XrInput = {
  name: 'XrInput',
  functional: true,
  props: {
    showWordLimit: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: [String, Number],
    },
    unit: {
      type: String,
    },
  },
  render(h, context) {
    const unit = context.props.unit
    const maxlength = context.props.maxlength
    const showWordLimit = context.props.showWordLimit
    let value = context?.data?.props?.value ?? context?.data?.model?.value ?? ''

    const inputVNode = h(
      ElInput,
      {
        ...context.data,
        attrs: {
          ...context.data.attrs,
          maxlength: maxlength,
        },
      },
      context.children
    )

    const limitVNode = h(
      'div',
      {
        style: {
          marginLeft: '8px',
          fontSize: '12px',
          minWidth: '30px',
          flex: 'none',
        },
      },
      [
        h(
          'span',
          {
            style: value.length === 0 ? colorStyle : colorStyle2,
          },
          value.length
        ),
        h(
          'span',
          {
            style: colorStyle,
          },
          '/'
        ),
        h(
          'span',
          {
            style: colorStyle,
          },
          maxlength
        ),
      ]
    )

    const unitVNode = unit
      ? h(
          'span',
          {
            class: 'input-unit text-black',
          },
          unit
        )
      : ''

    if (isUndef(maxlength) || !showWordLimit) return inputVNode

    return h(
      'div',
      {
        style: {
          display: 'flex',
        },
      },
      [inputVNode, limitVNode, unitVNode]
    )
  },
}

export default XrInput
