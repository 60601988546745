<template>
    <div class="selection-criteria">
        <el-button icon="el-icon-circle-plus" type="text" @click="addCriteria">添加</el-button>
        <el-form v-if="!taskInfo" ref="stageFormFrist" :disabled="visible" :model="form" :rules="rules"
            label-width="100px" label-position="left">
            <div class="criteria-list" v-if="form.selectCriteriaList.length > 0">
                <div class="criteria-item-container" v-for="(item, index) in form.selectCriteriaList" :key="index">
                    <el-form-item :label="item.paramName" :prop="`selectCriteriaList.${index}.paramName`">
                        <div class="flex justify-between">
                            <div class="critecriteriaria-left">
                                <div v-if="item.paramType == 6" class="item-input flex-center">
                                    <el-form-item :prop="`selectCriteriaList.${index}.selectConditionType`"
                                        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
                                        <el-select style="width:90px;margin-right: 12px;"
                                            v-model="item.selectConditionType">
                                            <el-option v-for="items in isNotList" :key="items.value"
                                                :label="items.label" :value="items.value">
                                            </el-option>
                                            <!-- 性别/动态参数单选 为空-->
                                            <el-option v-if="item.paramsType == 1 || item.paramsType == 0 && item.id == 2" label="为空" value="2"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <!-- 动态参数（item.value 有值） -->
                                    <el-form-item v-if="item.selectConditionType != '2'" :prop="`selectCriteriaList.${index}.selectConditionValueList`"
                                        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
                                        <!-- 性别（前端写死） -->
                                        <el-select v-if="item.tag == 101" v-model="item.selectConditionValueList"
                                            style="width: 220px;" filterable clearable remote collapse-tags multiple
                                            reserve-keyword placeholder="请选择">
                                            <el-option v-for="itemm in sexList" :key="itemm.label" :label="itemm.label"
                                                :value="itemm.value">
                                            </el-option>
                                        </el-select>
                                        <el-select v-else v-model="item.selectConditionValueList" style="width: 220px;"
                                            filterable clearable remote collapse-tags multiple reserve-keyword
                                            placeholder="请选择">
                                            <el-option v-for="itemm in JSON.parse(item.value)" :key="itemm"
                                                :label="itemm" :value="itemm">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <!-- 单行文本/单选 -->
                                <div v-if="item.paramType == 1" class="item-input flex-center">
                                    <el-form-item :prop="`selectCriteriaList.${index}.selectConditionType`"
                                        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
                                        <el-select style="width:90px;margin-right: 12px;"
                                            v-model="item.selectConditionType">
                                            <el-option v-for="items in isNotList" :key="items.value"
                                                :label="items.label" :value="items.value">
                                            </el-option>
                                            <!-- 姓名/手机号/身份证号动态类型单选 为空-->
                                            <el-option v-if="item.paramsType == 1 || item.paramsType == 0 && [1,6,8].includes(item.id)" label="为空" :value="2"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <!-- 静态参数(item.value 无值) -->
                                    <el-form-item v-if="item.selectConditionType != '2'" :prop="`selectCriteriaList.${index}.selectConditionValueList`"
                                        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
                                        <div class="selectOption" @click="showDialogValue(1, item, index)">
                                            <el-select size="small"
                                                style="width: 220px;height:32px;pointer-events: none;"
                                                v-model="item.selectConditionValueList" collapse-tags multiple
                                                reserve-keyword placeholder="请选择">
                                            </el-select>
                                        </div>
                                    </el-form-item>
                                </div>
                                <!-- 文本/复选 -->
                                <div v-if="item.paramType == 2 || item.paramType == 7" class="item-input flex-center">
                                    <el-form-item :prop="`selectCriteriaList.${index}.selectConditionType`"
                                        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
                                        <el-select style="width:90px;margin-right: 12px;"
                                            v-model="item.selectConditionType">
                                            <el-option v-for="items in isNotList" :key="items.value"
                                                :label="items.label" :value="items.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <!-- 动态参数（item.value 有值） -->
                                    <el-form-item :prop="`selectCriteriaList.${index}.selectConditionValueList`"
                                        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
                                        <el-select v-if="item.value" v-model="item.selectConditionValueList" multiple
                                            style="width: 220px;" filterable clearable remote collapse-tags
                                            reserve-keyword placeholder="请选择">
                                            <el-option v-for="itemm in JSON.parse(item.value)" :key="itemm"
                                                :label="itemm" :value="itemm">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <!-- 日期/日期时间 -->
                                <div v-if="item.paramType == 3 || item.paramType == 4" class="item-input flex-center">
                                    <el-form-item :prop="`selectCriteriaList.${index}.timeConditionType`"
                                        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
                                        <el-select @visible-change="setNotice(triggerConditeDataList[0])"
                                            popper-class="notice-view" v-model="item.timeConditionType"
                                            style="width: 90px;margin-right: 12px;" @change="changeTriggerData(item)"
                                            placeholder="请选择">
                                            <div class="notice-info padding-xs">
                                                <div class="title text-black">{{ currentTitle }}</div>
                                                <div class="text-sm text-999 margin-top-xs">{{ currentNotice }}</div>
                                            </div>
                                            <el-option v-for="item in triggerConditeDataList" :key="item.triggerType"
                                                :label="item.triggerName" :value="item.triggerType">
                                                <div @mouseenter="setNotice(item)">
                                                    {{ item.triggerName }}
                                                </div>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <!-- 1前/2后 10超过/11不超过-->
                                    <div v-if="[1, 2, 10, 11].includes(item.timeConditionType)"
                                        class="item-input flex-center">
                                        <el-form-item :prop="`selectCriteriaList.${index}.timeConditionIntValue`"
                                            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                                            <el-input v-model.trim="item.timeConditionIntValue"
                                                @input="filterInput(item)" maxlength="4" style="width: 127px;"
                                                placeholder="请输入" />
                                        </el-form-item>
                                        <el-form-item :prop="`selectCriteriaList.${index}.timeConditionCycleType`"
                                            :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">

                                            <el-select v-model="item.timeConditionCycleType"
                                                style="width: 82px;margin-left: 10px" placeholder="请选择">
                                                <el-option v-for="item in triggerDate" :key="item.triggerType"
                                                    :label="item.triggerName" :value="item.triggerType">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <!-- 是/不是 -->
                                    <div v-if="item.timeConditionType == 3 || item.timeConditionType == 4"
                                        class="item-input flex-center">
                                        <el-form-item :prop="`selectCriteriaList.${index}.timeConditionDateValue`"
                                            :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
                                            <el-date-picker v-model="item.timeConditionDateValue"
                                                :format="formatDataValue(item)" :value-format="formatDataValue(item)"
                                                style="width: 220px;" type="datetime" placeholder="选择日期时间">
                                            </el-date-picker>
                                        </el-form-item>
                                    </div>
                                    <!-- 介于 -->
                                    <div v-if="item.timeConditionType == 5" class="item-input flex-center">
                                        <el-form-item :prop="`selectCriteriaList.${index}.timeConditionDateStartValue`"
                                            :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
                                            <el-date-picker v-model="item.timeConditionDateStartValue"
                                                :format="formatDataValue(item)" :value-format="formatDataValue(item)"
                                                style="width: 180px;" type="date" placeholder="开始时间">
                                            </el-date-picker>
                                        </el-form-item>
                                        <span class="margin-lr-xs">至</span>
                                        <el-form-item :prop="`selectCriteriaList.${index}.timeConditionDateEndValue`"
                                            :rules="[{ required: true, message: '请选择', trigger: 'blur' }, { validator: validateDateTime, trigger: ['blur', 'change'] }]">
                                            <el-date-picker v-model="item.timeConditionDateEndValue"
                                                :format="formatDataValue(item)" :value-format="formatDataValue(item)"
                                                style="width: 180px;" type="date" placeholder="结束时间">
                                            </el-date-picker>
                                        </el-form-item>
                                    </div>
                                    <!-- 当天/非当天/为空/不为空 -->

                                </div>
                                <!-- 数字类型 -->
                                <div v-if="item.paramType == 5" class="item-input flex-center">
                                    <el-form-item :prop="`selectCriteriaList.${index}.numberConditionType`"
                                        :rules="[{ required: true, message: '请选择', trigger: ['blur', 'change'] }]">
                                        <el-select popper-class="notice-view"
                                            @visible-change="setNotice(triggerConditeNumberList[0])"
                                            v-model="item.numberConditionType" style="width: 90px;margin-right: 12px;"
                                            placeholder="请选择">
                                            <div class="notice-info padding-xs">
                                                <div class="title text-black">{{ currentTitle }}</div>
                                                <div class="text-sm text-999 margin-top-xs">{{ currentNotice }}</div>
                                            </div>
                                            <el-option v-for="item in triggerConditeNumberList" :key="item.triggerType"
                                                :label="item.triggerName" :value="item.triggerType">
                                                <div @mouseenter="setNotice(item)">
                                                    {{ item.triggerName }}
                                                </div>
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <!-- 等于/不等于/大于/小于/大于等于/小于等于 -->
                                    <div v-if="[1, 2, 3, 4, 5, 6].includes(item.numberConditionType)"
                                        class="item-input flex-center">
                                        <el-form-item :prop="`selectCriteriaList.${index}.numberConditionIntValue`"
                                            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                                            <el-input controls-position="right" :precision="0"
                                                v-model.trim="item.numberConditionIntValue" style="width: 127px;"
                                                placeholder="数值" :min="1"
                                                oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" />
                                        </el-form-item>
                                    </div>
                                    <!-- 介于 -->
                                    <div v-if="item.numberConditionType == 7" class="item-input flex-center">
                                        <el-form-item :prop="`selectCriteriaList.${index}.numberConditionIntStartValue`"
                                            :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                                            <el-input v-model="item.numberConditionIntStartValue" style="width: 132px;"
                                                placeholder="数值" min="1" maxlength="8"
                                                oninput="if(value){value=value.replace(/[^\d]/g,'')}" />
                                        </el-form-item>
                                        <span class="margin-lr-xs">至</span>
                                        <el-form-item :prop="`selectCriteriaList.${index}.numberConditionIntEndValue`"
                                            :rules="[{ required: true, message: '请输入', trigger: 'blur' }, { validator: validateNumber, trigger: ['blur', 'change'] }]">
                                            <el-input v-model="item.numberConditionIntEndValue" style="width: 132px;"
                                                placeholder="数值" min="1" maxlength="8"
                                                oninput="if(value){value=value.replace(/[^\d]/g,'')}" />
                                        </el-form-item>
                                    </div>
                                    <!-- 为空 -->
                                </div>
                                <!-- 地区 -->
                                <div class="item-input flex-center"
                                    v-if="item.paramType == 100 || item.paramType == 102 || item.paramType == 204">
                                    <el-form-item :prop="`selectCriteriaList.${index}.selectConditionType`"
                                        :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                                        <el-select style="width:90px;margin-right: 12px;"
                                            v-model="item.selectConditionType">
                                            <el-option v-for=" items in isNotList " :key="items.value"
                                                :label="items.label" :value="items.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item style="width: 200px;"
                                        :prop="`selectCriteriaList.${index}.cityValueList`"
                                        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
                                        <el-cascader ref="cascaderArr" style="min-width: 220px;max-width: 300px;"
                                            collapse-tags v-model="item.cityValueList" :props="propsJson"
                                            @change="changeCity($event, index)" popper-class="areaPoper"
                                            @visible-change="areaVisibleChange">
                                        </el-cascader>
                                    </el-form-item>
                                </div>
                                <!-- 标签 -->
                                <div v-if="item.paramType == 101" class="item-input flex-center">
                                    <el-form-item :prop="`selectCriteriaList.${index}.equal`"
                                        :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                                        <el-select style="width:90px;margin-right: 5px;" v-model="item.equal"
                                            @change="changeEqual">
                                            <el-option v-for=" items in isNotList " :key="items.value"
                                                :label="items.label" :value="items.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item :prop="`selectCriteriaList.${index}.labelList`"
                                        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
                                        <select-tag :selectedTags="item.labelList" :key="String(item.labelList)"
                                            @setCurrentTags="setCurrentTags(arguments, item)" :type="type"
                                            :ref="`selectTag${index}`">
                                            <div slot="addTag" @click="$refs[`selectTag${index}`][0].open()">
                                                <el-select v-model="item.labelNameList" @clear="clearLabelList(item)"
                                                    style="pointer-events: none;width: 220px;" clearable collapse-tags
                                                    multiple placeholder="请选择">
                                                </el-select>
                                            </div>
                                        </select-tag>
                                    </el-form-item>

                                </div>
                            </div>
                            <i class="el-icon-error pointer"
                                style="color: #D3D2D2;width: 16px;height: 16px;margin-top: 8px;"
                                @click="removeCriteria(index)"></i>
                        </div>
                    </el-form-item>
                </div>
            </div>
        </el-form>
        <el-dialog title="选择条件" width="555px" :modal-append-to-body="false" append-to-body class="dialog-box"
            :class="[{ taskInfo: taskInfo }]" :visible.sync="dialogVisible" :before-close="handleClose">
            <div v-if="stageType == 100 || stageType == 200">
                <el-input class="criteria-input" @change="search" ref="form" v-model.trim="name"
                    placeholder="请输入参数名称" />
                <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
                <div class="text-red margin-top-xs" v-if="selectCriteriaObjList.length >= maxlength">
                    * 为了更好的收集效果，单次收集任务字段数量不能超过{{ maxlength }}个
                </div>
                <div class="criteria-content" style="margin: 0;">
                    <el-checkbox-group :max="maxlength - form.selectCriteriaList.length"
                        v-model="selectCriteriaObjList">
                        <el-checkbox :label="item.id" class="tags-item" v-for="(item, index) in criteriaList"
                            :key="index" border>
                            {{ item.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div v-if="stageType == 3" class="criteria-content">
                <el-radio v-model="selectCriteriaObj" label="用户意图" border>用户意图</el-radio>
            </div>
            <span slot="footer" class="flex justify-end">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="determineClick">确 定</el-button>
            </span>
        </el-dialog>

        <select-stage-dialog ref="selectDialog" @getCrowPeople="getCrowPeople" :isRadio="isRadio" :formData="{
            type,
            paramId,
            tag: tag,
            keyName: keyName,
            selectTitle: selectTitle,
            selectData: dialogItemValue,
            staffAccountId: ''
        }" disabledSomeColumn></select-stage-dialog>
    </div>
</template>
<script>
import { triggerConditeDataList, triggerConditeNumberList, triggerDate } from "@/utils/constants"
import _ from "lodash";
import areaJson from "@/utils/area.js";

import selectTag from '../../../components/selectTag/selectTag.vue'
import { getDynamicAll } from "@/api/custom"
import SelectStageDialog from "@/views/components/selectDialog/selectStageDialog.vue";
export default {
    components: {
        SelectStageDialog,
        selectTag
    },
    props: {
        stageType: {
            type: Number,// : 基本信息 :标签 
        },
        customerInfoConditionReqVOS: {
            type: Array,
            default: () => {
                return [];
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        taskInfo: {
            type: Boolean,
            default: false
        },
        selectCriteriaList: { // 回显收集信息条件
            type: Array,
            default: () => {
                return [];
            }
        },
        maxlength: { // 问法样例最大选择数量
            type: Number,
            default: 10
        }
    },
    watch: {
        stageType(val) {
            this.stageType = val;
            this.form.selectCriteriaList = []
        },
        customerInfoConditionReqVOS(val) {
            this.form.selectCriteriaList = val
        },
        "form.selectCriteriaList": {
            handler: function (params) {
                this.$emit("update:customerInfoConditionReqVOS", params)
                this.$nextTick(() => {
                    this.$refs.stageFormFrist && this.$refs.stageFormFrist.validate()
                })
            },
            deep: true
        }
    },

    data() {

        let validateNumber = (rule, value, callback) => {
            let index = Number(rule.field.split(".")[1]);
            const numberConditionIntStartValue = this.form.selectCriteriaList[index].numberConditionIntStartValue;
            const numberConditionIntEndValue = this.form.selectCriteriaList[index].numberConditionIntEndValue;
            if (Number(numberConditionIntEndValue) < Number(numberConditionIntStartValue)) {
                callback(new Error("后一个数值不能小于前一个数值"));
            } else {
                callback();
            }
        }
        let validateDateTime = (rule, value, callback) => {
            let index = Number(rule.field.split(".")[1]);
            const timeConditionDateEndValue = this.form.selectCriteriaList[index].timeConditionDateEndValue;
            const timeConditionDateStartValue = this.form.selectCriteriaList[index].timeConditionDateStartValue;
            if (new Date(timeConditionDateEndValue).getTime() < new Date(timeConditionDateStartValue).getTime()) {
                callback(new Error("后一个时间不能小于前一个时间"));
            } else {
                callback();
            }
        }
        return {
            currentNotice: "",
            currentTitle: "",
            selectCriteriaObjList: [],
            sexList: [{
                label: '男',
                value: '1'
            }, {
                label: '女',
                value: '2'
            }],
            selectTitle: '',
            rules: {},
            validateNumber,
            validateDateTime,
            labelList: [],
            paramId: '',
            type: 1,
            tag: '',
            keyName: '',
            isRadio: false,
            dialogItemValue: '',
            dialogIndex: 0,
            selectLoading: false,
            dialogVisible: false,
            name: '',
            criteriaList: [],
            selectCriteriaObj: '',
            form: {
                selectCriteriaList: [],
            },
            triggerConditeNumberList,
            triggerConditeDataList,
            triggerDate,
            areaJson,
            isNotList: [
                { value: 1, label: '是' },
                { value: 0, label: '不是' }
            ],
            propsJson: {
                lazy: true,
                multiple: true,
                emitPath: false,
                lazyLoad(node, resolve) {
                    const { level } = node;
                    if (level === 0) {
                        resolve(areaJson);
                    }
                    if (level === 1) {
                        resolve();
                    }
                    if (level === 2) {
                        resolve();
                    }
                }
            },

        }
    },
    mounted() {
        this.form.selectCriteriaList = _.cloneDeep(this.customerInfoConditionReqVOS);
        this.areaJson.map((v) => {
            v.children.map((m) => {
                m.leaf = "leaf";
            });
        });

    },
    methods: {

        changeEqual() {
            this.$forceUpdate()
        },
        changeCity(val, index) {
            // console.log(val, 'val----')
            // this.form.selectCriteriaList[index].cityValueList = val
        },
        search() {
            this.getCustomList()
        },
        reset() {
            this.name = ""
            this.getCustomList()
        },
        setCurrentTags([label], item) {
            item.labelList = label;
            item.labelNameList = label.map(v => v.name);
            item.labelIdList = label.map(item => item.id);
        },
        clearLabelList(item) {
            item.labelList = [];
            item.labelIdList = [];
        },
        addCriteria() {
            if (this.taskInfo) { // 信息收集
                this.selectCriteriaObjList = this.selectCriteriaList;
                this.getCustomList()
                this.dialogVisible = true;
                return;
            }
            this.selectCriteriaObjList = [];
            if (this.form.selectCriteriaList.length >= this.maxlength) {
                this.$message.warning(`最多添加${this.maxlength}条条件`);
                return false;
            }
            this.selectCriteriaObj = '';
            this.areaJson.map(v => {
                v.children.map(m => {
                    m.leaf = "leaf";
                })
            })
            // 基本信息
            if (this.stageType == 100 || this.stageType == 200) {
                this.dialogVisible = true
                this.getCustomList()
            }
            // 标签
            if (this.stageType == 101 || this.stageType == 201) {
                this.type = this.$route.query.sopType == 'clientGroupSOP' ? 2 : 1;
                // this.$nextTick(() => {
                //     this.$refs.selectTag.open();
                // })
                this.form.selectCriteriaList.push({
                    paramName: '标签',
                    equal: this.isNotList[0].value,
                    paramType: 101,
                    labelList: this.labelList,
                    labelIdList: this.labelList.map(item => item.id)
                })
            }
            this.$emit("sureCriteria")
        },
        getCustomList() {
            getDynamicAll({
                paramsType: this.stageType == 200 ? 0 : 2, // 1: 动态 2: 全部
                dataModule: this.stageType == 100 ? 1 : 2, // 1: 客户，2: 客户群
                name: this.name,
                isSop: this.taskInfo ? 1 : null
            }).then(res => {
                res.data.map(v => {
                    const id = ['staticParamId', 'dynamicParamId'][v.paramsType];
                    v.dynamicParam = v.paramsType == 1;
                    v[id] = v.id;
                    v.paramType = v.type;
                    v.paramName = v.name;
                    // 设置默认值
                    if ([1, 2, 6, 7, 100, 102, 204].includes(v.paramType)) {
                        v.selectConditionType = this.isNotList[0].value;
                    }
                    if ([3, 4].includes(v.paramType)) {
                        v.timeConditionType = this.triggerConditeDataList[0].triggerType;
                        v.timeConditionCycleType = this.triggerDate[0].triggerType
                    }
                    if ([5].includes(v.paramType)) {
                        v.numberConditionType = this.triggerConditeNumberList[0].triggerType;
                    }

                })
                this.criteriaList = res.data;
                // this.criteriaList = this.criteriaList.sort((a, b) => a.name.length - b.name.length);
            })
        },

        // 时间条件改变
        changeTriggerData(val) {
        },
        removeCriteria(index) {
            if (this.form.selectCriteriaList[index]?.paramType == 101) { // 删除标签
                this.labelList = []
            }
            this.form.selectCriteriaList.splice(index, 1)
        },
        // 弹框关闭
        handleClose() {
            this.dialogVisible = false
        },
        // 单选弹框 / 多选框
        showDialogValue(type, val, index) {
            if (val.paramsType == 1) {
                this.type = 3
                this.paramId = val.id
            } else {
                this.type = val?.tag < 200 ? 1 : 2;
            }
            this.isRadio = false;
            this.dialogItemValue = val?.selectConditionValueList ? val?.selectConditionValueList.map(v => { return { value: v } }) : [];
            this.tag = val?.tag ? val?.tag : '';
            this.keyName = val?.key ? val?.key : '';
            this.dialogIndex = index;
            this.selectTitle = val.paramName
            this.$nextTick(() => {
                this.$refs.selectDialog.open()
            })
        },
        getCrowPeople(val) {
            const selectCriteriaList = JSON.parse(JSON.stringify(this.form.selectCriteriaList))
            selectCriteriaList[this.dialogIndex].selectConditionValueList = val.map(v => v.value);
            this.form.selectCriteriaList = selectCriteriaList;
        },
        // 弹框确定
        determineClick() {
            this.reset();
            setTimeout(() => {
                this.selectCriteriaObjList.map(v => {
                    const criteriaItem = this.criteriaList.find(m => m.id == v);
                    this.form.selectCriteriaList.push(criteriaItem);
                    if (this.form.selectCriteriaList.length > 1) { // 排序
                        this.form.selectCriteriaList.sort((a, b) => a.name?.length - b.name?.length)
                    }
                })
                if (this.taskInfo) { // 信息采集
                    this.$emit("selectCriteria", this.form.selectCriteriaList);
                    this.dialogVisible = false;
                    this.form.selectCriteriaList = [];
                    return;
                }
                this.$emit('sureCriteria', this.form.selectCriterialist);
                this.dialogVisible = false;
            }, 100);
        },
        filterInput(item) {
            item.timeConditionIntValue = item.timeConditionIntValue.replace(/[^\d.]/g, "")
                .replace(/\.{2,}/g, ".")
                .replace(".", "$#$").replace(/\./g, "").replace("$#$", ".").replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3').replace(/^\./g, "")
                .replace(/^0+(\d)/, '');
        },
        setNotice(item) {
            this.currentNotice = item.notes;
            this.currentTitle = item.triggerName;
        },
        formatDataValue(item) {
            if ((item.name == '生日' || item.paramName == '生日') && item.paramType == 3) {
                return 'MM-dd'
            }
            if (item.paramType === 3) {
                return 'yyyy-MM-dd'
            }
            if (item.paramType === 4) {
                return 'yyyy-MM-dd HH:mm'
            }
        },
        // 隐藏地区第一级checkbox
        areaVisibleChange() {
            const areaPoper = document.querySelector(".areaPoper");
            const menuList = areaPoper.querySelector(".el-cascader-menu__list");
            const menuItemList = menuList.querySelectorAll(".el-cascader-node");
            menuItemList.forEach(v => {
                const menuItem = v.querySelector(".el-checkbox")
                menuItem.style.display = 'none';
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.selection-criteria ::v-deep {
    .selectOption {

        .el-input__suffix,
        .el-input__suffix-inner {
            pointer-events: none;
        }
    }

    .el-form {
        padding: 0 !important;
    }

    .el-form-item {
        margin-bottom: 0;
    }

    .criteria-list {
        border-radius: 2px;
        padding: 16px 16px 0 16px;
        opacity: 1;
        background: #FFFFFF;
        border: 0.5px solid #C0CCDA;
        margin-top: 16px;
        // max-height: 300px;
        // overflow: auto;
        margin-bottom: 90px;

        .item-value {
            width: 223px;
            display: inline-block;
            height: 32px;
            border-radius: 2px;
            opacity: 1;
            background: #FFFFFF;
            vertical-align: middle;
            border: 0.5px solid #BCBCBC;
            border-color: #e9e9eb;
            color: #909399;

            .item-title {
                font-size: 14px;
                font-weight: normal;
                color: #909399;
                display: inline-block;
                padding-left: 8px;
                padding-right: 4px;
                height: 24px;
                line-height: 24px;
                border-radius: 2px;
                opacity: 1;
                background-color: #f4f4f5;
                margin: 0 4px;

                .item-text {
                    display: inline-block;
                    max-width: 144px;
                    height: 100%;
                    overflow: hidden;
                    vertical-align: middle;
                }
            }

            .item-content {
                padding: 0 8px;
                display: inline-block;
                height: 24px;
                line-height: 24px;
                border-radius: 2px;
                background-color: #f4f4f5;
            }
        }

        .criteria-item-container {
            margin-bottom: 16px;

            .criteria-name {
                display: inline-block;
                width: 80px;
                text-align: left;
            }

        }
    }
}

.dialog-box ::v-deep {
    .tags-item {
        background: #F9F9F9;
        border-color: #F9F9F9;

        &.is-checked {
            background: rgba(48, 126, 242, 0.1);
            border: 1px solid #307EF2;
        }

        &.is-disabled {
            // background: rgba(48, 126, 242, 0.1);
            border: 1px solid transparent;
        }
    }

    .criteria-input {
        width: 180px;
        margin-right: 20px;
    }

    .criteria-content {
        margin-top: 16px;
        height: 250px;
        overflow: auto;

        .el-checkbox__input {
            display: none;
        }

        .el-checkbox__label {
            min-width: 53px;
            padding: 0;
            text-align: center;
        }

        .el-checkbox-group {
            padding: 16px 0;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
        }

        .el-checkbox {
            margin: 0;
            padding: 5px 10px;
        }
    }


}
</style>
<style></style>
