var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-card", { attrs: { shadow: "never" } }, [
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c("el-scrollbar", { staticClass: "flex-twice solids-right" }, [
              _c(
                "div",
                {
                  staticClass: "material-view",
                  class: [
                    _vm.$route.name == "createMessage"
                      ? "left-view-first "
                      : "solids-none",
                  ],
                },
                [
                  !_vm.isSopShow
                    ? _c(
                        "h3",
                        {
                          staticClass:
                            "solids-bottom text-bold padding-bottom-sm",
                          staticStyle: { "margin-top": "0" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(`编辑群${_vm.isNotice ? "公告" : "发"}`) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isSopShow
                    ? _c(
                        "el-form",
                        {
                          ref: "form",
                          staticClass: "padding-top-xs",
                          attrs: {
                            rules: _vm.rules,
                            model: _vm.queryParams,
                            "label-width": "100px",
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "inputvalue",
                              staticStyle: { width: "70%" },
                              attrs: {
                                label: `${_vm.notice}名称`,
                                "label-position": "left",
                                prop: "name",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "240px",
                                  display: "inline-block",
                                },
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.queryParams.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "name", $$v)
                                  },
                                  expression: "queryParams.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.isNotice
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: `${_vm.notice}内容`,
                                    "label-position": "left",
                                    prop: "contentBack",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { position: "relative" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          resize: "none",
                                          maxlength: "1000",
                                          "show-word-limit": "",
                                          placeholder: "请输入公告内容...",
                                          size: "medium",
                                          type: "textarea",
                                          autosize: { minRows: 3, maxRows: 3 },
                                        },
                                        on: { input: _vm.setNoticeData },
                                        model: {
                                          value: _vm.queryParams.contentBack,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParams,
                                              "contentBack",
                                              $$v
                                            )
                                          },
                                          expression: "queryParams.contentBack",
                                        },
                                      }),
                                      _c("select-emoji", {
                                        on: { addEmoji: _vm.addEmoji },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.isNotice
                            ? _c(
                                "el-form-item",
                                {
                                  staticClass: "resource",
                                  attrs: {
                                    label: "群发消息",
                                    "label-position": "left",
                                    prop: "resource",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.queryParams.resource,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "resource",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.resource",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "1" } },
                                        [_vm._v("是")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "2" } },
                                        [_vm._v("否")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  (_vm.queryParams.resource == 1 && _vm.isNotice) ||
                  !_vm.isNotice
                    ? _c(
                        "el-form",
                        {
                          ref: "materialElementsForm",
                          attrs: {
                            rules: _vm.rules,
                            model: _vm.queryParams,
                            "label-position":
                              _vm.materialElementsLength > 0 ? "top" : "left",
                            "label-width": "95px",
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: !_vm.isSopShow ? "发送内容" : "",
                                prop: "materialElements",
                              },
                            },
                            [
                              _c("material-detail", {
                                ref: "materialDetail",
                                class: {
                                  "margin-top-sm":
                                    _vm.materialElementsLength > 0,
                                },
                                attrs: {
                                  disabled: _vm.disabled,
                                  hideCustom: _vm.hideCustom,
                                  isSopShow: _vm.isSopShow,
                                  queryParams: _vm.queryParams,
                                  imported: _vm.queryParams.imported,
                                  isNewAdd: _vm.isNewAdd,
                                  isRule: _vm.isRule,
                                },
                                on: {
                                  "update:queryParams": function ($event) {
                                    _vm.queryParams = $event
                                  },
                                  "update:query-params": function ($event) {
                                    _vm.queryParams = $event
                                  },
                                  openMaterialDialog: _vm.openMaterialDialog,
                                  valueRules: _vm.valueRules,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            !_vm.isSopShow
              ? _c(
                  "div",
                  { staticClass: "flex-sub" },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "text-bold padding-left-sm padding-bottom-sm",
                        staticStyle: { "margin-top": "0" },
                      },
                      [_vm._v(" 预览效果 ")]
                    ),
                    _c("preview-material", {
                      ref: "previewMaterial",
                      attrs: { materialData: _vm.queryParams.materialElements },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "引用素材",
            "custom-class": "import-dalog",
            top: "10vh",
            "close-on-click-modal": "",
            "append-to-body": "",
            visible: _vm.dialogVisible,
            width: "1220px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex importDialog" }, [
            _c(
              "div",
              {
                staticClass: "left-tabs flex-sub",
                staticStyle: { "max-width": "180px" },
              },
              [
                _c(
                  "el-tabs",
                  {
                    attrs: { "tab-position": "left" },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c("el-tab-pane", {
                      attrs: { label: "素材包", name: "1" },
                    }),
                    _c("el-tab-pane", {
                      attrs: { label: "智能采集", name: "2" },
                    }),
                    _c("el-tab-pane", {
                      attrs: { label: "素材文件", name: "3" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { flex: "4", height: "590px" } },
              [
                _vm.activeName == "1"
                  ? _c("material", {
                      ref: "material",
                      attrs: { isSelect: true, isSopShow: _vm.isSopShow },
                      on: { useMaterial: _vm.setMaterialData },
                    })
                  : _vm._e(),
                _vm.activeName == "2"
                  ? _c("material-auto", {
                      ref: "materialAuto",
                      attrs: { isSelect: true },
                      on: { useMaterial: _vm.setMaterialData },
                    })
                  : _vm._e(),
                _vm.activeName == "3"
                  ? _c("material-file", { ref: "materialFile" })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "flex-center justify-end",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm.activeName == "3"
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary", autofocus: "" },
                      on: { click: _vm.useMiniProgram },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }