import { render, staticRenderFns } from "./clientCustom.vue?vue&type=template&id=254b3190&scoped=true"
import script from "./clientCustom.vue?vue&type=script&lang=js"
export * from "./clientCustom.vue?vue&type=script&lang=js"
import style0 from "./clientCustom.vue?vue&type=style&index=0&id=254b3190&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254b3190",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/AI前端应用/DEV-SCRM开发/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('254b3190')) {
      api.createRecord('254b3190', component.options)
    } else {
      api.reload('254b3190', component.options)
    }
    module.hot.accept("./clientCustom.vue?vue&type=template&id=254b3190&scoped=true", function () {
      api.rerender('254b3190', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/clientCustom/clientCustom.vue"
export default component.exports