var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "flex-center justify-between" }, [
      _c("div", { staticClass: "text-999" }, [_vm._v("社交账号")]),
      _c("div", [_vm._v(_vm._s(_vm.message.sendTime))]),
    ]),
    _c("div", { staticClass: "text-black padding-tb-xs" }, [
      _c("img", {
        staticStyle: { "vertical-align": "middle" },
        attrs: {
          src: _vm.message.accountType == "2" ? _vm.qwImg : _vm.wxImg,
          alt: "",
        },
      }),
      _vm._v(" " + _vm._s(_vm.message.nickname) + " "),
    ]),
    _c("div", { staticClass: "padding-bottom-xs text-999" }, [
      _vm._v("通知内容"),
    ]),
    _c("div", { staticStyle: { color: "#718096" } }, [
      _c(
        "span",
        { staticClass: "text-black", staticStyle: { "min-width": "30px" } },
        [_vm._v(_vm._s(_vm.message.sendName))]
      ),
      _vm._v(" " + _vm._s(_vm.message.content)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }