import { constantRoutes } from '@/router'
import { getRouters } from '@/api/menu'
import Layout from '@/layout/index'
import ParentView from '@/components/ParentView';
import { toCamelCase } from "@/utils";

const permission = {
	state: {
		routes: [],
		addRoutes: [],
		sidebarRouters: [], // 左侧边菜单的路由，被 Sidebar/index.vue 使用
		topbarRouters: [], // 顶部菜单的路由，被 TopNav/index.vue 使用
		showChatMain: false, // logo下方SCRM与聚合聊天切换
	},
	mutations: {
		SET_ROUTES: (state, routes) => {
			state.addRoutes = routes
			state.routes = constantRoutes.concat(routes)
		},
		SET_DEFAULT_ROUTES: (state, routes) => {
			state.defaultRoutes = constantRoutes.concat(routes)
		},
		SET_TOPBAR_ROUTES: (state, routes) => {
			state.topbarRouters = routes
		},
		SET_SIDEBAR_ROUTERS: (state, routes) => {
			state.sidebarRouters = routes
		},
		// 设置SCRM与聚合聊天切换是否显示
		SET_SHOWCHATMAIN: (state, value) => {
			state.showChatMain = value
		}
	},
	actions: {
		// 生成路由
		GenerateRoutes({ commit }) {
			return new Promise(resolve => {
				// 向后端请求路由数据（菜单）
				getRouters().then(res => {
					const isShowChatMain = res.data.findIndex(v => v.componentName == "wechatMain") != -1;
					commit("SET_SHOWCHATMAIN", isShowChatMain)
					const sdata = JSON.parse(JSON.stringify(res.data)) // 【重要】用于菜单中的数据
					const rdata = JSON.parse(JSON.stringify(res.data)) // 用于最后添加到 Router 中的数据
					const sidebarRoutes = filterAsyncRouter(sdata)
					const rewriteRoutes = filterAsyncRouter(rdata, false, true)
					rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })
					commit('SET_ROUTES', rewriteRoutes)
					commit('SET_SIDEBAR_ROUTERS', constantRoutes.concat(sidebarRoutes))
					commit('SET_DEFAULT_ROUTES', sidebarRoutes)
					commit('SET_TOPBAR_ROUTES', sidebarRoutes)
					resolve(rewriteRoutes)
				})
			})
		}
	}
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
	return asyncRouterMap.filter(route => {
		// 将 ruoyi 后端原有耦合前端的逻辑，迁移到此处
		// 处理 meta 属性
		route.meta = {
			title: route.name,
			icon: route.icon,
			noCache: !route.keepAlive,
		}
		route.hidden = !route.visible
		// 处理 name 属性
		if (route.componentName && route.componentName.length > 0) {
			route.name = route.componentName
		} else {
			// 路由地址转首字母大写驼峰，作为路由名称，适配 keepAlive
			route.name = toCamelCase(route.path, true)
			// 处理三级及以上菜单路由缓存问题，将 path 名字赋值给 name
			if (route.path.indexOf("/") !== -1) {
				const pathArr = route.path.split("/");
				route.name = toCamelCase(pathArr[pathArr.length - 1], true)
			}
		}
		// 处理 component 属性
		if (route.children) { // 父节点
			if (route.parentId === 0) {
				route.component = Layout
			} else {
				route.component = ParentView
			}
		} else { // 根节点
			route.component = loadView(route.component)
		}

		// filterChildren
		if (type && route.children) {
			route.children = filterChildren(route.children)
		}
		if (route.children != null && route.children && route.children.length) {
			route.children = filterAsyncRouter(route.children, route, type)
			route.alwaysShow = route.alwaysShow !== undefined ? route.alwaysShow : true
		} else {
			delete route['children']
			delete route['alwaysShow'] // 如果没有子菜单，就不需要考虑 alwaysShow 字段
		}
		return true
	})
}

function filterChildren(childrenMap, lastRouter = false) {
	let children = [];
	childrenMap.forEach((el, index) => {
		if (el.children && el.children.length) {
			if (!el.component && !lastRouter) {
				el.children.forEach(c => {
					c.path = el.path + '/' + c.path
					if (c.children && c.children.length) {
						children = children.concat(filterChildren(c.children, c))
						return
					}
					children.push(c)
				})
				return
			}
		}
		if (lastRouter) {
			el.path = lastRouter.path + '/' + el.path
		}
		children = children.concat(el)
	})
	return children
}

export const loadView = (view) => { // 路由懒加载
	//   if (process.env.NODE_ENV === 'development') {
	//     return (resolve) => require([`@/views/${view}`], resolve)
	//   } else {
	//     // 使用 import 实现生产环境的路由懒加载
	//     return () => import(`@/views/${view}`)
	//   }
	return (resolve) => require([`@/views/${view}`], resolve)
}

export default permission
