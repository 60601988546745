<template>
  <div class="send-message margin-bottom-sm">
    <el-collapse v-model="activeNames">
      <el-collapse-item :name="1">
        <template slot="title">
          <i class="header-icon el-icon-message-path"></i>
          <span class="text-black">发消息</span>
          <span class="expand">{{ activeNames.length > 0 ? '收起' : '展开' }}</span>
        </template>
        <el-form class="send-form" ref="ruleForm" :disabled="visibleStatus || visible" :rules="rules"
          :model="taskMessageForm" label-width="130px" label-position="left">
          <el-form-item label="发送方式" prop="sendType" required>
            <el-radio-group v-model="taskMessageForm.sendType" @change="changeSendType">
              <el-radio :label="1">立即发送</el-radio>
              <el-radio :label="2">定时发送</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="taskMessageForm.sendType == 2" prop="crossDayExecution" required>
            <span class="flex-center" slot="label">
              按顺序发送
              <question-tooltip placement="top">
                <div slot="content">
                  不按顺序，指消息超过24点后则不再发送<br />
                  按顺序，指消息超过24点会继续发送
                </div>
              </question-tooltip>
            </span>
            <el-radio-group v-model="taskMessageForm.crossDayExecution">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="taskMessageForm.sendType == 2" class="calendar margin-bottom-sm">
            <el-collapse v-model="openCollapse">
              <el-collapse-item name="1">
                <template slot="title">
                  <div class="flex-center text-black text-bold justify-between">
                    <div>发消息时间日历</div>
                    <span class="expand">{{ openCollapse ? '收起' : '展开' }}</span>
                  </div>
                </template>
                <div style="gap: 16px;" class="solids-top flex-center align-end flex-wrap padding-tb-xs margin-lr-sm">
                  <div class="text-center dayItem pointer" :class="[{ active: currentDay == item.day }]"
                    @click="selectCurrentDay(item)" v-for="item in dayList" :key="item.id">
                    <div class="text-black">第{{ item.day }}天</div>
                    <div>
                      <el-link type="primary" :underline="false" class="solids-bottom lines-blue">
                        <i class="el-icon-time"></i> {{ item.sendNow ? '立即发送' : item.sendTime }}
                      </el-link>
                    </div>
                  </div>
                  <el-link @click="addTask(null)" :disabled="visibleStatus || visible" type="primary" :underline="false">
                    <i class="el-icon-circle-plus text-df"></i>
                    添加消息</el-link>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="add-main">
            <el-collapse v-model="activeItemNames">
              <div class="add-item" v-show="item.originDay == currentDay"
                v-for="(item, index) in taskMessageForm.sendMessageReqVOList" :key="item.id">
                <el-collapse-item :name="index" :class="[taskMessageForm.sendType == 1 ? 'add-item-container' : '']">
                  <template slot="title">
                    <div v-if="taskMessageForm.sendType == 2" class="flex-center" @click.stop="stopHaneldle">
                      <i class="el-icon-remove" style="color: #D3D2D2;" @click="delectItem(item)"></i>
                      <span class="title-text">进入后, &nbsp;第</span>
                      <el-form-item class="form-day" :disabled="visibleStatus || visible" label-width="0"
                        style="margin: 0;" :prop="`sendMessageReqVOList.${index}.day`"
                        :rules="[{ required: true, validator: validDay, trigger: ['blur'] }]">
                        <el-input @input="inputDay(item, index)" :disabled="disabledInput(item)"
                          controls-position="right" style="width: 80px;" :min="1" :max="100" size="mini"
                          v-model="item.day" oninput="if(value){value=value.replace(/[^\d]/g,'')}" />
                      </el-form-item>
                      <span class="title-text">天</span>
                      <el-radio-group v-model="item.sendNow">
                        <el-radio :disabled="currentDay != 1" :label="1">立即发送</el-radio>
                        <el-radio :label="null">定时发送</el-radio>
                      </el-radio-group>
                      <el-form-item class="margin-left-xs" v-if="!item.sendNow" :disabled="visibleStatus || visible"
                        label-width="0" style="margin-bottom: 0;" :prop="`sendMessageReqVOList.${index}.sendTime`"
                        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
                        <el-time-picker v-model="item.sendTime" size="mini" value-format="HH:mm:ss"
                          placeholder="请选择开始时间(时分秒)">
                        </el-time-picker>
                      </el-form-item>
                      <span class="title-text">
                        <question-tooltip placement="top">
                          <div slot="content">
                            {{ '注: 第一天指当天' }}
                          </div>?
                        </question-tooltip>
                      </span>
                    </div>
                    <span @click.stop="" class="expand margin-right-xs">
                      <!-- <el-button type="primary" v-if="index == 0" @click="saveTask">保存</el-button> -->
                      <!-- {{ activeItemNames.includes(index) ? '收起' : '展开' }} -->
                    </span>
                  </template>
                  <div class="sendMessageReqVOList">
                    <div v-if="taskMessageForm.sendType == 2" class="add-line"></div>
                    <!-- <material-detail @setMaterialData="setMaterialData($event, index)"
                                        :ref="`materialDetail${index}`"></material-detail> -->
                    <el-form-item label-width="0" :prop="`sendMessageReqVOList.${index}.materialElements`"
                      :rules="[{ required: true, validator: validateMaterialElements, trigger: ['blur', 'change'] }]">
                      <edit-material class="sopEditMaterial" :ref="`editMaterial${index}`"
                        :disabled="visible || visibleStatus" :materialElements.sync="item.materialElements"
                        :isSopShow="true">
                      </edit-material>
                    </el-form-item>
                  </div>
                </el-collapse-item>
              </div>
            </el-collapse>
            <el-button type="primary" @click="addTask(currentDay)" style="width: 135px;"
              v-if="taskMessageForm.sendType == 2 && visibleStatus != true && visible != true">
              新建
            </el-button>
          </div>
        </el-form>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import editMaterial from "@/views/robot/components/editMaterial.vue";
import QuestionTooltip from '../../../components/questionTooltip/questionTooltip.vue';
import _ from "lodash";
export default {
  components: { editMaterial, QuestionTooltip },
  provide() {
    return {
      flag: () => {
        return 1
      }
    }
  },
  data() {
    const validateMaterialElements = (rule, value, callback) => {
      if (value?.length == 0) {
        callback(new Error("请输入发送内容"));
      } else {
        callback();
      }
    };
    const validDay = (rule, value, callback) => {
      if (value === '') {
        callback(new Error("请输入天数"));
      } else if (value === null || this.isRepeteData) {
        callback(new Error("输入的天数不能重复"));
      } else {
        callback();
      }
    }
    return {
      activeNames: [1],
      activeItemNames: [0],
      validateMaterialElements,
      validDay,
      rules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }
        ]
      },
      taskMessageForm: {
        actionType: 1,
        sendType: 1,
        crossDayExecution: 0,
        sendMessageReqVOList: [{
          day: "",
          originDay: "",
          sendNow: 1,
          materialElements: [{
            currentType: 0,
            contentBack: "",
            fileUrl: "",
            type: 0,
            ext: { process: 0 },
            text: "",
          }]
        }]
      },
      queryParams: {
        content: [],
        imported: ''
      },
      visible: this.$route.query?.visible == 3,
      visibleStatus: this.$route.query?.visibleStatus == 2,
      dayList: [], // 发消息时间日历列表
      currentDay: "", // 当前选中天数
      openCollapse: ["1"],
      isRepeteData: false, // 是否含有重复数据
    }
  },
  computed: {
    isGroup() {
      return this.$route.query.sopType == 'clientSOP' ? false : true;
    }
  },
  methods: {
    stopHaneldle() {
    },
    delectItem(item) {
      if (this.visible || this.visibleStatus) {
        return
      }
      const index = this.taskMessageForm.sendMessageReqVOList.findIndex(v => JSON.stringify(v) == JSON.stringify(item));
      this.taskMessageForm.sendMessageReqVOList.splice(index, 1);
      if (this.taskMessageForm.sendMessageReqVOList.findIndex(v => v.day == this.currentDay) == -1) {
        this.currentDay = this.taskMessageForm.sendMessageReqVOList[0]?.day;
      }
      this.saveTask()
    },
    changeSendType() {
      if (this.taskMessageForm.sendType == 1) {
        this.currentDay = this.taskMessageForm.sendMessageReqVOList[0]?.day;
      }
    },
    addTask(day) {
      if (this.taskMessageForm.sendMessageReqVOList.filter(v => v.day == day)?.length >= 3) {
        this.$message.error("单天的时间设置不允许超过3个");
        return;
      }
      this.$refs.ruleForm.validate(async valid => {
        const length = this.taskMessageForm.sendMessageReqVOList.length;
        if (length > 0 ? valid && await this.validChild() : true) {
          if (length < 30) {
            this.activeItemNames.push(length);
            this.taskMessageForm.sendMessageReqVOList.push({
              name: '任务名称',
              day: day,
              originDay: day,
              disabled: day ? true : false,
              sendNow: null,
              materialElements: []
            })
          } else {
            this.$message.warning('最多添加30条')
          }
          this.currentDay = day;
          this.saveTask();
        }
      })
    },
    saveTask() {
      this.taskMessageForm.sendMessageReqVOList.map(v => {
        v.originDay = v.day;
      })
      const list = _.uniqBy(this.taskMessageForm.sendMessageReqVOList, 'day');
      this.dayList = _.sortBy(list, function (v) { return Number(v.day) })
    },
    inputDay(item, index) {
      this.isRepeteData = this.taskMessageForm.sendMessageReqVOList.filter(v => v.day == item.day).length > 1;
      const list = this.taskMessageForm.sendMessageReqVOList.filter(v => v.originDay == item.originDay);
      item.originDay = this.isRepeteData ? null : Number(item.day);
      list.map(v => {
        v.day = item.day;
        v.originDay = item.originDay;
        v.sendNow = v.sendNow && item.day == 1 ? v.sendNow : null;
      })
      this.currentDay = this.isRepeteData ? item.originDay : item.day;
      if (this.isRepeteData) return;
      this.saveTask()
    },
    // 回显taskMessageForm数据
    setTaskMessageForm(data) {
      if (!data) return;
      this.taskMessageForm.sendType = data.sendType;
      this.taskMessageForm.crossDayExecution = data.crossDayExecution;
      this.activeItemNames = []
      data.sendMessageReqVOList.map((v, i) => {
        this.activeItemNames.push(i)
        v.originDay = v.day;
        v.sendNow = v.sendNow ? 1 : null;
        v.materialElements?.map(m => {
          if (m.ext) {
            m.ext = JSON.parse(m.ext);
          }
        })
      })
      this.taskMessageForm.sendMessageReqVOList = data.sendMessageReqVOList;
      const list = _.uniqBy(this.taskMessageForm.sendMessageReqVOList, 'day');
      this.dayList = _.sortBy(list, function (v) { return Number(v.day) });
      this.currentDay = this.dayList[0].day;
    },
    //校验子组件必填项
    validChild() {
      return new Promise((resolve, reject) => {
        this.taskMessageForm.sendMessageReqVOList.map((v, i) => {
          this.$refs[`editMaterial${i}`][0].$refs.materialDetail.$refs.queryParams.validate(validForm => {
            resolve(validForm);
          })
        })
      })
    },
    // 禁用定时发送天数输入框
    disabledInput(item) {
      const day = this.isRepeteData ? item.originDay : item.day;
      const currentDayList = this.taskMessageForm.sendMessageReqVOList.filter(v => v.originDay == day);
      const index = currentDayList.findIndex(v => JSON.stringify(v) === JSON.stringify(item));
      return index != 0;
    },
    // 切换当前天数
    selectCurrentDay(item) {
      this.$refs.ruleForm.validate(valid => {
        if (valid && !this.isRepeteData) { // 没有重复数据时
          this.currentDay = item.day;
        }
      });
    }
  },
  watch: {
    taskMessageForm: {
      handler: function (val) {
        let data = _.cloneDeep(val);
        data.sendMessageReqVOList.map(v => {
          if (!v.materialElements) return;
          v.materialElements.map(m => {
            if (m.ext && typeof m.ext == 'object') {
              m.ext = JSON.stringify(m.ext);
            }
          })
        })
        data.sendMessageReqVOList = data.sendMessageReqVOList.filter(v => v && v.materialElements.length > 0);
        this.$emit('taskMessage', data)
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
.send-message ::v-deep {
  position: relative;

  .left-view {
    display: none;
  }

  .textView .bottom-btns {
    position: absolute;
    bottom: 20px;
    left: 40px;
    z-index: 9;
  }

  .form-day {
    .el-form-item__error {
      min-width: 200px;
    }
  }

  .dayItem {
    padding: 10px;
    border-radius: 4px;

    &.active {
      background: #F6F6FA;
    }
  }

  .fileList,
  .material-view {
    height: auto !important;
    max-height: auto !important;
  }

  .content-view {
    width: 100% !important;
  }

  .solids-none {
    padding: 0;
  }

  .disableAdd {
    pointer-events: none;
  }

  .sendMessageReqVOList ::v-deep {
    .el-form-item__error {
      top: 90%;
    }

    .el-card__body {
      padding-bottom: 0 !important;
    }

    .el-form-item--small.el-form-item {
      margin-bottom: 0 !important;
    }

    .add-main .add-item {
      margin: 0;
    }
  }

  .content-view {
    .el-form-item__error {
      top: 100%;
    }
  }

  .sopEditMaterial {
    .dropdown.padding-bottom {
      padding: 0;
      margin: 0 !important;
    }

    .el-card__body {
      padding-bottom: 0;
    }

    .el-scrollbar__wrap {
      margin: 0 !important;
      overflow: hidden;
    }

    .flex-twice.solids-right::after {
      display: none;
    }

  }

  .send-form {
    padding: 16px 20px 0 40px;

    .el-form-item__label {
      display: flex;
    }
  }

  .el-card {
    background-color: transparent !important;

    // .clientInfo,
    .icon-view {
      background-color: #FFFFFF;
    }

    .wordCount {
      background: transparent !important;
    }

    .item,
    .margin-bottom-sm.flex-sub {
      position: relative;
      margin-bottom: 0;
    }
  }

  .el-icon-message-path {
    width: 14px;
    height: 14px;
    margin-right: 8px;
    background: url(../../../assets/images/messagePath.png) center center no-repeat;
    background-size: contain;
  }

  .el-collapse-item__wrap,
  .el-collapse,
  .el-collapse-item__header {
    border-bottom: none;
    border-top: none;
    background: none;
  }

  .calendar {
    border: 0.5px solid #BCBCBC;
    border-radius: 6px;
    box-sizing: content-box;

    .el-collapse-item__header {
      height: 53px;
      border-radius: 6px;
      padding: 0 16px;
      background-color: #FFFFFF;
    }

    .el-collapse-item:last-child {
      margin-bottom: 0;
    }
  }

  .el-collapse-item__header {
    padding: 0 24px 0 40px;
    height: 40px;
    opacity: 1;
    line-height: 40px;
    background: #F3FAEF;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    position: relative;
  }

  .el-icon-arrow-right {
    color: #307EF2;
  }

  .expand {
    position: absolute;
    font-size: 14px;
    font-weight: normal;
    color: #307EF2;
    right: 50px;
  }

  .add-main {
    // margin: 0px 24px 45px 0px;
    border-radius: 2px;
    // padding: 0px 16px;

    .el-form-item__content {
      margin: 0 !important;
    }

    .add-item {
      margin-bottom: 16px;
      padding: 0 16px;
      background: #F9F9F9;

      .el-form {
        padding: 0;
      }

    }

    .add-item-container {
      .el-collapse-item__header {
        display: none;
      }
    }

    .title-text {
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      color: #333333;
      margin: 0 10px;
    }

    .el-collapse-item__content {
      padding-bottom: 0;
      background-color: #F9F9F9;
    }

    .add-line {
      border-bottom: 1px dashed #7F8EB1;
      border-spacing: 10px;
    }

    .el-collapse-item__header {
      padding: 0;
      height: 66px;
      opacity: 1;
      line-height: 66px;
      background: #F9F9F9;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      position: relative;
    }

    .expand {
      position: absolute;
      font-size: 14px;
      font-weight: normal;
      color: #307EF2;
      right: 27px;
    }
  }
}
</style>