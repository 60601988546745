var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "task-forward margin-bottom-sm" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: 1 } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "header-icon el-icon-forward-path" }),
                _vm._v(" 转发 "),
                _c("span", { staticClass: "expand" }, [
                  _vm._v(_vm._s(_vm.activeNames.length > 0 ? "收起" : "展开")),
                ]),
              ]),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "forward-form",
                  attrs: {
                    rules: _vm.rules,
                    disabled: _vm.visible || _vm.visibleStatus,
                    model: _vm.taskForwardForm,
                    "label-width": "110px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "转发对象",
                        prop: "taskTransferCustomerReqVOList",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("span", { staticClass: "margin-right-xs" }, [
                            _vm._v("选择人"),
                          ]),
                          _c(
                            "el-button",
                            {
                              staticClass: "margin-left-xs",
                              attrs: {
                                type: "text",
                                size: "medium",
                                disabled: _vm.visible || _vm.visibleStatus,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addPeople("1")
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-circle-plus add-icon",
                              }),
                              _vm._v(" 添加 "),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.taskForwardForm.taskTransferCustomerReqVOList?.length
                        ? _c("div", { staticClass: "people-select" }, [
                            _c(
                              "span",
                              {
                                staticClass: "people-select-title",
                                on: {
                                  click: function ($event) {
                                    return _vm.addPeople("1")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.friendDataLimit))]
                            ),
                            _vm._v(" 等"),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskForwardForm
                                    .taskTransferCustomerReqVOList?.length
                                )
                              ),
                            ]),
                            _vm._v("个客户 "),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "line-add" }),
                      _c(
                        "div",
                        [
                          _c("span", { staticClass: "margin-right-xs" }, [
                            _vm._v("选择群"),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                size: "medium",
                                disabled: _vm.visible || _vm.visibleStatus,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addPeople("2")
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-circle-plus add-icon",
                              }),
                              _vm._v(" 添加 "),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.taskForwardForm.taskTransferCustomerGroupReqVOList
                        ?.length
                        ? _c("div", { staticClass: "people-select" }, [
                            _c(
                              "span",
                              {
                                staticClass: "people-select-title",
                                on: {
                                  click: function ($event) {
                                    return _vm.addPeople("2")
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.groupDataLimit))]
                            ),
                            _vm._v(" 等"),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.taskForwardForm
                                    .taskTransferCustomerGroupReqVOList?.length
                                )
                              ),
                            ]),
                            _vm._v("个客户群 "),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "taskForwardView",
                      attrs: {
                        label: "转发说明",
                        prop: "taskTransferMaterialElements",
                        rules: [
                          {
                            required: true,
                            message: "请输入转发说明",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("edit-material", {
                        ref: "editMaterial",
                        attrs: {
                          disabled: _vm.visible || _vm.visibleStatus,
                          materialElements:
                            _vm.taskForwardForm.taskTransferMaterialElements,
                          isSopShow: true,
                          hideCustom: true,
                        },
                        on: {
                          "update:materialElements": function ($event) {
                            return _vm.$set(
                              _vm.taskForwardForm,
                              "taskTransferMaterialElements",
                              $event
                            )
                          },
                          "update:material-elements": function ($event) {
                            return _vm.$set(
                              _vm.taskForwardForm,
                              "taskTransferMaterialElements",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("select-dialog", {
        ref: "selectDialog",
        attrs: {
          formData: {
            type: _vm.type,
            selectData:
              _vm.type == 1
                ? _vm.taskForwardForm.taskTransferCustomerReqVOList
                : _vm.taskForwardForm.taskTransferCustomerGroupReqVOList,
            staffAccountId: _vm.taskForwardForm.staffAccountId,
          },
        },
        on: { getCrowPeople: _vm.getCrowPeople },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }