<template>
    <div>
        <div class="node-wrap" v-if="nodeConfig.type == 1">
            <div class="node-wrap-box"
                :class="(nodeConfig.type == 1 ? 'start-node ' : '') + (isTried && nodeConfig.error ? 'active error' : '')">
                <div @click="editStageItem(sopItemId)">
                    <div class="title start-node-title" :class="(!sopItemId ? 'start-node-no' : '')">
                        <span v-if="nodeConfig.type == 1">{{ `客户${this.$route.query.sopType == 'clientSOP' ? '' : '群'}阶段设置`
                        }}</span>
                    </div>
                    <div class="flex-center justify-center padding-xs" style="gap: 10px; align-items: stretch;">
                        <div class="content flex-sub radius bg-gray">
                            <div class="text text-left">
                                <div class="content-text margin-bottom-xs">
                                    <template v-if="sopItemId">
                                        <i class="el-icon-sop-y sop-right vertical-middle"></i>
                                        <span style="max-width: 90%;" class="sop-middle text-cut">
                                            阶段名称：{{ nodeConfig.name }}
                                        </span>
                                    </template>
                                    <template v-else>
                                        <i class="el-icon-sop-no-y sop-right vertical-middle"></i>
                                        <span class="sop-middle">创建用户阶段名称、触发条件</span>

                                    </template>
                                </div>
                                <div class="content-text ">执行任务：{{ condObj[nodeConfig.selectCondition] }}</div>
                            </div>
                        </div>
                        <div class="content flex-sub radius bg-gray">
                            <div class="text text-left">
                                <div class="content-text margin-bottom-xs">
                                    <i v-if="sopItemId" class="el-icon-sop-s sop-right vertical-middle"></i>
                                    <i v-else class="el-icon-sop-no-s sop-right vertical-middle"></i>
                                    <span class="sop-middle">配置阶段任务</span>
                                </div>
                                <div class="content-text">{{ sopItemId ? nodeConfig.details : '设置阶段的执行任务' }}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="add-node-btn-box">
                <div class="add-node-btn">
                    <button class="btn" :disabled="visible || visibleStatus" type="button"
                        @click="addStageTask(nodeConfig, $parent.nodeConfig, true)">
                        <span class="iconfont"></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="branch-wrap" v-if="nodeConfig.type == 4">
            <div class="branch-box-wrap">
                <div class="branch-box">
                    <div class="col-box" v-for="(item, index) in nodeConfig.conditionNodes" :key="index">
                        <div class="condition-node">
                            <div class="condition-node-box">
                                <div class="arrow-down-img">
                                    <img class="imgs" src="@/assets/images/arrow-downs.png" />
                                </div>
                                <div class="auto-judge" :class="isTried && item.error ? 'error active' : ''"
                                    @click="handleCommand(['editTask'], item, ($parent.nodeConfig?.conditionNodes && $parent.nodeConfig?.conditionNodes[index]) || $parent.nodeConfig)">
                                    <div class="title-wrapper">
                                        <!-- <span class="editable-title">{{ item?.exra ? JSON.parse(item?.exra).name : ''
                                        }}</span> -->
                                        <span class="editable-title">{{ item.name || item.nodeName }} </span>
                                        <span class="priority-title">
                                            <el-dropdown trigger="click"
                                                @command="handleCommand(arguments, item, ($parent.nodeConfig?.conditionNodes && $parent.nodeConfig?.conditionNodes[index]) || $parent.nodeConfig, { childNode: nodeConfig, id: nodeConfig.id })">
                                                <i @click.stop="" class="el-icon-arrow-down el-icon-setting"></i>
                                                <el-dropdown-menu slot="dropdown">
                                                    <el-dropdown-item command="editTask">编辑</el-dropdown-item>
                                                    <el-dropdown-item :disabled="visible || visibleStatus"
                                                        command="delTerm">删除</el-dropdown-item>
                                                    <el-dropdown-item :disabled="visible || visibleStatus"
                                                        command="copyClick">复制</el-dropdown-item>
                                                    <el-dropdown-item :disabled="visible || visibleStatus"
                                                        command="addTask">添加节点</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </span>

                                    </div>
                                    <div class="content">
                                        <div class="content-text" v-if="item.showCollectFlag">
                                            收集情况：{{ condObjClick(item.selectCondition) }}
                                        </div>
                                        <div class="content-text" v-else>
                                            回复情况：{{ condObjClick(item.selectCondition) }}
                                        </div>
                                        <div class="content-text">执行任务：{{ item.performTypeName }}</div>
                                    </div>
                                </div>
                                <div class="add-node-btn-box" @click.stop="">
                                    <div class="add-node-btn">
                                        <button class="btn" type="button" :disabled="visible || visibleStatus"
                                            @click="addNextTask(nodeConfig.conditionNodes[index], $parent.nodeConfig)">
                                            <span class="iconfont"></span>
                                        </button>
                                    </div>
                                </div>
                                <!-- <addNode :childNodeP.sync="item.childNode"></addNode> -->
                            </div>
                        </div>
                        <nodeWrap v-on="$listeners" v-if="item.childNode" :nodeConfig.sync="item.childNode"></nodeWrap>
                        <span style="color: #f3f3f3;height:20px;"> </span>
                        <!-- #f3f3f3; -->
                        <template v-if="index == 0">
                            <div class="top-left-cover-line"></div>
                            <div class="bottom-left-cover-line"></div>
                        </template>
                        <template v-if="index == nodeConfig.conditionNodes.length - 1">
                            <div class="top-right-cover-line"></div>
                            <div class="bottom-right-cover-line"></div>
                        </template>
                    </div>
                </div>
                <!-- <addNode :childNodeP.sync="nodeConfig.childNode"></addNode> -->
            </div>
        </div>
        <nodeWrap v-on="$listeners" v-if="nodeConfig.childNode" :nodeConfig.sync="nodeConfig.childNode">
        </nodeWrap>
    </div>
</template>
<script>
import addDrawer from '@/views/sop/addDrawer.vue';
import { mapState, mapMutations } from 'vuex'
export default {
    components: {
        addDrawer
    },
    props: ["nodeConfig", "flowPermission", 'sopItemId'],
    data() {
        return {
            isTried: false,
            condObj: {
                '100': '客户基本信息',
                '101': '客户标签',
                '102': '客户回复',
                '103': '客户不回复',
                '104': '收集成功',
                '105': '收集失败',
                '200': '客户群基本信息',
                '201': '客户群标签',
                '202': '客户群回复',
                '203': '客户群不回复'
            },
            parentNodeId: '0',
            nodeIndex: -1, // 当前节点
            visible: false, //
            visibleStatus: false
        }
    },
    mounted() {
        this.parentNodeId = '0';
        this.visible = this.$route.query?.visible == 3;
        this.visibleStatus = this.$route.query?.visibleStatus == 2
    },
    watch: {
    },
    computed: {
        ...mapState('app', ['conditionsConfig1']),
    },

    methods: {
        condObjClick(val) {
            return this.condObj[val]
        },
        handleCommand([args], nodeConfig, parentNodeConfig, delData) {
            this.$emit(args, nodeConfig, parentNodeConfig, delData);
        },
        // 添加阶段下第一级任务
        addStageTask(nodeConfig, parentNodeConfig) {
            this.$emit("addStageTask", nodeConfig, parentNodeConfig);
        },
        // 添加下一级任务
        addNextTask(nodeConfig, parentNodeConfig) {
            this.$emit('addNextTask', nodeConfig, parentNodeConfig);
        },
        //编辑/新建sop阶段
        editStageItem(sopItemId) {
            this.$emit("editStageItem", sopItemId);
        }
    }
}
</script>
<style lang="scss" scoped>
.priority-title i {
    padding: 10px;
    position: absolute;
    right: -10px;
    top: -20px;
}

.sop-right {
    margin-right: 5px;
    vertical-align: middle;
}

.sop-middle {
    display: inline-block;
    vertical-align: middle;
}

.el-icon-sop-no-y {
    width: 13.5px;
    height: 13.5px;
    background: url(../../../assets/images/sop_no_y.png) center center no-repeat;
    background-size: contain;
}


.el-icon-sop-no-s {
    width: 13.5px;
    height: 13.5px;
    background: url(../../../assets/images/sop_no_s.png) center center no-repeat;
    background-size: contain;
}

.el-icon-sop-y {
    width: 13.5px;
    height: 13.5px;
    background: url(../../../assets/images/sop_y.png) center center no-repeat;
    background-size: contain;
}

.el-icon-sop-s {
    width: 13.5px;
    height: 13.5px;
    background: url(../../../assets/images/sop_s.png) center center no-repeat;
    background-size: contain;
}

.add-node-btn-box {
    width: 240px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        margin: auto;
        width: 2px;
        height: 100%;
        background-color: #cacaca
    }

    .add-node-btn {
        user-select: none;
        width: 240px;
        padding: 00px 0 32px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        flex-shrink: 0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        z-index: 999;

        .btn {
            outline: none;
            width: 30px;
            height: 30px;
            background: #fff;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
            border-radius: 50%;
            position: relative;
            line-height: 30px;
            border: none;
            margin-top: -20px;
            -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
            transition: all .3s cubic-bezier(.645, .045, .355, 1);

            .iconfont {
                color: #999;
                font-size: 16px
            }

            &:hover {

                background: #3296fa;
                transform: scale(1.3);

                box-shadow: 0 13px 27px 0 rgba(0, 0, 0, .1);

                .iconfont {
                    color: #fff;
                }
            }

            &:active {
                transform: none;
                background: #1e83e9;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1)
            }
        }
    }
}

.error_tip {
    position: absolute;
    top: 0px;
    right: 0px;
    transform: translate(150%, 0px);
    font-size: 24px;
}

.promoter_person .el-dialog__body {
    padding: 10px 20px 14px 20px;
}

.selected_list {
    margin-bottom: 20px;
    line-height: 30px;
}

.selected_list span {
    margin-right: 10px;
    padding: 3px 6px 3px 9px;
    line-height: 12px;
    white-space: nowrap;
    border-radius: 2px;
    border: 1px solid rgba(220, 220, 220, 1);
}

.selected_list img {
    margin-left: 5px;
    width: 7px;
    height: 7px;
    cursor: pointer;
}

.arrow-down-img {
    opacity: 1;
    height: 22px;
    z-index: 99;
    background-color: #f3f3f3;
    position: relative;
    width: 16px;

    .imgs {
        position: absolute;
        left: 2px;
        display: inline-block;
        width: 12px;
        height: 12px;
    }
}




.start-node-title {
    /* background-color: red !important; */
    /* text-align: center; */
    /* background: linear-gradient(180deg, #ABB5CE 0%, #BAC3D8 100%); */
}

.start-node-no {
    background: #B8C1D6 !important;
    color: #fff;
}
</style>