import request from '@/utils/request'

// 编辑标签
export function editLabel(data) {
	return request({
		url: '/label/manager/edit',
		method: 'POST',
		data: data
	})
}

// 创建标签
export function createLabel(data) {
	return request({
		url: '/label/manager/create',
		method: 'POST',
		data: data
	})
}

// 标签分页
export function pageLabel(query) {
	return request({
		url: '/label/manager/page',
		method: 'get',
		params: query
	})
}

// 删除标签
export function deleteLabel(query) {
	return request({
		url: '/label/manager/delete',
		method: 'get',
		params: query
	})
}

// 打标签
export function signLabel(data) {
	return request({
		url: '/label/manager/mark',
		method: 'POST',
		data
	})
}
// 把发送的对象存为标签 
export function sendGroupLabelSave(data) {
	return request({
		url: '/send/group/label/save',
		method: 'POST',
		data
	})
}
// 打标签(批量追加)
export function markBatchLabel(data) {
	return request({
		url: '/label/manager/markBatch',
		method: 'POST',
		data
	})
}
// 获取使用的微信列表
export function getPageLabel(params) {
	return request({
		url: '/label/manager/usedWx/list',
		method: 'get',
		params
	})
}
// 所有标签
export function getlabelManagerAll(params) {
	return request({
		url: '/label/manager/all',
		method: 'get',
		params
	})
}
// 获取客户标签
export function getManagerLabelList(params) {
	return request({
		url: '/label/manager/list',
		method: 'get',
		params
	})
}
