var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.nodeConfig.type == 1
        ? _c("div", { staticClass: "node-wrap" }, [
            _c(
              "div",
              {
                staticClass: "node-wrap-box",
                class:
                  (_vm.nodeConfig.type == 1 ? "start-node " : "") +
                  (_vm.isTried && _vm.nodeConfig.error ? "active error" : ""),
              },
              [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.editStageItem(_vm.sopItemId)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "title start-node-title",
                        class: !_vm.sopItemId ? "start-node-no" : "",
                      },
                      [
                        _vm.nodeConfig.type == 1
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  `客户${
                                    this.$route.query.sopType == "clientSOP"
                                      ? ""
                                      : "群"
                                  }阶段设置`
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex-center justify-center padding-xs",
                        staticStyle: { gap: "10px", "align-items": "stretch" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "content flex-sub radius bg-gray" },
                          [
                            _c("div", { staticClass: "text text-left" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "content-text margin-bottom-xs",
                                },
                                [
                                  _vm.sopItemId
                                    ? [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-sop-y sop-right vertical-middle",
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "sop-middle text-cut",
                                            staticStyle: { "max-width": "90%" },
                                          },
                                          [
                                            _vm._v(
                                              " 阶段名称：" +
                                                _vm._s(_vm.nodeConfig.name) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-sop-no-y sop-right vertical-middle",
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "sop-middle" },
                                          [_vm._v("创建用户阶段名称、触发条件")]
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _c("div", { staticClass: "content-text" }, [
                                _vm._v(
                                  "执行任务：" +
                                    _vm._s(
                                      _vm.condObj[
                                        _vm.nodeConfig.selectCondition
                                      ]
                                    )
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "content flex-sub radius bg-gray" },
                          [
                            _c("div", { staticClass: "text text-left" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "content-text margin-bottom-xs",
                                },
                                [
                                  _vm.sopItemId
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-sop-s sop-right vertical-middle",
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-sop-no-s sop-right vertical-middle",
                                      }),
                                  _c("span", { staticClass: "sop-middle" }, [
                                    _vm._v("配置阶段任务"),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "content-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.sopItemId
                                      ? _vm.nodeConfig.details
                                      : "设置阶段的执行任务"
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "add-node-btn-box" }, [
              _c("div", { staticClass: "add-node-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    attrs: {
                      disabled: _vm.visible || _vm.visibleStatus,
                      type: "button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.addStageTask(
                          _vm.nodeConfig,
                          _vm.$parent.nodeConfig,
                          true
                        )
                      },
                    },
                  },
                  [_c("span", { staticClass: "iconfont" }, [_vm._v("")])]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.nodeConfig.type == 4
        ? _c("div", { staticClass: "branch-wrap" }, [
            _c("div", { staticClass: "branch-box-wrap" }, [
              _c(
                "div",
                { staticClass: "branch-box" },
                _vm._l(_vm.nodeConfig.conditionNodes, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "col-box" },
                    [
                      _c("div", { staticClass: "condition-node" }, [
                        _c("div", { staticClass: "condition-node-box" }, [
                          _vm._m(0, true),
                          _c(
                            "div",
                            {
                              staticClass: "auto-judge",
                              class:
                                _vm.isTried && item.error ? "error active" : "",
                              on: {
                                click: function ($event) {
                                  _vm.handleCommand(
                                    ["editTask"],
                                    item,
                                    (_vm.$parent.nodeConfig?.conditionNodes &&
                                      _vm.$parent.nodeConfig?.conditionNodes[
                                        index
                                      ]) ||
                                      _vm.$parent.nodeConfig
                                  )
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "title-wrapper" }, [
                                _c("span", { staticClass: "editable-title" }, [
                                  _vm._v(
                                    _vm._s(item.name || item.nodeName) + " "
                                  ),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "priority-title" },
                                  [
                                    _c(
                                      "el-dropdown",
                                      {
                                        attrs: { trigger: "click" },
                                        on: {
                                          command: function ($event) {
                                            _vm.handleCommand(
                                              arguments,
                                              item,
                                              (_vm.$parent.nodeConfig
                                                ?.conditionNodes &&
                                                _vm.$parent.nodeConfig
                                                  ?.conditionNodes[index]) ||
                                                _vm.$parent.nodeConfig,
                                              {
                                                childNode: _vm.nodeConfig,
                                                id: _vm.nodeConfig.id,
                                              }
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-arrow-down el-icon-setting",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                            },
                                          },
                                        }),
                                        _c(
                                          "el-dropdown-menu",
                                          {
                                            attrs: { slot: "dropdown" },
                                            slot: "dropdown",
                                          },
                                          [
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: { command: "editTask" },
                                              },
                                              [_vm._v("编辑")]
                                            ),
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.visible ||
                                                    _vm.visibleStatus,
                                                  command: "delTerm",
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.visible ||
                                                    _vm.visibleStatus,
                                                  command: "copyClick",
                                                },
                                              },
                                              [_vm._v("复制")]
                                            ),
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                attrs: {
                                                  disabled:
                                                    _vm.visible ||
                                                    _vm.visibleStatus,
                                                  command: "addTask",
                                                },
                                              },
                                              [_vm._v("添加节点")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "content" }, [
                                item.showCollectFlag
                                  ? _c("div", { staticClass: "content-text" }, [
                                      _vm._v(
                                        " 收集情况：" +
                                          _vm._s(
                                            _vm.condObjClick(
                                              item.selectCondition
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", { staticClass: "content-text" }, [
                                      _vm._v(
                                        " 回复情况：" +
                                          _vm._s(
                                            _vm.condObjClick(
                                              item.selectCondition
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                _c("div", { staticClass: "content-text" }, [
                                  _vm._v(
                                    "执行任务：" + _vm._s(item.performTypeName)
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "add-node-btn-box",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "add-node-btn" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn",
                                    attrs: {
                                      type: "button",
                                      disabled:
                                        _vm.visible || _vm.visibleStatus,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addNextTask(
                                          _vm.nodeConfig.conditionNodes[index],
                                          _vm.$parent.nodeConfig
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "iconfont" }, [
                                      _vm._v(""),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      item.childNode
                        ? _c(
                            "nodeWrap",
                            _vm._g(
                              {
                                attrs: { nodeConfig: item.childNode },
                                on: {
                                  "update:nodeConfig": function ($event) {
                                    return _vm.$set(item, "childNode", $event)
                                  },
                                  "update:node-config": function ($event) {
                                    return _vm.$set(item, "childNode", $event)
                                  },
                                },
                              },
                              _vm.$listeners
                            )
                          )
                        : _vm._e(),
                      _c("span", {
                        staticStyle: { color: "#f3f3f3", height: "20px" },
                      }),
                      index == 0
                        ? [
                            _c("div", { staticClass: "top-left-cover-line" }),
                            _c("div", {
                              staticClass: "bottom-left-cover-line",
                            }),
                          ]
                        : _vm._e(),
                      index == _vm.nodeConfig.conditionNodes.length - 1
                        ? [
                            _c("div", { staticClass: "top-right-cover-line" }),
                            _c("div", {
                              staticClass: "bottom-right-cover-line",
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _vm.nodeConfig.childNode
        ? _c(
            "nodeWrap",
            _vm._g(
              {
                attrs: { nodeConfig: _vm.nodeConfig.childNode },
                on: {
                  "update:nodeConfig": function ($event) {
                    return _vm.$set(_vm.nodeConfig, "childNode", $event)
                  },
                  "update:node-config": function ($event) {
                    return _vm.$set(_vm.nodeConfig, "childNode", $event)
                  },
                },
              },
              _vm.$listeners
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "arrow-down-img" }, [
      _c("img", {
        staticClass: "imgs",
        attrs: { src: require("@/assets/images/arrow-downs.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }