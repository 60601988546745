var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contailner" },
    [
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c(
            "div",
            { staticClass: "top-tabs" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.queryParams.type,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.queryParams,
                        "type",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "queryParams.type",
                  },
                },
                [
                  _c("el-tab-pane", { attrs: { label: "客户群", name: "2" } }),
                  _c("el-tab-pane", { attrs: { label: "客户", name: "1" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "queryParams",
                  staticClass: "search-form",
                  attrs: { model: _vm.queryParams, "label-width": "auto" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "采集名称：", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        on: {
                          change: function ($event) {
                            return _vm.getList("inquire")
                          },
                        },
                        model: {
                          value: _vm.queryParams.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryParams,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryParams.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "状态：",
                        "label-width": "70px",
                        prop: "status",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择（启用/停用）",
                            clearable: "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getList("inquire")
                            },
                          },
                          model: {
                            value: _vm.queryParams.status,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryParams,
                                "status",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "queryParams.status",
                          },
                        },
                        _vm._l(
                          this.getDictDatas(
                            _vm.DICT_TYPE.ROBOT_MATERIALAUTO_STATUS
                          ),
                          function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "AI员工", prop: "staffAccountId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.queryParams.staffAccountId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryParams,
                                "staffAccountId",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "queryParams.staffAccountId",
                          },
                        },
                        _vm._l(_vm.staffList, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: { label: item.nickname, value: item.id },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "wechat-all",
                                  staticStyle: { width: "340px" },
                                },
                                [
                                  _c("div", { staticClass: "wechat-id" }, [
                                    _vm._v(
                                      _vm._s(item.nickname) +
                                        " ( " +
                                        _vm._s(item.externalOpenId) +
                                        " )"
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "accountType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", "collapse-tags": "" },
                          model: {
                            value: _vm.queryParams.accountType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "accountType", $$v)
                            },
                            expression: "queryParams.accountType",
                          },
                        },
                        _vm._l(
                          _vm.getDictDatas(_vm.DICT_TYPE.ROBOT_CUSTOMER_TPYE),
                          function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-bottom": "0" } },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["system:materialAuto:query"],
                              expression: "['system:materialAuto:query']",
                            },
                          ],
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: {
                            click: function ($event) {
                              return _vm.getList("inquire")
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh-right" },
                          on: {
                            click: function ($event) {
                              return _vm.resetForm()
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isSelect
            ? _c(
                "div",
                {
                  staticClass:
                    "dropdown margin-bottom-xs flex-center justify-end",
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["system:materialAuto:create"],
                          expression: "['system:materialAuto:create']",
                        },
                      ],
                      staticStyle: { padding: "9px 15px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.handleEdit },
                    },
                    [_vm._v(" 新建采集 ")]
                  ),
                  _c(
                    "el-dropdown",
                    {
                      staticClass: "margin-left-xs",
                      on: { command: _vm.handleCommand },
                    },
                    [
                      _c(
                        "el-button",
                        { staticClass: "el-dropdown-link dropdown-button" },
                        [
                          _vm._v(" 批量操作 "),
                          _c("i", { staticClass: "el-icon-caret-bottom" }),
                        ]
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c("el-dropdown-item", { attrs: { command: "0" } }, [
                            _vm._v("批量开启"),
                          ]),
                          _c("el-dropdown-item", { attrs: { command: "1" } }, [
                            _vm._v("批量关闭"),
                          ]),
                          _c(
                            "el-dropdown-item",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["system:materialAuto:delete"],
                                  expression: "['system:materialAuto:delete']",
                                },
                              ],
                              attrs: { command: "2" },
                            },
                            [_vm._v("批量删除")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              attrs: {
                "row-key": _vm.getRowKeys,
                data: _vm.list,
                height: "calc(100vh - 390px)",
              },
              on: { "selection-change": _vm.selectChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "left",
                  type: "selection",
                  "reserve-selection": true,
                  width: "55",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "序号", type: "index", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "采集名称",
                  "show-overflow-tooltip": "",
                  prop: "name",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  width: "150",
                  label:
                    _vm.queryParams.type == 1
                      ? "被采集客户名称"
                      : "被采集客群名称",
                  prop: "collectWxName",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.collectWxName
                                  ? scope.row.collectWxName
                                  : "无群名称"
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "AI员工", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.aiStaffName))]),
                        _c("channelType", {
                          attrs: {
                            type: _vm.DICT_TYPE.ROBOT_CHANNEL_TYPE,
                            value: scope.row.accountType,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "社交账号", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          { attrs: { trigger: "hover", placement: "top" } },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-center" },
                              [
                                _c("wechatImg", {
                                  staticClass: "margin-right-xs",
                                  attrs: { type: scope.row.accountType },
                                }),
                                _c("div", { staticClass: "wechat-state" }, [
                                  _vm._v(_vm._s(scope.row.fromWxName)),
                                ]),
                                _c("dict-tag", {
                                  staticClass: "margin-left-xs",
                                  attrs: {
                                    showTag: false,
                                    type: _vm.DICT_TYPE.ROBOT_ACCOUNT_STATE,
                                    value: scope.row.wechatState,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "27px" } },
                              [_vm._v(_vm._s(scope.row.wechatNo))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "name-wrapper",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex-center" },
                                  [
                                    _c("wechatImg", {
                                      staticClass: "margin-right-xs",
                                      attrs: { type: scope.row.accountType },
                                    }),
                                    _c("div", { staticClass: "wechat-state" }, [
                                      _vm._v(_vm._s(scope.row.fromWxName)),
                                    ]),
                                    _c("dict-tag", {
                                      staticClass: "margin-left-xs",
                                      attrs: {
                                        showTag: false,
                                        type: _vm.DICT_TYPE.ROBOT_ACCOUNT_STATE,
                                        value: scope.row.wechatState,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-cut",
                                    staticStyle: { "margin-left": "27px" },
                                  },
                                  [_vm._v(_vm._s(scope.row.wechatNo))]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              !_vm.isSelect
                ? _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      width: "100",
                      label: "编辑时间",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.parseTime(scope.row.updateTime))
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1337858563
                    ),
                  })
                : _vm._e(),
              !_vm.isSelect
                ? _c("el-table-column", {
                    attrs: { label: "使用状态" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("dict-tag", {
                                attrs: {
                                  type: _vm.DICT_TYPE.ROBOT_MATERIALAUTO_STATUS,
                                  value: scope.row.status,
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      163791866
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  fixed: "right",
                  align: "left",
                  "class-name": "small-padding fixed-width",
                  width: "260",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !_vm.isSelect
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSwitch(scope.row, "switch")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.status === 1 ? "停用" : "启用"
                                    )
                                ),
                              ]
                            )
                          : _vm._e(),
                        !_vm.isSelect
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["system:materialAuto:edit"],
                                    expression: "['system:materialAuto:edit']",
                                  },
                                ],
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            )
                          : _vm._e(),
                        !_vm.isSelect
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSee(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 查看 ")]
                            )
                          : _vm._e(),
                        _vm.isSelect
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUse(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 引用 ")]
                            )
                          : _vm._e(),
                        !_vm.isSelect
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["system:materialAuto:delete"],
                                    expression:
                                      "['system:materialAuto:delete']",
                                  },
                                ],
                                staticStyle: { color: "#F03308" },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSwitch(scope.row, "delete")
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.queryParams.pageNo,
              limit: _vm.queryParams.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryParams, "pageNo", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryParams, "pageSize", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c("DialogSee", { ref: "dialogSee", on: { getData: _vm.getData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }