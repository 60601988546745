<template>
  <div class="content" :style="{
    background: 'url(' + imgUrl + ') center center no-repeat', 'background-size': 'contain'
  }">
    <div v-if="Object.keys(noticeInfo).length > 0"
      class="text-cut bg-white margin-lr-sm padding-xs radius margin-top-xs">
      <i class="icon-notice" style="vertical-align: middle;"></i> {{ noticeInfo.contentBack || noticeInfo.text }}
    </div>
    <el-scrollbar ref="scrollContainer" :style="{ height: Object.keys(noticeInfo).length > 0 ? '440px' : '' }"
      class="scroll-container">
      <div ref="container" class="padding-bottom-sm padding-left-sm">
        <div class="flex margin-top-xs" :ref="`material${index}`" v-for="(item, index) in materialData"
          :key="JSON.stringify(item) || index">
          <div class="icon-avatar"></div>
          <div class="preview-content margin-left-xs">
            <!-- 文字 -->
            <div v-if="item.type == '0'" class="padding-text" :class="[item.isSuccess ? 'preview-max' : '']">
              <el-scrollbar>
                <div style="line-height: 32px;" v-html="item.contentBack"></div>
              </el-scrollbar>
              <!-- {{ item.content }} -->
              <!-- <i v-if="Number(item.isSuccess) === 2" class="el-icon-warning"></i> -->
            </div>
            <!-- 图片 -->
            <div class="preview-view pointer" v-if="item.type == '1'" :class="[item.isSuccess ? 'preview-max' : '']">
              <el-image style="border-radius: 8px" :src="item.fileUrl" :preview-src-list="[item.fileUrl]"></el-image>
              <i v-if="Number(item.isSuccess) === 2" class="el-icon-warning"></i>
            </div>

            <!-- 视频 -->
            <div class="preview-view pointer" v-if="item.type == '2'" :class="[item.isSuccess ? 'preview-max' : '']">
              <video style="width: 100%; border-radius: 8px" :src="item.fileUrl"></video>
              <div class="icon-play"></div>
              <i v-if="Number(item.isSuccess) === 2" class="el-icon-warning"></i>
            </div>
            <!-- 文档 -->
            <div class="preview-view pointer" v-if="officeArr.indexOf(String(item.type)) != -1"
              :class="[item.isSuccess ? 'preview-max' : '']">
              {{ item.fileName }}
              <i v-if="Number(item.isSuccess) === 2" class="el-icon-warning"></i>
            </div>
            <div class="preview-view pointer" v-if="item.type == '7'" :class="[item.isSuccess ? 'preview-max' : '']">
              <div class="item-h5">
                <div class="h-title">
                  {{ item.title }}
                </div>
                <div class="h-content">
                  {{ item.description }}
                </div>
                <img class="h-img" :src="item.url" alt="" />
                <!-- <div class="h-collect">底部</div> -->
              </div>
            </div>
            <!-- 群公告 -->
            <div v-if="item.type == '8'" class="preview-text" :class="[item.isSuccess ? 'preview-max' : '']">
              <div>群公告</div>
              <el-scrollbar class="margin-top-xs">
                <div style="word-break: break-all;max-height: 150px;white-space: pre-line;"> {{ item.contentBack ||
    item.text }}</div>
              </el-scrollbar>
              <i v-if="Number(item.isSuccess) === 2" class="el-icon-warning"></i>
            </div>
            <!-- 小程序 -->
            <div v-if="item.type == '10'" style="width: 200px;"
              class="solid program-view margin-top-xs bg-white radius-8 pointer padding-xs">
              <div class="flex-center">
                <i v-if="!item.mpLogo" class="icon-top-logo margin-right-xs"></i>
                <el-image v-else :src="item.mpLogo" class="icon-top-logo margin-right-xs"></el-image>
                <div class="text-cut" style="max-width: 170px">{{ item.title }}</div>
              </div>
              <div class="text-cut2 margin-top-xs" style="line-height: 1.5;">
              </div>
              <el-image :src="item.url" fit="contain" class="image"></el-image>
              <div class="flex-center solid-top padding-top-xs margin-top-xs">
                <i class="icon-bottom-logo margin-right-xs"></i>小程序
              </div>
            </div>
            <div class="download" v-if="![0, 7, 8, 10, '0'].includes(item.type)">
              <el-button v-if="officeArr.indexOf(String(item.type)) == -1" @click="previewFile(item)"
                style="padding: 5px 10px" size="small" type="primary" plain>
                <i class="icon-preview"></i> <span class="text-sm">预览</span>
              </el-button>
              <el-button @click="downloadFile(item)" style="padding: 5px 10px" type="primary" plain>
                <i class="icon-down"></i> <span class="text-sm">下载</span>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <!-- 视频预览 -->
    <preview-video ref="previewVideo" :url="playUrl"></preview-video>
    <!-- 图片预览 -->
    <el-image-viewer z-index="99999" v-if="showViewer" :onClose="() => { showViewer = false; }"
      :url-list="previewUrlList" />
  </div>
</template>

<script>
//isSuccess 0发送中 1 成功 2失败 只在群发管理 - 查看详情存在
import previewVideo from "../../../components/previewVideo/previewVideo.vue";
import _ from "lodash";
export default {
  components: {
    previewVideo,
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  props: {
    materialData: {
      type: [Object, Array],
    },
    imgUrl: {
      default:
        "https://oss.isuiyou.cn/scrm/723a76cd-1cd6-4bc6-bac0-a80d1508bd67/mobilePhone.png",
      type: String,
    },
  },
  data() {
    return {
      officeArr: ["3", "4", "5"],
      playUrl: "",
      previewUrlList: [],
      showViewer: false,
      materialDataList: [],
    };
  },
  computed: {
    noticeInfo() {
      return this.materialData?.find(v => v.type == 8) || {}
    }
  },
  watch: {
    materialData: {
      handler: function (val, oldVal) {
        this.getElement(val)
      },
      deep: true
    }
  },
  methods: {
    previewFile(item) {
      if (item.type == 2) {
        this.playUrl = item.fileUrl;
        this.$nextTick(() => {
          this.$refs.previewVideo.playVideo();
        });
      }
      if (item.type == 1) {
        this.previewUrlList = [item.fileUrl];
        this.showViewer = true;
      }
    },
    downloadFile({ fileUrl, fileName }) {
      this.$util.downFile({ url: fileUrl, name: fileName });
    },
    getElement: _.debounce(function (val) {
      const differenceData = _.differenceWith(val, this.materialDataList, _.isEqual)[0];
      const index = val.findIndex(v => v.id == differenceData?.id);
      if (this.$refs[`material${index}`]) {
        this.$refs.scrollContainer.wrap.scrollTop = this.getElementDistance(this.$refs[`material${index}`][0]);
      }
      this.$nextTick(() => {
        this.materialDataList = _.cloneDeep(val);
      })
    }, 500),
    scrollToView(data) {
      console.log(data);
      this.$refs.scrollContainer.wrap.scrollTop = data;
    },
    getElementDistance(element) {
      const container = this.$refs.container;
      const rectContainer = container.getBoundingClientRect();
      const rectElement = element.getBoundingClientRect();
      return Math.abs(rectElement.top - rectContainer.top);
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 0 auto;
  width: 292px;
  height: 596px;
  padding-top: 80px;
  background-size: contain;
}

.scroll-container {
  width: 280px;
  height: 490px;
  overflow: hidden;
}

.icon-avatar {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  // background-color: #468eff;
  background: url("../../../assets/images/avatar.png") center center no-repeat;
  background-size: contain;
}

.preview-content {
  position: relative;
  flex: 1;

  .download {
    position: absolute;
    top: 0;
    left: 0;
    min-width: calc(100% - 20px);
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.2);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
  }

  &:hover {
    .download {
      opacity: 1;
    }
  }

  .icon-play {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
    width: 40px;
    height: 40px;
    background: url(../../../assets/images/play.png) center center no-repeat;
    background-size: contain;
  }

  .preview-text {
    width: 100%;
    flex: 1;
    max-width: 200px;
    padding: 15px 0 15px 15px;
    font-size: 12px;
    border-radius: 4px;
    background-color: #fff;
  }

  .preview-view {
    max-width: 200px;
    padding: 12px;
    font-size: 12px;
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    word-break: break-word;
  }

  .preview-max {
    max-width: 180px;
  }

  .item-h5 {
    width: 180px;
    height: 100px;

    .h-title {
      position: absolute;
      width: calc(100% - 21px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .h-content {
      width: 60%;
      position: absolute;
      left: 15px;
      top: 40px;
      height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .h-img {
      width: 40px;
      height: 40px;
      right: 20px;
      top: 40px;
      position: absolute;
    }

    .h-collect {
      position: absolute;
      left: 15px;
      bottom: 10px;
    }
  }

  .icon-down {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: text-bottom;
    background: url(https://oss.isuiyou.cn/direct/zPPEXWIZRxQ3ba8e5ead8e4996ae577ab0a4d949e677.png) no-repeat center center;
    background-size: 16px 16px;
  }

  .icon-preview {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: text-bottom;
    background: url(../../../assets/images/preview.png) no-repeat center center;
    background-size: 16px 16px;
  }
}

.el-icon-warning {
  color: red;
  font-size: 20px;
  position: absolute;
  bottom: 0;
  right: -25px;
}

.icon-notice {
  width: 14px;
  height: 14px;
  display: inline-block;
  background: url(../../../assets/images/notice.png) no-repeat center center;
  background-size: contain;
}

.padding-text ::v-deep {
  width: 100%;
  flex: 1;
  max-width: 200px;
  padding: 0 15px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #fff;

  p {
    line-height: 1.2;
  }
}

.program-view {
  width: 240px;

  // height: 316px;
  .margin-right-xs {
    margin-right: 5px;
  }

  .image {
    width: 100%;
    height: 132px;
    vertical-align: top;
  }

  .icon-top-logo {
    width: 20px;
    height: 20px;
    min-width: 20px;
    border-radius: 50%;
    background: url(https://oss.isuiyou.cn/162a06ac42441132c166b031a8282528f5bb69dc89d17fb82ba5745b68d894c3.png) center center no-repeat;
    background-size: contain;
  }

  .icon-bottom-logo {
    width: 12px;
    height: 12px;
    background: url(https://oss.isuiyou.cn/570de39153493035369ea04f58ab79c1c848f95c376cab6557e8efff6b98d617.png) center center no-repeat;
    background-size: contain;
  }
}
</style>
