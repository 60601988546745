<template>
  <json-schema-popover
    :schema="schema"
    :labelWidth="80"
    width="700"
    ref="popoverRef"
  />
</template>

<script>
import { defineComponent, ref, getCurrentInstance } from 'vue'
import { JsonSchemaPopover } from '@/formily/base/index'

const defaultFieldsMap = {
  externalOpenId: {
    type: 'string',
    title: '账号',
    'x-decorator': 'FormItem',
    'x-component': 'Input',
    'x-component-props': {
      placeholder: '请输入',
      maxlength: '20',
      showWordLimit: true
    },
  },
  name: {
    type: 'string',
    title: '姓名',
    'x-decorator': 'FormItem',
    'x-component': 'Input',
    'x-component-props': {
      placeholder: '请输入',
      maxlength: '20',
      showWordLimit: true
    },
  },
  mobile: {
    type: 'string',
    title: '手机号',
    'x-decorator': 'FormItem',
    'x-component': 'Input',
    'x-component-props': {
      placeholder: '请输入',
    },
    'x-validator': 'phone',
  },
  notes: {
    type: 'string',
    title: '称呼',
    'x-decorator': 'FormItem',
    'x-component': 'Input',
    'x-component-props': {
      placeholder: '请输入',
      maxlength: '20',
      showWordLimit: true
    },
  },
  followEmployees: {
    type: 'string',
    title: '跟进员工',
    'x-decorator': 'FormItem',
    'x-component': 'Input',
    'x-component-props': {
      placeholder: '请输入',
      maxlength: '20',
      showWordLimit: true
    },
  },
}

export default defineComponent({
  components: { JsonSchemaPopover },
  props: {
    fields: {
      type: Array,
      default: ['externalOpenId', 'name', 'mobile', 'notes', 'followEmployees'],
    },
  },
  setup({ fields }) {
    const vm = getCurrentInstance()?.proxy

    const closePopover = () => {
      vm.$refs.popoverRef.closePopover()
    }

    const schema = ref({
      type: 'object',
      properties: {
        grid: {
          type: 'void',
          'x-component': 'FormGrid',
          'x-component-props': {
            maxColumns: [2],
          },
          properties: {
            ...fields.reduce((pre, key) => {
              const value = defaultFieldsMap[key]
              return {
                ...pre,
                [key]: value,
              }
            }, {}),
            actions: {
              type: 'void',
              title: ' ',
              'x-decorator': 'FormItem',
              'x-component': 'Actions',
              'x-component-props': {
                onSubmit(values) {
                  // console.log('onSubmit: ', values)
                  vm.$emit('change', values)
                  closePopover()
                },
                onReset(values) {
                  // console.log('onReset: ', values)
                  vm.$emit('change', values)
                  closePopover()
                },
              },
            },
          },
        },
      },
    })

    const reset = () => {
      vm.$refs.popoverRef.getForm().reset()
    }

    return {
      schema,
      reset
    }
  },
})
</script>
