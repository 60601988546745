var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "number-range" },
    [
      _c("el-input-number", {
        staticClass: "input",
        attrs: {
          "controls-position": "right",
          min: 1,
          max: 99999999,
          precision: 0,
        },
        on: { change: _vm.handleStartChange },
        model: {
          value: _vm.startValue,
          callback: function ($$v) {
            _vm.startValue = $$v
          },
          expression: "startValue",
        },
      }),
      _c("span", { staticClass: "text" }, [_vm._v("至")]),
      _c("el-input-number", {
        staticClass: "input",
        attrs: {
          "controls-position": "right",
          min: _vm.startValue,
          max: 99999999,
          precision: 0,
        },
        model: {
          value: _vm.endValue,
          callback: function ($$v) {
            _vm.endValue = $$v
          },
          expression: "endValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }