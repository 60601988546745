var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content conent-draggable" },
    [
      _c(
        "el-scrollbar",
        { ref: "scrollbar" },
        [
          _c(
            "el-form",
            {
              ref: "queryParams",
              staticClass: "fileList",
              style: {
                "max-height": `calc(100vh - ${_vm.isNotice ? "650" : "450"}px)`,
              },
              attrs: { model: _vm.queryParams, rules: _vm.rules },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm._l(
                _vm.queryParams.materialElements.filter((v) => v.type != 8),
                function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item flex-center margin-bottom-sm",
                      class: [{ textView: _vm.isText(item) }],
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "content-view flex",
                          staticStyle: { width: "85%" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "padding-left-xs left-view" },
                            [_vm._v(_vm._s(_vm.formatFileType(item)))]
                          ),
                          _vm.isText(item)
                            ? _c(
                                "el-form-item",
                                {
                                  ref: `formItem${index}`,
                                  refInFor: true,
                                  attrs: {
                                    prop: `materialElements.${
                                      _vm.isNotice ? index + 1 : index
                                    }.contentBack`,
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入文本内容",
                                        trigger: ["blur", "change"],
                                      },
                                    ],
                                  },
                                },
                                [
                                  _vm.showTinymce
                                    ? _c(
                                        "tinymce",
                                        {
                                          ref: `tinymce${index}`,
                                          refInFor: true,
                                          attrs: {
                                            height: 178,
                                            linmitNum: "1000",
                                            textarea: "",
                                            selectEmoji: "",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.$refs.queryParams.validate()
                                            },
                                            getContent: function ($event) {
                                              return _vm.tinymceInput(
                                                arguments,
                                                item,
                                                index
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.contentBack,
                                            callback: function ($$v) {
                                              _vm.$set(item, "contentBack", $$v)
                                            },
                                            expression: "item.contentBack",
                                          },
                                        },
                                        [
                                          _vm.showClientInfo && !_vm.isRule
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "clientInfo flex-center",
                                                  attrs: { slot: "footer" },
                                                  slot: "footer",
                                                },
                                                [
                                                  _c("client-params", {
                                                    on: {
                                                      command: function (
                                                        $event
                                                      ) {
                                                        return _vm.clientInfoCommand(
                                                          arguments,
                                                          item,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.showClientCustom
                                                    ? _c("client-custom", {
                                                        attrs: {
                                                          isSopShow:
                                                            _vm.isSopShow,
                                                        },
                                                        on: {
                                                          command: function (
                                                            $event
                                                          ) {
                                                            return _vm.clientInfoCommand(
                                                              arguments,
                                                              item,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isFile(item)
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: `materialElements.${
                                      _vm.isNotice ? index + 1 : index
                                    }.fileUrl`,
                                    rules: [
                                      {
                                        required: true,
                                        message: "请上传文件",
                                        trigger: ["blur", "change"],
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      key: _vm.uploadKey,
                                      class: [
                                        {
                                          active: item.fileUrl,
                                          "upload-demo flex-sub": true,
                                        },
                                      ],
                                      attrs: {
                                        disabled: _vm.isEditFile,
                                        "show-file-list": false,
                                        drag: "",
                                        "on-change": _vm.onChange,
                                        action: _vm.upload.action,
                                        "http-request": _vm.uploadFile,
                                        accept: item.accept,
                                        headers: _vm.upload.headers,
                                        "on-success": _vm.onSuccess,
                                        "on-error": _vm.onError,
                                        limit: 1,
                                        "before-upload": _vm.beforeUpload,
                                      },
                                    },
                                    [
                                      !item.fileUrl
                                        ? _c(
                                            "div",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editMaterialData(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              item.ext.process
                                                ? _c("el-progress", {
                                                    attrs: {
                                                      percentage:
                                                        item.ext.process,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c("i", {
                                                staticClass: "el-icon-upload",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "el-upload__text",
                                                },
                                                [
                                                  _vm._v("将文件拖到此处，或"),
                                                  _c("em", [
                                                    _vm._v("点击上传"),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "margin-bottom-sm",
                                                },
                                                [
                                                  _vm._v(
                                                    "支持上传文件为图片、视频、pdf、word、excel"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editMaterialData(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              item.ext.process
                                                ? _c("el-progress", {
                                                    attrs: {
                                                      percentage:
                                                        item.ext.process,
                                                    },
                                                  })
                                                : _vm._e(),
                                              item.type == 1
                                                ? _c("el-image", {
                                                    staticClass: "avatar",
                                                    attrs: {
                                                      fit: "scale-down",
                                                      src: item.fileUrl,
                                                    },
                                                  })
                                                : _vm._e(),
                                              item.type == 2
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "avatar solid",
                                                      staticStyle: {
                                                        position: "relative",
                                                      },
                                                    },
                                                    [
                                                      _c("video", {
                                                        staticClass: "avatar",
                                                        attrs: {
                                                          src: item.fileUrl,
                                                        },
                                                      }),
                                                      _c("div", {
                                                        staticClass:
                                                          "icon-play",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.type > 2 && item.type < 6
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "content-text radius content-office",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-center",
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "img-icon margin-right-xs",
                                                            class: [
                                                              item?.ext?.img,
                                                            ],
                                                          }),
                                                          _c("div", [
                                                            _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.fileName
                                                                )
                                                              ),
                                                            ]),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-left margin-top-xs",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.ext
                                                                      .size
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.type == 7
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: `materialElements.${
                                      _vm.isNotice ? index + 1 : index
                                    }.title`,
                                    rules: [
                                      {
                                        required: true,
                                        message: "请编辑产品笔记",
                                        trigger: ["blur", "change"],
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "content-text radius flex justify-between pointer padding-sm",
                                      staticStyle: { width: "100%" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editMaterialData(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { "max-width": "80%" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "text-cut text-bold",
                                              staticStyle: {
                                                "max-width": "500px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.title) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-cut2 margin-top-xs",
                                            },
                                            [_vm._v(_vm._s(item.description))]
                                          ),
                                        ]
                                      ),
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "92px",
                                          height: "92px",
                                        },
                                        attrs: { src: item.url },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          item.type == 10
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: `materialElements.${
                                      _vm.isNotice ? index + 1 : index
                                    }.title`,
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择小程序",
                                        trigger: ["blur", "change"],
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "solid program-view bg-white radius-8 pointer padding-xs",
                                      on: {
                                        click: function ($event) {
                                          return _vm.editMaterialData(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-center" },
                                        [
                                          !item.mpLogo
                                            ? _c("i", {
                                                staticClass:
                                                  "icon-top-logo margin-right-xs",
                                              })
                                            : _c("el-image", {
                                                staticClass:
                                                  "icon-top-logo margin-right-xs",
                                                attrs: { src: item.mpLogo },
                                              }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "text-cut",
                                              staticStyle: {
                                                "max-width": "90%",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.title))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("div", {
                                        staticClass: "text-cut2",
                                        staticStyle: {
                                          "line-height": "1.5",
                                          "margin-bottom": "5px",
                                        },
                                      }),
                                      _c("el-image", {
                                        staticClass: "image",
                                        attrs: {
                                          src: item.url,
                                          fit: "contain",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-center solid-top margin-top-xs padding-top-xs",
                                          staticStyle: { "line-height": "1" },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon-bottom-logo margin-right-xs",
                                          }),
                                          _vm._v("小程序 "),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "bottom-btns margin-lr-sm" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "上移一层",
                                "popper-class": "popper-class",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "move-class",
                                  attrs: {
                                    disabled: index === 0 || _vm.disabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.upClick(item)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-up" })]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "下移一层",
                                "popper-class": "popper-class",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "move-class",
                                  attrs: {
                                    disabled:
                                      index ===
                                        _vm.queryParams.materialElements
                                          .length -
                                          1 || _vm.disabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downClick(item)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-down" })]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "删除",
                                "popper-class": "popper-class",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "move-class",
                                  attrs: { disabled: _vm.disabled },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(item)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-delete text-red text-xl text-bold",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex dropdown align-center justify-between padding-bottom",
                  staticStyle: { width: "280px" },
                  style: {
                    "margin-left":
                      _vm.queryParams.materialElements.filter(
                        (v) => v.type != 8
                      ).length > 0
                        ? "100px"
                        : "",
                  },
                },
                [
                  _c(
                    "el-dropdown",
                    {
                      attrs: {
                        disabled:
                          (_vm.$route.query &&
                            _vm.$route.query.type == "visible") ||
                          _vm.disabled,
                        placement: "right-start",
                        trigger: "click",
                      },
                      on: { command: _vm.handleCommand },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "132px" },
                          attrs: { type: "primary" },
                        },
                        [_vm._v("添加")]
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        _vm._l(
                          _vm.materialTypeList.filter((v) =>
                            _vm.isRule ? v.type < 7 : v
                          ),
                          function (item, index) {
                            return _c(
                              "el-dropdown-item",
                              {
                                key: index,
                                staticClass: "dropdown-item",
                                attrs: { command: item.type },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-center padding-left-sm",
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isNewAdd && !_vm.isRule
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { width: "132px" },
                          attrs: { disabled: _vm.disabled },
                          on: { click: _vm.openMaterialDialog },
                        },
                        [_vm._v("引用素材库")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "产品笔记",
                top: "9vh",
                "custom-class": "tinymce-dialog",
                visible: _vm.dialogVisible,
                width: "760px",
                "before-close": _vm.handleClose,
                "close-on-click-modal": false,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("tinymce", {
                ref: "tinymce",
                attrs: {
                  placeholder: "请输入产品笔记内容…",
                  height: 580,
                  id: _vm.id,
                },
                model: {
                  value: _vm.contentBack,
                  callback: function ($$v) {
                    _vm.contentBack = $$v
                  },
                  expression: "contentBack",
                },
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "70px" },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "70px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.configHClick },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("choose-mini-program", {
        ref: "chooseMiniProgram",
        on: { setMiniProgram: _vm.setMiniProgram },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }