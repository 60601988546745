var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "mini-dialog",
      attrs: {
        title: "选择小程序",
        "append-to-body": "",
        "close-on-click-modal": false,
        visible: _vm.dialogVisible,
        width: "920px",
      },
      on: {
        close: _vm.resetForm,
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "chooseContent" }, [
        _c(
          "div",
          { staticClass: "top-step margin-top-sm" },
          [
            _c(
              "el-steps",
              {
                staticClass: "padding-tb-sm sop-step",
                attrs: { active: _vm.activeTab },
              },
              _vm._l(_vm.stepList, function (item, index) {
                return _c("el-step", { key: index }, [
                  _c(
                    "div",
                    {
                      staticClass: "step-item",
                      attrs: { slot: "icon" },
                      slot: "icon",
                    },
                    [
                      _c("div", { staticClass: "icon-circle" }, [
                        _vm._v(_vm._s(index + 1)),
                      ]),
                      _c(
                        "span",
                        { staticClass: "text-black text-cut2 padding-left-xs" },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  ),
                ])
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTab == 1,
                expression: "activeTab == 1",
              },
            ],
          },
          [
            _c(
              "div",
              { staticClass: "margin-top-sm search-view" },
              [
                _c(
                  "el-input",
                  {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入要搜索的小程序标题/AppID",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.getList.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.keyword,
                      callback: function ($$v) {
                        _vm.keyword = $$v
                      },
                      expression: "keyword",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-input__icon el-icon-search",
                      staticStyle: { cursor: "pointer" },
                      attrs: { slot: "suffix" },
                      on: { click: _vm.getList },
                      slot: "suffix",
                    }),
                  ]
                ),
                _c("div", { staticClass: "margin-top-xs" }, [
                  _vm._v("我的小程序 "),
                ]),
              ],
              1
            ),
            _c(
              "el-scrollbar",
              {
                directives: [
                  {
                    name: "loadmore",
                    rawName: "v-loadmore",
                    value: _vm.getMaterialMpPage,
                    expression: "getMaterialMpPage",
                  },
                ],
                staticClass: "margin-top-xs",
              },
              [
                _c(
                  "div",
                  { staticClass: "miniProgramList" },
                  _vm._l(_vm.tableData, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "miniProgramItem pointer solid radius-8",
                        on: {
                          click: function ($event) {
                            return _vm.setCurrentMiniProgram(item)
                          },
                        },
                      },
                      [
                        _c("el-image", {
                          staticClass: "image",
                          attrs: {
                            src: item.mpLogo ? item.mpLogo : _vm.defaultLogo,
                          },
                        }),
                        _c("div", { staticClass: "flex-sub" }, [
                          _c("div", { staticClass: "text-cut2" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _c("div", {
                            staticClass: "text-xs text-gray text-cut2",
                          }),
                        ]),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTab == 2,
                expression: "activeTab == 2",
              },
            ],
            staticClass: "margin-top",
            staticStyle: { "padding-bottom": "14px" },
          },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "130px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "小程序标题", prop: "title" } },
                  [
                    _c("el-input", {
                      staticClass: "inputvalue uni-input",
                      attrs: {
                        placeholder: "请输入小程序标题",
                        maxlength: "30",
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.ruleForm.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "title", $$v)
                        },
                        expression: "ruleForm.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "flex-label", attrs: { prop: "mpUsername" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-center",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _vm._v(" 账号原始ID "),
                        _c(
                          "question-tooltip",
                          {
                            staticClass: "margin-left-xs",
                            attrs: {
                              popperClass: "tooltip-view-1 popper-editMaterial",
                              effect: "light",
                              placement: "bottom-start",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "tooltip-image",
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex margin-top justify-between margin-bottom-sm padding-lr text-group",
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "1、请前往微信小程序右上角，点击「小程序名称」"
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        "2、进入「详情页」，点击「更多资料」"
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        "3、复制「账号原始ID」后，粘贴到此处"
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("el-image", {
                                  staticStyle: {
                                    width: "810px",
                                    height: "250px",
                                    "margin-left": "-1px",
                                  },
                                  attrs: {
                                    fit: "contain",
                                    src: "https://oss.isuiyou.cn/6bf2b96508c9c49397f9e4ff1ec201941507c28f6c6b67588e1d17c227c3ea24.png",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("el-input", {
                      attrs: {
                        disabled: !_vm.isAdd,
                        placeholder: "请输入账号原始ID",
                        maxlength: "30",
                        oninput:
                          "value=value.replace(/[\\u4e00-\\u9fa5]/g, '')",
                      },
                      model: {
                        value: _vm.ruleForm.mpUsername,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "mpUsername", $$v)
                        },
                        expression: "ruleForm.mpUsername",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "flex-label", attrs: { prop: "appId" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-center",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _vm._v(" 小程序AppID "),
                        _c(
                          "question-tooltip",
                          {
                            staticClass: "margin-left-xs",
                            attrs: { effect: "light", placement: "bottom" },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _vm._v(" APPID获取方式请参见："),
                                _c("br"),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-edit",
                                    staticStyle: {
                                      "text-decoration": "underline",
                                    },
                                    attrs: {
                                      href: "http://www.mpgcw.com/9765.html",
                                      target: "_blank",
                                      rel: "noopener noreferrer",
                                    },
                                  },
                                  [_vm._v("http://www.mpgcw.com/9765.html")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("el-input", {
                      attrs: {
                        disabled: !_vm.isAdd,
                        placeholder: "请输入小程序AppID",
                        onkeyup: "value=value.replace(/[^\\w]/ig,'')",
                        maxlength: "30",
                      },
                      model: {
                        value: _vm.ruleForm.appId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "appId", $$v)
                        },
                        expression: "ruleForm.appId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "flex-label", attrs: { prop: "path" } },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "label" }, slot: "label" },
                      [
                        _vm._v(" 小程序路径 "),
                        _c(
                          "question-tooltip",
                          {
                            staticClass: "margin-left-xs",
                            attrs: {
                              popperClass: "tooltip-view popper-editMaterial",
                              effect: "light",
                              placement: "bottom",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "tooltip-image",
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "308px",
                                    height: "355px",
                                  },
                                  attrs: {
                                    fit: "cover",
                                    src: "https://oss.isuiyou.cn/433d85e0c2635ce43e8b4f7aba45dd611dcd4e713905731cb96e7145a771441c.png",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("el-input", {
                      attrs: {
                        disabled: !_vm.isAdd,
                        maxlength: "50",
                        placeholder:
                          "微信打开小程序，右上角复制链接后粘贴到此处",
                      },
                      model: {
                        value: _vm.ruleForm.path,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "path", $$v)
                        },
                        expression: "ruleForm.path",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "小程序封面", prop: "url" } },
                  [
                    _c(
                      "el-upload",
                      {
                        staticStyle: { width: "192px" },
                        attrs: {
                          "list-type": "picture-card",
                          accept: ".png,.jpg",
                          action: _vm.uploadFileUrl,
                          "show-file-list": false,
                          "file-list": _vm.fileList,
                          headers: _vm.headers,
                          "on-success": _vm.handleUploadSuccess,
                          limit: 1,
                          "before-upload": _vm.beforeUpload,
                          "on-error": _vm.onError,
                        },
                      },
                      [
                        _vm.ruleForm.url
                          ? _c("el-image", {
                              staticClass: "avatar",
                              attrs: {
                                fit: "scale-down",
                                src: _vm.ruleForm.url,
                              },
                              on: {
                                mouseover: function ($event) {
                                  return _vm.reupload(true)
                                },
                                mouseleave: function ($event) {
                                  return _vm.reupload(false)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.ruleForm.url && _vm.showReupload
                          ? _c("div", { staticClass: "reupload" }, [
                              _vm._v("重新上传"),
                            ])
                          : _vm._e(),
                        !_vm.ruleForm.url
                          ? _c(
                              "div",
                              {
                                staticClass: "margin-top-lg",
                                staticStyle: { "line-height": "2" },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-plus text-38",
                                }),
                                _c("div", [_vm._v("上传图片")]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "text-gray margin-top-xs" }, [
                      _vm._v("建议图片尺寸为5:4，大小不超过5M，暂不支持动图"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "flex-center justify-between" }, [
          _c(
            "div",
            [
              _vm.activeTab == 2
                ? _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.prev } },
                    [_vm._v("上一步")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm.activeTab == 2
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.saveFieldOption },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }