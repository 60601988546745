import _ from "lodash";
export default {
    bind(el, binding) {
        const scrollbar = el.querySelector(".el-scrollbar__wrap");
        console.log(scrollbar, 'scrollbar');
        scrollbar && scrollbar.addEventListener("scroll", _.debounce(function (e) {
            const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
            if (condition) {
                binding.value(binding.arg);
            }
        }, 500), {
            passive: true
        });
        return;
    }
}