
<template>
  <div class="el-loading-mask" style="background-color: rgba(0, 0, 0, 0.5);">
    <div class="update-modal">
      <div class="update-top">
        <i class="el-icon-close text-lg text-bold text-blue padding-tb-xs padding-right-sm" @click="handleAfterLeave"></i>
        <div class="bg-update"></div>
      </div>
      <div class="padding-lr-lg padding-tb">
        <div class="title">
          小主系统更新啦！
        </div>
        <div class="content">
          保存好当前正在编辑的内容，刷新页面即可使用最新系统。
        </div>
        <div class="actions">
          <!-- <button @click="handleAfterLeave">忽略</button> -->
          <button @click="refresh">知道了</button>
        </div>
      </div>
    </div>
  </div>
</template>
   
<script>
export default {
  methods: {
    handleAfterLeave() {
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },
    refresh() {
      this.handleAfterLeave();
      // window.location.replace(window.location.href); //没有刷新缓存
      location.reload(true);  // 刷新了缓存
    },
  },
};
</script>
   
<style scoped>
.update-modal {
  user-select: none;
  position: fixed;
  right: 50%;
  bottom: 50%;
  max-width: 555px;
  min-width: 550px;
  margin-right: -255px;
  margin-bottom: -200px;
  height: 426px;
  width: 50%;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  /* padding: 10px 15px; */
  animation: shakeY 1.5s linear;
  z-index: 99999;
}

@keyframes shakeY {

  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(0, -10px, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(0, 10px, 0);
  }
}

.update-top {
  background-color: #E6F1FF;

}

.bg-update {
  width: 555px;
  height: 193.5px;
  background: url(https://oss.isuiyou.cn/ed8c2fd87b4bd5f9117f8aeb58d0725e46277b23bbf2c22b748992db848ba459.png) center center no-repeat;
  background-size: contain;
}

.el-icon-close {
  position: absolute;
  right: 0px;
}

.shakeY {
  animation-name: shakeY;
}

.update-modal .title {
  color: #000;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  font-size: 22px;
  margin-bottom: 10px;
}

.update-modal .content {
  /* text-indent: 2em; */
  font-size: 16px;
}

.update-modal .actions {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
}

.update-modal .actions button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
}

.update-modal .actions button:last-child {
  background-color: #409eff;
  color: #fff;
  border-color: #409eff;
}
</style>