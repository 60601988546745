<template>
  <div class="selected-list">
    <div class="selected-list__header">
      <span>已选{{ selectedList.length }}项</span>
      <el-button type="text" @click="$emit('on-clear')">清空</el-button>
    </div>
    <div class="selected-list__items">
      <div
        class="selected-list__item"
        v-for="item in selectedList"
        :key="item?.[realId]"
      >
        <span>{{ item?.[realLabel] }}</span>
        <i
          class="el-icon-circle-close"
          style="color: #ccc"
          @click="$emit('on-delete', item)"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  name: 'SelectedList',
  props: {
    selectedList: {
      type: Array,
      default: () => [],
    },
    realLabel: {
      type: String,
      default: 'label',
    },
    realId: {
      type: String,
      default: 'id',
    },
  },
  setup() {
    const data = reactive({})

    return {
      ...toRefs(data),
    }
  },
})
</script>

<style lang="scss" scoped>
.selected-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 6px 0px 24px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    background: #fff;
    color: #9a9a9a;
    flex: none;
  }
  &__items {
    padding-right: 6px;
    flex: 1;
    overflow: auto;
  }
  &__item {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dfe6ec;
    i {
      cursor: pointer;
    }
  }
}
</style>
