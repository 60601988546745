/**
 * Created by 芋道源码
 *
 * 枚举类
 */
import { beginOfDay, endOfDay } from '@/utils/dateUtils'

export const datePickerOptions = {
  shortcuts: [
    {
      text: '最近一周',
      onClick(picker) {
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        const end = new Date()
        picker.$emit('pick', [beginOfDay(start), endOfDay(end)])
      },
    },
    {
      text: '最近一个月',
      onClick(picker) {
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        const end = new Date()
        picker.$emit('pick', [beginOfDay(start), endOfDay(end)])
      },
    },
    {
      text: '最近三个月',
      onClick(picker) {
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        const end = new Date()
        picker.$emit('pick', [beginOfDay(start), endOfDay(end)])
      },
    },
  ],
}

// ========== 静态变量 ==========

/**
 * 全局通用状态枚举
 */
export const CommonStatusEnum = {
  ENABLE: 0, // 开启
  DISABLE: 1, // 禁用
}

/**
 * 菜单的类型枚举
 */
export const SystemMenuTypeEnum = {
  DIR: 1, // 目录
  MENU: 2, // 菜单
  BUTTON: 3, // 按钮
}

/**
 * 角色的类型枚举
 */
export const SystemRoleTypeEnum = {
  SYSTEM: 1, // 内置角色
  CUSTOM: 2, // 自定义角色
}

/**
 * 数据权限的范围枚举
 */
export const SystemDataScopeEnum = {
  ALL: 1, // 全部数据权限
  DEPT_CUSTOM: 2, // 指定部门数据权限
  DEPT_ONLY: 3, // 部门数据权限
  DEPT_AND_CHILD: 4, // 部门及以下数据权限
  DEPT_SELF: 5, // 仅本人数据权限
}

/**
 * 代码生成模板类型
 */
export const InfraCodegenTemplateTypeEnum = {
  CRUD: 1, // 基础 CRUD
  TREE: 2, // 树形 CRUD
  SUB: 3, // 主子表 CRUD
}

/**
 * 任务状态的枚举
 */
export const InfraJobStatusEnum = {
  INIT: 0, // 初始化中
  NORMAL: 1, // 运行中
  STOP: 2, // 暂停运行
}

/**
 * API 异常数据的处理状态
 */
export const InfraApiErrorLogProcessStatusEnum = {
  INIT: 0, // 未处理
  DONE: 1, // 已处理
  IGNORE: 2, // 已忽略
}

/**
 * 用户的社交平台的类型枚举
 */
export const SystemUserSocialTypeEnum = {
  DINGTALK: {
    title: '钉钉',
    type: 20,
    source: 'dingtalk',
    img: 'https://s1.ax1x.com/2022/05/22/OzMDRs.png',
  },
  WECHAT_ENTERPRISE: {
    title: '企业微信',
    type: 30,
    source: 'wechat_enterprise',
    img: 'https://s1.ax1x.com/2022/05/22/OzMrzn.png',
  },
}

/**
 * 支付渠道枚举
 */
export const PayChannelEnum = {
  WX_PUB: {
    code: 'wx_pub',
    name: '微信 JSAPI 支付',
  },
  WX_LITE: {
    code: 'wx_lite',
    name: '微信小程序支付',
  },
  WX_APP: {
    code: 'wx_app',
    name: '微信 APP 支付',
  },
  WX_NATIVE: {
    code: 'wx_native',
    name: '微信扫码支付',
  },
  WX_BAR: {
    code: 'wx_bar',
    name: '微信条码支付',
  },
  ALIPAY_PC: {
    code: 'alipay_pc',
    name: '支付宝 PC 网站支付',
  },
  ALIPAY_WAP: {
    code: 'alipay_wap',
    name: '支付宝 WAP 网站支付',
  },
  ALIPAY_APP: {
    code: 'alipay_app',
    name: '支付宝 APP 支付',
  },
  ALIPAY_QR: {
    code: 'alipay_qr',
    name: '支付宝扫码支付',
  },
  ALIPAY_BAR: {
    code: 'alipay_bar',
    name: '支付宝条码支付',
  },
}

/**
 * 支付的展示模式每局
 */
export const PayDisplayModeEnum = {
  URL: {
    mode: 'url',
  },
  IFRAME: {
    mode: 'iframe',
  },
  FORM: {
    mode: 'form',
  },
  QR_CODE: {
    mode: 'qr_code',
  },
  APP: {
    mode: 'app',
  },
}

/**
 * 支付订单状态枚举
 */
export const PayOrderStatusEnum = {
  WAITING: {
    status: 0,
    name: '未支付',
  },
  SUCCESS: {
    status: 10,
    name: '已支付',
  },
  CLOSED: {
    status: 20,
    name: '支付关闭',
  },
}

/**
 * 支付退款订单状态枚举
 */
export const PayRefundStatusEnum = {
  CREATE: {
    status: 0,
    name: '退款订单生成',
  },
  SUCCESS: {
    status: 1,
    name: '退款成功',
  },
  FAILURE: {
    status: 2,
    name: '退款失败',
  },
  PROCESSING_NOTIFY: {
    status: 3,
    name: '退款中，渠道通知结果',
  },
  PROCESSING_QUERY: {
    status: 4,
    name: '退款中，系统查询结果',
  },
  UNKNOWN_RETRY: {
    status: 5,
    name: '状态未知，请重试',
  },
  UNKNOWN_QUERY: {
    status: 6,
    name: '状态未知，系统查询结果',
  },
  CLOSE: {
    status: 99,
    name: '退款关闭',
  },
}

/**
 * 商品 SPU 状态
 */
export const ProductSpuStatusEnum = {
  RECYCLE: {
    status: -1,
    name: '回收站',
  },
  DISABLE: {
    status: 0,
    name: '下架',
  },
  ENABLE: {
    status: 1,
    name: '上架',
  },
}

/**
 * 优惠类型枚举
 */
export const PromotionDiscountTypeEnum = {
  PRICE: {
    type: 1,
    name: '满减',
  },
  PERCENT: {
    type: 2,
    name: '折扣',
  },
}

/**
 * 优惠劵模板的有限期类型的枚举
 */
export const CouponTemplateValidityTypeEnum = {
  DATE: {
    type: 1,
    name: '固定日期可用',
  },
  TERM: {
    type: 2,
    name: '领取之后可用',
  },
}

/**
 * 营销的商品范围枚举
 */
export const PromotionProductScopeEnum = {
  ALL: {
    scope: 1,
    name: '全部商品参与',
  },
  SPU: {
    scope: 2,
    name: '指定商品参与',
  },
}

/**
 * 营销的条件类型枚举
 */
export const PromotionConditionTypeEnum = {
  PRICE: {
    type: 10,
    name: '满 N 元',
  },
  COUNT: {
    type: 20,
    name: '满 N 件',
  },
}

/**
 * 促销活动的状态枚举
 */
export const PromotionActivityStatusEnum = {
  WAIT: {
    type: 10,
    name: '未开始',
  },
  RUN: {
    type: 20,
    name: '进行中',
  },
  END: {
    type: 30,
    name: '已结束',
  },
  CLOSE: {
    type: 40,
    name: '已关闭',
  },
}

/**
 * 群发
 * clientGroupPost 客户群发 clientGroupsPost 客户群群发 noticeGroupPost 群公告群发
 */

export const groupsPost = {
  clientGroupPost: { sendType: 1, clientType: '' },
  clientGroupsPost: { sendType: 2, clientType: '群' },
  noticeGroupPost: { sendType: 3, clientType: '公告' },
}

export const triggerConditeDataList = [
  {
    triggerName: '前',
    triggerType: 1,
    notes: '指的是某个日期前的一段时间',
  },
  {
    triggerName: '后',
    triggerType: 2,
    notes: '指的是某个日期后的一段时间',
  },
  {
    triggerName: '是',
    triggerType: 3,
    notes: '指的是某个时间点',
  },
  {
    triggerName: '不是',
    triggerType: 4,
    notes: '指的不是某个时间点',
  },
  {
    triggerName: '介于',
    triggerType: 5,
    notes: '指的是两个时间点之间的一段时间',
  },
  {
    triggerName: '当天',
    triggerType: 6,
    notes: '指的是这个时间点',
  },
  {
    triggerName: '非当天',
    triggerType: 7,
    notes: '指的不是某个时间点',
  },
  {
    triggerName: '为空',
    triggerType: 8,
    notes: '数据为空时，也可以作为触发条件',
  },

  {
    triggerName: '不为空',
    triggerType: 9,
    notes: '数据为空时，也可以作为触发条件',
  },
  {
    triggerName: '超过',
    triggerType: 10,
    notes: '指超过某个时间点的一段时间',
  },
  {
    triggerName: '不超过',
    triggerType: 11,
    notes: '指不超过某个时间点的一段时间',
  },
]

export const triggerConditeNumberList = [
  {
    triggerName: '=',
    triggerType: 1,
    notes: '某个具体数值',
  },
  {
    triggerName: '≠',
    triggerType: 2,
    notes: '除某个具体数值之外',
  },
  {
    triggerName: '>',
    triggerType: 3,
    notes: '大于且不包含某个具体数值',
  },
  {
    triggerName: '<',
    triggerType: 4,
    notes: '小于且不包含某个具体数值',
  },
  {
    triggerName: '≥',
    triggerType: 5,
    notes: '大于且包含某个具体数值',
  },
  {
    triggerName: '≤',
    triggerType: 6,
    notes: '小于且包含某个具体数值',
  },
  {
    triggerName: '介于',
    triggerType: 7,
    notes: '发生或处于两个具体时间或数字之间',
  },
  {
    triggerName: '为空',
    triggerType: 8,
    notes: '数据为空时，也可作为触发条件',
  },
]

export const triggerDate = [
  {
    triggerName: '天',
    triggerType: 1,
  },
  {
    triggerName: '周',
    triggerType: 2,
  },
  {
    triggerName: '月',
    triggerType: 3,
  },
]

// 自定义触发条件

export const selectConditionList = {
  100: '客户基本信息',
  101: '客户标签',
  102: '客户回复',
  103: '客户不回复',
  200: '客户群基本信息',
  201: '客户群标签',
  202: '客户群回复',
  203: '客户群不回复',
}

export const textValue = 1
export const textareaValue = 2
export const dateValue = 3
export const datetimeValue = 4
export const numberValue = 5
export const radioValue = 6
export const checkboxValue = 7
// 自定义参数 动态参数
export const DynamicParamTypeEnum = [
  {
    type: textValue,
    name: '文本',
  },
  {
    type: textareaValue,
    name: '多行文本',
  },
  {
    type: dateValue,
    name: '日期',
  },
  {
    type: datetimeValue,
    name: '日期时间',
  },
  {
    type: numberValue,
    name: '数字',
  },
  {
    type: radioValue,
    name: '单选框',
  },
  {
    type: checkboxValue,
    name: '复选框',
  },
  {
    type: 8,
    name: '附件',
  },
]

export const containValue = 1
export const betweenValue = 2
export const nullValue = 3
export const getContainOption = () => ({
  label: '包含',
  value: containValue,
})
export const getBelongtoOption = () => ({
  label: '属于',
  value: containValue,
})
export const getBetweenOption = () => ({
  label: '介于',
  value: betweenValue,
})
export const getNullOption = () => ({
  label: '为空',
  value: nullValue,
})
export const getConditionMap = () => ({
  [textValue]: [getContainOption()],
  [textareaValue]: [getContainOption()],
  [radioValue]: [getBelongtoOption()],
  [checkboxValue]: [getContainOption()],
  [dateValue]: [getBetweenOption()],
  [datetimeValue]: [getBetweenOption()],
  [numberValue]: [getBetweenOption()],
})
// 该函数按照设计图1:1逻辑还原
export const getComponent = (type, condition) => {
  if (condition === nullValue) return 'Null'
  if (type === textValue && condition === containValue) return 'Input'
  if (type === textareaValue && condition === containValue) return 'Input'
  if (type === dateValue && condition === betweenValue) return 'DatePicker'
  if (type === datetimeValue && condition === betweenValue) return 'DatePicker'
  if (type === numberValue && condition === betweenValue) return 'NumberRange'
  if (type === radioValue && condition === containValue) return 'Select'
  if (type === checkboxValue && condition === containValue) return 'Select'
  return 'Null'
}

// 客户列表表头
export const TableHeader = [
  {
    label: '头像',
    checked: true,
    prop: 'headImg',
    width: 140,
    id: 5,
  },
  {
    label: '社交账号',
    checked: true,
    prop: 'externalOpenId',
    width: 140,
    id: 6,
  },
  {
    label: '昵称',
    checked: true,
    prop: 'nickname',
    width: 140,
    id: 8,
  },
  {
    label: '社交账号',
    checked: true,
    prop: 'socialAccount',
    width: 140,
    id: 12,
  },

  {
    label: '手机号',
    prop: 'mobile',
    checked: true,
    width: 140,
    id: 2,
  },
  {
    label: '年龄',
    checked: true,
    prop: 'age',
    width: 140,
    id: 3,
  },
  {
    label: '出生日期',
    prop: 'birthday',
    checked: true,
    width: 140,
    id: 4,
  },
  {
    label: '姓名',
    prop: 'name',
    checked: true,
    width: 140,
    id: 1,
  },
  {
    label: '性别',
    checked: true,
    prop: 'sex',
    width: 140,
    id: 7,
  },
  {
    label: '地区',
    checked: true,
    prop: 'area',
    width: 140,
    id: 9,
  },
  {
    label: '客户标签',
    checked: true,
    prop: 'labelNames',
    width: 280,
    id: 10,
  },
  {
    label: '跟进员工',
    checked: true,
    prop: 'followEmployees',
    width: 240,
    id: 11,
  },

  {
    label: '称呼',
    checked: true,
    prop: 'notes',
    width: 140,
    id: 13,
  },
]

// 101单文本 102多文本 103日期 104日期范围 105链接 106附件 107图片 108视频
// 301单选框 302多选框 303下拉选择
// 401数字 402开关 501是静态类型 502动态类型
// 控件类型
export const Input_Type = 101
export const Textarea_Type = 102
export const Date_Type = 103
export const Daterange_Type = 104
export const Number_Type = 401
export const Select_Type = 303

export const Checkbox_Type = 302
export const File_Type = 106
export const Image_Type = 107
export const Link_Type = 105
export const Radio_Type = 301
export const Switch_Type = 402
export const Video_Type = 108
export const Email_Type = 2
