var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "task-label margin-bottom-sm" },
    [
      _c(
        "el-collapse",
        {
          staticClass: "task-collapse",
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: 1 } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex-center title padding-left-lg justify-between",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("div", { staticClass: "text-black" }, [
                    _c("i", { staticClass: "icon-info" }),
                    _vm._v(" 信息收集 "),
                  ]),
                  _c("span", { staticClass: "text-blue margin-right-5" }, [
                    _vm._v(
                      _vm._s(_vm.activeNames.length > 0 ? "收起" : "展开")
                    ),
                  ]),
                ]
              ),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "ruleForm",
                  attrs: {
                    size: "small",
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    disabled: _vm.visible || _vm.visibleStatus,
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "设置收集规则",
                        prop: "selectCriteriaList",
                        rules: [
                          {
                            required: true,
                            message: "请设置收集规则",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-alert", {
                        attrs: {
                          title:
                            "AI员工将根据信息字段和问法样例，主动与用户对话并收集对应信息",
                          closable: false,
                          type: "warning",
                          "show-icon": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(
                    _vm.ruleForm.selectCriteriaList.filter((v) => v.id),
                    function (item, index) {
                      return _c(
                        "el-form-item",
                        { key: item.id, staticClass: "solid" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex-center solid-bottom padding-xs text-sm justify-between",
                            },
                            [
                              _c("div", { staticClass: "flex-center" }, [
                                _c("span", { staticClass: "text-gray" }, [
                                  _vm._v("需收集字段："),
                                ]),
                                _vm._v(
                                  _vm._s(item.name || item.paramName) + " "
                                ),
                                _c(
                                  "div",
                                  { staticClass: "margin-left-sm" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        model: {
                                          value: item.repeatedCollection,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "repeatedCollection",
                                              $$v
                                            )
                                          },
                                          expression: "item.repeatedCollection",
                                        },
                                      },
                                      [_vm._v("是否重复收集")]
                                    ),
                                    _c("question-tooltip", [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [_vm._v("指客户信息已存在也要再次收集")]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "right-btn" },
                                [
                                  _c("el-button", {
                                    staticClass: "text-black",
                                    attrs: {
                                      type: "text",
                                      disabled: index === 0,
                                      icon: "el-icon-up",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.upClick(item)
                                      },
                                    },
                                  }),
                                  _c("el-button", {
                                    staticClass: "text-black",
                                    attrs: {
                                      type: "text",
                                      disabled:
                                        index ===
                                        _vm.ruleForm.selectCriteriaList.length -
                                          1,
                                      icon: "el-icon-down",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downClick(item)
                                      },
                                    },
                                  }),
                                  _c("el-button", {
                                    staticClass: "text-black text-xl",
                                    attrs: {
                                      type: "text",
                                      icon: "el-icon-close",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCriteria(index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "padding-lr-xs question-item" },
                            [
                              _vm._l(item.questionList, function (v, i) {
                                return _c(
                                  "el-form-item",
                                  {
                                    key: `${item.id}${i}`,
                                    staticClass: "margin-top-sm",
                                    staticStyle: { "margin-bottom": "5px" },
                                    attrs: {
                                      label: `问题${i + 1}:`,
                                      prop: `selectCriteriaList.${index}.questionList.${i}.data`,
                                      rules: [
                                        {
                                          required: true,
                                          message: "请输入",
                                          trigger: "blur",
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "inputValue",
                                      attrs: {
                                        size: "small",
                                        autosize: { minRows: 1, maxRows: 2 },
                                        type: "textarea",
                                        resize: "none",
                                        maxlength: "50",
                                        "show-word-limit": "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: v.data,
                                        callback: function ($$v) {
                                          _vm.$set(v, "data", $$v)
                                        },
                                        expression: "v.data",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "right-icon-error" },
                                      [
                                        item.questionList.length > 1
                                          ? _c("el-button", {
                                              staticClass: "text-gray",
                                              attrs: {
                                                type: "text",
                                                icon: "el-icon-error",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteQuestionItem(
                                                    item,
                                                    i
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-bottom": "5px" },
                                  attrs: {
                                    disabled: item.questionList.length >= 3,
                                    icon: "el-icon-circle-plus",
                                    type: "text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addQuestionItem(item)
                                    },
                                  },
                                },
                                [_vm._v("添加问法样例")]
                              ),
                            ],
                            2
                          ),
                        ]
                      )
                    }
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "margin-top-xs" },
                    [
                      _c("selection-criteria", {
                        attrs: {
                          selectCriteriaList: _vm.selectCriteriaList,
                          taskInfo: true,
                          stageType: _vm.$route.query.sendType == 1 ? 100 : 200,
                        },
                        on: { selectCriteria: _vm.selectCriteria },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }