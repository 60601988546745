var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "footer" },
    [
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.$emit("on-cancel")
            },
          },
        },
        [_vm._v(" 取消 ")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.$emit("on-sure")
            },
          },
        },
        [_vm._v(" 确定 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }