// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/labelPath.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".task-label-collapse[data-v-5ee0a82a] .label-content {\n  display: inline-block;\n  min-width: 600px;\n  background: #F9F9F9;\n  margin-left: 30px;\n}\n.task-label-collapse[data-v-5ee0a82a] .el-collapse-item__wrap,\n.task-label-collapse[data-v-5ee0a82a] .el-collapse,\n.task-label-collapse[data-v-5ee0a82a] .el-collapse-item__header {\n  border-bottom: none;\n  border-top: none;\n}\n.task-label-collapse[data-v-5ee0a82a] .el-icon-label-path {\n  width: 14px;\n  height: 14px;\n  margin-right: 8px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n  background-size: contain;\n}\n.task-label-collapse[data-v-5ee0a82a] .el-collapse-item__header {\n  padding: 0 24px 0 40px;\n  height: 40px;\n  opacity: 1;\n  line-height: 40px;\n  background: #ECF5FC;\n  font-size: 14px;\n  font-weight: 500;\n  color: #333333;\n  position: relative;\n}\n.task-label-collapse[data-v-5ee0a82a] .expand {\n  position: absolute;\n  font-size: 14px;\n  font-weight: normal;\n  color: #307EF2;\n  right: 50px;\n}\n.task-label-collapse[data-v-5ee0a82a] .el-icon-arrow-right {\n  color: #307EF2;\n}\n.task-label-collapse[data-v-5ee0a82a] .label-info {\n  padding: 24px 0 32px 40px;\n  font-size: 14px;\n  font-weight: normal;\n  color: #333333;\n}\n.task-label-collapse[data-v-5ee0a82a] .el-input--small .el-input__inner {\n  width: 208px;\n  height: 32px !important;\n  line-height: 32px !important;\n}\n.task-label-collapse[data-v-5ee0a82a] .select-type .el-select-dropdown__list {\n  padding: 12px !important;\n}", ""]);
// Exports
module.exports = exports;
