<template>
  <div class="importCustom padding-left-xs">
    <el-link @click="openDialog" :underline="false" icon="el-icon-circle-plus">引用自定义参数</el-link>
    <custom-dialog ref="customDialog" :paramsType="1" :dataModule="1" :isSop="isSopShow" @command="command" :stageType="100" title="引用自定义参数"></custom-dialog>
  </div>
</template>

<script>
import customDialog from './customDialog.vue'
export default {
  components: { customDialog },
  data() {
    return {}
  },
  props: ["isSopShow"],
  methods: {
    // 点击引用自定义参数
    openDialog() {
      this.$refs.customDialog.open();
    },
    // 选择自定义参数回调
    command(data){
      this.$emit("command", data)
    }
  }
}
</script>

<style lang="scss" scoped>
.importCustom ::v-deep{
  position: relative;
  &::before{
    width: 1px;
    height: 16px;
    background: #BCBCBC;
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
  }
  .el-link{
    color: #778CA2;
  }
}
</style>
