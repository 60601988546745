import { Image as ElImage } from "element-ui";

// 给 ElImage组件 提供个默认error插槽内容,除此之外保留 ElImage组件 所有功能
const Image = {
  name: "CsImage",

  functional: true,

  props: {
    errorImg: {
      type: String,
      default: require("@/assets/images/error.png")
    }
  },

  render (h, context) {
    const { errorImg } = context.props
    
    return h(ElImage, {
      ...context.data,
      scopedSlots: {
        error() {
          return h('img', { attrs: { src: errorImg }, style: { width: '100%', height: '100%' } })
        },
        ...context.scopedSlots
      }

    }, context.children)
  }
}

export default Image