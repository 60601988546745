<template>
  <el-popover
    placement="bottom"
    popper-class="emoji-popover"
    v-model="popover"
    slot="suffix"
    title="表情"
    trigger="manual"
  >
    <picker
      :showSearch="false"
      :showPreview="false"
      :i18n="I18N"
      :showCategories="false"
      :backgroundImageFn="backgroundImageFn"
      @select="addEmoji(arguments)"
    />
    <div
      slot="reference"
      class="flex-center justify-center margin-top-xs icon-view pointer"
      @click="handleClick"
    >
      <slot name="reference">
        <i class="icon icon-emoji text-xl"></i>
      </slot>
    </div>
  </el-popover>
</template>

<script>
// import png from "@/assets/images/64.png";
import { Picker } from "emoji-mart-vue";
const I18N = {
  search: "搜索",
  notfound: "No Emoji Found",
  categories: {
    search: "搜索结果",
    recent: "经常使用",
    smileys: "表情与情感",
    people: "人物与身体",
    nature: "动物与自然",
    foods: "食物与饮料",
    activity: "活动",
    places: "旅行与地理",
    objects: "物品",
    symbols: "符号标志",
    flags: "旗帜",
    custom: "自定义",
  },
};
export default {
  components: { Picker },
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      I18N,
      popover: false,
    };
  },
  methods: {
    addEmoji(arg) {
      const value = arg[0].native;
      this.popover = false;
      this.$emit("addEmoji", value);
    },
    // 替换远程图片
    backgroundImageFn() {
      return "https://oss.isuiyou.cn/scrm/dcb97302-1e56-40db-8118-59f7ea15678f/64.png";
    },
    handleClick() {
      this.popover = !this.popover;
    },
  },
  created() {
    // this.popover = this.value;
  },
  watch: {
    popover: {
      handler: function (params) {
        this.$emit("update:value", params);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-view {
  display: inline-flex;
  width: 46px;
  height: 32px;
  border: 0.5px solid #c0ccda;
  border-radius: 2px;
}

.icon {
  display: inline-block;
  width: 18px;
  height: 18px;
}

.icon-emoji {
  background: url(../../assets/images/emoji.png) center center no-repeat;
  background-size: contain;
}
</style>