<template>
  <div class="switch" @click="handleToggle">
    <div class="switch__text">
      <span :style="{ opacity: active ? 1 : 0 }">{{ inactiveText }}</span>
      <span :style="{ opacity: active ? 0 : 1 }">{{ activeText }}</span>
    </div>
    <div class="switch__slider" ref="sliderRef">
      {{ active ? activeText : inactiveText }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CsSwitch",
  props: {
    activeText: {
      type: String,
      default: "聚合聊天",
    },
    inactiveText: {
      type: String,
      default: "SCRM",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.active = value;
      },
      immediate: true,
    },
    active: {
      handler(value) {
        this.updateLeft(value);
      },
      immediate: true,
    },
  },
  methods: {
    handleToggle() {
      const curValue = (this.active = !this.active);
      this.$emit("input", curValue);
      this.$emit("change", curValue);
    },
    updateLeft(active) {
      this.$nextTick(() => {
        const { sliderRef } = this.$refs;
        sliderRef.style.left = active ? sliderRef.offsetWidth + "px" : "6px";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.switch {
  color: #000;
  height: 40px;
  border-radius: 50px;
  background: #f2f2f2;
  position: relative;
  font-size: 14px;
  color: #666666;
  display: inline-block;
  padding: 4px 12px;
  cursor: pointer;
  user-select: none;
  &__text {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      transition: all 0.2s ease-in-out;
      padding: 6px 18px;
    }
  }
  &__slider {
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    border-radius: 16px;
    padding: 6px 18px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(163, 167, 184, 0.2);
    font-weight: 600;
    color: #333333;
  }
}
</style>
