import request from '@/utils/request'
// import { createAxios } from '@/utils/request'
// const request = createAxios({
//   baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
// })
// const robotRequest = createAxios({
//   baseURL: '/admin-api/',
// })
// 获取会话历史记录
export function queryConversationHistory(data) {
  return request({
    url: 'im/chatHistory/queryOldHistory',
    method: 'post',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    data,
  })
}

// 发送消息
export function sendMessage(data) {
  return request({
    url: 'im/send/message',
    method: 'post',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    data,
  })
}

// 获取社交账号
export function getAllAccountData(params) {
  return request({
    url: 'im/staff/page',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    method: 'get',
    params,
  })
}

// 获取聊天联系人
// export function getContactInfo(query) {
//   return request({
//     url: 'im/conversation/page',
//     method: 'get',
//     // baseURL: "/im-api/",
//     params: query,
//   })
// }
export function getContactInfo(data) {
  return request({
    url: 'im/conversation/page',
    method: 'get',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    ...data,
  })
}

// 获取聊天对话信息
export function getWChatDialog(query) {
  return request({
    url: 'im/chatHistory/queryConversationHistory',
    method: 'get',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    params: query,
  })
}

export function topContact(data) {
  return request({
    url: 'im/conversation/topping',
    method: 'post',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    data: data,
  })
}

export function getContactInfos(query) {
  return request({
    url: 'im/conversation/search',
    method: 'get',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    params: query,
  })
}

export function getGroupInfos(query) {
  return request({
    url: 'im/conversation/searchGroup',
    method: 'get',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    params: query,
  })
}

export function getChatBarInfo(query) {
  return request({
    url: 'im/chatHistory/queryStaffParticipateTimeOrCustodyStatus',
    method: 'get',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    params: query,
  })
}

export function trusteeship(data) {
  // robotRequest
  return request({
    url: 'wechat/entrust/customerAndGroup',
    method: 'post',
    data: data,
  })
}

export function cancelTrusteeship(data) {
  // robotRequest
  return request({
    url: 'wechat/entrust/cancel',
    method: 'post',
    data: data,
  })
}

export function msgAlreadyRead(query) {
  return request({
    url: 'im/chatHistory/conversationHistorySetRead',
    method: 'get',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    params: query,
  })
}

export function coversationChoose(data) {
  return request({
    url: 'im/conversation/choosing',
    method: 'post',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    data: data,
  })
}

export function updateHeadImg(data) {
  return request({
    url: 'im/refresh/headImage',
    method: 'post',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    data: data,
  })
}

// tab页红点标记查询
export function queryMark(query) {
  return request({
    url: 'im/conversation/queryMark',
    method: 'get',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    params: query,
  })
}

// 退出人工介入(自动回复)
export function autoReply(data) {
  return request({
    url: 'im/chatHistory/autoReply',
    method: 'post',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    data: data,
  })
}

// 获取最新消息
export function getConversationInfo(query) {
  return request({
    url: 'im/conversation/get',
    method: 'post',
    baseURL: process.env.VUE_APP_BASE_API + '/im-api/',
    data: query,
  })
}
