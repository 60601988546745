<template>
    <div style="display: inline-block;margin-left: 5px;">
        <el-tooltip class="item" :popper-class="popperClass" :effect="effect" :placement="placement">
            <div slot="content" style="line-height: 1.5;">
                <slot name="content"></slot>
            </div>
            <i class="icon-question"></i>
        </el-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        popperClass: {
            type: String,
            default: 'popper-editMaterial'
        },
        placement: {
            type: String,
            default: 'top'
        },
        effect: {
            type: String,
            default: 'dark'
        }
    },
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped></style>