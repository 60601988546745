var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "el-loading-mask",
      staticStyle: { "background-color": "rgba(0, 0, 0, 0.5)" },
    },
    [
      _c("div", { staticClass: "update-modal" }, [
        _c("div", { staticClass: "update-top" }, [
          _c("i", {
            staticClass:
              "el-icon-close text-lg text-bold text-blue padding-tb-xs padding-right-sm",
            on: { click: _vm.handleAfterLeave },
          }),
          _c("div", { staticClass: "bg-update" }),
        ]),
        _c("div", { staticClass: "padding-lr-lg padding-tb" }, [
          _c("div", { staticClass: "title" }, [_vm._v(" 小主系统更新啦！ ")]),
          _c("div", { staticClass: "content" }, [
            _vm._v(" 保存好当前正在编辑的内容，刷新页面即可使用最新系统。 "),
          ]),
          _c("div", { staticClass: "actions" }, [
            _c("button", { on: { click: _vm.refresh } }, [_vm._v("知道了")]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }