var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "padding-top-xs notice-view margin-right" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom",
            width: "322",
            "popper-class": "padding-0 notice-popover",
            trigger: "click",
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                slot: "reference",
                effect: "dark",
                content: "消息中心",
                placement: "left",
              },
              slot: "reference",
            },
            [
              _c(
                "el-badge",
                {
                  attrs: {
                    value: _vm.unreadCount,
                    hidden: _vm.unreadCount == 0,
                    max: 99,
                    type: "danger",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-message-solid pointer text-xl",
                    on: { click: _vm.getNoticeList },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { "element-loading-text": "加载中,请稍候" },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.list.length > 0,
                      expression: "list.length > 0",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "padding-sm" }, [
                    _c("div", { staticClass: "text-df text-bold" }, [
                      _vm._v("系统通知"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "text-sm flex-center margin-top-xs" },
                      [
                        _c(
                          "el-link",
                          { attrs: { underline: false, type: "primary" } },
                          [_vm._v("转人工通知")]
                        ),
                        _c("el-badge", {
                          staticClass: "circle margin-left-xs",
                          attrs: {
                            slot: "reference",
                            type: "primary",
                            value: _vm.unreadCount,
                            hidden: _vm.unreadCount == 0,
                            max: 99,
                          },
                          slot: "reference",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loadmore",
                          rawName: "v-loadmore",
                          value: _vm.getList,
                          expression: "getList",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        {
                          ref: "scrollbar",
                          staticClass: "noticeList el-scrollbar__wrap",
                        },
                        _vm._l(_vm.list, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "padding-lr-sm item pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.goMyList(item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex-center text-black" },
                                [
                                  _c("wechat-img", {
                                    staticStyle: { "margin-right": "5px" },
                                    attrs: {
                                      type: item.templateParams.accountType,
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(item.templateParams.nickname) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-cut2 text-black margin-tb-5",
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.templateParams.sendName) + " "
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#718096" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.templateParams.content)
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticStyle: { color: "#A0AEC0" } }, [
                                _vm._v(_vm._s(item.templateParams.sendTime)),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              ),
              _c("el-empty", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.list.length == 0,
                    expression: "list.length == 0",
                  },
                ],
                staticClass: "padding-tb-xxl",
                attrs: {
                  image: require("@/assets/images/empty.png"),
                  description: "暂无通知",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-view text-black flex-center" },
            [
              _c(
                "el-button",
                {
                  class: [
                    _vm.unreadCount > 0
                      ? "item"
                      : "text-gray flex-sub solids-right",
                  ],
                  attrs: { type: "text", disabled: _vm.unreadCount == 0 },
                  on: {
                    click: function ($event) {
                      return _vm.handleUpdateAll()
                    },
                  },
                },
                [_vm._v("全部已读")]
              ),
              _c(
                "div",
                {
                  staticClass: "item text-blue pointer text-center",
                  on: { click: _vm.goMyList },
                },
                [_vm._v("查看所有通知")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }