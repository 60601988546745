<template>
  <div class="number-range">
    <!-- <el-input class="input" v-model="startValue"></el-input> -->
    <el-input-number
      class="input"
      v-model="startValue"
      controls-position="right"
      :min="1"
      :max="99999999"
      :precision="0"
      @change="handleStartChange"
    ></el-input-number>
    <span class="text">至</span>
    <el-input-number
      class="input"
      v-model="endValue"
      controls-position="right"
      :min="startValue"
      :max="99999999"
      :precision="0"
    ></el-input-number>
    <!-- <el-input class="input" v-model="endValue"></el-input> -->
  </div>
</template>

<script>
import { defineComponent, computed, nextTick } from 'vue'

export default defineComponent({
  name: 'NumberRange',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const startValue = computed({
      get() {
        return this.value?.[0]
      },
      set(val) {
        // const value = [val, props.value?.[1]]
        const value = [val, endValue.value]
        emit('input', value)
        emit('change', value)
      },
    })

    const endValue = computed({
      get() {
        return props.value?.[1]
      },
      set(val) {
        // const value = [props.value?.[0], val]
        const value = [startValue.value, val]
        emit('input', value)
        emit('change', value)
      },
    })

    const handleStartChange = (val) => {
      nextTick(() => {
        if (val >= endValue.value) {
          endValue.value = val
        }
      })
    }

    return {
      startValue,
      endValue,
      handleStartChange
    }
  },
})
</script>

<style scoped lang="scss">
.number-range {
  display: flex;
  align-items: center;
  .input {
    flex: 1;
  }
  .text {
    flex: none;
    color: #b3b3b3;
    font-size: 14px;
    margin: 0 8px;
    line-height: 32px;
  }
}
</style>
