import { render, staticRenderFns } from "./materialFile.vue?vue&type=template&id=6b29c5cd&scoped=true"
import script from "./materialFile.vue?vue&type=script&lang=js"
export * from "./materialFile.vue?vue&type=script&lang=js"
import style0 from "./materialFile.vue?vue&type=style&index=0&id=6b29c5cd&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b29c5cd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/AI前端应用/DEV-SCRM开发/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b29c5cd')) {
      api.createRecord('6b29c5cd', component.options)
    } else {
      api.reload('6b29c5cd', component.options)
    }
    module.hot.accept("./materialFile.vue?vue&type=template&id=6b29c5cd&scoped=true", function () {
      api.rerender('6b29c5cd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/robot/components/materialFile.vue"
export default component.exports