var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "task-label margin-bottom-sm" },
    [
      _c(
        "el-collapse",
        {
          staticClass: "task-label-collapse",
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: 1 } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "header-icon el-icon-label-path" }),
                _vm._v(" 打标签 "),
                _c("span", { staticClass: "expand" }, [
                  _vm._v(_vm._s(_vm.activeNames.length > 0 ? "收起" : "展开")),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "label-info" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      staticStyle: { "padding-left": "0" },
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        disabled: _vm.visible || _vm.visibleStatus,
                        "label-width": "270px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: `${
                              _vm.$route.query.sendType == 2 ? "客户群" : "用户"
                            }进入当前阶段时，可以打上标签`,
                            prop: "labelIdList",
                          },
                        },
                        [
                          _c(
                            "select-tag",
                            {
                              ref: "selectTag",
                              attrs: {
                                type:
                                  _vm.$route.query.sendType == 1 ||
                                  !_vm.stageDrawer
                                    ? 1
                                    : 2,
                                mode: 2,
                                selectedTags: _vm.ruleForm.labelIdList,
                              },
                              on: {
                                "update:selectedTags": function ($event) {
                                  return _vm.$set(
                                    _vm.ruleForm,
                                    "labelIdList",
                                    $event
                                  )
                                },
                                "update:selected-tags": function ($event) {
                                  return _vm.$set(
                                    _vm.ruleForm,
                                    "labelIdList",
                                    $event
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "addTag",
                                    type: "text",
                                    icon: "el-icon-circle-plus",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTag("selectTag")
                                    },
                                  },
                                  slot: "addTag",
                                },
                                [_vm._v("添加")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.labelIdList?.length > 0
                        ? _c(
                            "div",
                            { staticClass: "padding-sm label-content" },
                            [
                              _c("el-scrollbar", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-center flex-wrap",
                                    staticStyle: {
                                      "max-height": "470px",
                                      gap: "12px",
                                      "min-height": "40px",
                                    },
                                  },
                                  _vm._l(
                                    _vm.ruleForm.labelIdList,
                                    function (item, index) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: index,
                                          staticClass: "tag-items",
                                          attrs: {
                                            size: "small",
                                            closable: "",
                                          },
                                          on: {
                                            close: function ($event) {
                                              return _vm.closeTag(
                                                _vm.ruleForm.labelIdList,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.name) + " ")]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-divider"),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: `${
                              _vm.$route.query.sendType == 2 ? "客户群" : "用户"
                            }进入当前阶段时，可以解绑标签`,
                            prop: "fallOffLabelIdList",
                          },
                        },
                        [
                          _c(
                            "select-tag",
                            {
                              ref: "selectFallTag",
                              attrs: {
                                type:
                                  _vm.$route.query.sendType == 1 ||
                                  !_vm.stageDrawer
                                    ? 1
                                    : 2,
                                mode: 2,
                                selectedTags: _vm.ruleForm.fallOffLabelIdList,
                              },
                              on: {
                                "update:selectedTags": function ($event) {
                                  return _vm.$set(
                                    _vm.ruleForm,
                                    "fallOffLabelIdList",
                                    $event
                                  )
                                },
                                "update:selected-tags": function ($event) {
                                  return _vm.$set(
                                    _vm.ruleForm,
                                    "fallOffLabelIdList",
                                    $event
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "addTag",
                                    type: "text",
                                    icon: "el-icon-circle-plus",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTag("selectFallTag")
                                    },
                                  },
                                  slot: "addTag",
                                },
                                [_vm._v("添加")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.ruleForm.fallOffLabelIdList?.length > 0
                        ? _c(
                            "div",
                            { staticClass: "padding-sm label-content" },
                            [
                              _c("el-scrollbar", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-center flex-wrap",
                                    staticStyle: {
                                      "max-height": "470px",
                                      gap: "12px",
                                      "min-height": "40px",
                                    },
                                  },
                                  _vm._l(
                                    _vm.ruleForm.fallOffLabelIdList,
                                    function (item, index) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: index,
                                          staticClass: "tag-items",
                                          attrs: {
                                            size: "small",
                                            closable: "",
                                          },
                                          on: {
                                            close: function ($event) {
                                              return _vm.closeTag(
                                                _vm.ruleForm.fallOffLabelIdList,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.name) + " ")]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }