<template>
  <div class="footer">
    <el-button @click="$emit('on-cancel')"> 取消 </el-button>
    <el-button type="primary" @click="$emit('on-sure')"> 确定 </el-button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {},
})
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
</style>
