<template>
  <el-button type="text" @click="$emit('click')">
    <i class="advanced-filter-dialog__filter"></i>
    高级筛选
  </el-button>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {},
})
</script>

<style scoped lang="scss">
.advanced-filter-dialog__filter {
  width: 12px;
  height: 12px;
  display: inline-block;
  background: url(../../../assets/images/filter.png) center center no-repeat;
  background-size: contain;
}
</style>
