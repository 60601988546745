<template>
    <div class="task-label margin-bottom-sm">
        <el-collapse v-model="activeNames" class="task-collapse">
            <el-collapse-item :name="1">
                <div class="flex-center title padding-left-lg justify-between" slot="title">
                    <div class="text-black">
                        <i class="icon-info"></i> 信息收集
                    </div>
                    <span class="text-blue margin-right-5">{{ activeNames.length > 0 ? '收起' : '展开' }}</span>
                </div>
                <el-form size="small" :model="ruleForm" :rules="rules" :disabled="visible || visibleStatus"
                    ref="ruleForm" label-position="left" class="ruleForm">
                    <el-form-item label="设置收集规则" prop="selectCriteriaList"
                        :rules="[{ required: true, message: '请设置收集规则', trigger: 'blur' }]">
                        <el-alert title="AI员工将根据信息字段和问法样例，主动与用户对话并收集对应信息" :closable="false" type="warning" show-icon>
                        </el-alert>
                    </el-form-item>
                    <el-form-item class="solid" v-for="(item, index) in ruleForm.selectCriteriaList.filter(v => v.id)"
                        :key="item.id">
                        <div class="flex-center solid-bottom padding-xs text-sm justify-between">
                            <div class="flex-center">
                                <span class="text-gray">需收集字段：</span>{{ item.name || item.paramName }}
                                <div class="margin-left-sm">
                                    <el-checkbox v-model="item.repeatedCollection">是否重复收集</el-checkbox>
                                    <question-tooltip>
                                        <div slot="content">指客户信息已存在也要再次收集</div>
                                    </question-tooltip>
                                </div>
                            </div>
                            <div class="right-btn">
                                <el-button type="text" @click="upClick(item)" :disabled="index === 0" class="text-black"
                                    icon="el-icon-up">
                                </el-button>
                                <el-button type="text" @click="downClick(item)"
                                    :disabled="index === (ruleForm.selectCriteriaList.length - 1)" class="text-black"
                                    icon="el-icon-down">
                                </el-button>
                                <el-button type="text" @click="deleteCriteria(index)" class="text-black text-xl"
                                    icon="el-icon-close">
                                </el-button>
                            </div>
                        </div>
                        <div class="padding-lr-xs question-item">
                            <el-form-item :label="`问题${i + 1}:`" style="margin-bottom: 5px;" class="margin-top-sm"
                                v-for="(v, i) in item.questionList" :key="`${item.id}${i}`"
                                :prop="`selectCriteriaList.${index}.questionList.${i}.data`"
                                :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                                <el-input size="small" class="inputValue" :autosize="{ minRows: 1, maxRows: 2 }"
                                    type="textarea" resize="none" maxlength="50" show-word-limit v-model="v.data"
                                    placeholder="请输入">
                                </el-input>
                                <div class="right-icon-error">
                                    <el-button type="text" @click="deleteQuestionItem(item, i)"
                                        v-if="item.questionList.length > 1" class="text-gray" icon="el-icon-error">
                                    </el-button>
                                </div>
                            </el-form-item>
                            <el-button @click="addQuestionItem(item)" :disabled="item.questionList.length >= 3"
                                icon="el-icon-circle-plus" style="margin-bottom: 5px;" type="text">添加问法样例</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item class="margin-top-xs">
                        <selection-criteria :selectCriteriaList="selectCriteriaList" @selectCriteria="selectCriteria"
                            :taskInfo="true" :stageType="$route.query.sendType == 1 ? 100 : 200">
                        </selection-criteria>
                    </el-form-item>
                </el-form>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import SelectDialog from "@/views/components/selectDialog/selectDialog.vue";
import { getlabelManagerAll } from "@/api/label/label";
import SelectionCriteria from './selectionCriteria.vue';
import QuestionTooltip from '../../../components/questionTooltip/questionTooltip.vue';
export default {
    props: {
        stageDrawer: { type: Boolean }
    },
    components: {
        SelectDialog,
        SelectionCriteria,
        QuestionTooltip,
    },
    data() {
        return {
            activeNames: [1],
            name: '',
            muLimit: 1,
            querForm: {
                pageNo: 1,
                pageSize: 10,
                type: 1,
                name: ''
            },
            optionList: [],
            ruleForm: {
                sendType: 1,
                actionType: 5,
                labelIdList: [],
                selectCriteriaList: [],
                sopMessageCollects: []
            },
            rules: {
                labelIdList: [{ required: true, message: "请选择标签", trigger: 'blur' }]
            },
            visible: this.$route.query?.visible == 3,
            visibleStatus: this.$route.query?.visibleStatus == 2,
            selectCriteriaList: []
        }
    },
    mounted() {
        this.getlabel();
    },
    computed: {
        isGroup() {
            return this.$route.query.sopType == 'clientSOP' ? false : true;
        }
    },
    methods: {
        getlabel() {
            const formData = { mode: '2', type: this.$route.query.sendType == 2 && this.stageDrawer ? 2 : 1 };
            getlabelManagerAll(formData).then((res) => {
                this.optionList = res.data;
            });
        },
        // 回显taskLabels数据
        setTaskInfoForm(data) {
            if (!data) return;
            data.sopMessageCollects.map(v => {
                v.questionList = v.questionContent.map(m => { return { data: m } })
                v.id = v.staticParamId ? v.staticParamId : v.dynamicParamId;
            })
            data.selectCriteriaList = data.sopMessageCollects;
            this.ruleForm = data;
            this.selectCriteriaList = data.selectCriteriaList.map(v => v.id);
        },
        //选择规则条件
        selectCriteria(data) {
            const ids = data.map(v => {
                if (this.ruleForm.selectCriteriaList.findIndex(m => m.id == v.id) == -1) {
                    this.ruleForm.selectCriteriaList = this.ruleForm.selectCriteriaList.concat([{ ...v, questionList: [{ data: v.questionContent }] }])
                    this.selectCriteriaList = this.ruleForm.selectCriteriaList.map(v => v.id);
                    this.$refs.ruleForm.clearValidate();
                }
                return v.id;
            })
            this.ruleForm.selectCriteriaList.map((v, i) => {
                if (!ids.includes(v.id)) {
                    this.ruleForm.selectCriteriaList.splice(i, 1)
                }
            })
            if (data.length == 0) {
                this.ruleForm.selectCriteriaList = [];
                this.selectCriteriaList = [];
            }
            this.selectCriteriaList = ids;
        },
        // 添加问法样例
        addQuestionItem(item, index) {
            this.ruleForm.selectCriteriaList.map(v => {
                if (v.id == item.id) {
                    v.questionList.push({ data: "" });
                }
            })
        },
        //删除规则条件
        deleteCriteria(index) {
            this.$confirm('确定要删除该字段规则吗?', '提示!', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.ruleForm.selectCriteriaList.splice(index, 1);
                this.selectCriteriaList.splice(index, 1);
                if (this.ruleForm.selectCriteriaList.length == 0) {
                    this.selectCriteriaList = [];
                }

            }).catch(() => {

            });

        },
        //删除问法样例
        deleteQuestionItem(item, index) {
            item.questionList.splice(index, 1);
        },
        //上移
        upClick(item) {
            const index = this.ruleForm.selectCriteriaList.findIndex(v => v.id == item.id);
            if (index != -1) {
                this.$set(this.ruleForm.selectCriteriaList, index, this.ruleForm.selectCriteriaList.splice(index - 1, 1, this.ruleForm.selectCriteriaList[index])[0])
            }

        },
        //下移
        downClick(item) {
            const index = this.ruleForm.selectCriteriaList.findIndex(v => v.id == item.id);
            if (index != -1) {
                this.$set(this.ruleForm.selectCriteriaList, index, this.ruleForm.selectCriteriaList.splice(index + 1, 1, this.ruleForm.selectCriteriaList[index])[0])
            }
        },
    },
    watch: {
        ruleForm: {
            handler: function (val) {
                const sopMessageCollects = this.ruleForm.selectCriteriaList.map(v => {
                    const questionContent = v.questionList.map(v => v.data)
                    return { dynamicParam: v.dynamicParam, staticParamId: v.staticParamId, dynamicParamId: v.dynamicParamId, questionContent, paramName: v.paramName, repeatedCollection: v.repeatedCollection }
                })
                this.$emit('taskInfo', { sendType: 1, actionType: 5, sopMessageCollects });
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" scoped>
.task-collapse ::v-deep {
    .el-collapse-item__header {
        padding-right: 24px;
        background: #EFEFFA;
        height: 40px;
    }

    .el-collapse-item__header {
        .el-collapse-item__arrow {
            color: #307EF2;
        }
    }

    .title {
        width: 100%;
    }

    .margin-right-5 {
        color: #307EF2;
        font-size: 14px;
        margin-right: 5px;
    }

    .icon-info {
        width: 16px;
        height: 16px;
        background: url(../../../assets/images/icon-info.png) center center no-repeat;
        background-size: contain;
    }

    .el-icon-up {
        width: 18px;
        height: 16px;
        margin: 1px;
        background: url(../../../assets/images/up.png) center center no-repeat;
        background-size: contain;
    }

    .el-icon-down {
        width: 18px;
        height: 16px;
        margin: 1px;
        background: url(../../../assets/images/down.png) center center no-repeat;
        background-size: contain;
    }

    .ruleForm {
        padding: 20px 20px 20px 40px;
    }

    .el-alert {
        margin-top: 15px;
        border: 1px solid #F9F9F9;
        padding: 0 16px;
        background: #F9F9F9;

        .el-alert__title {
            font-size: 14px;
            font-weight: normal;
            color: #999999;
        }

        .el-alert__icon {
            color: #B7C1D7;
        }
    }

    .inputValue {
        flex: 1;

        .el-input__count {
            top: 2px;
            right: -50px;
        }
    }

    .right-icon-error {
        width: 150px;
        text-align: right;
    }

    .right-btn {
        .text-xl {
            font-size: 22px !important;
        }
    }

    .question-item {
        .el-form-item__content {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
</style>