var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "switch", on: { click: _vm.handleToggle } }, [
    _c("div", { staticClass: "switch__text" }, [
      _c("span", { style: { opacity: _vm.active ? 1 : 0 } }, [
        _vm._v(_vm._s(_vm.inactiveText)),
      ]),
      _c("span", { style: { opacity: _vm.active ? 0 : 1 } }, [
        _vm._v(_vm._s(_vm.activeText)),
      ]),
    ]),
    _c("div", { ref: "sliderRef", staticClass: "switch__slider" }, [
      _vm._v(
        " " + _vm._s(_vm.active ? _vm.activeText : _vm.inactiveText) + " "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }