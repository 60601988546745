<template>
  <div class="content">
    <!-- 查看按钮的弹窗 -->
    <el-dialog append-to-body title="查看" :visible.sync="dialogSee" width="40%" close-on-click-modal>
      <el-scrollbar ref="scrollContainer" :vertical="false" class="scroll-container padding-top-xs">
        <div class="record flex justify-between padding-right-sm" v-for="(item, index) in lists" :key="index"
          :class="[index !== 0 ? 'margin-top-sm' : '']">
          <div class="flex flex-sub">
            <div v-if="type" class="tipNumber" :class="[item.tips ? 'tipNumberOn' : 'tipNumberUn']"
              @click="tipValue(item, index)">
              {{ item.tips }}
            </div>
            <div class="photo">
              <el-avatar fit="fit" shape="square" :src="item.avatar ? item.avatar : imgPerson"></el-avatar>
            </div>
            <div class="contaliner">
              <div class="createTime margin-bottom-xs">
                <span class="nickname">{{ collectWxName }}</span>
                <span>{{ parseTime(item.createTime) }}</span>
              </div>
              <div class="container1 flex flex-direction">
                <div class="nameNick">{{ item.nickname }}</div>
                <div class="desc" v-if="item.type === 0">
                  {{ item.content }}
                </div>
                <div class="descImg" v-if="item.type === 1">
                  <el-image :src="item.content" fit="fit"></el-image>
                </div>
                <div class="descImg" v-if="item.type === 2">
                  <video class="vodeoCss" controls :src="item.content"></video>
                </div>
                <div class="descImgWord" v-if="item.type === 3 || item.type === 4 || item.type === 5">
                  <div class="desc flex align-center">
                    <div style="width: 26.95px; height: 32px" :class="getFileClass(item.content)"></div>
                    <span>{{ item.contentName }}</span>
                  </div>
                  <el-button @click="downWorld(item)" style="padding: 5px 10px" type="primary" plain>
                    <i class="icon-down"></i> <span class="text-sm">下载</span>
                  </el-button>
                </div>
                <div v-if="item.type === 10" class="solid program-view radius-8 pointer padding-xs"
                  @click="editMaterialData(item)">
                  <div class="flex-center">
                    <i v-if="!item.content.mpLogo" class="icon-top-logo margin-right-xs"></i>
                    <el-image v-else :src="item.content.mpLogo" class="icon-top-logo margin-right-xs"></el-image>
                    <div class="text-cut">{{ item.content.title }}</div>
                  </div>
                  <div class="text-cut2 margin-tb-xs" style="line-height: 1.5">
                  </div>
                  <el-image :src="item.content.url" fit="fill" class="image"></el-image>
                  <div class="flex-center solid-top margin-top-xs padding-top-xs" style="line-height: 1">
                    <i class="icon-bottom-logo margin-right-xs"></i>小程序
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="srollBottom" v-if="isShow">暂无更多数据</div>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer" v-if="type">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" v-no-more-click @click="pitchOnData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { lookMaterialAuto } from "@/api/material/material";
export default {
  name: "record",
  props: {},
  data() {
    return {
      imgPerson:
        "https://oss.isuiyou.cn/direct/wRP9RjsK4yMp72d15bcfd86a31c2a9b263516fef3a73.png",
      isShow: false,
      dialogSee: false,
      lists: [],
      total: 0,
      dataPage: [],
      params: {},
      type: "",
      id: "",
      collectWxName: "",
    };
  },
  mounted() { },
  methods: {
    getFileClass(content) {
      return "img_" + content.split(".").pop();
    },
    downWorld({ content, contentName }) {
      this.$util.downFile({
        url: content,
        name: contentName,
      });
    },
    close() {
      this.dialogSee = false;
    },
    resetData() {
      this.total = 0;
      this.params = {
        pageNo: 1,
        pageSize: 10,
        id: "",
      };
      this.lists = [];
      this.dataPage = [];
    },
    async open(row, type) {
      this.resetData();
      this.type = type;
      this.params.id = row.id;
      this.collectWxName = row.collectWxName;
      this.listData();
      this.scrollBottom();
    },

    async listData() {
      const res = await lookMaterialAuto(this.params);
      this.dialogSee = true;
      if (res.data.list.length > 0) {
        res.data.list.map((v, i) => {
          v.id = Math.random().toString(12);
          if (v.type != 0) {
            v.value = v.content;
          }
          if (v.type == 10) {
            v.content = JSON.parse(v.content);
          }
        });
        this.lists = this.lists.concat(res.data.list);
        this.total = res.data.total;
        if (this.lists.length === this.total) {
          this.isShow = true;
        }
      } else {
        this.isShow = true;
      }
    },
    scrollBottom() {
      setTimeout(() => {
        let scrollbarEl = this.$refs.scrollContainer;
        scrollbarEl.wrap.onscroll = (e) => {
          let elScrollTop = e.target.scrollTop + e.target.clientHeight;
          let elClientHeight = e.target.children[0].clientHeight;
          if (
            elScrollTop === elClientHeight ||
            elClientHeight - elScrollTop < 10
          ) {
            if (this.lists.length < this.total) {
              this.params.pageNo++;
              this.listData();
            }
          }
        };
      }, 1000);
    },
    tipValue(item, index) {
      this.lists.forEach((element) => {
        if (item.id === element.id) {
          if (item.tips === undefined || !item.tips) {
            this.$set(this.lists, index, {
              ...this.lists[index],
              tips: this.pitchOn(),
            });
          } else {
            this.pitchUn(item.tips);
            this.$set(this.lists, index, {
              ...this.lists[index],
              tips: "",
            });
          }
        }
      });
    },
    pitchOn() {
      let tipsArray = this.lists.map((item) => item.tips);
      tipsArray = tipsArray.filter((item) => item !== undefined);
      if (tipsArray.length === 0) {
        return 1;
      } else {
        let maxval = Math.max(...tipsArray);
        return maxval + 1;
      }
    },
    pitchUn(tipVal) {
      this.lists.forEach((item, index) => {
        if (item.tips && tipVal < item.tips) {
          let num = item.tips - 1;
          this.$set(this.lists, index, {
            ...this.lists[index],
            tips: num,
          });
        }
      });
    },
    pitchOnData() {
      this.dataPage = this.lists.filter((item) => {
        return item.tips !== "" && item.tips;
      });
      this.dataPage.sort((a, b) => a.tips - b.tips);

      if (this.dataPage.length > 0) {
        this.$emit("getData", this.dataPage);
      }
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.content::v-deep {
  .el-dialog__footer {
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.1);
  }
}

.container1 {
  flex: 1;
  min-width: 300px;
}

.contaliner {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 20px;

  .nickname {
    color: #333333;
  }
}

// 查看按钮
// 头像
.photo {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  opacity: 1;
  background: rgba(28, 83, 217, 0.5);
}

.nameNick {
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  letter-spacing: 0.054000000000000006em;
  color: #333333;
}

.desc {
  max-width: 300px;
  padding: 16px;
  border-radius: 8px;
  background: #efefef;
}

.descImg {
  display: flex;
  align-items: center;
  max-width: 200px;
  border-radius: 8px;
  margin: 0px 0 16px 0;

  .vodeoCss {
    border-radius: 8px;
    max-width: 166px;
    height: auto;
  }
}

.descImgWord {
  display: flex;
  align-items: center;
  align-items: flex-end;
  max-width: 300px;
  border-radius: 8px;
  margin: 0px 0 16px 0;
  gap: 10px;

  .icon-down {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: text-bottom;
    background: url(https://oss.isuiyou.cn/direct/zPPEXWIZRxQ3ba8e5ead8e4996ae577ab0a4d949e677.png) no-repeat center center;
    background-size: 16px 16px;
  }

  .desc {
    background: #f6f6f6;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.18);
    gap: 0 10px;
  }
}

.createTime {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: normal;
  text-align: right;
  color: #999999;
}

.scroll-container ::v-deep {
  height: 550px;
}

.tipNumber {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  color: #fff;
  margin: 1% 26px 0 0;
  cursor: pointer;
  user-select: none;
}

.tipNumberOn {
  background: #1c53d9;
}

.tipNumberUn {
  border: 1px solid #999999;
}

.srollBottom {
  margin-top: 50px;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.054000000000000006em;
  color: #999999;
}

.icons {
  cursor: pointer;
  font-size: 20px;
  color: #1c53d9;
}

.img-icon {
  width: 27px;
  height: 32px;
}

.img_word,
.img_docx {
  background: url(https://oss.isuiyou.cn/direct/ewiqBQB1ujV5da6bc2e35c266443ec952ed67257e273.png) center center no-repeat;
  background-size: contain;
}

.img_excel,
.img_xlsx {
  background: url(https://oss.isuiyou.cn/direct/m7JOVyjs88Ica2bd27ccdb0bbf183d329a6cb8ea2efe.png) center center no-repeat;
  background-size: contain;
}

.img_pdf {
  background: url(https://oss.isuiyou.cn/direct/6rdlBVZFC3Jz313c06128fc6462538c00fca6fe8a1a8.png) center center no-repeat;
  background-size: contain;
}

.program-view {
  width: 240px;
  // height: 316px;

  .image {
    width: 100%;
    height: 132px;
    vertical-align: top;
  }

  .icon-top-logo {
    border-radius: 50%;
    width: 20px;
    min-width: 20px;
    height: 20px;
    background: url(https://oss.isuiyou.cn/162a06ac42441132c166b031a8282528f5bb69dc89d17fb82ba5745b68d894c3.png) center center no-repeat;
    background-size: contain;
  }

  .icon-bottom-logo {
    width: 12px;
    height: 12px;
    background: url(https://oss.isuiyou.cn/570de39153493035369ea04f58ab79c1c848f95c376cab6557e8efff6b98d617.png) center center no-repeat;
    background-size: contain;
  }
}
</style>
