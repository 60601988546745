<template>
    <div class="task-label margin-bottom-sm">
        <el-collapse v-model="activeNames" class="task-label-collapse">
            <el-collapse-item :name="1">
                <template slot="title">
                    <i class="header-icon el-icon-label-path"></i>
                    打标签
                    <span class="expand">{{ activeNames.length > 0 ? '收起' : '展开' }}</span>
                </template>
                <div class="label-info">
                    <el-form style="padding-left: 0;" :model="ruleForm" :rules="rules"
                        :disabled="visible || visibleStatus" ref="ruleForm" label-width="270px" class="demo-ruleForm">
                        <el-form-item :label="`${$route.query.sendType == 2 ? '客户群' : '用户'}进入当前阶段时，可以打上标签`"
                            prop="labelIdList">
                            <select-tag :type="$route.query.sendType == 1 || !stageDrawer ? 1 : 2" ref="selectTag"
                                :mode="2" :selectedTags.sync="ruleForm.labelIdList">
                                <el-button @click="addTag('selectTag')" slot="addTag" type="text"
                                    icon="el-icon-circle-plus">添加</el-button>
                            </select-tag>
                        </el-form-item>
                        <div v-if="ruleForm.labelIdList?.length > 0" class="padding-sm label-content">
                            <el-scrollbar>
                                <div class="flex-center flex-wrap"
                                    style="max-height: 470px;gap: 12px;min-height: 40px;">
                                    <el-tag class="tag-items" size="small" closable
                                        @close="closeTag(ruleForm.labelIdList, index)"
                                        v-for="(item, index) in ruleForm.labelIdList" :key="index">
                                        {{ item.name }}
                                    </el-tag>
                                </div>
                            </el-scrollbar>
                        </div>
                        <el-divider></el-divider>
                        <el-form-item :label="`${$route.query.sendType == 2 ? '客户群' : '用户'}进入当前阶段时，可以解绑标签`"
                            prop="fallOffLabelIdList">
                            <select-tag :type="$route.query.sendType == 1 || !stageDrawer ? 1 : 2" ref="selectFallTag" :mode="2"
                                :selectedTags.sync="ruleForm.fallOffLabelIdList">
                                <el-button @click="addTag('selectFallTag')" slot="addTag" type="text"
                                    icon="el-icon-circle-plus">添加</el-button>
                            </select-tag>
                        </el-form-item>
                        <div v-if="ruleForm.fallOffLabelIdList?.length > 0" class="padding-sm label-content">
                            <el-scrollbar>
                                <div class="flex-center flex-wrap"
                                    style="max-height: 470px;gap: 12px;min-height: 40px;">
                                    <el-tag class="tag-items" size="small" closable
                                        @close="closeTag(ruleForm.fallOffLabelIdList, index)"
                                        v-for="(item, index) in ruleForm.fallOffLabelIdList" :key="index">
                                        {{ item.name }}
                                    </el-tag>
                                </div>
                            </el-scrollbar>
                        </div>
                    </el-form>
                    <!--  -->

                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import SelectDialog from "@/views/components/selectDialog/selectDialog.vue";
import { getlabelManagerAll } from "@/api/label/label";
import SelectTag from '../../../components/selectTag/selectTag.vue';
import _ from "lodash"

export default {
    props: {
        stageDrawer: { type: Boolean }
    },
    components: {
        SelectDialog,
        SelectTag,
    },
    data() {
        const validLabel = (rule, value, callback) => {
            if (this.ruleForm.labelIdList.length == 0 && this.ruleForm.fallOffLabelIdList.length == 0) {
                callback("请选择标签")
            } else {
                callback()
            }
        }
        return {
            activeNames: [1],
            name: '',
            muLimit: 1,
            querForm: {
                pageNo: 1,
                pageSize: 10,
                type: 1,
                name: ''
            },
            optionList: [],
            ruleForm: {
                sendType: 1,
                actionType: 3,
                labelIdList: [],
                fallOffLabelIdList: []
            },
            rules: {
                labelIdList: [{ required: false, validator: validLabel, message: "请选择标签", trigger: 'blur' }],
                fallOffLabelIdList: [{ required: false, validator: validLabel, message: "请选择标签", trigger: 'blur' }]
            },
            visible: this.$route.query?.visible == 3,
            visibleStatus: this.$route.query?.visibleStatus == 2,
        }
    },
    mounted() {
        // this.getlabel();
    },
    computed: {
        isGroup() {
            return this.$route.query.sopType == 'clientSOP' ? false : true;
        }
    },
    methods: {
        getlabel() {
            const formData = { mode: '2', type: this.$route.query.sendType == 2 && this.stageDrawer ? 2 : 1 };
            return getlabelManagerAll(formData).then((res) => {
                console.log(this.optionList, 'this.optionList');
                this.optionList = res.data;
            });
        },
        // 回显taskLabels数据
        async setTaskLabels(data) {
            await this.getlabel();
            if (!data) return;
            data.labelIdList = _.filter(this.optionList, v => data.labelIdList?.includes(v.id)) || []
            data.fallOffLabelIdList = _.filter(this.optionList, v => data.fallOffLabelIdList?.includes(v.id)) || []
            this.ruleForm = data;
        },
        // 添加标签
        addTag(tagType) {
            this.$refs[tagType].open()
        },
        // 删除标签
        closeTag(list, index) {
            list.splice(index, 1);
        }
    },
    watch: {
        ruleForm: {
            handler: function (val) {
                let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
                ruleForm.labelIdList = ruleForm.labelIdList.map(v => v.id)
                ruleForm.fallOffLabelIdList = ruleForm.fallOffLabelIdList.map(v => v.id)
                this.$emit('taskLabel', ruleForm);
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" scoped>
.task-label-collapse ::v-deep {
    .label-content {
        display: inline-block;
        min-width: 600px;
        background: #F9F9F9;
        margin-left: 30px;
    }

    // margin-top: 30px;
    // margin-bottom: 90px;

    .el-collapse-item__wrap,
    .el-collapse,
    .el-collapse-item__header {
        border-bottom: none;
        border-top: none;
    }

    .el-icon-label-path {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        background: url(../../../assets/images/labelPath.png) center center no-repeat;
        background-size: contain;
    }

    .el-collapse-item__header {
        padding: 0 24px 0 40px;
        height: 40px;
        opacity: 1;
        line-height: 40px;
        background: #ECF5FC;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        position: relative;
    }

    .expand {
        position: absolute;
        font-size: 14px;
        font-weight: normal;
        color: #307EF2;
        right: 50px;
    }

    .el-icon-arrow-right {
        color: #307EF2;
    }

    .label-info {
        padding: 24px 0 32px 40px;
        font-size: 14px;
        font-weight: normal;
        color: #333333;
    }

    .el-input--small .el-input__inner {
        width: 208px;
        height: 32px !important;
        line-height: 32px !important;
    }

    .select-type {
        .el-select-dropdown__list {
            padding: 12px !important;
        }
    }
}
</style>