var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-button",
    {
      attrs: { type: "text" },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("i", { staticClass: "advanced-filter-dialog__filter" }),
      _vm._v(" 高级筛选 "),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }