var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selected-list" }, [
    _c(
      "div",
      { staticClass: "selected-list__header" },
      [
        _c("span", [_vm._v("已选" + _vm._s(_vm.selectedList.length) + "项")]),
        _c(
          "el-button",
          {
            attrs: { type: "text" },
            on: {
              click: function ($event) {
                return _vm.$emit("on-clear")
              },
            },
          },
          [_vm._v("清空")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "selected-list__items" },
      _vm._l(_vm.selectedList, function (item) {
        return _c(
          "div",
          { key: item?.[_vm.realId], staticClass: "selected-list__item" },
          [
            _c("span", [_vm._v(_vm._s(item?.[_vm.realLabel]))]),
            _c("i", {
              staticClass: "el-icon-circle-close",
              staticStyle: { color: "#ccc" },
              on: {
                click: function ($event) {
                  return _vm.$emit("on-delete", item)
                },
              },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }