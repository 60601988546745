<template>
    <div class="stage-second-set">
        <el-form class="task-form" :disabled="visibleStatus || visible" :rules="rules" :model="ruleForm" ref="ruleForm"
            label-width="110px" label-position="left">
            <el-form-item label="执行任务" prop="performType">
                <el-checkbox-group v-model="ruleForm.performType" @change="selectPerformType" size="medium">
                    <el-checkbox v-for="(item, index) in taskList" :key="index" :label="item.id" border>
                        <i :class="[ruleForm.performType.indexOf(item.id) != -1 ? item.icon : item.iconNo, 'icon']"></i>
                        {{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>
        <div class="task-all">
            <taskMessage ref="taskMessages" @taskMessage="stageSecondSetData"
                v-show="ruleForm.performType.indexOf(1) != -1" />
            <taskForward ref="taskForwards" @taskForward="stageSecondSetData"
                v-show="ruleForm.performType.indexOf(2) != -1" />
            <taskLabel :stageDrawer="stageDrawer" ref="taskLabels" @taskLabel="stageSecondSetData"
                v-show="ruleForm.performType.indexOf(3) != -1" />
            <taskNotice ref="taskNotice" @taskNotice="stageSecondSetData"
                v-show="ruleForm.performType.indexOf(4) != -1" />
            <task-info ref="taskInfo" @taskInfo="stageSecondSetData"
                v-show="ruleForm.performType.indexOf(5) != -1"></task-info>
        </div>
    </div>
</template>
<script>
import taskMessage from '@/views/sop/components/taskMessage.vue'
import taskForward from '@/views/sop/components/taskForward.vue';
import taskLabel from '@/views/sop/components/taskLabel.vue';
import taskNotice from '@/views/sop/components/taskNotice.vue';
import { getSopStageDetail } from '@/api/sop/index.js'
import TaskInfo from './taskInfo.vue';
export default {
    components: { taskMessage, taskForward, taskLabel, taskNotice, TaskInfo },
    props: {
        id: {
            type: String,
            default: ''
        },
        stageDrawer: {
            type: Boolean
        },
        selectCondition: {
            default: ''
        }
    },
    data() {
        return {
            ruleForm: {
                performType: [],
            },
            visible: this.$route.query?.visible == 3,
            visibleStatus: this.$route.query?.visibleStatus == 2,
            taskList: [
                {
                    icon: 'el-icon-message-active',
                    iconNo: 'el-icon-messageno',
                    name: '发消息',
                    id: 1
                },
                {
                    icon: 'el-icon-forward-active',
                    iconNo: 'el-icon-forward',
                    name: '转发',
                    id: 2
                },
                {
                    icon: 'el-icon-label-active',
                    iconNo: 'el-icon-label',
                    name: '打标签',
                    id: 3
                }
            ],
            rules: {
                performType: [{ required: this.$route.query.isStage == 0 ? true : false, message: '请选择执行任务', trigger: 'blur' }],
            },
            taskDataList: [],// 任务消息列表
            performName: [],// 任务类型名称列表
            taskDataListCopy: [],
        }
    },
    computed: {
        isGroup() {
            return this.$route.query.sopType == 'clientSOP' ? false : true;
        }
    },
    mounted() {
        const noticeData = {
            icon: 'el-icon-label-active',
            iconNo: 'el-icon-label',
            name: '群公告',
            id: 4
        };
        const informationData = {
            icon: 'icon-informationActive',
            iconNo: 'icon-information',
            name: '信息收集',
            id: 5
        }
        if (this.isGroup) {
            // this.taskList.push(noticeData);
        }
        if (this.ruleForm.performType.length == 0) {
            this.ruleForm.performType.push(1);
        }
        if (this.$route.query.isStage == 0 && this.$route.query.sendType == 1 && this.selectCondition == 102) {
            this.taskList.push(informationData);
        }
    },
    methods: {
        // 回显阶段详情
        initData() {
            getSopStageDetail({ id: this.id }).then(res => {
                this.ruleForm.performType = res.data.sopExecuteTaskDetailVOs.map(v => v.actionType);
                this.createDetailData(res.data.sopExecuteTaskDetailVOs);
            })
        },
        // 回显数据
        createDetailData(data) {
            this.$refs.taskMessages.setTaskMessageForm(data.find(v => v.actionType == 1));
            this.$refs.taskForwards.setTaskForwards(data.find(v => v.actionType == 2));
            this.$refs.taskLabels.setTaskLabels(data.find(v => v.actionType == 3));
            this.$refs.taskNotice.setTaskNoticeForm(data.find(v => v.actionType == 4));
            this.$refs.taskInfo.setTaskInfoForm(data.find(v => v.actionType == 5));
            if (this.ruleForm.performType.length == 0) {
                this.ruleForm.performType.push(1);
            }
        },
        // 更新执行任务数据
        stageSecondSetData(val) {
            const index = this.taskDataListCopy.findIndex(v => v.actionType == val.actionType);
            val = JSON.parse(JSON.stringify(val))
            if (val.actionType == 1 && val.sendType == 1) { // crossDayExecution
                delete val.crossDayExecution;
                val.sendMessageReqVOList = val.sendMessageReqVOList.slice(0, 1);
            }
            if (index != -1) {
                this.taskDataListCopy?.splice(index, 1, val);
            } else {
                this.taskDataListCopy.push(val);
            }
            this.$nextTick(() => {
                this.selectPerformType();
            })
        },
        selectPerformType() {
            this.performName = this.taskDataListCopy.filter(v => this.ruleForm.performType.indexOf(v.id) != -1).map(v => {
                return v.name;
            })
            this.taskDataList = this.taskDataListCopy.filter(v => this.ruleForm.performType.indexOf(v.actionType) != -1) || [];
        }
    },
    watch: {
        'ruleForm.performType': {
            handler: function (val, oldVal) {
                if (val.length > oldVal.length) {
                    const taskList = ['taskMessages', 'taskForwards', 'taskLabels', 'taskNotice', 'taskInfo'];
                    const drawerBody = document.querySelector(".el-drawer__body");
                    this.$nextTick(() => {
                        drawerBody.scrollTo(0, this.$refs[taskList[val.at(-1) - 1]].$el.offsetTop - 80);
                    })
                }
            },
            deep: true
        },
        selectCondition: {
            handler: function (val, oldVal) {
                const informationData = {
                    icon: 'icon-informationActive',
                    iconNo: 'icon-information',
                    name: '信息收集',
                    id: 5
                }
                if (this.$route.query.isStage == 0 && this.$route.query.sendType == 1 && val == 102) {
                    this.taskList.push(informationData);
                } else {
                    this.taskList = this.taskList.filter(v => v.id != 5)
                }
            },
            deep: true
        }
    }
}
</script>


<style lang="scss" scoped>
.stage-second-set ::v-deep {
    .task-form .el-checkbox__inner {
        display: none;
    }

    .task-all {
        padding-bottom: 90px;
    }

    .line {
        width: calc(100% - 21px);
        height: 1px;
        background-color: #d8d8d8;
        margin-left: 21px;
    }


    .icon {
        vertical-align: middle;
    }

    .el-icon-label {
        width: 14px;
        height: 14px;
        background: url(../../../assets/images/label.png) center center no-repeat;
        background-size: contain;
    }

    .el-icon-label-active {
        width: 14px;
        height: 14px;
        background: url(../../../assets/images/label-active.png) center center no-repeat;
        background-size: contain;
    }

    .el-icon-messageno {
        width: 14px;
        height: 14px;
        background: url(../../../assets/images/messageno.png) center center no-repeat;
        background-size: contain;
    }

    .el-icon-message-active {
        width: 14px;
        height: 14px;
        background: url(../../../assets/images/message-active.png) center center no-repeat;
        background-size: contain;
    }

    .el-icon-forward {
        width: 16px;
        height: 16px;
        background: url(../../../assets/images/forward.png) center center no-repeat;
        background-size: contain;
    }

    .el-icon-forward-active {
        width: 16px;
        height: 16px;
        background: url(../../../assets/images/forward-active.png) center center no-repeat;
        background-size: contain;
    }

    .el-icon-horn-active {
        width: 15px;
        height: 15px;
        background: url(../../../assets/images/horn-active.png) center center no-repeat;
        background-size: contain;
    }

    .el-info-label {
        width: 16px;
        height: 16px;
        background: url(../../../assets/images/information.png) center center no-repeat;
        background-size: contain;
    }

    .el-info-label-active {
        width: 16px;
        height: 16px;
        background: url(../../../assets/images/informationActive.png) center center no-repeat;
        background-size: contain;
    }


    .el-drawer__header {
        font-weight: 500;
        color: #000;
        font-size: 16px;
        margin-bottom: 27px;
    }

    .icon-information {
        width: 16px;
        height: 16px;
        display: inline-block;
        background: url(../../../assets/images/information.png) center center no-repeat;
        background-size: contain;
    }

    .icon-informationActive {
        width: 16px;
        height: 16px;
        display: inline-block;
        background: url(../../../assets/images/informationActive.png) center center no-repeat;
        background-size: contain;
    }

    .task-form {
        padding: 0 40px;
        margin-top: 30px;
    }

    .custom-name {
        display: inline-block;
        margin-right: 35px;
        width: 120px;
        height: 40px;
        text-align: center;
        border-radius: 2px;
        opacity: 1;
        border: 0.5px solid #C0CCDA;
        cursor: pointer;
        position: relative;
        line-height: 40px;

        .icon-bg {
            position: absolute;
            top: 13px;
            left: 24px;
        }

        .icon-two {
            left: 30px
        }

        .horn {
            position: absolute;
            top: -5px;
            right: -5px;
        }

        span {
            display: inline-block;
            // vertical-align: middle;
            margin-left: 11px;
            font-size: 14px;
            font-weight: normal;
            color: #333333;
        }
    }

    .custom-active {
        border: 0.5px solid #307EF2;
    }
}
</style>
