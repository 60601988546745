<template>
    <div class="padding-top-xs notice-view margin-right">
        <el-popover placement="bottom" v-model="show" width="322" popper-class="padding-0 notice-popover"
            trigger="click">
            <!-- icon 展示 -->
            <el-tooltip slot="reference" class="item" effect="dark" content="消息中心" placement="left">
                <el-badge :value="unreadCount" :hidden="unreadCount == 0" :max="99" type="danger">
                    <i class="el-icon-message-solid pointer text-xl" @click="getNoticeList"></i>
                </el-badge>
            </el-tooltip>
            <!-- 弹出列表 -->
            <div v-loading="loading" element-loading-text="加载中,请稍候">
                <div v-show="list.length > 0">
                    <div class="padding-sm">
                        <div class="text-df text-bold">系统通知</div>
                        <div class="text-sm flex-center margin-top-xs">
                            <el-link :underline="false" type="primary">转人工通知</el-link>
                            <el-badge slot="reference" type="primary" class="circle margin-left-xs" :value="unreadCount"
                                :hidden="unreadCount == 0" :max="99">
                            </el-badge>
                            <!-- <div class="circle margin-left-xs">{{ list.length }}</div> -->
                        </div>
                    </div>
                    <div v-loadmore="getList">
                        <div ref="scrollbar" class="noticeList el-scrollbar__wrap">
                            <div @click="goMyList(item)" class="padding-lr-sm item pointer"
                                v-for="(item, index) in list" :key="index">
                                <div class="flex-center text-black">
                                    <wechat-img style="margin-right: 5px;"
                                        :type="item.templateParams.accountType"></wechat-img>
                                    {{ item.templateParams.nickname }}
                                </div>
                                <div class="text-cut2 text-black margin-tb-5">{{ item.templateParams.sendName }}
                                    <span style="color: #718096;"> {{ item.templateParams.content }}</span>
                                </div>
                                <div style="color: #A0AEC0;">{{ item.templateParams.sendTime }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <el-empty class="padding-tb-xxl" :image="require('@/assets/images/empty.png')" v-show="list.length == 0"
                    description="暂无通知"></el-empty>
            </div>
            <!-- 更多 -->
            <div class="bottom-view text-black flex-center">
                <el-button type="text" :disabled="unreadCount == 0" @click="handleUpdateAll()"
                    :class="[unreadCount > 0 ? 'item' : 'text-gray flex-sub solids-right']">全部已读</el-button>
                <div @click="goMyList" class="item text-blue pointer text-center">查看所有通知</div>
            </div>
        </el-popover>
    </div>
</template>

<script>
import { getUnreadNotifyMessageCount, getUnreadNotifyMessageList, getMyNotifyMessagePage } from "@/api/system/notify/message";
import { updateAllNotifyMessageRead, updateNotifyMessageRead } from "@/api/system/notify/message";
import wechatImg from '../../../components/wechatImg/wechatImg.vue';
import { EventSourcePolyfill } from 'event-source-polyfill';
import message from "./message.vue"
import _ from "lodash";
import {
  getAccessToken, getTenantId
} from '@/utils/auth'
let reconnectAttempts = 0;
const reconnectDelay = 5000; // 重连间隔时间（毫秒）
export default {
    components: { wechatImg, message },
    data() {
        return {
            unreadCount: 0,
            list: [],
            show: false,
            queryParams: {
                pageNo: 0,
                pageSize: 10,
            },
            hasMore: true,
            total: 0,
            loading: false,
            scrollbar: null,
            readNotifyArr: [], // 已读id列表

        }
    },
    methods: {
        getNoticeList() {
            this.loading = true;
            this.queryParams.pageNo = 0;
            this.list = [];
            this.hasMore = true;
            this.getList();
        },
        getList: function () {
            this.queryParams.pageNo++;
            if (!this.hasMore) return;
            getMyNotifyMessagePage(this.queryParams).then(response => {
                this.list = this.list.concat(response.data.list);
                this.loading = false;
                this.total = response.data.total || 0;
                this.hasMore = this.total > this.list.length;
                // 强制设置 unreadCount 为 0，避免小红点因为轮询太慢，不消除
                // this.unreadCount = 0
                // this.scrollNotice();
            });
        },
        getUnreadCount: function () {
            getUnreadNotifyMessageCount().then(response => {
                this.unreadCount = response.data;
            })
        },
        goMyList: function (item) {
            if (item.id) {
                this.handleUpdate([item.id]);
            }
            this.show = false;
            this.$router.push({
                name: 'MyNotify'
            });
        },
        // 全部已读
        handleUpdateAll() {
            updateAllNotifyMessageRead().then(response => {
                this.$modal.msgSuccess("全部已读成功！");
                // this.getList();
            });
        },
        // 标为已读
        handleUpdate(ids) {
            if (ids.length == 0) return;
            updateNotifyMessageRead(ids).then(response => {
                // this.$modal.msgSuccess("标记已读成功！");
                // this.getList();
                this.getUnreadCount()
            });
        },
        // 创建消息弹框
        createNotify(message) {
            const h = this.$createElement;
            this.$notify({
                title: "通知",
                customClass: "notify-position",
                dangerouslyUseHTMLString: true,
                message: h('message', { props: { message } })
            });
        },
        // 初始化EventSource
        initEventSource(url) {
          const token = getAccessToken();
          const eventSource = new EventSourcePolyfill(url, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'tenant-id': getTenantId(),
            }
          });

          eventSource.onerror = (e) => {
            console.log("SSE连接错误", e);
            if (e.readyState === EventSource.CLOSED || eventSource.readyState === EventSource.CONNECTING) {
              console.log("SSE连接已关闭或正在重连");
            } else {
              // 当发生错误时，尝试重新连接
              console.log(`尝试第${reconnectAttempts + 1}次重连`);
              reconnectAttempts++;
              setTimeout(() => {
                eventSource.close(); // 关闭当前连接
                this.initEventSource(url); // 重新初始化EventSource
              }, reconnectDelay);
            }
          };

          eventSource.addEventListener("message", res => {
            const data = JSON.parse(res.data)
            if (data.type == 2) {
              this.unreadCount = data.content;
            }
            if (data.type == 1) {
              this.createNotify(data)
            }
          })
        },
        // 滚动标为已读
        scrollNotice() {
            this.formatReadId(this.list.slice(0, 3));
            this.scrollbar = this.$refs.scrollbar;
            const indexArr = [];
            this.scrollbar?.addEventListener("scroll", _.debounce(res => {
                const itemHeight = 110;
                const index = Math.floor(res.target.scrollTop / itemHeight) + 3;
                if (indexArr.indexOf(index) == -1) {
                    indexArr.push(index)
                    this.formatReadId(this.list.slice(3, Math.max(...indexArr)))
                }
            }, 100))
        },
        formatReadId(list) {
            this.handleUpdate(list.map(v => v.id));
        }
    },
    created() {
        const url = `${process.env.VUE_APP_BASE_API}/admin-api/system/notify-template/events`;
        this.initEventSource(url)
        // // 首次加载小红点
        this.getUnreadCount()
        // this.getList();
        // // 轮询刷新小红点
        // setInterval(() => {
        //     this.getUnreadCount()
        // }, 1000 * 60 * 2)

    }
}
</script>

<style lang="scss" scoped>
.circle ::v-deep {

    height: 20px;
    line-height: 20px;

    .el-badge__content {
        height: 20px;
        color: #3070FF;
        background: #E3EEFF;
    }
}

.el-icon-message-solid {
    color: #99A3B5;

    &:hover {
        color: #8591A6;
    }
}

.noticeList {
    max-height: 300px;
    overflow-y: auto;

    .item {
        padding: 10px 20px;
        border-top: 1px solid #F3F4F7;

        // &:nth-child(1){
        //     border: 0 none;
        // }
        &:hover {
            background: #F2F6F9;
        }

        .margin-tb-5 {
            margin: 5px 0;
        }
    }
}

.bottom-view {
    width: 320px;
    height: 44px;
    background: #FFFFFF;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.08);

    .el-button {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .item {
        flex: 1;

        &:hover {
            color: #307EF2;
        }

        &:nth-child(1) {
            border-right: 1px solid #F1F1F1;
        }
    }
}
</style>
