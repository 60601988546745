var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "查看",
            visible: _vm.dialogSee,
            width: "40%",
            "close-on-click-modal": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSee = $event
            },
          },
        },
        [
          _c(
            "el-scrollbar",
            {
              ref: "scrollContainer",
              staticClass: "scroll-container padding-top-xs",
              attrs: { vertical: false },
            },
            [
              _vm._l(_vm.lists, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "record flex justify-between padding-right-sm",
                    class: [index !== 0 ? "margin-top-sm" : ""],
                  },
                  [
                    _c("div", { staticClass: "flex flex-sub" }, [
                      _vm.type
                        ? _c(
                            "div",
                            {
                              staticClass: "tipNumber",
                              class: [
                                item.tips ? "tipNumberOn" : "tipNumberUn",
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.tipValue(item, index)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.tips) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "photo" },
                        [
                          _c("el-avatar", {
                            attrs: {
                              fit: "fit",
                              shape: "square",
                              src: item.avatar ? item.avatar : _vm.imgPerson,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "contaliner" }, [
                        _c(
                          "div",
                          { staticClass: "createTime margin-bottom-xs" },
                          [
                            _c("span", { staticClass: "nickname" }, [
                              _vm._v(_vm._s(_vm.collectWxName)),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.parseTime(item.createTime))),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "container1 flex flex-direction" },
                          [
                            _c("div", { staticClass: "nameNick" }, [
                              _vm._v(_vm._s(item.nickname)),
                            ]),
                            item.type === 0
                              ? _c("div", { staticClass: "desc" }, [
                                  _vm._v(" " + _vm._s(item.content) + " "),
                                ])
                              : _vm._e(),
                            item.type === 1
                              ? _c(
                                  "div",
                                  { staticClass: "descImg" },
                                  [
                                    _c("el-image", {
                                      attrs: { src: item.content, fit: "fit" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.type === 2
                              ? _c("div", { staticClass: "descImg" }, [
                                  _c("video", {
                                    staticClass: "vodeoCss",
                                    attrs: { controls: "", src: item.content },
                                  }),
                                ])
                              : _vm._e(),
                            item.type === 3 ||
                            item.type === 4 ||
                            item.type === 5
                              ? _c(
                                  "div",
                                  { staticClass: "descImgWord" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "desc flex align-center" },
                                      [
                                        _c("div", {
                                          class: _vm.getFileClass(item.content),
                                          staticStyle: {
                                            width: "26.95px",
                                            height: "32px",
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(item.contentName)),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { padding: "5px 10px" },
                                        attrs: { type: "primary", plain: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.downWorld(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", { staticClass: "icon-down" }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "text-sm" }, [
                                          _vm._v("下载"),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.type === 10
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "solid program-view radius-8 pointer padding-xs",
                                    on: {
                                      click: function ($event) {
                                        return _vm.editMaterialData(item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex-center" },
                                      [
                                        !item.content.mpLogo
                                          ? _c("i", {
                                              staticClass:
                                                "icon-top-logo margin-right-xs",
                                            })
                                          : _c("el-image", {
                                              staticClass:
                                                "icon-top-logo margin-right-xs",
                                              attrs: {
                                                src: item.content.mpLogo,
                                              },
                                            }),
                                        _c("div", { staticClass: "text-cut" }, [
                                          _vm._v(_vm._s(item.content.title)),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("div", {
                                      staticClass: "text-cut2 margin-tb-xs",
                                      staticStyle: { "line-height": "1.5" },
                                    }),
                                    _c("el-image", {
                                      staticClass: "image",
                                      attrs: {
                                        src: item.content.url,
                                        fit: "fill",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex-center solid-top margin-top-xs padding-top-xs",
                                        staticStyle: { "line-height": "1" },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "icon-bottom-logo margin-right-xs",
                                        }),
                                        _vm._v("小程序 "),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              _vm.isShow
                ? _c("div", { staticClass: "srollBottom" }, [
                    _vm._v("暂无更多数据"),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _vm.type
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "no-more-click", rawName: "v-no-more-click" },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.pitchOnData },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }