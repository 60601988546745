<template>
    <div class="materail-file">
        <el-card shadow="never" class="file-tabs">
            <el-tabs v-model="activeName">
                <!-- <el-tab-pane label="文本" name="text">
                    <div class="search-view">
                        <el-input placeholder="请输入内容" v-model="queryParams.input" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </div>
                    <el-table class="margin-tb-sm" height="433px" ref="multipleTable" :data="queryParams.tableData"
                        tooltip-effect="dark" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column label="文本内容">
                            <template slot-scope="scope">{{ scope.row.date }}</template>
</el-table-column>
<el-table-column prop="name" label="最近更新时间">
</el-table-column>
</el-table>
</el-tab-pane>
<el-tab-pane label="图片" name="image">
    <el-scrollbar>
        <el-checkbox-group class="file-content-view margin-tb-sm" v-model="queryParams.checkList">
            <div class="file-item" v-for="item in queryParams.tableData" :key="item">
                <el-checkbox :label="item"> </el-checkbox>
                <el-image :src="item" :preview-src-list="queryParams.tableData"></el-image>
            </div>
        </el-checkbox-group>
    </el-scrollbar>
</el-tab-pane>
<el-tab-pane label="视频" name="video">
    <el-scrollbar>
        <el-checkbox-group class="file-content-view margin-tb-sm" v-model="queryParams.checkList">
            <div class="file-item" v-for="item in queryParams.tableData" :key="item">
                <el-checkbox :label="item"> </el-checkbox>
                <video class="video" :src="item"></video>
                <div @click="previewVideo(item)" class="icon-play pointer"></div>
            </div>
        </el-checkbox-group>
    </el-scrollbar>
</el-tab-pane>
<el-tab-pane label="文件" name="file">
    <div class="search-view">
        <el-input placeholder="请输入内容" v-model="queryParams.input" class="input-with-select">
            <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
    </div>
    <el-table class="margin-tb-sm" height="433px" ref="multipleTable" :data="queryParams.tableData"
        tooltip-effect="dark" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="文件名称">
            <template slot-scope="scope">{{ scope.row.date }}</template>
        </el-table-column>
        <el-table-column prop="name" label="最近更新时间">
        </el-table-column>
    </el-table>
</el-tab-pane> -->
                <el-tab-pane label="小程序" name="miniProgram">
                    <div class="search-view">
                        <!-- <el-input placeholder="请输入要搜索的小程序标题/AppID" @change="getList" v-model="queryParams.keyword"
                            class="input-with-select">
                            <el-button slot="append" @click="getList" icon="el-icon-search"></el-button>
                        </el-input> -->
                        <el-input clearable placeholder="请输入要搜索的小程序标题/AppID" v-model="queryParams.keyword" @change="getList">
                            <i slot="suffix" style="cursor: pointer" class="el-input__icon el-icon-search" @click="getList"></i>
                        </el-input>
                    </div>
                    <el-scrollbar>
                        <el-checkbox-group class="file-content-view margin-tb-sm" v-model="queryParams.checkList">
                            <!-- 小程序 -->
                            <div class="solid text-sm program-view radius-8 pointer padding-xs"
                                v-for="item in queryParams.tableData" :key="item.appId"
                                @click="selectMiniProgram(item)">
                                <div class="flex-center justify-between">
                                    <div class="flex-center text-xs flex-sub" style="max-width: 90%;">
                                        <i v-if="!item.mpLogo" class="icon-top-logo margin-right-xs"></i>
                                        <el-image v-else :src="item.mpLogo"
                                            class="icon-top-logo margin-right-xs"></el-image>
                                        <div class="text-cut" style="max-width: 95%;">{{ item.title }}</div>
                                    </div>
                                    <el-checkbox :label="item"></el-checkbox>
                                </div>
                                <div class="text-cut2 margin-tb-xs" style="line-height: 1.5;">

                                </div>
                                <el-image :src="item.url" fit="contain" class="image"></el-image>
                                <div class="flex-center text-xs solid-top margin-top-xs padding-top-xs"
                                    style="line-height: 1;">
                                    <i class="icon-bottom-logo margin-right-xs"></i>小程序
                                </div>
                            </div>
                        </el-checkbox-group>
                    </el-scrollbar>
                </el-tab-pane>
            </el-tabs>
            <div class="padding-top-xs">
                <pagination :total="queryParams.total" :pageSizes="[12, 24, 36, 48, 60]" :page.sync="queryParams.pageNo"
                    :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>
        </el-card>
        <!-- 视频预览 -->
        <preview-video ref="previewVideo" :url="playUrl"></preview-video>
    </div>
</template>

<script>
import previewVideo from '../../../components/previewVideo/previewVideo.vue'
import { getMaterialMpPage } from "@/api/robot/robot.js"
import { v4 as uuidv4 } from "uuid"
export default {
    components: { previewVideo },
    data() {
        return {
            activeName: "miniProgram",
            playUrl: "", // 视频预览地址
            materialFileList: [{ // 文本数据
                checkList: [],
                input: "",
                pageSize: 12,
                pageNo: 1,
                total: 0,
                tableData: [],
            }, { // 图片数据
                tableData: ['https://oss.isuiyou.cn/scrm/5e028a0c-e542-4cf8-bb6c-72d01f0591bb/ai佛体验版.png', 'https://oss.isuiyou.cn/scrm/5e028a0c-e542-4cf8-bb6c-72d01f0591bb/ai佛体验版.png'],
                checkList: [],
                pageSize: 12,
                pageNo: 1,
                total: 0,
            }, { //视频数据
                tableData: ['https://oss.isuiyou.cn/scrm/09018414-449f-4950-a8b8-10bc161802cf/948a1378-c399-4298-9c5e-4a6443378d70.mp4', 'https://oss.isuiyou.cn/scrm/09018414-449f-4950-a8b8-10bc161802cf/948a1378-c399-4298-9c5e-4a6443378d70.mp4'],
                checkList: [],
                pageSize: 12,
                pageNo: 1,
                total: 0,
            }, { // 文件数据
                checkList: [],
                input: "",
                pageSize: 12,
                pageNo: 1,
                total: 0,
                tableData: [],
            }, { // 小程序数据
                type: 10,
                keyword: "",
                tableData: [],
                checkList: [],
                pageSize: 12,
                pageNo: 1,
                total: 0,
            }]

        }
    },
    computed: {
        // 当前tabs下标
        currentIndex() {
            const fileTypeList = ["text", "image", "video", "file", "miniProgram"];
            return fileTypeList.findIndex(v => v == this.activeName);
        },
        // 当前tabs数据
        queryParams() {
            return this.materialFileList[this.currentIndex];
        }
    },
    methods: {
        // 初始化弹框数据
        getList() {
            // 获取小程序数据
            getMaterialMpPage({ keyword: this.queryParams.keyword, pageNo: this.queryParams.pageNo, pageSize: this.queryParams.pageSize, searchCount: true }).then(res => {
                res.data.list.map(v => {
                    v.type = 10;
                    v.id = uuidv4();
                })
                this.queryParams.tableData = res.data.list;
                this.queryParams.total = res.data.total;
                this.loading = false
            })
        },
        // 表格数据选中
        handleSelectionChange() {

        },
        // 预览视频
        previewVideo(item) {
            this.playUrl = item;
            this.$nextTick(() => {
                this.$refs.previewVideo.playVideo();
            });
        },
        selectMiniProgram(item) {
            const index = this.queryParams.checkList.findIndex(v => v.id == item.id);
            if (index == -1) {
                this.queryParams.checkList.push(item)
            } else {
                this.queryParams.checkList.splice(index, 1)
            }
        }
    },
    created() {
        this.getList()
    },
}
</script>

<style lang="scss" scoped>
.materail-file ::v-deep {
    .file-tabs {
        .el-tabs__header {
            margin: 0 !important;
        }
    }

    .search-view {
        padding-top: 20px;
        width: 480px;

        .el-input-group__append,
        .el-input-group__prepend {
            background: transparent;
            background-color: transparent;
            border-left-color: transparent;
        }

        .el-input__inner {
            &:focus+.el-input-group__append {
                outline: none;
                border-color: #307EF2;
            }
        }

        .el-input-group--append .el-input__inner {
            border-right: 0 none;
        }
    }

    .file-content-view {
        display: flex;
        flex-wrap: wrap;
        height: 435px;
        gap: 40px;

        .file-item {
            width: 160px;
            height: 160px;
            border: 1px solid #eaeaea;
            position: relative;

            .el-checkbox {
                position: absolute;
                top: 10px;
                left: 10px;
            }

            .el-checkbox__label {
                display: none;
            }

            .video {
                width: 100%;
                height: 100%;
            }

            .icon-play {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -20px;
                margin-left: -20px;
                width: 40px;
                height: 40px;
                background: url(../../../assets/images/play.png) center center no-repeat;
                background-size: contain;
            }
        }

        .program-view {
            width: 240px;
            height: 270px;

            &:hover {
                box-shadow: 0px 4px 10px 0px rgba(76, 132, 255, 0.24);
            }

            .el-checkbox__label {
                display: none;
            }

            .image {
                width: 220px;
                height: 176px;
                vertical-align: top;
            }

            .icon-top-logo {
                width: 20px;
                height: 20px;
                min-width: 20px;
                border-radius: 50%;
                background: url(https://oss.isuiyou.cn/162a06ac42441132c166b031a8282528f5bb69dc89d17fb82ba5745b68d894c3.png) center center no-repeat;
                background-size: contain;
            }

            .icon-bottom-logo {
                width: 12px;
                height: 12px;
                background: url(https://oss.isuiyou.cn/570de39153493035369ea04f58ab79c1c848f95c376cab6557e8efff6b98d617.png) center center no-repeat;
                background-size: contain;
            }
        }
    }
}
</style>