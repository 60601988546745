<template>
  <div class="contailner">
    <!-- 搜索部分 -->
    <el-card shadow="never">
      <el-form :model="queryParams" label-width="auto" ref="queryForm" class="search-form">
        <!-- <div class="flex" style="width:100%;gap:0 20px;"> -->
        <el-form-item label="素材名称:" prop="name">
          <el-input @change="getList('inquire')" v-model.trim="queryParams.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="创建时间:" prop="createTime">
          <el-date-picker v-model="queryParams.createTime" @change="getList('inquire')"
            value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="-" start-placeholder="年月日"
            end-placeholder="年月日" :default-time="['00:00:00', '23:59:59']" />
        </el-form-item>
        <el-form-item v-if="!isSelect" label="编辑时间:" prop="updateTime">
          <el-date-picker v-model="queryParams.updateTime" @change="getList('inquire')"
            value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="-" start-placeholder="年月日"
            end-placeholder="年月日" :default-time="['00:00:00', '23:59:59']" />
        </el-form-item>
        <el-form-item label="素材类型:" prop="materialTypeId">
          <el-select v-model.trim="queryParams.materialTypeId" @change="getList('inquire')" placeholder="请选择" clearable>
            <el-option v-for="item in materialType" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 查询按钮 -->
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" v-hasPermi="['system:material:query']"
            @click="getList('inquire')">查询</el-button>
          <el-button icon="el-icon-refresh-right" @click="resetForm()">重置</el-button>
        </el-form-item>
        <!-- </div> -->
      </el-form>
    </el-card>

    <!-- table部分 -->
    <div class="padding-lr-sm bg-white padding-bottom-sm">
      <!-- 新建 -->
      <el-row :gutter="10" type="flex" justify="end" class="margin-bottom-sm" v-if="!isSelect">
        <el-col :span="1.5">
          <el-button type="primary" v-hasPermi="['system:material:create']" @click="jumpBuild">新建素材</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="primary" v-hasPermi="['system:material:delete']" plain
            @click="handleDelete(' ')">批量删除</el-button>
        </el-col>
      </el-row>
      <el-table @selection-change="handleSelectionChange" :row-key="getRowKeys" v-loading="loading" :data="list"
        ref="table" :height="isSelect ? '430px' : `calc(100vh - ${offsetTop}px)`">
        <el-table-column type="selection" v-if="!isSelect" :reserve-selection="true" width="55" align="left" />
        <el-table-column label="序号" type="index" width="55" />
        <el-table-column label="素材名称" prop="name" />
        <el-table-column label="素材类型">
          <template slot-scope="scope">
            <span>{{ scope.row.materialTypeId === 1 ? '商品素材' : '营销素材' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime">
          <template v-slot="scope">
            <span>{{ parseTime(scope.row.createTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="编辑时间" prop="updateTime" v-if="!isSelect">
          <template v-slot="scope">
            <span>{{ parseTime(scope.row.updateTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" class-name="small-padding fixed-width">
          <template v-slot="scope">
            <el-button type="text" @click="getMaterialDetailFn(scope.row)" v-if="!isSelect"> 预览 </el-button>
            <el-button type="text" v-hasPermi="['system:material:edit']" @click="handleEdit(scope.row)"
              v-if="!isSelect">
              编辑 </el-button>
            <el-button type="text" v-hasPermi="['system:material:delete']" @click="handleDelete(scope.row)"
              style="color:#F03308;" v-if="!isSelect"> 删除 </el-button>
            <el-button type="text" :disabled="isSopShow" @click="handleUse(scope.row)" v-else> 引用 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <div class="padding-tb-xs bg-white">
        <pagination :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize"
          @pagination="getList" />
      </div>
    </div>

    <!-- 预览按钮的弹窗 -->
    <el-dialog append-to-body :title="isShowTit" width="40%" :visible.sync="dialogVisible" close-on-click-modal>
      <preview-material :materialData="materialData"></preview-material>
      <div class="closeBtn">
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMaterial,
  getMaterialType,
  getDeleteMaterial,
  getMaterialDetail,
} from "@/api/material/material";
import PreviewMaterial from "../../robot/components/previewMaterial.vue";
export default {
  name: "material",
  props: {
    isSelect: {
      type: Boolean,
      default: false,
    },
    isSopShow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PreviewMaterial,
  },
  data() {
    return {
      /** 预览回显标题 */
      isShowTit: "",
      /** 预览回显数据 */
      materialData: [],
      /** 预览按钮的弹窗 */
      dialogVisible: false,
      /** table数据 */
      list: [],
      /** table页数 */
      total: 0,
      /** table加载过渡 */
      loading: true,
      /** 查询数据 */
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        name: "",
        materialTypeId: "",
        createTime: [],
        updateTime: [],
      },
      /** 多选的数组 */
      multipleSelection: [],
      /** 素材类型列表 */
      materialType: [],
      offsetTop: 318
    };
  },
  created() {
    this.getMaterialType();
    this.getList();
  },
  methods: {
    /** 获取素材详情 */
    async getMaterialDetailFn({ id }) {
      const res = await getMaterialDetail({ id });
      this.isShowTit = res.data.name;
      this.materialData = JSON.parse(res.data.content);
      this.dialogVisible = true;
    },
    /** 素材类型列表 */
    async getMaterialType() {
      const data = await getMaterialType();
      this.materialType = data.data;
    },
    /** 查询列表 */
    async getList(dom) {
      if (dom === "inquire") {
        this.queryParams.pageNo = 1;
      }
      const {
        pageNo,
        pageSize,
        materialTypeId,
        name,
        type,
        createTime,
        updateTime,
      } = this.queryParams;
      let params = {
        pageNo,
        pageSize,
        materialTypeId,
        name,
        type,
        startCreateTime: createTime ? createTime[0] : null,
        endCreateTime: createTime ? createTime[1] : null,
        startUpdateTime: updateTime ? updateTime[0] : null,
        endUpdateTime: updateTime ? updateTime[1] : null,
      };
      try {
        this.loading = true;
        const data = await getMaterial(params);
        this.total = data.data.total;
        this.list = data.data.list;
      } finally {
        this.loading = false;
      }
      setTimeout(() => {
        this.offsetTop = this.$refs.table.$vnode.elm.offsetTop + 20;
      }, 500);
    },
    /** table表格多选  */
    handleSelectionChange(data) {
      this.multipleSelection = data;
    },
    /** table表格多选 -- key */
    getRowKeys(row) {
      return row.id;
    },
    /** table表格多选 -- 清除选中 */
    clearSelection() {
      this.$refs.table.clearSelection();
    },
    /**修改 */
    handleUpdate(row) { },
    /**删除*/
    handleDelete(row) {
      this.$modal
        .confirm(
          row !== " "
            ? '是否确认删除素材名称为"' + row.name + '"的素材?'
            : "是否批量删除素材？"
        )
        .then(async () => {
          let arrayId = [];
          if (row !== " ") {
            arrayId = [row.id];
          } else {
            if (this.multipleSelection.length > 0) {
              arrayId = this.multipleSelection.map((v) => v.id);
            } else {
              this.$modal.msgError("你还未勾选素材！");
              return;
            }
          }
          const res = await getDeleteMaterial({ ids: arrayId.join(",") });
          this.$message.success("删除成功");
          this.getList();
        });
    },
    /**编辑素材*/
    handleEdit({ id }) {
      this.$util.redirectTo("materialBuild", { id });
    },
    /**跳转新建标签页*/
    jumpBuild() {
      this.$util.redirectTo("materialBuild", { type: "add" });
    },
    // 重置查询表单
    resetForm() {
      this.$refs.queryForm.resetFields();
      this.getList("inquire");
    },
    // 点击使用
    handleUse(row) {
      this.$emit("useMaterial", row || []);
    },
  },
};
</script>

<style lang="scss" scoped>
.contailner ::v-deep {
  .el-dialog__body {
    padding: 16px 0 0 0;
    overflow: hidden;
  }

  .search-form {
    gap: 0 30px;
  }

  // .search-form .el-form-item {
  // width: 31%;
  // }
}

.closeBtn {
  margin-top: 32px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.1);
}
</style>