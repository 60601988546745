<template>
  <div>
    <slot name="addTag" v-if="showAddTag"></slot>
    <div class="table-tags" v-else>
      <el-popover
        placement="top"
        :trigger="currentTags.length > 0 ? 'hover' : null"
      >
        <el-scrollbar>
          <div class="table-tags" style="max-width: 300px; max-height: 200px">
            <div
              class="tag-item text-cut"
              style="max-width: inherit"
              v-for="(item, index) in currentTags"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
        </el-scrollbar>
        <div class="table-tags pointer" slot="reference">
          <div
            class="tag-item text-cut"
            v-for="(item, index) in viewTags"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </el-popover>
      <el-tag type="info" @click="open" class="cursor">
        <i v-if="currentTags.length < 2" class="el-icon-plus"></i>
        <i v-else class="el-icon-more"></i>
      </el-tag>
    </div>

    <el-dialog
      title="选择标签"
      custom-class="tagDialog"
      @close="resetTagData"
      close-on-click-modal
      append-to-body
      :visible.sync="dialogVisible"
      width="800px"
    >
      <div class="flex justify-between">
        <div class="flex-treble" style="border-right: 1px solid #eaeaea;">
          <el-tabs
            v-model="activeMode"
            tab-position="left"
            style="height: 450px; margin-top: 12px"
          >
            <el-tab-pane label="动态标签" name="1" v-if="showDynamicTag">
              <div style="margin-top: 0px">
                <el-input
                  style="width: 200px"
                  @change="getDynamicTags"
                  placeholder="请输入关键词"
                  v-model="dynamicSearchName"
                  @keyup.enter.native="getDynamicTags"
                >
                  <i slot="suffix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <el-button
                  style="margin-left: 10px"
                  type="primary"
                  @click="onSearch(1)"
                  icon="el-icon-search"
                >
                  查询
                </el-button>
                <el-button @click="onReset(1)" icon="el-icon-refresh-right">
                  重置
                </el-button>
              </div>
              <div
                class="tags-view padding-tb-sm padding-right-sm gap_sm1"
                style="height: 390px; overflow: auto"
              >
                <el-tag
                  @click="addCurrentTags(tag, 1)"
                  style="user-select: none"
                  class="cursor tags-item"
                  :effect="
                    currentTags &&
                    currentTags.findIndex((v) => v.id === tag.id) === -1
                      ? 'plain'
                      : 'light'
                  "
                  :key="tag.id"
                  v-for="tag in dynamicTags"
                  :disable-transitions="false"
                >
                  {{ tag.name }}
                </el-tag>
              </div>
            </el-tab-pane>
            <el-tab-pane label="静态标签" name="2" v-if="showStaticTag">
              <div style="margin-top: 0px">
                <el-input
                  style="width: 200px"
                  @change="getStaticTags"
                  placeholder="请输入关键词"
                  v-model="staticSearchName"
                  @keyup.enter.native="getStaticTags"
                >
                  <i slot="suffix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <el-button
                  style="margin-left: 10px"
                  type="primary"
                  @click="onSearch(2)"
                  icon="el-icon-search"
                >
                  查询
                </el-button>
                <el-button @click="onReset(2)" icon="el-icon-refresh-right">
                  重置
                </el-button>
              </div>
              <div
                class="tags-view padding-tb-sm padding-right-sm gap_sm1"
                style="height: 390px; overflow: auto"
              >
                <el-popover
                  placement="bottom-start"
                  v-model="show"
                  width="380"
                  trigger="click"
                  ref="popoverRef"
                  style="height: 32px; margin-right: 8px"
                >
                  <div>
                    <el-form
                      :model="ruleForm"
                      @submit.native.prevent
                      :rules="rules"
                      ref="ruleForm"
                      label-width="90px"
                      class="demo-ruleForm"
                    >
                      <el-form-item label="标签名称" prop="name">
                        <el-input
                          v-model="ruleForm.name"
                          placeholder="请输入标签名称"
                          maxlength="20"
                          showWordLimit
                          @keyup.enter.native="sure"
                        />
                      </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer dialog-footer-end">
                      <el-button @click="cancel">取 消</el-button>
                      <el-button
                        type="primary"
                        @click="sure"
                        :loading="loading"
                      >
                        确 定
                      </el-button>
                    </span>
                  </div>
                  <el-button plain effect="plain" slot="reference" v-if="false">
                    <i class="el-icon-plus text-bold"></i>
                    新建标签
                  </el-button>
                </el-popover>

                <el-tag
                  @click="addCurrentTags(tag, 2)"
                  style="user-select: none"
                  class="cursor tags-item"
                  :effect="
                    currentTags &&
                    currentTags.findIndex((v) => v.id === tag.id) === -1
                      ? 'plain'
                      : 'light'
                  "
                  :key="tag.id"
                  v-for="tag in staticTags"
                  :disable-transitions="false"
                >
                  {{ tag.name }}
                </el-tag>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="flex-sub padding-lr-sm">
          <div class="flex-center padding-top-sm justify-between">
            <div>已选{{ currentTags && currentTags.length }}项</div>
            <el-link @click="clearTags" type="primary" :underline="false"
              >清空</el-link
            >
          </div>
          <el-scrollbar-v2
            ref="scrollContainer"
            :vertical="false"
            class="scroll-container padding-top-xs"
          >
            <div
              class="flex-center padding-bottom-xs justify-between"
              v-for="item in currentTags"
              :key="item.id"
            >
              <div class="text-black">{{ item.name }}</div>
              <div class="pointer padding-lr-xs" 
                v-if="!item.mode || (item.mode == 1 && !disableDynamicTag) || (item.mode == 2 && !disableStaticTag)" 
                @click="deleteTag(item)"
              >
                <i class="el-icon-error text-ccc"></i>
              </div>
            </div>
          </el-scrollbar-v2>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer padding-top-sm footer"
        style="padding-top: 10px"
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="signLabel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  getlabelManagerAll,
  createLabel,
  signLabel,
  markBatchLabel,
} from '@/api/label/label.js'
import Input from '@/components/input/index'

export default {
  components: { ElInput: Input },
  props: {
    selectedTags: {
      type: Array,
      default: () => {
        return []
      },
    },
    type: {
      type: [String, Number],
    },
    mode: {
      type: [String, Number],
      default: null,
    },
    id: {
      type: [String, Number, Array],
    },
    disableDynamicTag: {
      type: Boolean,
      default: false
    },
    disableStaticTag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      dynamicTags: [], // 动态标签列表
      staticTags: [], // 静态标签列表
      currentTags: [], // 当前选中标签
      activeMode: this.mode == 1 ? '1' : this.mode == 2 ? '2' : '2', // mode: 1动态标签 2静态标签
      dynamicSearchName: '',
      staticSearchName: '',
      ruleForm: {
        name: '',
      },
      rules: {
        name: [{ required: true, message: '请输入标签名称', trigger: 'blur' }],
      },
      loading: false,
      show: false,
    }
  },
  computed: {
    showDynamicTag() {
      return this.mode == 1 || !this.mode
    },
    showStaticTag() {
      return this.mode == 2 || !this.mode
    },
    viewTags() {
      return this.selectedTags && this.selectedTags.slice(0, 2)
    },
    showAddTag() {
      return this.$slots.addTag
    },
  },
  methods: {
    sure() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          const ids = typeof this.id == 'object' ? this.id : [this.id]
          createLabel({
            name: this.ruleForm.name,
            type: this.type,
            ids: ids,
            mode: 2,
          })
            .then(() => {
              this.initList()
              this.$message.success('创建成功')
              this.ruleForm.name = ''
              this.show = false
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    cancel() {
      this.show = false
      this.$refs['ruleForm'].resetFields()
    },
    onReset(mode) {
      if (mode == 1) {
        this.dynamicSearchName = ''
        this.getDynamicTags()
      } else {
        this.staticSearchName = ''
        this.getStaticTags()
      }
    },
    onSearch(mode) {
      mode == 1 ? this.getDynamicTags() : this.getStaticTags()
    },
    initList() {
      this.getDynamicTags()
      this.getStaticTags()
    },
    getDynamicTags() {
      this.getlabelManagerAll(1)
    },
    getStaticTags() {
      this.getlabelManagerAll(2)
    },
    getlabelManagerAll(mode) {
      let name = mode === 2 ? this.staticSearchName : this.dynamicSearchName
      if (!mode) {
        name = this.staticSearchName || this.dynamicSearchName
      }
      const formData = {
        type: this.type,
        name,
        mode: mode ?? this.mode,
      }
      getlabelManagerAll(formData).then((res) => {
        // mode: 1动态标签 2静态标签
        const list = res.data ?? []
        if (mode === 1) {
          this.dynamicTags = list
        } else {
          this.staticTags = list
        }
        this.correctCurrentTags(list)
      })
    },
    // 修正已选list
    correctCurrentTags(list) {
      this.currentTags.forEach(item => {
        const newItem = list.find(_ => _.id === item.id)
        if (newItem) {
          if (!item.mode) {
            this.$set(item, 'mode', newItem.mode)
          }
        }
      })
    },
    //单个删除
    deleteTag(item) {
      const index = this.currentTags.findIndex((v) => v.id == item.id)
      this.currentTags.splice(index, 1)
    },
    //清空
    clearTags() {
      if (this.disableDynamicTag) {
         this.currentTags = this.currentTags.filter(_ => _.mode === 1)
      } else if (this.disableStaticTag) {
        this.currentTags = this.currentTags.filter(_ => _.mode === 2)
      } else {
        this.currentTags = []
      }
    },
    signLabel() {
      if (!this.id) {
        // 如果不是给指定id设置标签,直接返回当前选中标签
        this.$emit('setCurrentTags', this.currentTags)
        this.$emit('update:selectedTags', this.currentTags)
        this.dialogVisible = false
        return
      }
      const ids = typeof this.id == 'object' ? this.id : [this.id]
      const index = typeof this.id == 'object' ? 1 : 0
      if (index == 1 && this.currentTags.length == 0) {
        // 列表全选打标签
        this.$message.error('请选择标签')
        return
      }
      const url = [signLabel, markBatchLabel][index]
      let labelIds = this.currentTags.map((v) => v.id)
      if (index == 0 && labelIds.length == 0) {
        labelIds = null
      }
      url({
        ids: ids,
        type: this.type,
        labelIds: labelIds,
      }).then((res) => {
        this.$message.success('保存成功')
        this.dialogVisible = false
        this.$emit('getList')
        this.$emit('setCurrentTags', this.currentTags)
        this.$emit('update:selectedTags', this.currentTags)
      })
    },
    addCurrentTags(tag, mode) {
      const { disableDynamicTag, disableStaticTag } = this
      if (disableDynamicTag && mode == 1 || disableStaticTag && mode == 2) {
        this.$message.warning(`${mode == 1 ? '动态' : '静态'}标签不能选择`)
        return
      }
      const index = this.currentTags.findIndex((item) => item.id == tag.id)
      if (index == -1) {
        this.currentTags.push(tag)
      } else {
        this.currentTags.splice(index, 1)
      }
    },
    open() {
      this.dynamicSearchName = ''
      this.staticSearchName = ''
      // this.getlabelManagerAll();
      this.initList()
      this.currentTags = _.cloneDeep(this.selectedTags) || []
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
    },
    //重置数据
    resetTagData() {
      this.currentTags = _.cloneDeep(this.selectedTags) || []
    },
  },
  created() {
    this.currentTags = _.cloneDeep(this.selectedTags) || []
    this.dynamicTags = _.cloneDeep(this.currentTags) || []
  },
}
</script>

<style lang="scss" scoped>
.scroll-container ::v-deep {
  // height: 49vh;
  max-height: 420px;

  .el-scrollbar__thumb {
    display: none;
  }
}

::v-deep .tagDialog {
  .el-tabs__nav-wrap::after {
    width: 0.5px !important;
    background: #eaeaea;
  }
  .el-tabs__item.is-left.is-active {
    background: #EDF2FF;
  }
  .el-dialog__body {
    padding: 0 !important;
  }
  .el-tabs__item {
    padding: 0 35px !important;
  }
}

.gap_sm {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px 10px;
}

.tags-item {
  background: #f9f9f9;
  height: 33px;
  line-height: 32px;
  min-width: 83px;
  text-align: center;
  border: 0 none;
  color: #999999;
  font-size: 14px;
  border: 0.5px solid transparent;
  margin-right: 8px;
  margin-bottom: 10px;
}

.el-tag--light {
  background: rgba(48, 126, 242, 0.1);
  color: #307ef2;
  border: 0.5px solid rgba(48, 126, 242, 0.2);
}

.footer {
  justify-content: flex-end !important;
}
</style>