var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("json-schema-popover", {
    ref: "popoverRef",
    attrs: { schema: _vm.schema, labelWidth: 80, width: "700" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }