<template>
  <div>
    <el-card shadow="never">
      <div class="flex">
        <el-scrollbar class="flex-twice solids-right">
          <div class="material-view" :class="[$route.name == 'createMessage' ? 'left-view-first ' : 'solids-none']">
            <h3 v-if="!isSopShow" style="margin-top: 0" class="solids-bottom text-bold padding-bottom-sm">
              {{ `编辑群${isNotice ? '公告' : '发'}` }}
            </h3>
            <el-form v-if="!isSopShow" ref="form" class="padding-top-xs" :rules="rules" :model="queryParams"
              @submit.native.prevent label-width="100px">
              <el-form-item :label="`${notice}名称`" class="inputvalue" label-position="left" style="width: 70%;"
                prop="name">
                <el-input v-model="queryParams.name" maxlength="50" show-word-limit
                  style="width: 240px;display: inline-block;" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item v-if="isNotice" :label="`${notice}内容`" label-position="left" prop="contentBack">
                <div style="position: relative">
                  <el-input resize="none" maxlength="1000" @input="setNoticeData" show-word-limit
                    placeholder="请输入公告内容..." size="medium" type="textarea" :autosize="{ minRows: 3, maxRows: 3 }"
                    v-model="queryParams.contentBack">
                  </el-input>
                  <select-emoji @addEmoji="addEmoji"></select-emoji>
                </div>
              </el-form-item>
              <el-form-item v-if="isNotice" class="resource" label="群发消息" label-position="left" prop="resource">
                <el-radio-group v-model="queryParams.resource">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <el-form ref="materialElementsForm" v-if="(queryParams.resource == 1 && isNotice) || !isNotice"
              :rules="rules" :model="queryParams" :label-position="materialElementsLength > 0 ? 'top' : 'left'"
              label-width="95px" @submit.native.prevent>
              <el-form-item :label="!isSopShow ? '发送内容' : ''" prop="materialElements">
                <material-detail :disabled="disabled" :hideCustom="hideCustom" :isSopShow="isSopShow" :class="{ 'margin-top-sm': materialElementsLength > 0 }"
                  :queryParams.sync="queryParams" @openMaterialDialog="openMaterialDialog" @valueRules="valueRules"
                  :imported="queryParams.imported" ref="materialDetail" :isNewAdd="isNewAdd"
                  :isRule="isRule"></material-detail>
              </el-form-item>
            </el-form>
          </div>
        </el-scrollbar>
        <div v-if="!isSopShow" class="flex-sub">
          <h3 style="margin-top: 0" class="text-bold padding-left-sm padding-bottom-sm">
            预览效果
          </h3>
          <preview-material :materialData="queryParams.materialElements" ref="previewMaterial"></preview-material>
        </div>
      </div>
    </el-card>
    <el-dialog title="引用素材" custom-class="import-dalog" top="10vh" close-on-click-modal append-to-body
      :visible.sync="dialogVisible" width="1220px">
      <div class="flex importDialog">
        <div class="left-tabs flex-sub" style="max-width: 180px">
          <el-tabs v-model="activeName" tab-position="left">
            <el-tab-pane label="素材包" name="1"></el-tab-pane>
            <el-tab-pane label="智能采集" name="2"></el-tab-pane>
            <el-tab-pane label="素材文件" name="3"></el-tab-pane>
          </el-tabs>
        </div>
        <div style="flex: 4;height: 590px;">
          <material v-if="activeName == '1'" ref="material" @useMaterial="setMaterialData" :isSelect="true" :isSopShow="isSopShow">
          </material>
          <material-auto v-if="activeName == '2'" ref="materialAuto" @useMaterial="setMaterialData"
            :isSelect="true"></material-auto>
          <material-file v-if="activeName == '3'" ref="materialFile"></material-file>
        </div>
      </div>
      <div slot="footer" class="flex-center justify-end">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" autofocus @click="useMiniProgram" v-if="activeName == '3'">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Material from "../../material/libraryMaterial/material.vue";
import materialDetail from "./materialDetail.vue";
import PreviewMaterial from "./previewMaterial.vue";
import { getMaterialDetail, getMaterialType } from "@/api/material/material.js";
import MaterialAuto from "../../material/automatic/materialAuto.vue";
import SelectEmoji from "../../../components/selectEmoji/selectEmoji.vue";
import { v4 as uuidv4 } from "uuid"
import MaterialFile from './materialFile.vue';

export default {
  components: {
    materialDetail,
    PreviewMaterial,
    Material,
    MaterialAuto,
    SelectEmoji,
    MaterialFile,
  },
  props: {
    isSopShow: {
      type: Boolean,
      default: false,
    },
    isRule: {
      type: Boolean,
      default: false,
    },
    isNewAdd: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: [Boolean, String],
      default: false
    },
    index: {
      type: Number
    },
    materialElements: {
      type: Array
    },
    hideCustom:{
      type: Boolean,
      default: false
    }
  },
  data() {
    const validateMaterialElements = (rule, value, callback) => {
      if (
        this.isNotice &&
        this.queryParams.materialElements.filter((v) => v.type != 8).length == 0
      ) {
        callback(new Error("请输入发送内容"));
      } else if (this.queryParams.materialElements.length == 0) {
        callback(new Error("请输入发送内容"));
      } else {
        callback();
      }
    };
    return {
      queryParams: {
        materialId: "", // 素材id
        materialElements: [],
        name: "",
        contentBack: '',
        materialTypeId: "", //素材类型
        resource: "2",
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: ["blur"] },
        ],
        materialTypeId: [
          { required: true, message: "请选择素材类型", trigger: ["blur"] },
        ],
        materialElements: [{ required: true, validator: validateMaterialElements, trigger: ["blur"] }],
        contentBack: [{ required: true, message: "请输入群公告内容", trigger: ["blur"] }],
      },
      dialogVisible: false,
      activeName: "1",
      materialType: [],
    };
  },
  computed: {
    isNotice() {
      return this.$route.query.sendType == 3; // 群公告
    },
    notice() {
      return this.isNotice ? "公告" : "群发";
    },
    materialElementsLength() {
      return this.queryParams.materialElements.filter((v) => v.type != 8).length;
    },
  },
  methods: {
    setMaterialData(data) {
      if (data?.id) {
        this.getMaterialDetail(data, "concat");
      } else {
        data = JSON.parse(JSON.stringify(data));
        const dataList = data.map(async (v, i) => {
          if (v.type == 10) { // 重置小程序id
            v.id = uuidv4();
          }
          if (this.queryParams.materialElements.findIndex((m) => m?.id === v?.id) == -1) {
            const item = v.ext || v.type == 10 ? v : await this.formatData(v); // 如果自动采集数据formatData
            return { ...item, id: item.id, index: i };
          }
        });
        Promise.all(dataList).then(res => {
          this.queryParams.materialElements = this.queryParams.materialElements.concat(res.sort(v => v.index));
          this.$emit("update:materialElements", this.queryParams.materialElements);
        })
      }
      this.dialogVisible = false;
    },
    sortMaterialData(data) {
      this.queryParams.materialElements = data;
    },
    // 获取素材详情
    getMaterialDetail(params, type) {
      params.id = type ? params.id : params.materialId;
      if (!params.id) return;
      getMaterialDetail({ id: params.id }).then((res) => {
        let taskName = params.taskName ? params.taskName : this.queryParams.taskName;
        let { id, materialElements, imported, materialTypeId, name, content } = res.data;
        materialElements = JSON.parse(materialElements ? materialElements : content);
        materialElements.map(v => {
          v.id = uuidv4();
          if (v.ext && typeof v.ext == "string") {
            v.ext = JSON.parse(v.ext);
            v = { v, ...v.ext };
          }
        })
        if (this.$route.query.sendType != 3 && materialElements.findIndex(v => v.type == 8) != -1) {
          this.$message.error("不可引用群公告素材"); // 客户群发/客群群发不能引用群公告素材
          return;
        }
        if (materialElements[0].type == '6') {
          materialElements[0].value = this.$route.query.type == 'edit' ? materialElements[0].value : JSON.parse(materialElements[0].value);
        }
        if (this.queryParams.materialTypeId || type == 'concat') { // 点击编辑详情素材名称类型不显示 
          const newNotice = materialElements.find(v => v.type == 8);
          if (newNotice) { // 如果引用群公告
            this.queryParams.materialElements[0] = newNotice;
            this.queryParams.contentBack = newNotice.contentBack;
            this.queryParams.text = newNotice.text;
          }
          materialElements = materialElements.filter(v => v.type != 8);
          this.queryParams.materialElements = this.queryParams.materialElements.concat(materialElements);
        } else {
          this.queryParams = { id, name, imported, materialTypeId, materialElements, taskName, resource, contentBack };
        }
      });
    },
    openMaterialDialog() {
      this.dialogVisible = true;
    },
    // 素材类型列表
    async getMaterialType() {
      const res = await getMaterialType();
      this.materialType = res.data;
    },
    valueRules() {
      this.$refs.materialElementsForm.validate((valid) => {

      })
    },
    addEmoji(data) {
      if (this.queryParams.contentBack.length < 1000) {
        this.queryParams.contentBack += data;
        const materialElements = JSON.parse(JSON.stringify(this.queryParams.materialElements));
        materialElements.map(v => {
          if (v.type == 8) {
            v.text += data;
            v.contentBack += data;
          }
        })
        this.queryParams.materialElements = materialElements
      } else {
        console.log("超出长度");
      }
    },
    // 格式化自动采集数据 & 转人工
    async formatData(data) {
      const { type, content, text } = data;
      let ext = {};
      if (data.type == 0) {
        return { type, contentBack: content, text: content, id: uuidv4(), ext: { process: 0 }, content };
      }
      if (data.type >= 1 && data.type != 7) {
        const fileName = new URL(content).pathname.split("/").at(-1);
        await this.$util.urlToFile(data.content, (file) => {
          file.raw = { type: '' };
          ext = { ...this.$util.getMaterialInfo(file), process: 0 };
        })
        return { fileUrl: content, fileName, ext, type, id: uuidv4(), process: 0, content };
      }
    },
    //设置群公告信息
    setNoticeData() {
      const noticeData = this.queryParams.materialElements.find(
        (v) => v.type == 8
      );
      if (!noticeData) {
        this.queryParams.materialElements.unshift({ type: 8, text: this.queryParams.contentBack, contentBack: this.queryParams.contentBack });
      } else {
        noticeData.text = this.queryParams.contentBack;
        noticeData.contentBack = this.queryParams.contentBack;
      }
    },
    //引入素材文件
    useMiniProgram() {
      const materialFileList = this.$refs.materialFile.materialFileList;
      materialFileList.map(v => {
        if (v) {
          this.setMaterialData(v.checkList);
          v.checkList = []; // 清空选中内容
        }
      })
    }
  },
  watch: {
    materialElements: {
      handler: function (val, oldVal) {
        this.queryParams.materialElements = this.materialElements ? this.materialElements : []; // 默认值 { type: 0, ext: { process: 0 }, text: "", contentBack: "" }
      },
      deep: true,
      immediate: true
    },
    queryParams: {
      handler: function (val, oldVal) {
        this.$emit("update", { queryParams: val });
        this.$emit("update:materialElements", this.queryParams.materialElements);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getMaterialType();
  },
};
</script>

<style lang="scss" scoped>
.material-view ::v-deep {
  height: calc(100vh - 300px);

  .el-form-item__label {
    color: #333;
  }

  .el-scrollbar__bar.is-vertical>div {
    display: none;
  }

  .fileList {
    max-height: initial !important;
  }
}

.flex ::v-deep {
  .flex-twice {
    .el-scrollbar__bar.is-vertical {
      display: none;
    }
  }

  .el-input .el-input__count .el-input__count-inner {
    background-color: transparent;
  }

  .el-form-item__label {
    display: flex;
  }

  .el-form--label-top .el-form-item__label {
    padding-bottom: 0;
  }

  .inputvalue .el-input__suffix {
    right: -50px;
  }

  .el-input__count {
    bottom: -40px;
  }

  .resource {
    padding-left: 10px;
  }

  .left-view-first {
    height: calc(100vh - 320px);
  }

  &.importDialog {
    .el-card__body {
      padding: 0;

      .el-card__body {
        padding-top: 20px;
      }
    }

    .el-tabs__header {
      width: 90%;
    }

    .left-tabs .el-tabs__item {
      height: 48px;
      text-align: center;
      line-height: 48px;
    }

    .el-tabs--left .el-tabs__header.is-left {
      margin-right: 20px;
    }
  }
}
</style>
