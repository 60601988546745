var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "selection-criteria" },
    [
      _c(
        "el-button",
        {
          attrs: { icon: "el-icon-circle-plus", type: "text" },
          on: { click: _vm.addCriteria },
        },
        [_vm._v("添加")]
      ),
      !_vm.taskInfo
        ? _c(
            "el-form",
            {
              ref: "stageFormFrist",
              attrs: {
                disabled: _vm.visible,
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
                "label-position": "left",
              },
            },
            [
              _vm.form.selectCriteriaList.length > 0
                ? _c(
                    "div",
                    { staticClass: "criteria-list" },
                    _vm._l(_vm.form.selectCriteriaList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "criteria-item-container" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: item.paramName,
                                prop: `selectCriteriaList.${index}.paramName`,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "flex justify-between" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "critecriteriaria-left" },
                                    [
                                      item.paramType == 6
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-input flex-center",
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: `selectCriteriaList.${index}.selectConditionType`,
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "请选择",
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "90px",
                                                        "margin-right": "12px",
                                                      },
                                                      model: {
                                                        value:
                                                          item.selectConditionType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "selectConditionType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.selectConditionType",
                                                      },
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.isNotList,
                                                        function (items) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: items.value,
                                                              attrs: {
                                                                label:
                                                                  items.label,
                                                                value:
                                                                  items.value,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      item.paramsType == 1 ||
                                                      (item.paramsType == 0 &&
                                                        item.id == 2)
                                                        ? _c("el-option", {
                                                            attrs: {
                                                              label: "为空",
                                                              value: "2",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                              item.selectConditionType != "2"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop: `selectCriteriaList.${index}.selectConditionValueList`,
                                                        rules: [
                                                          {
                                                            required: true,
                                                            message: "请选择",
                                                            trigger: "blur",
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    [
                                                      item.tag == 101
                                                        ? _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "220px",
                                                              },
                                                              attrs: {
                                                                filterable: "",
                                                                clearable: "",
                                                                remote: "",
                                                                "collapse-tags":
                                                                  "",
                                                                multiple: "",
                                                                "reserve-keyword":
                                                                  "",
                                                                placeholder:
                                                                  "请选择",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.selectConditionValueList,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "selectConditionValueList",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.selectConditionValueList",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.sexList,
                                                              function (itemm) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: itemm.label,
                                                                    attrs: {
                                                                      label:
                                                                        itemm.label,
                                                                      value:
                                                                        itemm.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        : _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "220px",
                                                              },
                                                              attrs: {
                                                                filterable: "",
                                                                clearable: "",
                                                                remote: "",
                                                                "collapse-tags":
                                                                  "",
                                                                multiple: "",
                                                                "reserve-keyword":
                                                                  "",
                                                                placeholder:
                                                                  "请选择",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.selectConditionValueList,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "selectConditionValueList",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.selectConditionValueList",
                                                              },
                                                            },
                                                            _vm._l(
                                                              JSON.parse(
                                                                item.value
                                                              ),
                                                              function (itemm) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: itemm,
                                                                    attrs: {
                                                                      label:
                                                                        itemm,
                                                                      value:
                                                                        itemm,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.paramType == 1
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-input flex-center",
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: `selectCriteriaList.${index}.selectConditionType`,
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "请选择",
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "90px",
                                                        "margin-right": "12px",
                                                      },
                                                      model: {
                                                        value:
                                                          item.selectConditionType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "selectConditionType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.selectConditionType",
                                                      },
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.isNotList,
                                                        function (items) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: items.value,
                                                              attrs: {
                                                                label:
                                                                  items.label,
                                                                value:
                                                                  items.value,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      item.paramsType == 1 ||
                                                      (item.paramsType == 0 &&
                                                        [1, 6, 8].includes(
                                                          item.id
                                                        ))
                                                        ? _c("el-option", {
                                                            attrs: {
                                                              label: "为空",
                                                              value: 2,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                              item.selectConditionType != "2"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop: `selectCriteriaList.${index}.selectConditionValueList`,
                                                        rules: [
                                                          {
                                                            required: true,
                                                            message: "请选择",
                                                            trigger: "blur",
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "selectOption",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.showDialogValue(
                                                                1,
                                                                item,
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("el-select", {
                                                            staticStyle: {
                                                              width: "220px",
                                                              height: "32px",
                                                              "pointer-events":
                                                                "none",
                                                            },
                                                            attrs: {
                                                              size: "small",
                                                              "collapse-tags":
                                                                "",
                                                              multiple: "",
                                                              "reserve-keyword":
                                                                "",
                                                              placeholder:
                                                                "请选择",
                                                            },
                                                            model: {
                                                              value:
                                                                item.selectConditionValueList,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "selectConditionValueList",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.selectConditionValueList",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.paramType == 2 || item.paramType == 7
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-input flex-center",
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: `selectCriteriaList.${index}.selectConditionType`,
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "请选择",
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "90px",
                                                        "margin-right": "12px",
                                                      },
                                                      model: {
                                                        value:
                                                          item.selectConditionType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "selectConditionType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.selectConditionType",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.isNotList,
                                                      function (items) {
                                                        return _c("el-option", {
                                                          key: items.value,
                                                          attrs: {
                                                            label: items.label,
                                                            value: items.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: `selectCriteriaList.${index}.selectConditionValueList`,
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "请选择",
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  item.value
                                                    ? _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "220px",
                                                          },
                                                          attrs: {
                                                            multiple: "",
                                                            filterable: "",
                                                            clearable: "",
                                                            remote: "",
                                                            "collapse-tags": "",
                                                            "reserve-keyword":
                                                              "",
                                                            placeholder:
                                                              "请选择",
                                                          },
                                                          model: {
                                                            value:
                                                              item.selectConditionValueList,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "selectConditionValueList",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.selectConditionValueList",
                                                          },
                                                        },
                                                        _vm._l(
                                                          JSON.parse(
                                                            item.value
                                                          ),
                                                          function (itemm) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: itemm,
                                                                attrs: {
                                                                  label: itemm,
                                                                  value: itemm,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.paramType == 3 || item.paramType == 4
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-input flex-center",
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: `selectCriteriaList.${index}.timeConditionType`,
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "请选择",
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "90px",
                                                        "margin-right": "12px",
                                                      },
                                                      attrs: {
                                                        "popper-class":
                                                          "notice-view",
                                                        placeholder: "请选择",
                                                      },
                                                      on: {
                                                        "visible-change":
                                                          function ($event) {
                                                            return _vm.setNotice(
                                                              _vm
                                                                .triggerConditeDataList[0]
                                                            )
                                                          },
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeTriggerData(
                                                            item
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          item.timeConditionType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "timeConditionType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.timeConditionType",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "notice-info padding-xs",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "title text-black",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.currentTitle
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-sm text-999 margin-top-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.currentNotice
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._l(
                                                        _vm.triggerConditeDataList,
                                                        function (item) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: item.triggerType,
                                                              attrs: {
                                                                label:
                                                                  item.triggerName,
                                                                value:
                                                                  item.triggerType,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  on: {
                                                                    mouseenter:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.setNotice(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.triggerName
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                              [1, 2, 10, 11].includes(
                                                item.timeConditionType
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-input flex-center",
                                                    },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: `selectCriteriaList.${index}.timeConditionIntValue`,
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请输入",
                                                                trigger: "blur",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "127px",
                                                            },
                                                            attrs: {
                                                              maxlength: "4",
                                                              placeholder:
                                                                "请输入",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.filterInput(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item.timeConditionIntValue,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "timeConditionIntValue",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.timeConditionIntValue",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: `selectCriteriaList.${index}.timeConditionCycleType`,
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请选择",
                                                                trigger: "blur",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "82px",
                                                                "margin-left":
                                                                  "10px",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请选择",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.timeConditionCycleType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "timeConditionCycleType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.timeConditionCycleType",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.triggerDate,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.triggerType,
                                                                    attrs: {
                                                                      label:
                                                                        item.triggerName,
                                                                      value:
                                                                        item.triggerType,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.timeConditionType == 3 ||
                                              item.timeConditionType == 4
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-input flex-center",
                                                    },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: `selectCriteriaList.${index}.timeConditionDateValue`,
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请选择",
                                                                trigger: "blur",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c("el-date-picker", {
                                                            staticStyle: {
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              format:
                                                                _vm.formatDataValue(
                                                                  item
                                                                ),
                                                              "value-format":
                                                                _vm.formatDataValue(
                                                                  item
                                                                ),
                                                              type: "datetime",
                                                              placeholder:
                                                                "选择日期时间",
                                                            },
                                                            model: {
                                                              value:
                                                                item.timeConditionDateValue,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "timeConditionDateValue",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.timeConditionDateValue",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.timeConditionType == 5
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-input flex-center",
                                                    },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: `selectCriteriaList.${index}.timeConditionDateStartValue`,
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请选择",
                                                                trigger: "blur",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c("el-date-picker", {
                                                            staticStyle: {
                                                              width: "180px",
                                                            },
                                                            attrs: {
                                                              format:
                                                                _vm.formatDataValue(
                                                                  item
                                                                ),
                                                              "value-format":
                                                                _vm.formatDataValue(
                                                                  item
                                                                ),
                                                              type: "date",
                                                              placeholder:
                                                                "开始时间",
                                                            },
                                                            model: {
                                                              value:
                                                                item.timeConditionDateStartValue,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "timeConditionDateStartValue",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.timeConditionDateStartValue",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "margin-lr-xs",
                                                        },
                                                        [_vm._v("至")]
                                                      ),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: `selectCriteriaList.${index}.timeConditionDateEndValue`,
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请选择",
                                                                trigger: "blur",
                                                              },
                                                              {
                                                                validator:
                                                                  _vm.validateDateTime,
                                                                trigger: [
                                                                  "blur",
                                                                  "change",
                                                                ],
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c("el-date-picker", {
                                                            staticStyle: {
                                                              width: "180px",
                                                            },
                                                            attrs: {
                                                              format:
                                                                _vm.formatDataValue(
                                                                  item
                                                                ),
                                                              "value-format":
                                                                _vm.formatDataValue(
                                                                  item
                                                                ),
                                                              type: "date",
                                                              placeholder:
                                                                "结束时间",
                                                            },
                                                            model: {
                                                              value:
                                                                item.timeConditionDateEndValue,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "timeConditionDateEndValue",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.timeConditionDateEndValue",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.paramType == 5
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-input flex-center",
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: `selectCriteriaList.${index}.numberConditionType`,
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "请选择",
                                                        trigger: [
                                                          "blur",
                                                          "change",
                                                        ],
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "90px",
                                                        "margin-right": "12px",
                                                      },
                                                      attrs: {
                                                        "popper-class":
                                                          "notice-view",
                                                        placeholder: "请选择",
                                                      },
                                                      on: {
                                                        "visible-change":
                                                          function ($event) {
                                                            return _vm.setNotice(
                                                              _vm
                                                                .triggerConditeNumberList[0]
                                                            )
                                                          },
                                                      },
                                                      model: {
                                                        value:
                                                          item.numberConditionType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "numberConditionType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.numberConditionType",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "notice-info padding-xs",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "title text-black",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.currentTitle
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-sm text-999 margin-top-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.currentNotice
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._l(
                                                        _vm.triggerConditeNumberList,
                                                        function (item) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: item.triggerType,
                                                              attrs: {
                                                                label:
                                                                  item.triggerName,
                                                                value:
                                                                  item.triggerType,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  on: {
                                                                    mouseenter:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.setNotice(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.triggerName
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                              [1, 2, 3, 4, 5, 6].includes(
                                                item.numberConditionType
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-input flex-center",
                                                    },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: `selectCriteriaList.${index}.numberConditionIntValue`,
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请输入",
                                                                trigger: "blur",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "127px",
                                                            },
                                                            attrs: {
                                                              "controls-position":
                                                                "right",
                                                              precision: 0,
                                                              placeholder:
                                                                "数值",
                                                              min: 1,
                                                              oninput:
                                                                "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                                                            },
                                                            model: {
                                                              value:
                                                                item.numberConditionIntValue,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "numberConditionIntValue",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.numberConditionIntValue",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.numberConditionType == 7
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-input flex-center",
                                                    },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: `selectCriteriaList.${index}.numberConditionIntStartValue`,
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请输入",
                                                                trigger: "blur",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "132px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                "数值",
                                                              min: "1",
                                                              maxlength: "8",
                                                              oninput:
                                                                "if(value){value=value.replace(/[^\\d]/g,'')}",
                                                            },
                                                            model: {
                                                              value:
                                                                item.numberConditionIntStartValue,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "numberConditionIntStartValue",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.numberConditionIntStartValue",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "margin-lr-xs",
                                                        },
                                                        [_vm._v("至")]
                                                      ),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: `selectCriteriaList.${index}.numberConditionIntEndValue`,
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请输入",
                                                                trigger: "blur",
                                                              },
                                                              {
                                                                validator:
                                                                  _vm.validateNumber,
                                                                trigger: [
                                                                  "blur",
                                                                  "change",
                                                                ],
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "132px",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                "数值",
                                                              min: "1",
                                                              maxlength: "8",
                                                              oninput:
                                                                "if(value){value=value.replace(/[^\\d]/g,'')}",
                                                            },
                                                            model: {
                                                              value:
                                                                item.numberConditionIntEndValue,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "numberConditionIntEndValue",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.numberConditionIntEndValue",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.paramType == 100 ||
                                      item.paramType == 102 ||
                                      item.paramType == 204
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-input flex-center",
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: `selectCriteriaList.${index}.selectConditionType`,
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "请输入",
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "90px",
                                                        "margin-right": "12px",
                                                      },
                                                      model: {
                                                        value:
                                                          item.selectConditionType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "selectConditionType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.selectConditionType",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.isNotList,
                                                      function (items) {
                                                        return _c("el-option", {
                                                          key: items.value,
                                                          attrs: {
                                                            label: items.label,
                                                            value: items.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: {
                                                    prop: `selectCriteriaList.${index}.cityValueList`,
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "请选择",
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c("el-cascader", {
                                                    ref: "cascaderArr",
                                                    refInFor: true,
                                                    staticStyle: {
                                                      "min-width": "220px",
                                                      "max-width": "300px",
                                                    },
                                                    attrs: {
                                                      "collapse-tags": "",
                                                      props: _vm.propsJson,
                                                      "popper-class":
                                                        "areaPoper",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.changeCity(
                                                          $event,
                                                          index
                                                        )
                                                      },
                                                      "visible-change":
                                                        _vm.areaVisibleChange,
                                                    },
                                                    model: {
                                                      value: item.cityValueList,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "cityValueList",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.cityValueList",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.paramType == 101
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "item-input flex-center",
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: `selectCriteriaList.${index}.equal`,
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "请输入",
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "90px",
                                                        "margin-right": "5px",
                                                      },
                                                      on: {
                                                        change: _vm.changeEqual,
                                                      },
                                                      model: {
                                                        value: item.equal,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "equal",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.equal",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.isNotList,
                                                      function (items) {
                                                        return _c("el-option", {
                                                          key: items.value,
                                                          attrs: {
                                                            label: items.label,
                                                            value: items.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop: `selectCriteriaList.${index}.labelList`,
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "请选择",
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "select-tag",
                                                    {
                                                      key: String(
                                                        item.labelList
                                                      ),
                                                      ref: `selectTag${index}`,
                                                      refInFor: true,
                                                      attrs: {
                                                        selectedTags:
                                                          item.labelList,
                                                        type: _vm.type,
                                                      },
                                                      on: {
                                                        setCurrentTags:
                                                          function ($event) {
                                                            return _vm.setCurrentTags(
                                                              arguments,
                                                              item
                                                            )
                                                          },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          attrs: {
                                                            slot: "addTag",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.$refs[
                                                                `selectTag${index}`
                                                              ][0].open()
                                                            },
                                                          },
                                                          slot: "addTag",
                                                        },
                                                        [
                                                          _c("el-select", {
                                                            staticStyle: {
                                                              "pointer-events":
                                                                "none",
                                                              width: "220px",
                                                            },
                                                            attrs: {
                                                              clearable: "",
                                                              "collapse-tags":
                                                                "",
                                                              multiple: "",
                                                              placeholder:
                                                                "请选择",
                                                            },
                                                            on: {
                                                              clear: function (
                                                                $event
                                                              ) {
                                                                return _vm.clearLabelList(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item.labelNameList,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "labelNameList",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.labelNameList",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-error pointer",
                                    staticStyle: {
                                      color: "#D3D2D2",
                                      width: "16px",
                                      height: "16px",
                                      "margin-top": "8px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeCriteria(index)
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-box",
          class: [{ taskInfo: _vm.taskInfo }],
          attrs: {
            title: "选择条件",
            width: "555px",
            "modal-append-to-body": false,
            "append-to-body": "",
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.stageType == 100 || _vm.stageType == 200
            ? _c(
                "div",
                [
                  _c("el-input", {
                    ref: "form",
                    staticClass: "criteria-input",
                    attrs: { placeholder: "请输入参数名称" },
                    on: { change: _vm.search },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "name",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.search },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh-right" },
                      on: { click: _vm.reset },
                    },
                    [_vm._v("重置")]
                  ),
                  _vm.selectCriteriaObjList.length >= _vm.maxlength
                    ? _c("div", { staticClass: "text-red margin-top-xs" }, [
                        _vm._v(
                          " * 为了更好的收集效果，单次收集任务字段数量不能超过" +
                            _vm._s(_vm.maxlength) +
                            "个 "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "criteria-content",
                      staticStyle: { margin: "0" },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: {
                            max:
                              _vm.maxlength -
                              _vm.form.selectCriteriaList.length,
                          },
                          model: {
                            value: _vm.selectCriteriaObjList,
                            callback: function ($$v) {
                              _vm.selectCriteriaObjList = $$v
                            },
                            expression: "selectCriteriaObjList",
                          },
                        },
                        _vm._l(_vm.criteriaList, function (item, index) {
                          return _c(
                            "el-checkbox",
                            {
                              key: index,
                              staticClass: "tags-item",
                              attrs: { label: item.id, border: "" },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.stageType == 3
            ? _c(
                "div",
                { staticClass: "criteria-content" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "用户意图", border: "" },
                      model: {
                        value: _vm.selectCriteriaObj,
                        callback: function ($$v) {
                          _vm.selectCriteriaObj = $$v
                        },
                        expression: "selectCriteriaObj",
                      },
                    },
                    [_vm._v("用户意图")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "flex justify-end",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.determineClick },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("select-stage-dialog", {
        ref: "selectDialog",
        attrs: {
          isRadio: _vm.isRadio,
          formData: {
            type: _vm.type,
            paramId: _vm.paramId,
            tag: _vm.tag,
            keyName: _vm.keyName,
            selectTitle: _vm.selectTitle,
            selectData: _vm.dialogItemValue,
            staffAccountId: "",
          },
          disabledSomeColumn: "",
        },
        on: { getCrowPeople: _vm.getCrowPeople },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }