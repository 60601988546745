<template>
  <div class="contailner">
    <!-- table部分 -->
    <el-card shadow="never">
      <!-- 新建 -->
      <!-- table标签切换 -->
      <div class="top-tabs">
        <el-tabs v-model.trim="queryParams.type" @tab-click="handleClick">
          <el-tab-pane label="客户群" name="2"></el-tab-pane>
          <el-tab-pane label="客户" name="1"></el-tab-pane>
        </el-tabs>
      </div>
      <!-- 搜索部分 -->
      <el-card shadow="never">
        <el-form :model="queryParams" label-width="auto" ref="queryParams" class="search-form">
          <el-form-item label="采集名称：" prop="name">
            <el-input @change="getList('inquire')" v-model.trim="queryParams.name" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="状态：" label-width="70px" prop="status">
            <el-select v-model.trim="queryParams.status" @change="getList('inquire')" placeholder="请选择（启用/停用）"
              clearable>
              <el-option v-for="item in this.getDictDatas(DICT_TYPE.ROBOT_MATERIALAUTO_STATUS)" :key="item.value"
                :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="AI员工" prop="staffAccountId">
            <el-select v-model.trim="queryParams.staffAccountId" placeholder="请选择" clearable>
              <el-option v-for="item in staffList" :key="item.id" :label="item.nickname" :value="item.id">
                <div class="wechat-all" style="width: 340px;">
                  <div class="wechat-id">{{ item.nickname }}
                    ( {{ item.externalOpenId }} )</div>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型" prop="accountType">
            <el-select clearable collapse-tags v-model="queryParams.accountType">
              <el-option v-for="item in getDictDatas(DICT_TYPE.ROBOT_CUSTOMER_TPYE)" :key="item.value"
                :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- 查询按钮 -->
          <el-form-item style="margin-bottom:0">
            <el-button @click="getList('inquire')" v-hasPermi="['system:materialAuto:query']" type="primary"
              icon="el-icon-search">查询</el-button>
            <el-button @click="resetForm()" icon="el-icon-refresh-right">重置</el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <div class="dropdown margin-bottom-xs flex-center justify-end" v-if="!isSelect">
        <el-button @click="handleEdit" v-hasPermi="['system:materialAuto:create']" style="padding: 9px 15px;"
          type="primary">
          新建采集
        </el-button>
        <el-dropdown class="margin-left-xs" @command="handleCommand">
          <el-button class="el-dropdown-link dropdown-button">
            批量操作 <i class="el-icon-caret-bottom"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0">批量开启</el-dropdown-item>
            <el-dropdown-item command="1">批量关闭</el-dropdown-item>
            <el-dropdown-item v-hasPermi="['system:materialAuto:delete']" command="2">批量删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-table @selection-change="selectChange" :row-key="getRowKeys" v-loading="loading" :data="list"
        height="calc(100vh - 390px)" ref="table">
        <el-table-column align="left" type="selection" :reserve-selection="true" width="55" />
        <el-table-column label="序号" type="index" width="55" />
        <el-table-column label="采集名称" show-overflow-tooltip prop="name" />
        <el-table-column show-overflow-tooltip width="150" :label="queryParams.type == 1 ? '被采集客户名称' : '被采集客群名称'"
          prop="collectWxName">
          <template slot-scope="scope">
            <div>
              {{ scope.row.collectWxName ? scope.row.collectWxName : "无群名称" }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column :label="queryParams.type == 0 ? '被采集群ID' : '被采集客户ID'" prop="collectWxId" /> -->
        <el-table-column label="AI员工" width="150">
          <template v-slot="scope">
            <div>{{ scope.row.aiStaffName }}</div>
            <channelType :type="DICT_TYPE.ROBOT_CHANNEL_TYPE" :value="scope.row.accountType"></channelType>
          </template>
        </el-table-column>
        <el-table-column label="社交账号" width="200">
          <template v-slot="scope">
            <el-popover trigger="hover" placement="top">
              <div class="flex-center">
                <wechatImg class="margin-right-xs" :type="scope.row.accountType"></wechatImg>
                <div class="wechat-state">{{ scope.row.fromWxName }}</div>
                <dict-tag class="margin-left-xs" :showTag="false" :type="DICT_TYPE.ROBOT_ACCOUNT_STATE"
                  :value="scope.row.wechatState" />
              </div>
              <!-- <div style="margin-left: 27px;">{{ scope.row.fromWxId }}</div> -->
              <div style="margin-left: 27px;">{{ scope.row.wechatNo }}</div>
              <div slot="reference" class="name-wrapper">
                <div class="flex-center">
                  <wechatImg class="margin-right-xs" :type="scope.row.accountType"></wechatImg>
                  <div class="wechat-state">{{ scope.row.fromWxName }}</div>
                  <dict-tag class="margin-left-xs" :showTag="false" :type="DICT_TYPE.ROBOT_ACCOUNT_STATE"
                    :value="scope.row.wechatState" />
                </div>
                <!-- <div class="text-cut" style="margin-left: 27px;">{{ scope.row.fromWxId }}</div> -->
                <div class="text-cut" style="margin-left: 27px;">{{ scope.row.wechatNo }}</div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip width="100" label="编辑时间" v-if="!isSelect">
          <template v-slot="scope">
            <span>{{ parseTime(scope.row.updateTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="使用状态" v-if="!isSelect">
          <template v-slot="scope">
            <dict-tag :type="DICT_TYPE.ROBOT_MATERIALAUTO_STATUS" :value="scope.row.status" />
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="left" class-name="small-padding fixed-width" width="260">
          <template v-slot="scope">
            <el-button type="text" @click="handleSwitch(scope.row, 'switch')" v-if="!isSelect">
              {{ scope.row.status === 1 ? '停用' : '启用' }}</el-button>
            <el-button type="text" v-hasPermi="['system:materialAuto:edit']" @click="handleEdit(scope.row)"
              v-if="!isSelect"> 编辑 </el-button>
            <el-button type="text" @click="handleSee(scope.row)" v-if="!isSelect"> 查看 </el-button>
            <el-button type="text" @click="handleUse(scope.row)" v-if="isSelect"> 引用 </el-button>
            <el-button type="text" v-hasPermi="['system:materialAuto:delete']"
              @click="handleSwitch(scope.row, 'delete')" style="color:#F03308;" v-if="!isSelect"> 删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize"
        @pagination="getList" />
    </el-card>
    <!-- 查看按钮的弹窗 -->
    <DialogSee ref="dialogSee" @getData='getData' />
  </div>
</template>

<script>
import {
  pageMaterialAuto,
  openMaterialAuto,
  closeMaterialAuto,
  deleteMaterialAuto,
} from "@/api/material/material";
import DialogSee from "@/views/material/components/record";
import { getWechatList } from "@/api/robot/robot.js";
export default {
  name: "materialAuto",
  components: {
    DialogSee,
  },
  props: {
    isSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /** table数据 */
      list: [],
      /** table页数 */
      total: 0,
      /** table加载过渡 */
      loading: true,
      /** 查询数据 */
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        name: "",
        status: "",
        type: "2",
        staffAccountId: "",
        accountType: ""
      },
      /** 多选的数组 */
      selectData: {},
      arrayData: {
        0: [],
        1: [],
      },
      staffList: []
    };
  },
  activated() {
    this.getList();
    this.getWechatList();
  },
  created() {
    this.getList();
    this.getWechatList();
  },
  methods: {
    /** 查询列表 */
    async getList(dom) {
      if (dom === "inquire") {
        this.queryParams.pageNo = 1;
      }
      try {
        this.loading = true;
        const res = await pageMaterialAuto(this.queryParams);
        this.total = res.data.total;
        this.list = res.data.list;
      } finally {
        this.loading = false;
      }
    },
    // 勾选select
    selectChange(selection) {
      let { type } = this.queryParams;
      Number(type) === 0
        ? this.dispose(type, 0, 1, selection)
        : this.dispose(type, 1, 0, selection);
    },
    dispose(type, atData, otherData, selection) {
      if (this.arrayData[otherData].length === 0) {
        this.arrayData[atData] = JSON.parse(JSON.stringify(selection));
      } else {
        let ids = this.arrayData[otherData].map((item) => item.id);
        this.arrayData[atData] = selection.filter(
          (item) => !ids.includes(item.id)
        );
      }
      this.selectData[type] = this.arrayData[atData];
    },
    /** table表格多选 -- key */
    getRowKeys(row) {
      return row.id;
    },
    /** table表格多选 -- 清除选中 */
    clearSelection() {
      this.$refs.table.clearSelection();
    },
    /**修改 */
    handleUpdate(row) { },
    /**tab切换*/
    handleClick(tab, event) {
      this.getList("inquire");
    },
    // 重置查询表单
    resetForm() {
      this.$refs.queryParams.resetFields();
      this.getList("inquire");
    },
    /**跳转新建标签页*/
    handleEdit({ id }) {
      this.$util.redirectTo("newBuild", {
        id: id === void 0 ? "" : id,
        type: this.queryParams.type,
      });
    },
    /**选择开启关闭删除 - 批量*/
    async handleCommand(command) {
      let selectList = this.selectData[this.queryParams.type] || [];
      if (selectList.length === 0) {
        this.$modal.msgError("你还未勾选采集素材！");
        return;
      }
      let ids = selectList.map((v) => v.id).join(",");
      let apiArray = [
        { msg: "开启", api: openMaterialAuto },
        { msg: "关闭", api: closeMaterialAuto },
        { msg: "删除?", api: deleteMaterialAuto },
      ];
      let url = apiArray[command].api;
      this.$modal
        .confirm(`是否确认批量${apiArray[command].msg}勾选的采集素材？`)
        .then(async () => {
          const res = await url({ ids });
          this.$refs.table.clearSelection();
          this.getList();
        })
        .catch(() => { });
    },
    /**选择开启关闭 - 单个  1启用 0停用*/
    handleSwitch({ status, id, name }, type) {
      let url = "";
      let msg = "";
      let tip = "";
      if (type === "switch") {
        url = status === 0 ? openMaterialAuto : closeMaterialAuto;
        msg = `是否确认${status === 0 ? "开启" : "停用"
          }采集名称为${name}的数据?`;
        tip = status === 0 ? "开启" : "停用";
      } else {
        url = deleteMaterialAuto;
        msg = `是否确认删除采集名称为${name}的数据?`;
        tip = "删除";
      }
      this.$modal
        .confirm(msg)
        .then(async () => {
          const res = await url({ ids: id });
          this.getList();
          this.$message.success(tip + "成功");
        })
        .catch(() => { });
    },
    handleSee(row) {
      this.$refs.dialogSee.open(row, false);
    },
    // 点击使用
    handleUse(row) {
      this.$refs.dialogSee.open(row, true);
    },
    //弹窗 子组件传递的参数
    getData(data) {
      data.map(v => {
        v = Object.assign(v, v.content)
        v.edit = false;
      })
      console.log(data);
      this.$emit("useMaterial", data);
    },
    getWechatList() {
      getWechatList().then((res) => {
        this.staffList = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contailner ::v-deep {
  .el-dialog__body {
    padding: 16px 24px;
    overflow: hidden;
  }
}
</style>