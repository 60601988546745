<template>
    <div class="send-notice margin-bottom-sm">
        <el-collapse v-model="activeNames">
            <el-collapse-item :name="1">
                <template slot="title">
                    <i class="header-icon el-icon-message-path"></i>
                    群公告
                    <span class="expand">{{ activeNames.length > 0 ? '收起' : '展开' }}</span>
                </template>
                <el-form class="send-form" ref="ruleForm" :disabled="visible" :rules="rules" :model="taskNoticeForm"
                    label-width="110px" label-position="left">
                    <el-form-item label="发送方式" prop="name">
                        <el-radio-group v-model="taskNoticeForm.sendType">
                            <el-radio :label="1">立即发送</el-radio>
                            <el-radio :label="2">定时发送</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <!-- <el-form-item label="发送时间" prop="name">
                        <el-radio-group v-model="taskNoticeForm.radio">
                            <el-radio :label="3">按天发送</el-radio>
                            <el-radio :label="6">指定日期</el-radio>
                        </el-radio-group>
                    </el-form-item> -->
                </el-form>
                <div class="add-btn" v-if="taskNoticeForm.sendType == 2" @click="addTask">新建</div>
                <div class="add-main" v-if="taskNoticeForm.sendNoticeReqVOList?.length > 0 && taskNoticeForm.sendType == 2">
                    <el-collapse v-model="activeItemNames">
                        <div class="add-item" v-for="(item, index) in taskNoticeForm.sendNoticeReqVOList" :key="index">
                            <el-collapse-item :name="index"
                                :class="[taskNoticeForm.sendType == 1 ? 'add-item-container' : '']">
                                <template slot="title">
                                    <div @click.stop="stopHaneldle">
                                        <i class="el-icon-remove" style="color: #D3D2D2;" @click="delectItem(index)"></i>
                                        <span class="title-text">进入后, &nbsp;第</span>
                                        <el-input :disabled="visible" style="width: 60px;" type="number"
                                            v-model="item.day" />
                                        <span class="title-text">天,</span>
                                        <el-time-picker v-model="item.sendTime" :disabled="visible"
                                            :value-format="dataFormat" placeholder="请选择开始时间">
                                        </el-time-picker>
                                        <span class="title-text">发送</span>
                                    </div>
                                    <span class="expand">{{ activeItemNames.length > 0 ? '收起' : '展开' }}</span>
                                </template>
                                <div class="item_notice">
                                    <el-form :rules="rules" ref="ruleForm" :disabled="visible" :model="taskNoticeForm" label-width="110px"
                                        label-position="left">
                                        <el-form-item label="公告内容" label-position="left" prop="text">
                                            <div style="position: relative" v-for="items in item.materialElements"
                                                :key="items.id">
                                                <el-input resize="none" maxlength="1000" @change="setNoticeData"
                                                    show-word-limit placeholder="请输入公告内容..." size="medium" type="textarea"
                                                    :autosize="{ minRows: 3, maxRows: 3 }" v-model="items.text">
                                                </el-input>
                                                <select-emoji @addEmoji="addEmoji($event, index)"></select-emoji>
                                            </div>
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </el-collapse-item>
                        </div>
                    </el-collapse>
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import editMaterial from "@/views/robot/components/editMaterial.vue";
import SelectEmoji from "../../../components/selectEmoji/selectEmoji.vue";
export default {
    components: { editMaterial, SelectEmoji },
    props: {
        visible: {
            type: [Boolean, String],
            default: false
        }
    },
    data() {
        return {
            activeNames: [1],
            activeItemNames: [0],
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    }
                ],
                text: [
                    {
                        required: true,
                        message: '请输入内容',
                        trigger: 'blur'
                    }
                ]
            },
            taskNoticeForm: {
                actionType: 4,
                sendType: 1,
                sendNoticeReqVOList: [{
                    materialElements: [{
                        type: 8,
                        text: ''
                    }]
                }]
            },
            dataFormat: 'HH:mm:ss',
            queryParams: {
                content: [],
                imported: ''
            },
        }
    },
    computed: {
        isGroup() {
            return this.$route.query.sopType == 'clientSOP' ? false : true;
        }
    },
    watch: {
        taskNoticeForm: {
            handler: function (val) {
                this.$emit('taskNotice', this.taskNoticeForm)
            },
            deep: true
        }
    },
    methods: {
        setNoticeData() {
            console.log(this.taskNoticeForm.sendNoticeReqVOList, 'this.taskNoticeForm.sendNoticeReqVOList')
        },
        addEmoji(data, index) {
            this.$nextTick(() => {
                this.taskNoticeForm.sendNoticeReqVOList[index].materialElements[0].text += data;
            });
        },
        stopHaneldle() {
        },
        delectItem(index) {
            this.taskNoticeForm.sendNoticeReqVOList.splice(index, 1)
        },
        addTask() {
            this.taskNoticeForm.sendNoticeReqVOList.push({
                name: '任务名称',
                materialElements: [{
                    type: 8,
                    text: ''
                }]
            })
        },
        // 回显taskNoticeForm数据
        setTaskNoticeForm(data) {
            if (!data) return;
            this.taskNoticeForm.sendNoticeReqVOList = data.sendNoticeReqVOList;
            this.taskNoticeForm.sendType = data.sendType;
            // this.taskNoticeForm.sendNoticeReqVOList.map((v, i) => {
            //     this.$refs[`editMaterialNotice${i}`][0].queryParams.materialElements = v.materialElements;
            // })
        }
    }
}
</script>
<style lang="scss" scoped>
.send-notice ::v-deep {
    .send-form {
        padding: 16px 20px 40px 40px !important;

    }

    .el-icon-message-path {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        background: url(../../../assets/images/messagePath.png) center center no-repeat;
        background-size: contain;
    }


    .el-collapse-item__wrap,
    .el-collapse,
    .el-collapse-item__header {
        border-bottom: none;
        border-top: none;
    }

    .el-collapse-item__header {
        padding: 0 24px 0 40px;
        height: 40px;
        opacity: 1;
        line-height: 40px;
        background: #EFEFFA;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        position: relative;
    }

    .el-icon-arrow-right {
        color: #307EF2;
    }

    .item_notice {
        .el-form-item {
            margin-bottom: 0;
        }
    }

    .expand {
        position: absolute;
        font-size: 14px;
        font-weight: normal;
        color: #307EF2;
        right: 50px;
    }

    .add-main {
        margin: 16px 24px 30px 40px;
        border-radius: 2px;
        background: #F9F9F9;
        padding: 13px 16px 29px 16px;

        .add-item {
            margin-bottom: 16px;

            .el-form {
                padding: 0;
            }
        }

        .add-item-container {
            .el-collapse-item__header {
                display: none;
            }
        }

        .title-text {
            display: inline-block;
            font-size: 14px;
            font-weight: normal;
            color: #333333;
            margin: 0 10px;
        }

        .el-collapse-item__content {
            padding-bottom: 0;
            background-color: #F9F9F9;
        }

        .add-line {
            margin-bottom: 16px;
            border-bottom: 1px dashed #7F8EB1;
            border-spacing: 10px;

        }

        .el-collapse-item__header {
            padding: 0 3px 0 16px;
            height: 55px;
            opacity: 1;
            line-height: 55px;
            background: #F9F9F9;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            position: relative;
        }

        .expand {
            position: absolute;
            font-size: 14px;
            font-weight: normal;
            color: #307EF2;
            right: 27px;
        }
    }

    .add-btn {
        display: inline-block;
        margin-left: 40px;
        width: 132px;
        height: 32px;
        border-radius: 2px;
        opacity: 1;
        background: #6090F9;
        font-size: 14px;
        font-weight: normal;
        color: #FFFFFF;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
    }
}
</style>