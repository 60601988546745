var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { "has-logo": _vm.showLogo },
      style: {
        background:
          _vm.settings.sideTheme === "theme-dark"
            ? _vm.variables.menuBackground
            : _vm.variables.menuLightBackground,
      },
    },
    [
      _vm.showLogo
        ? _c("logo", {
            staticStyle: { "margin-bottom": "8px" },
            attrs: { collapse: _vm.isCollapse },
          })
        : _vm._e(),
      _c(
        "el-scrollbar",
        {
          class: _vm.settings.sideTheme,
          attrs: { "wrap-class": "scrollbar-wrapper" },
        },
        [
          !_vm.isCollapse && _vm.showChatMain
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "margin-bottom": "16px",
                  },
                },
                [
                  _c("cs-switch", {
                    attrs: {
                      "active-text": "聚合聊天",
                      "inactive-text": "SCRM",
                    },
                    on: { change: _vm.handleSwitch },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-menu",
            {
              key: _vm.sidebar.opened,
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "default-openeds": _vm.open,
                "text-color":
                  _vm.settings.sideTheme === "theme-dark"
                    ? _vm.variables.menuColor
                    : _vm.variables.menuLightColor,
                "unique-opened": false,
                "active-text-color": _vm.settings.theme,
                "collapse-transition": false,
                mode: "vertical",
              },
            },
            _vm._l(_vm.sidebarRouters, function (route, index) {
              return _c("sidebar-item", {
                key: route.path + index,
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }