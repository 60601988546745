<template>
    <span>
        <template v-for="(dict, index) in this.getDictDatas2(type, value)">
            <!-- 默认样式 -->
            <span :key="index" v-if="dict.cssClass" :class="[dict.cssClass]">
                @{{ dict.label }}
            </span>
        </template>
    </span>
</template>
  
<script>
export default {
    name: "channelType",
    props: {
        type: String,
        value: [Number, String, Boolean, Array],
    },
};
</script>
<style scoped>
.el-tag+.el-tag {
    margin-left: 10px;
}

.icon-circle {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
}
</style>
  