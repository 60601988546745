<template>
  <div :class="{ 'has-logo': showLogo }" :style="{
    background:
      settings.sideTheme === 'theme-dark'
        ? variables.menuBackground
        : variables.menuLightBackground,
  }">
    <logo style="margin-bottom: 8px" v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar :class="settings.sideTheme" wrap-class="scrollbar-wrapper">
      <div v-if="!isCollapse && showChatMain" style="display: flex; justify-content: center; margin-bottom: 16px">
        <cs-switch v-model="value" active-text="聚合聊天" inactive-text="SCRM" @change="handleSwitch" />
      </div>
      <el-menu :key="sidebar.opened" :default-active="activeMenu" :collapse="isCollapse" :default-openeds="open"
        :text-color="settings.sideTheme === 'theme-dark'
      ? variables.menuColor
      : variables.menuLightColor
    " :unique-opened="false" :active-text-color="settings.theme" :collapse-transition="false" mode="vertical">
        <!-- 根据 sidebarRouters 路由，生成菜单 -->
        <sidebar-item v-for="(route, index) in sidebarRouters" :key="route.path + index" :item="route"
          :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import variables from "@/assets/styles/variables.scss";
import _ from "lodash";
import Switch from "./Switch.vue";

export default {
  components: { SidebarItem, Logo, CsSwitch: Switch },
  data() {
    return {
      open: [], // 默认展开菜单
      value: false,
    };
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["sidebarRouters", "sidebar", "showChatMain"]),
    activeMenu() {
      this.open = _.uniq([
        ...this.sidebarRouters.map((v) => v.path),
        "/system/tenant/tenant",
      ]);
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
    isWechatRoute() {
      const { $route } = this;
      return $route.name === "wechatMain";
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.isWechatRoute) {
          this.$store.dispatch("app/sideBarClose");
          this.value = true;
        } else {
          this.value = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSwitch() {
      if (this.value) {
        this.$router.push({ name: "wechatMain" });
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
