var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._b(
      {
        ref: "cellGroupRef",
        class: ["mb-collapse", _vm.transition ? "transition" : ""],
        style: [_vm.isCollapse ? { overflow: "hidden" } : {}],
        attrs: { inset: "" },
      },
      "div",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "div",
        { ref: "contentRef", staticClass: "mb-collapse__content" },
        [
          _vm._t("default"),
          _vm.showTrigger
            ? _c(
                "div",
                {
                  ref: "triggerRef",
                  staticClass: "mb-collapse__trigger-wrapper",
                  class: !_vm.isExpand ? "mb-collapse__button--expand" : "",
                  attrs: { center: "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCollapse()
                    },
                  },
                },
                [
                  _vm._t(
                    "trigger",
                    function () {
                      return [
                        _c("div", { staticClass: "mb-collapse__button" }, [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(!_vm.isExpand ? "查看更多" : "收起") +
                                " "
                            ),
                          ]),
                          _c("i", {
                            class: _vm.isExpand
                              ? "el-icon-arrow-up"
                              : "el-icon-arrow-down",
                            style: {
                              fontSize: "16px",
                            },
                          }),
                        ]),
                      ]
                    },
                    null,
                    { isCollapse: _vm.isCollapse }
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }