var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "importCustom padding-left-xs" },
    [
      _c(
        "el-link",
        {
          attrs: { underline: false, icon: "el-icon-circle-plus" },
          on: { click: _vm.openDialog },
        },
        [_vm._v("引用自定义参数")]
      ),
      _c("custom-dialog", {
        ref: "customDialog",
        attrs: {
          paramsType: 1,
          dataModule: 1,
          isSop: _vm.isSopShow,
          stageType: 100,
          title: "引用自定义参数",
        },
        on: { command: _vm.command },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }