<template>
  <div class="editor">
    <slot name="header"></slot>
    <textarea :id="tinymceId" style="visibility: hidden" />
    <slot name="footer"></slot>
    <div class="flex-center justify-between">
      <div @click="clickEmoji"><select-emoji @addEmoji="addEmoji" v-if="selectEmoji"></select-emoji></div>
      <div v-if="linmitNum" class="wordCount bg-white">{{ wordCount }} / {{ linmitNum }}</div>
    </div>
    <el-dialog :show-close="false" top="40vh" width="300px" append-to-body custom-class="upload-loading"
      :close-on-press-escape="false" :close-on-click-modal="false" :visible.sync="dialogVisible">
      <div class="text-center text-lg margin-bottom-xs"><i class="el-icon-loading"></i></div>
      <div class="text-center">共 {{ toBeSent }} 个 成功 {{ sendNumber }} 个 失败 {{ failNumber }} 个</div>
    </el-dialog>
  </div>
</template>

<script>
import loadTinymce from "@/utils/loadTinymce";
import { plugins, toolbar, toolbar1 } from "./config";
import { debounce } from "throttle-debounce";
import { uploadFile } from "@/api/index.js";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid"
import { html2Text } from "@/utils/index.js";
import selectEmoji from '../selectEmoji/selectEmoji.vue';
let num = 1;

export default {
  components: { selectEmoji },
  props: {
    id: {
      type: String,
      default: () => {
        num === 10000 && (num = 1);
        return `tinymce${+new Date()}${num++}`;
      },
    },
    value: {
      default: "",
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      default: '示例:<strong class="mceNonEditable editor-tag" contenteditable="false">xx客户</strong><strong class="mceNonEditable editor-tag" contenteditable="false">性别</strong>您好!'
    },
    linmitNum: {
      default: false,
    },
    height: {
      default: 500,
    },
    selectEmoji: {
      type: Boolean,
      default: false,
    },
    atWho: {
      type: Boolean,
      default: false,
    },
    atWhoQueryCallback: {
      type: Function,
    }
  },
  data() {
    return {
      tinymceId: this.id,
      wordCount: "0",
      toBeSent: 0, // 待发送数量
      sendNumber: 0, // 已发送数量
      failNumber: 0, // 失败数量
      dialogVisible: false,
      content: ''
    };
  },
  watch: {
    height(newHeight) {
      const { editor } = this
      if (editor) {
        editor.getContainer().style.height = newHeight + 'px'
      }
    }
  },
  mounted() {
    loadTinymce((tinymce) => {
      // eslint-disable-next-line global-require
      require("./zh_CN");
      require("./plugins/ax_wordlimit/plugin");
      require("./plugins/attachment/plugin.min");
      require("./plugins/atwho/plugin");
      let conf = {
        selector: `#${this.tinymceId}`,
        language: "zh_CN",
        menubar: false,
        plugins,
        toolbar: !this.textarea ? toolbar1 : "",
        resize: !this.textarea,
        statusbar: !this.textarea,
        height: this.height,
        min_height: this.height,
        max_height: this.height || 240,
        paste_auto_cleanup_on_paste: this.textarea,
        paste_remove_styles: this.textarea,
        paste_remove_styles_if_webkit: this.textarea,
        paste_strip_class_attributes: this.textarea,
        paste_as_text: this.textarea,
        branding: false,
        object_resizing: false,
        images_upload_url:
          process.env.VUE_APP_BASE_API + "/admin-api/infra/file/upload",
        end_container_on_empty_block: true,
        powerpaste_word_import: "clean",
        ax_wordlimit_num: this.linmitNum ? this.linmitNum : 'false',
        wordlimit: {
          spaces: true,
        },
        paste_data_images: !this.textarea,
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: "square",
        advlist_number_styles: "default",
        default_link_target: "_blank",
        link_title: false,
        nonbreaking_force_tab: true,
        attachment_assets_path: "/attachment_assets/icons",
        content_css: "tinymce/skins/content/snow/content.css",
        ax_wordlimit_callback: (editor, txt, num) => {
          // txt = txt.substring(0, this.linmitNum || 300);
          // editor.setContent(txt);
          // this.$message.error("当前字数：" + txt.length + "，限制字数：" + num);
        },
        // setup: function (ed) {
        //   ed.on('keydown', function (e) {
        //     if (!e.ctrlKey && e.keyCode == 13) {
        //       e.preventDefault();
        //     }
        //   });
        // },
        loading: null,
        content_style: `.editor-tag {
          background-color: #E8EDFB;
          color:#1C53D9;
          font-size: 12px;
          border: 0 none;
          display: inline-block;
          margin: 2px 5px;
          padding: 1px 5px;
        }
        .attachment {
          cursor: pointer !important;
          width: 90%;
          margin: 10px auto;
          padding: 20px;
          border-radius: 2px;
          background: #F6F6FA;
          display: flex !important;
          align-items: center;
          color: #333;
          font-size: 14px;
          text-decoration: none !important;
          vertical-align: middle;
        }
        .attachment em{
         font-style: inherit;
        }
        .upload_error {
            background: #FFE5E0;
            border: 1px solid #EA644A;
        }
        .attachment > img {
            width: 55px;
            vertical-align: middle;
            padding-right:4px;
        }
        .attachment > a {
            color: #333;
            font-size: 14px;
            text-decoration: none;
            vertical-align: middle;
        }
        .attachment > div{
          font-size: 12px;
          color: #999;
          margin-top: 15px;
        }
        .attachment > span {
            vertical-align: middle;
            padding-right:4px;
          } 
        .file-item{
          display: block;
          margin-top: 10px;
        }
        body{
          font-family: 'pingfang Sc';
          font-size: 14px;
          // margin: 0 10px;
          color: #333;
        }
        p{
          margin: 0px;
          line-height: 18px;
        }
        img{
          max-width: 100%;
        }
        .mce-object-video{
          width: 100%;
          height: 225px;
        }
        .mce-object-video video{
          width: 100%;
          height: 100%;
        }
        .mce-content-body,.mce-object-video{
          cursor: pointer !important;
          border: 0 none;
        }
        .tinymce-placeholder{
          color: #999;
          font-family: 'pingfang Sc';
        }`
      };
      // 处理@人的逻辑
      if (this.atWho) {
        const vm = this
        conf.cfyun_atwho = true
        conf.atwho_max_num = 100
        conf.atwho_query_all = true
        // conf.atwho_query_callback = function(name, callback) {
        //   setTimeout(() => {
        //     return callback([ {uid: 'userid', username: '徐志伟', url: '/user/1'},
        //       {uid: 'userid2', username: '尹仁青', url: '/user/2'}]);
        //   }, 1000)
        // }
        conf.atwho_query_callback = this.atWhoQueryCallback ?? (() => { })
        conf.atwho_data_callback = function (data) {
          vm.$emit('on-at-who', data)
        }
      }

      conf = Object.assign(conf, this.$attrs);
      conf.init_instance_callback = (editor) => {
        if (this.value) editor.setContent(this.value);
        this.wordCount = editor.plugins.wordcount.body.getCharacterCountWithoutSpaces();
        this.vModel(editor);
      };
      //粘贴文本格式处理
      conf.paste_preprocess = (plugin, args) => {
        if (args.content.startsWith("<img src=")) return;
        args.content = "";
        setTimeout(() => {
          let content = this.content.split(/[(\r\n)\r\n]/);
          this.pasteContent(args, content);
        }, 200)
      }
      //上传附件
      conf.attachment_upload_handler = (file, successCallback, failureCallback, progressCallback) => {
        this.toBeSent++;
        if (file.type.indexOf("image") != -1) {
          this.$util.compressImage(file).then((result) => {
            let fileObj = new File([result.compressFile], file.name, { type: file.type })
            this.uploadFile(fileObj, successCallback, failureCallback, progressCallback);
          })
        } else {
          this.uploadFile(file, successCallback, failureCallback, progressCallback);
        }
      };
      // // 上传图片
      conf.images_upload_handler = (file, success, failure) => {
        this.toBeSent++;
        file = file.blob();
        const fileType = file.type + '';
        if (fileType.indexOf("image") != -1) {
          this.$util.compressImage(file).then((result) => {
            let fileObj = new File([result.compressFile], file.name, { type: file.type })
            this.uploadFile(fileObj, success, failure);
          })
        } else {
          this.uploadFile(file, success, failure);
        }
      };
      // conf.file_picker_callback = (callback, value, meta) => {
      //   this.filePickerCallback(callback, value, meta);
      //   // this.uploadFile(file.blob(), success, failure);
      // };
      // conf.handleImgUpload = (blobInfo, success, failure) => {
      // }
      tinymce.init(conf);
      const editor = tinymce.get(this.tinymceId);
      this.editor = editor
      editor.on('blur', (e) => {
        this.$emit("blur", e);
      })
      editor.on('init blur', () => {
        this.setPlaceholder();
      });
      editor.on('input change', function () {
        if (editor.getContent().includes('class="tinymce-placeholder"')) {
          editor.setContent('');
        }
      });

      editor.on('focus', function () {
        if (editor.getContent().includes('class="tinymce-placeholder"')) {
          editor.setContent('');
        }
      });
      // setTimeout(() => {
      //   this.goEnd();
      // }, 500)
    });

  },
  destroyed() {
    this.destroyTinymce();
  },
  methods: {
    vModel(editor) {
      // 控制连续写入时setContent的触发频率
      const debounceSetContent = debounce(20, editor.setContent)
      this.$watch('value', (val, prevVal) => {
        this.wordCount = editor.plugins.wordcount.body.getCharacterCountWithoutSpaces();
        if (editor && val !== prevVal && val !== editor.getContent()) {
          if (typeof val !== "string") val = val.toString();
          debounceSetContent.call(editor, val);
          setTimeout(() => {
            this.$emit("getContent", this.getContent());
          }, 500)
        }
      });

      editor.on("change keyup undo redo input", () => {
        this.$emit("input", editor.getContent());
        // this.$nextTick(() => {
        this.$emit("getContent", this.getContent());
        // })
      });
      editor.on('paste', (e) => {
        this.content = e.clipboardData.getData('text/plain');
      })
    },
    destroyTinymce() {
      if (!window.tinymce) return;
      const tinymce = window.tinymce.get(this.tinymceId);
      if (tinymce) {
        tinymce.destroy();
      }
    },
    // 富文本插入内容
    insertContent(content, id, paramValueId, type) {
      content = typeof content !== 'string' ? "" : `<strong id="${id}" class="mceNonEditable editor-tag ${type == 2 ? 'bg-red' : ''} ${paramValueId}" contenteditable="false">${content}</strong>`;
      const tinymce = window.tinymce.get(this.tinymceId)
      if (!this.linmitNum || this.wordCount < this.linmitNum) {
        tinymce.insertContent(content);
        this.$nextTick(() => {
          this.$emit("getContent", this.getContent());
        })
      }
    },
    // 获取创建素材参数
    getParams() {
      const tinymce = window.tinymce.get(this.tinymceId);
      const content = tinymce.getContent({ format: "html" });
      // 创建一个新的DOMParser实例
      const parser = new DOMParser();
      // 解析一段HTML字符串
      const doc = parser.parseFromString(content, "text/html");
      const pList = [...doc.body.getElementsByTagName("p")];
      let textArr = _.compact(pList.map(v => {
        if (v.childNodes[0].nodeType == 3 && v?.textContent?.trim() != '') {
          return v?.childNodes[0].data || v.innerText;
        }
      }));
      // 获取第一个p标签的内容
      const pContent = textArr[0]?.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\.\,\!\?]+/g, '')?.slice(0, 20);
      const description = textArr[1]?.replace(/[^a-zA-Z0-9_\u4e00-\u9fa5\' ']/g, '')?.slice(0, 20);
      const image = [...doc.body.getElementsByTagName("img")];
      let mpLogo = 'https://oss.isuiyou.cn/scrm/note/note.png';
      image.forEach(e => {
        if (mpLogo == '' && e?.getAttribute("src").startsWith('https') && e?.getAttribute("src").split(".")[1] != 'jpg') {
          mpLogo = e?.getAttribute("src")
        }
      })
      mpLogo = mpLogo || "https://oss.isuiyou.cn/scrm/b8f0c679-2abc-439c-9b33-4f8f7e25e7c2/weixinImg.png";
      return {
        title: pContent || "产品笔记",
        description: description || "",
        logo: mpLogo,
        mpLogo
      };
    },
    // 获取纯文本
    getContent(content) {
      const tinymce = window.tinymce.get(this.tinymceId);
      content = content ?? tinymce.getContent({ format: "html" });
      // 创建一个新的DOMParser实例
      const parser = new DOMParser();
      // 解析一段HTML字符串
      const doc = parser.parseFromString(content, "text/html");
      const strongList = [...doc.body.getElementsByTagName("strong")];
      let ids = [];
      let str = "";

      strongList.map(v => {
        const idStr = v.getAttribute("id");
        const paramValueId = v.getAttribute("class").split(" ").at(-1);
        const isRule = this.$route.name == "manualIntervention"
        if (paramValueId == 'null' || isRule) {
          ids.push(idStr);
        } else {
          ids.push({ paramId: idStr, paramValueId: paramValueId == 'undefined' ? null : paramValueId, paramName: v.textContent });
        }
        v.textContent = '%s';
      })
      str = getPlainTextWithNewlines(doc.body.innerHTML);
      function getPlainTextWithNewlines(html) {
        var body = document.createElement('body');
        body.innerHTML = html;
        var text = '';
        var nodes = body.childNodes;
        for (var i = 0; i < nodes.length; i++) {
          var node = nodes[i];
          if (node.nodeType === 3) {
            text += node.nodeValue;
          }
          if (node.nodeType === 1) {
            if (node.nodeName == 'BR') {
              text += '\n';
            }
            text += getPlainTextWithNewlines(node.innerHTML);
          }
        }
        return text;
      }
      return { str, ids, }
    },
    uploadFile(file, successCallback, failureCallback, progressCallback) {
      this.dialogVisible = true;
      // if (!progressCallback) {
      //   this.dialogVisible = false;
      // }
      const formData = new FormData();
      formData.append("file", file);
      // if (file.type.indexOf("image") == -1) {
      // formData.append("path", `scrm/${uuidv4()}/${file.name}`);
      // }
      uploadFile(formData, (e) => {
        const progress = (((e.loaded / e.total) * 100) | 0) + "%";
        progressCallback && progressCallback(progress);
      })
        .then((res) => {
          this.sendNumber++;
          successCallback(res.data);
          this.uploadCallback();
        })
        .catch((err) => {
          failureCallback(err);
          this.dialogVisible = false;
          this.failNumber++; // 失败数量
          this.uploadCallback();
        });
    },
    uploadCallback() {
      if (this.toBeSent == this.sendNumber) {
        this.toBeSent = 0; // 待发送数量
        this.sendNumber = 0; // 已发送数量
        this.failNumber = 0; // 失败数量
        this.dialogVisible = false;
        const tinymce = window.tinymce.get(this.tinymceId);
        tinymce.insertContent("<p></p>");
        this.$message.success("上传成功");
      }
      if (this.toBeSent == this.failNumber) {
        this.toBeSent = 0; // 待发送数量
        this.sendNumber = 0; // 已发送数量
        this.failNumber = 0; // 失败数量
        this.dialogVisible = false;
      }
    },
    goEnd() {
      const editor = window.tinymce.get(this.tinymceId);
      editor.execCommand('selectAll');
      editor.selection.getRng().collapse(false);
      editor.focus();
    },
    pasteContent(args, content) {
      const tinymce = window.tinymce.get(this.tinymceId);
      let str = "";
      content.map((v, i) => {
        str += `${i > 0 ? '<br>' : ''}${v}`;
      })
      tinymce.insertContent(str);
      this.$nextTick(() => {
        this.$emit("getContent", this.getContent());
      })
      // args.content = '';
    },
    // 点击添加表情
    clickEmoji() {
      const editor = window.tinymce.get(this.tinymceId);
      editor.focus();
    },
    // 添加表情文本
    addEmoji(value) {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (!this.linmitNum || this.wordCount < this.linmitNum) {
        this.$nextTick(() => {
          this.$emit("getContent", this.getContent());
        })
        tinymce.insertContent(value);
      }
    },
    setPlaceholder() {
      const editor = window.tinymce.get(this.tinymceId);
      if (editor.getContent() === '') {
        editor.setContent(`<p class="tinymce-placeholder">${this.placeholder}</p>`);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.editor ::v-deep {
  position: relative;

  .tox-tinymce {
    border: 0.5px solid #d8d8d8;
    border-radius: 4px 4px 0 0;
  }

  .wordCount {
    padding-right: 10px;
  }
}
</style>
