
import request from '@/utils/request'
import { getBaseHeader } from "@/utils/request";
export function uploadFile(data, onUploadProgress) {
    console.log(data);
    return request({
        url: "/infra/file/upload",
        method: 'post',
        headers: getBaseHeader(),
        onUploadProgress,
        timeout: 180000,
        data
    })
}
