import { defineComponent } from 'vue'
import { ArrayBase } from '@/formily/index'
import { h } from '@formily/vue'
import { Button } from 'element-ui'
const { useIndex, useArray } = ArrayBase
const stylePrefix = 'formily-element'

const ArrayBaseRemove = defineComponent({
  name: 'ArrayBaseRemove',
  props: ['title', 'index', 'firstNotDel'],
  setup(props, { attrs, listeners }) {
    const indexRef = useIndex(props.index)
    const base = useArray()
    const prefixCls = `${stylePrefix}-array-base`
    return () => {
      const values = base?.field?.value?.value ?? []
      if (base?.field.value.pattern !== 'editable') return null
      if (indexRef.value === 0 && props.firstNotDel && values.length <= 1) return null
      return h(
        Button,
        {
          class: `${prefixCls}-remove`,
          attrs: {
            type: 'text',
            size: 'mini',
            icon: 'el-icon-delete',
            ...attrs,
          },
          on: {
            ...listeners,
            click: (e) => {
              e.stopPropagation()
              if (Array.isArray(base?.keyMap)) {
                base?.keyMap?.splice(indexRef.value, 1)
              }

              base?.field.value.remove(indexRef.value)
              base?.listeners?.remove?.(indexRef.value)

              if (listeners.click) {
                listeners.click(e)
              }
            },
          },
        },
        {
          default: () => [props.title],
        },
      )
    }
  },
})

export default ArrayBaseRemove
