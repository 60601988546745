var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c(
        "div",
        { staticClass: "contailner" },
        [
          _c(
            "el-dialog",
            {
              staticClass: "dialog select-dialog",
              attrs: {
                top: "10vh",
                "modal-append-to-body": false,
                "append-to-body": "",
                title: `选择${_vm.formData.selectTitle}`,
                visible: _vm.dialogVisible,
                width: "1220px",
                "destroy-on-close": true,
                "close-on-click-modal": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
                close: _vm.close,
              },
            },
            [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "flex-sub content-left" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dialogParams",
                          staticClass: "flex flex-wrap",
                          attrs: {
                            model: _vm.dialogParams,
                            align: "left",
                            "label-width": "120px",
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.formData.selectTitle,
                                prop: "value",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                on: {
                                  change: function ($event) {
                                    return _vm.getList("inquire")
                                  },
                                },
                                model: {
                                  value: _vm.dialogParams.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dialogParams,
                                      "value",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "dialogParams.value",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "submit-reset" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getList("inquire")
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "reset",
                              attrs: { icon: "el-icon-refresh-right" },
                              on: {
                                click: function ($event) {
                                  return _vm.resetForm()
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                          _vm.formData.type == 1 && !_vm.disabledSomeColumn
                            ? _c("AdvancedFilter", {
                                ref: "advancedFilterRef",
                                on: { change: _vm.handleChange },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.formData?.masterWechatId && _vm.formData.type == 2
                        ? _c(
                            "div",
                            { staticClass: "margin-bottom-sm" },
                            [
                              _c("el-alert", {
                                attrs: {
                                  title:
                                    "当选择发送群公告时，只能往自己为群主的客户群发送",
                                  closable: false,
                                  type: "warning",
                                  "show-icon": "",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-table",
                        {
                          ref: "multipleTable",
                          attrs: {
                            data: _vm.list,
                            height: "calc(100vh - 500px)",
                            "row-key": _vm.getRowKeys,
                          },
                          on: { "selection-change": _vm.handleSelect },
                        },
                        [
                          !_vm.isRadio
                            ? _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  "reserve-selection": true,
                                  width: "60",
                                  align: "center",
                                },
                              })
                            : _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  "reserve-selection": true,
                                  width: "55",
                                },
                              }),
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              align: "left",
                              prop: "title",
                              width: "60",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: _vm.formData.selectTitle,
                              align: "left",
                              prop: "wechatName",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.groupName ||
                                              scope.row.nickname ||
                                              scope.row.value
                                          )
                                        ),
                                      ]),
                                      _c("channelType", {
                                        attrs: {
                                          type: _vm.DICT_TYPE
                                            .ROBOT_CHANNEL_TYPE,
                                          value: scope.row.accountType,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4053004540
                            ),
                          }),
                          _vm.formData.type == 1 && !_vm.disabledSomeColumn
                            ? [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "社交账号",
                                    align: "left",
                                    prop: "externalOpenId",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.externalOpenId ||
                                                    "--"
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2783017589
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "姓名",
                                    align: "left",
                                    prop: "name",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(scope.row.name || "--")
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    302602838
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "手机号",
                                    align: "left",
                                    prop: "mobile",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(scope.row.mobile || "--")
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3440366513
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "称呼",
                                    align: "left",
                                    prop: "notes",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(scope.row.notes || "--")
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    111214770
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "跟进员工",
                                    align: "left",
                                    prop: "followEmployees",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.followEmployees ||
                                                    "--"
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3011380401
                                  ),
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "page-list" },
                        [
                          _c("pagination", {
                            attrs: {
                              total: _vm.total,
                              pagerCount: _vm.pagerCount,
                              page: _vm.dialogParams.pageNo,
                              limit: _vm.dialogParams.pageSize,
                            },
                            on: {
                              "update:page": function ($event) {
                                return _vm.$set(
                                  _vm.dialogParams,
                                  "pageNo",
                                  $event
                                )
                              },
                              "update:limit": function ($event) {
                                return _vm.$set(
                                  _vm.dialogParams,
                                  "pageSize",
                                  $event
                                )
                              },
                              pagination: _vm.getList,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "content-right" },
                  [
                    _c("div", { staticClass: "flex justify-between" }, [
                      _c("div", { staticStyle: { color: "#999999" } }, [
                        _vm._v("已选" + _vm._s(_vm.selectData.length) + "项"),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: { color: "#1c53d9", cursor: "pointer" },
                          on: { click: _vm.reset },
                        },
                        [_vm._v(" 清空 ")]
                      ),
                    ]),
                    _c(
                      "el-scrollbar",
                      {
                        ref: "scrollContainer",
                        staticClass: "scroll-container padding-top-xs",
                        attrs: { vertical: false },
                      },
                      _vm._l(_vm.selectData, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "tip flex justify-between margin-top-xs align-center padding-right-sm",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-center" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "desc margin-right-xs text-cut",
                                    staticStyle: { "max-width": "180px" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.groupName ||
                                            item.nickname ||
                                            item.value
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("channelType", {
                                  attrs: {
                                    type: _vm.DICT_TYPE.ROBOT_CHANNEL_TYPE,
                                    value: item.accountType,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("i", {
                              staticClass:
                                "el-icon-error pointer icons margin-left-sm",
                              staticStyle: {
                                color: "#D3D2D2",
                                width: "16px",
                                height: "16px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteTo(item)
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "select-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取消"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitData },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }