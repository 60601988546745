<template>
    <div @click.stop="isFocused = false">
        <div class="editor-input-view solids">
            <div contenteditable="true" @focus="focus" v-html="innerHTML" @blur="blur" :style="{ height: `${height}px` }"
                class="editor" id="editor" ref="editor">
            </div>
            <div @mouseenter="isFocused = true" class="solids-top">
                <slot name="footer"></slot>
            </div>
        </div>
        <div class="flex-center justify-between">
            <div @click="clickEmoji" @mouseenter="mouseenter">
                <select-emoji @addEmoji="addEmoji" v-if="selectEmoji"></select-emoji>
            </div>
            <div v-if="linmitNum" class="wordCount bg-white">{{ wordCount }} / {{ linmitNum }}</div>
        </div>
    </div>
</template>

<script>
import selectEmoji from '../selectEmoji/selectEmoji.vue';
export default {
    components: { selectEmoji },
    props: {
        height: {
            default: 150
        },
        value: {
            default: "",
        },
        placeholder: {
            default: '示例:<strong class="mceNonEditable editor-tag" contenteditable="false">xx客户</strong><strong class="mceNonEditable editor-tag" contenteditable="false">性别</strong>您好!'
        },
        linmitNum: {
            default: false,
        },
        height: {
            default: 500,
        },
        selectEmoji: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            selection: null,
            range: null,
            editor: null,
            innerHTML: null,
            isFocused: false, // 输入框是否聚焦
            wordCount: 0, // 字数统计
        }
    },
    methods: {
        // 富文本插入内容
        insertContent(content, id, type) {
            content = typeof content !== 'string' ? "" : `&nbsp;<strong id="${id}" class="mceNonEditable editor-tag ${type == 2 ? 'bg-red' : ''}" contenteditable="false">${content}</strong>&nbsp;`;
            if (!this.linmitNum || this.wordCount < this.linmitNum) {
                // 获取当前元素的光标位置
                const elementIsInFocus = (el) => (el === document.activeElement);
                // 如果聚焦输入框
                if (elementIsInFocus(this.editor)) {
                    this.insertHtmlAtCaret(content)
                } else {
                    var el = document.createElement('div');
                    el.innerHTML = content;
                    // 如果输入框内容为空
                    const placeholder = this.editor.innerHTML.includes('class="tinymce-placeholder"');
                    placeholder ? this.$refs.editor.innerHTML = content : this.$refs.editor.appendChild(el);
                    this.changeContentValue();
                }
            }
        },
        // 输入时监听内容变化
        changeContentValue(e) {
            const innerHTML = this.$refs.editor.innerHTML;
            this.$emit("update:value", innerHTML);
            this.$emit("getContent", this.getContent());
            this.wordCount = this.getDomValuelength(this.getContent().str.replaceAll(/\s/g, ""));
        },
        blur(e) {
            if (this.isFocused) {
                this.$refs.editor.focus();
                return;
            }
            this.setPlaceholder();
        },
        focus() {
            if (this.editor.innerHTML.includes('class="tinymce-placeholder"')) {
                this.$refs.editor.innerHTML = `<div></div>`;
            }
        },
        mouseenter() {
            this.isFocused = true;
            this.blur();
        },
        insertHtmlAtCaret(html) {
            if (window.getSelection) {
                this.selection = window.getSelection();
                this.range = this.selection.getRangeAt(0);
                // IE9 and non-IE
                if (this.selection.getRangeAt && this.selection.rangeCount) {
                    var el = document.createElement('span');
                    el.innerHTML = html;
                    var frag = document.createDocumentFragment();
                    var node;
                    var lastNode;
                    while ((node = el.firstChild)) {
                        lastNode = frag.appendChild(node);
                    }
                    this.range.insertNode(frag);

                    if (lastNode) {
                        this.range = this.range.cloneRange();
                        this.range.setStartAfter(lastNode);
                        this.range.collapse(true);
                        this.selection.removeAllRanges();
                        this.selection.addRange(this.range);
                    }
                    this.$nextTick(() => {
                        this.changeContentValue();
                    })
                }
            }
        },
        setPlaceholder() {
            if (['\n', ''].includes(this.editor.innerText)) {
                this.$refs.editor.innerHTML = `<p class="tinymce-placeholder">${this.placeholder}</p>`;
            }
        },
        // 获取纯文本
        getContent() {
            const content = this.$refs.editor.innerHTML;
            // 创建一个新的DOMParser实例
            const parser = new DOMParser();
            // 解析一段HTML字符串
            const doc = parser.parseFromString(content, "text/html");
            const strongList = [...doc.body.getElementsByTagName("strong")];
            let ids = [];
            let str = "";
            strongList.map(v => {
                ids.push(v.getAttribute("id"));
                v.textContent = '%s';
            })
            str = getPlainTextWithNewlines(doc.body.innerHTML);
            function getPlainTextWithNewlines(html) {
                var body = document.createElement('body');
                body.innerHTML = html;
                var text = '';
                var nodes = body.childNodes;
                for (var i = 0; i < nodes.length; i++) {
                    var node = nodes[i];
                    if (node.nodeType === 3) {
                        text += node.nodeValue;
                    }
                    if (node.nodeType === 1) {
                        if (node.nodeName == 'BR') {
                            text += '\r';
                        }
                        text += getPlainTextWithNewlines(node.innerHTML);
                    }
                }
                return text;
            }
            return { str, ids }
        },
        // 点击添加表情
        clickEmoji() {
            this.mouseenter();
            // const editor = window.tinymce.get(this.tinymceId);
            // editor.focus();
        },
        // 添加表情文本
        addEmoji(value) {
            if (!this.linmitNum || this.wordCount < this.linmitNum) {
                this.insertHtmlAtCaret(value);
                this.$nextTick(() => {
                    this.$emit("getContent", this.getContent());
                })
            }
        },
        // 统计字数
        getDomValuelength(elem) {
            var reg = /<a[^>]+?href=["']?([^"']+)["']?[^>]*>([^<]+)<\/a>/gi;
            var data = elem.toLowerCase().replace(reg, function ($1, $2, $3) {
                return $3;
            });
            return data.length;
        },
        resetEditor() {
            this.isFocused = false;
        }
    },
    mounted() {
        this.editor = this.$refs.editor;
        this.innerHTML = this.value;
        this.$refs.editor.addEventListener('input', this.changeContentValue);
        this.setPlaceholder();
        this.$nextTick(() => {
            this.wordCount = this.getDomValuelength(this.getContent().str.replaceAll(/\s/g, ""));
        })
        // 输入框聚焦时内容为空时隐藏placeholder
        document.getElementById("editor").onfocus = (e) => {
            if (this.editor.innerHTML.includes('class="tinymce-placeholder"')) {
                this.$refs.editor.innerHTML = '<div></div>';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.editor-input-view {
    min-height: 150px;

    .editor {
        padding: 10px;
        overflow: auto;
    }
}
</style>