import { defineComponent, h } from 'vue'
import { createForm } from '@formily/core'
import { createSchemaField } from '@formily/vue'
import Actions from '@/formily/base/actions/index'
import {
  Form,
  FormItem,
  FormGrid,
  Input,
  InputNumber,
  Submit,
  Reset,
  Checkbox,
  DatePicker,
  Radio,
  Select,
  Space,
  Switch,
  TimePicker,
} from '@/formily/index'

const components = {
  Form,
  FormItem,
  FormGrid,
  Input,
  InputNumber,
  Submit,
  Reset,
  Checkbox,
  DatePicker,
  Radio,
  Select,
  Space,
  FSwitch: Switch,
  TimePicker,
  Actions,
}

const { SchemaField } = createSchemaField({
  components,
})

const staticKlass = 'json-schema__form'

export default defineComponent({
  name: 'JsonSchema',
  components,
  props: {
    formProps: {
      type: Object,
      default: () => ({}),
    },
    schema: {
      type: Object,
    },
  },
  setup(props, { attrs, expose }) {
    const { schema, formProps } = props

    const form = createForm(formProps)

    expose({ form })

    return () => {
      return h(
        Form,
        {
          attrs: {
            labelWidth: 120,
            wrapperWidth: 240,
            colon: false,
            ...attrs,
            form,
          },
          class: staticKlass,
        },
        [
          h(SchemaField, {
            props: {
              schema: schema,
            },
          }),
        ],
      )
    }
  },
})
