import {
  addResizeListener,
  removeResizeListener,
} from 'element-ui/src/utils/resize-event'

function getScroll(target, top) {
  const prop = top ? 'pageYOffset' : 'pageXOffset'
  const method = top ? 'scrollTop' : 'scrollLeft'
  let ret = target[prop]
  if (typeof ret !== 'number') {
    ret = window.document.documentElement[method]
  }
  return ret
}

function getOffset(element) {
  const rect = element.getBoundingClientRect()

  const scrollTop = getScroll(window, true)
  const scrollLeft = getScroll(window)

  const docEl = window.document.body
  const clientTop = docEl.clientTop || 0
  const clientLeft = docEl.clientLeft || 0

  return {
    top: rect.top + scrollTop - clientTop,
    left: rect.left + scrollLeft - clientLeft,
  }
}

const isTable = (vnode) =>
  vnode.tag.includes('ElTable') || vnode.tag.includes('DraggableTable')

const toText = (obj = {}) => {
  return Object.keys(obj).reduce((pre, key) => {
    const value = obj[key]
    pre = pre + `${key}: ${value};`
    return pre
  }, '')
}

const defaultBottomOffset = 0
const defaultBoundaryValue = 680
const defaultCssText = {
  background: '#fff',
}

const doResizeForTable = (el, binding, vnode) => {
  const { componentInstance: $table } = vnode
  const { value } = binding

  if (!$table.height) {
    throw new Error(
      `el-table component must set the height. Such as height='500px'`,
    )
  }

  if (!$table) return
  const nextTick = $table.$nextTick
  const bottomOffset = value?.bottomOffset ?? defaultBottomOffset
  const boundaryValue = value?.boundaryValue ?? defaultBoundaryValue

  nextTick(() => {
    const { top } = getOffset(el)
    const height = window.innerHeight - top - bottomOffset
    const lowerHeight = height <= boundaryValue

    if (lowerHeight) {
      $table.layout.setHeight('')
      $table.doLayout()
      nextTick(() => {
        $table.layout.bodyHeight = ''
      })
      el.style.marginBottom = bottomOffset + 'px'
    } else {
      $table.layout.setHeight(height)
      $table.doLayout()
      el.style.marginBottom = el.__originMarginBottom__
        ? el.__originMarginBottom__
        : '0px'
    }
  })
}

const doResizeForElement = (el, binding, vnode) => {
  const { value } = binding
  const { top } = getOffset(el)
  const { scrollHeight } = el
  const bottomOffset = value?.bottomOffset ?? defaultBottomOffset
  const height = window.innerHeight - top

  let cssText = value?.cssText
  cssText = {
    ...defaultCssText,
    ...cssText,
    'min-height': height + 'px',
  }
  // 用户如果有固定元素布局，则设置bottomOffset属性
  if (scrollHeight > height) {
    cssText['margin-bottom'] = bottomOffset + 'px'
  }

  el.style.cssText = toText(cssText)
}

const doResize = (el, binding, vnode) => {
  const { arg } = binding
  if (arg === 'invalid') return

  isTable(vnode)
    ? doResizeForTable(el, binding, vnode)
    : doResizeForElement(el, binding, vnode)
}

export default {
  bind(el, binding, vnode) {
    el.resizeListener = () => {
      doResize(el, binding, vnode)
    }
    el.__originMarginBottom__ = el.style.marginBottom
    addResizeListener(window.document.body, el.resizeListener)
  },
  inserted(el, binding, vnode) {
    doResize(el, binding, vnode)
  },
  update(el, binding, vnode) {
    doResize(el, binding, vnode)
  },
  unbind(el) {
    removeResizeListener(window.document.body, el.resizeListener)
  },
}
