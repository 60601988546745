var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _vm._t("header"),
      _c("textarea", {
        staticStyle: { visibility: "hidden" },
        attrs: { id: _vm.tinymceId },
      }),
      _vm._v(" "),
      _vm._t("footer"),
      _c("div", { staticClass: "flex-center justify-between" }, [
        _c(
          "div",
          { on: { click: _vm.clickEmoji } },
          [
            _vm.selectEmoji
              ? _c("select-emoji", { on: { addEmoji: _vm.addEmoji } })
              : _vm._e(),
          ],
          1
        ),
        _vm.linmitNum
          ? _c("div", { staticClass: "wordCount bg-white" }, [
              _vm._v(_vm._s(_vm.wordCount) + " / " + _vm._s(_vm.linmitNum)),
            ])
          : _vm._e(),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            "show-close": false,
            top: "40vh",
            width: "300px",
            "append-to-body": "",
            "custom-class": "upload-loading",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "text-center text-lg margin-bottom-xs" }, [
            _c("i", { staticClass: "el-icon-loading" }),
          ]),
          _c("div", { staticClass: "text-center" }, [
            _vm._v(
              "共 " +
                _vm._s(_vm.toBeSent) +
                " 个 成功 " +
                _vm._s(_vm.sendNumber) +
                " 个 失败 " +
                _vm._s(_vm.failNumber) +
                " 个"
            ),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }