<template>
    <div class="sop-add">
        <el-drawer title="任务节点配置" :visible.sync="sopDrawer" :modal-append-to-body="false" append-to-body
            :wrapperClosable="false" class="sop-add-drawer" :size="880" :before-close="handleClose">
            <div class="line"></div>
            <div class="top-step margin-top-sm">
                <el-steps class="padding-tb-sm sop-step" :active="activeTab">
                    <el-step v-for="(item, index) in stepList" :key="index">
                        <div class="step-item" slot="icon">
                            <div class="icon-circle">{{ index + 1 }}</div>
                            <span class="text-black padding-left-xs">{{ item.name }}</span>
                        </div>
                    </el-step>
                </el-steps>
            </div>
            <div v-show="activeTab == 1" class="stage-settings-frists">
                <el-form ref="stageFormFrist" :disabled="visible || visibleStatus" :rules="rules"
                    :model="sopTaskNodeReqVO" label-width="100px" label-position="left">
                    <el-form-item label="节点名称" prop="name">
                        <el-input v-model.trim="sopTaskNodeReqVO.name" class="width380" :placeholder="`请输入`"
                            maxlength="20" />
                        <span class="limit-text">{{ sopTaskNodeReqVO.name?.length }}/20</span>
                    </el-form-item>
                    <el-form-item label="用户满足条件"></el-form-item>
                    <div class="stage-tip">
                        <i class="el-icon-warning"></i>
                        主要用于执行任务的触发的条件，满足条件才可进入群发,转人工的动作
                    </div>
                    <el-form-item v-if="$route.query.isTask == 1" label="收集情况" prop="selectCondition">
                        <el-radio-group v-model="sopTaskNodeReqVO.selectCondition">
                            <el-radio :label="104">
                                信息收集成功<question-tooltip placement="top">
                                    <div slot="content">
                                        指需要收集的客户信息已全部补全<br />
                                        （不区分收集前有还是收集后有）
                                    </div>
                                </question-tooltip>
                            </el-radio>
                            <el-radio :label="105">
                                信息收集失败<question-tooltip placement="top">
                                    <div slot="content">指需要收集的客户信息未补全</div>
                                </question-tooltip>
                            </el-radio>
                            <el-radio :label="103">
                                客户不回复
                                <!-- <question-tooltip placement="top">
                                    <div slot="content">指收集信息开始后，24小时内客户<br />
                                        不回复任何消息</div>
                                </question-tooltip> -->
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-else label="回复情况" prop="selectCondition">
                        <el-radio-group @change="checkSelectCondition" v-model="sopTaskNodeReqVO.selectCondition">
                            <template v-if="sopType == 'clientSOP'">
                                <el-radio :label="103">客户不回复</el-radio>
                                <el-radio :label="102">客户回复</el-radio>
                            </template>
                            <template v-if="sopType == 'clientGroupSOP'">
                                <!-- <el-radio :label="203">客户群不回复</el-radio> -->
                                <el-radio :label="202">客户群回复</el-radio>
                            </template>
                        </el-radio-group>
                    </el-form-item>
                    <!-- 管理后台 - SOP条件请求参数 -->
                    <div>
                        <div v-for="(v, i) in sopTaskNodeReqVO.sopConditionReqVOs" :key="i">
                            <!-- 管理后台 - SOP条件请求参数-客户/群回复/不回复 -->
                            <div v-for="(m, n) in v[customerReplyList]" :key="n">
                                <el-form-item
                                    v-if="sopTaskNodeReqVO.selectCondition == 103 || sopTaskNodeReqVO.selectCondition == 203"
                                    :prop="`sopConditionReqVOs.${i}.${customerReplyList}.${n}.minute`"
                                    :rules="[{ required: true, message: '请输入', trigger: 'blur' }, { type: 'integer', min: 10, message: '不能小于10分钟', trigger: 'blur' }]">
                                    <span>超过</span>
                                    <el-input type="number" step="10" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                                        v-model.number="m.minute" style="width: 100px;margin: 0 4px;"></el-input>
                                    分钟，执行该动作
                                </el-form-item>
                                <!-- 信息收集失败 -->
                                <div class="flex-center justify-center" v-if="sopTaskNodeReqVO.selectCondition == 105">
                                    超过
                                    <el-form-item style="margin:0;" label-width="0"
                                    :prop="`sopConditionReqVOs.${i}.${customerReplyList}.${n}.num`"
                                    :rules="[{ required: true, validator: validTime, trigger: 'blur',m }]">
                                    <el-input type="number" step="10" max="60" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                                        v-model.number="m.num" style="width: 110px;margin: 0 4px;"></el-input>
                                        
                                </el-form-item>
                                <el-form-item style="margin:0;" label-width="0"
                                    :prop="`sopConditionReqVOs.${i}.${customerReplyList}.${n}.type`"
                                    :rules="[{ required: true, message: '请选择', trigger: 'blur' }]">
                                    <!-- <el-input type="number" step="10" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                                        v-model.number="m.minute" style="width: 100px;margin: 0 4px;"></el-input> -->
                                        <el-select v-model="m.type" @change="$refs.stageFormFrist.validateField(`sopConditionReqVOs.${i}.${customerReplyList}.${n}.num`)" style="width: 110px;margin: 0 4px;">
                                            <el-option label="分钟" :value="2"></el-option>
                                            <el-option label="小时" :value="1"></el-option>
                                        </el-select>
                                </el-form-item>
                                ，执行该动作
                                </div>
                                <el-form-item label="回复内容"
                                    :rules="[{ required: true, message: '请选择', trigger: ['blur', 'change'] }]"
                                    :prop="`sopConditionReqVOs.${i}.${customerReplyList}.${n}.anyContent`"
                                    v-if="sopTaskNodeReqVO.selectCondition == 102 || sopTaskNodeReqVO.selectCondition == 202">
                                    <el-radio-group v-model="m.anyContent">
                                        <el-radio :label="0">具体内容</el-radio>
                                        <el-radio :label="1">任意内容</el-radio>
                                    </el-radio-group>
                                    <div class="add-reply" v-if="m.anyContent == 0">
                                        <div v-for="(item, index) in m.specificContentList || [{}]" :key="index"
                                            class="add-reply-item margin-bottom-xs flex-center justify-between">
                                            <div class="flex">当用户表达
                                                <el-form-item style="margin: 0;"
                                                    :prop="`sopConditionReqVOs.${i}.${customerReplyList}.${n}.specificContentList.${index}.specificContent`"
                                                    :rules="[{ required: true, message: '请输入', trigger: 'blur' }]">
                                                    <el-input class="add-input-reply" resize="none" type="textarea" maxlength="150"
                                                        v-model="item.specificContent" placeholder="示例：请给我下商品的链接" />
                                                </el-form-item>
                                                的意思时，执行该动作。
                                            </div>
                                            <i class="el-icon-error pointer" v-if="m.specificContentList?.length > 1"
                                                style="color: #D3D2D2;" @click="removeReply(index)"></i>
                                        </div>
                                        <div>
                                            <el-button type="text" icon="el-icon-circle-plus" @click="addReply">
                                                添加 <span class="text-red">【单次最多添加5条问题】</span>
                                            </el-button>
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </el-form>
            </div>
            <div v-show="activeTab == 2">
                <stage-second-set :visible="visible || visibleStatus" ref="stageSecondSet"
                    :selectCondition="sopTaskNodeReqVO.selectCondition" :id="sopItemId"></stage-second-set>
            </div>
            <div class="foot">
                <el-button @click="prev" class="prev" v-if="activeTab == 2">上一步</el-button>
                <el-button @click="next" class="next" v-if="activeTab == 1" type="primary">下一步</el-button>
                <el-button class="finish" :disabled="visible" v-if="activeTab == 2" type="primary" v-no-more-click
                    @click="finishClick">完成</el-button>
                <el-button @click="handleClose" class="cancelClose">取消</el-button>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import taskMessage from '@/views/sop/components/taskMessage.vue'
import taskForward from '@/views/sop/components/taskForward.vue';
import taskLabel from '@/views/sop/components/taskLabel.vue';
import stageSecondSet from '@/views/sop/components/stageSecondSet.vue'
import { getSopTaskNodeDetail } from '@/api/sop/index.js'
import { mapState, mapMutations } from 'vuex'
import _ from "lodash"
import QuestionTooltip from '../../components/questionTooltip/questionTooltip.vue';
export default {
    components: { taskMessage, taskForward, taskLabel, stageSecondSet, QuestionTooltip },
    props: {
        parentNodeId: {
            type: String,
            default: '0'
        },
        sopItemId: {
            type: String,
            default: ''
        },
        nodeConfig: {
            type: Object,
        }
    },
    data() {
        const validTime = (rule, value, callback)=>{
            if(!value){
                callback(new Error("请输入"));
            }
            if(rule.m.type == 2 && (value > 60 || value < 10)){
                callback(new Error("请输入10-60正整数"))
                return;
            }
            if(rule.m.type == 1 && (value > 24 || value < 0)){
                callback(new Error("请输入1-24正整数"))
                return;
            }
            callback()
        }
        return {
            sopDrawer: false,
            nodeConfigDeep: {},
            sopType: 'clientSOP',
            sopTaskNodeReqVO: {
                sopStageId: localStorage.getItem('sopItemId'),
                name: '',
                parentId: 0,
                selectCondition: 102,
                conditionsRelation: 2,
                sopConditionReqVOs: [{
                    name: "1",
                    customerReplyConditionReqVOList: [],
                    customerGroupReplyConditionReqVOList: [{}],
                    customerNoReplyConditionReqVOList: [{}],
                    customerCollectFailReqVOList:[{type: 1,num: 24}],// 105信息收集失败 type 1:小时 2:分钟 默认24小时
                    customerGroupNoReplyConditionReqVOList: [{}]
                }],
                customerGroupReplyConditionReqVOList: [{
                    name: "1",
                    customerReplyConditionReqVOList: [],
                    customerGroupReplyConditionReqVOList: [{}],
                    customerNoReplyConditionReqVOList: [{}],
                    customerCollectFailReqVOList:[{type: 1,num: 24}], // 105信息收集失败 type 1:小时 2:分钟 默认24小时
                    customerGroupNoReplyConditionReqVOList: [{}]
                }]
            },
            stageFormDataSecond: {
                id: '',
                sopExecuteTaskReqVOs: []
            },
            eqVOSList:
            {
                102: 'customerReplyConditionReqVOList',
                103: 'customerNoReplyConditionReqVOList',
                104: 'sopMessageCollects',
                105: 'customerCollectFailReqVOList',
                202: 'customerGroupReplyConditionReqVOList',
                203: 'customerGroupNoReplyConditionReqVOList'
            },
            rules: {
                name: [{ required: true, message: '请输入阶段名称', trigger: 'blur' }],
                anyContent: [{ required: true, message: '请选择回复内容', trigger: 'blur' }],
                selectCondition: [{ required: true, message: '请选择回复情况', trigger: 'blur' }]
            },
            conditionsConfig: {},
            stageName: '阶段名称',
            activeTab: 1,
            stepList: [{ name: "节点触发条件" }, { name: "配置节点内容" }],
            visible: false,
            visibleStatus: false,
            validTime, // 信息收集失败校验时间
        }
    },
    computed: {
        ...mapState('app', ['conditionsConfig1']),
        customerReplyList() {
            return this.eqVOSList[this.sopTaskNodeReqVO.selectCondition];
        },
        isGroup() {
            return this.$route.query.sopType == 'clientSOP' ? false : true;
        }
    },
    created() {
        this.sopTaskNodeReqVO.selectCondition = this.isGroup ? 202 : 102;
        this.sopTaskNodeReqVO.sopConditionReqVOs.map(v => {
            v[this.customerReplyList] = [{ specificContentList: [{ sopStageId: this.sopItemId }], anyContent: 0 }];
        });
        this.sopTaskNodeReqVO.sopStageId = this.sopItemId;
    },
    methods: {
        ...mapMutations(['setConditionsConfig']),
        handleClose(isToast) {
            if (!isToast) {
                this.$refs.stageFormFrist.resetFields();
                this.sopDrawer = false;
                return;
            }
            this.$confirm('编辑的内容未保存，确定关闭弹窗吗？', '未保存提示!', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$refs.stageFormFrist.resetFields();
                this.sopDrawer = false;
                this.$emit("closeAddDrawer")
            })
        },
        open(isEdit) {
            this.visible = this.$route.query?.visible == 3;
            this.visibleStatus = this.$route.query?.visibleStatus == 2
            this.activeTab = 1;
            this.sopType = this.$route.query.sopType
            this.sopDrawer = true;
            if (isEdit) {
                this.getDetail();
            }
            if (this.$route.query.isTask == 1) {
                this.sopTaskNodeReqVO.selectCondition = 104;
            }
        },
        // 获取任务节点详情
        getDetail() {
            getSopTaskNodeDetail({ id: this.parentNodeId }).then(res => {
                res.data.sopTaskDetailVO?.sopConditionReqVOs?.map((v, i) => {
                    if (!v[this.customerReplyList]) {
                        v[this.customerReplyList] = [{ specificContentList: [{ sopStageId: this.sopItemId }], anyContent: 0 }];
                    }
                    const keys = Object.keys(v);
                    keys.map(m => {
                        if (!v[m]) {
                            v[m] = [{}];
                        }
                    })
                });
                this.sopTaskNodeReqVO = res.data.sopTaskDetailVO;
                this.$refs.stageSecondSet.ruleForm.performType = res.data.sopExecuteTaskReqVOs.map(v => v.actionType);
                this.$refs.stageSecondSet.createDetailData(res.data.sopExecuteTaskReqVOs); // 回显任务节点详情 
            })
        },
        prev() {
            this.activeTab = 1
        },
        next() {
            this.$refs.stageFormFrist.validate(valid => {
                if (valid) {
                    this.activeTab = 2
                }
            })
        },
        addReply() {
            this.sopTaskNodeReqVO.sopConditionReqVOs.map(v => {
                v[this.customerReplyList].map(m => {
                    if (m.specificContentList.length >= 5) {
                        this.$message({
                            type: 'warning',
                            message: '最多添加5条回复内容',
                        })
                        return;
                    }
                    m.specificContentList.push({ sopStageId: this.sopItemId })
                })
            })
        },
        removeReply(index) {
            this.sopTaskNodeReqVO.sopConditionReqVOs.map(v => {
                v[this.customerReplyList].map(m => {
                    m.specificContentList.splice(index, 1)
                })
            })
        },
        finishClick() {
            this.$refs.stageSecondSet.$refs.ruleForm.validate(async valid => {
                if (valid && await this.validChildForm()) {
                    if (!this.sopTaskNodeReqVO.id) {
                        this.sopTaskNodeReqVO.parentId = this.parentNodeId;
                    }
                    this.sopTaskNodeReqVO.sopConditionReqVOs = this.sopTaskNodeReqVO.sopConditionReqVOs.map(v => {
                        return { [this.customerReplyList]: v[this.customerReplyList], conditionType: this.sopTaskNodeReqVO.selectCondition }
                    });
                    if(this.sopTaskNodeReqVO.selectCondition != 105){ // 如果当前任务收集情况不是 信息收集失败
                        this.sopTaskNodeReqVO.sopConditionReqVOs[0].customerCollectFailReqVOList = [{}];
                    }
                    const formData = {
                        flag: this.nodeConfig.flag,
                        insertId: this.nodeConfig.insertId,
                        sopTaskNodeReqVO: this.sopTaskNodeReqVO,
                        sopExecuteTaskReqVOs: this.$refs.stageSecondSet.taskDataList
                    };
                    this.$emit('nodeUpdateData', this.$util.delEmptyQueryNodes(formData), this.sopTaskNodeReqVO.id);
                }
            })
        },
        // 校验子组件表单
        async validChildForm() {
            const formList = ["taskMessages", "taskForwards", "taskLabels", "taskNotice", "taskInfo"];
            const validFormList = formList.filter((v, i) => this.$refs.stageSecondSet.ruleForm.performType.indexOf(i + 1) != -1);
            let validArr = [];
            validFormList.map(v => {
                this.$refs.stageSecondSet.$refs[v].$refs.ruleForm.validate(valid => {
                    validArr.push(valid);
                })
            })
            if (validFormList.includes("taskMessages")) {
                const validChild = await this.$refs.stageSecondSet.$refs.taskMessages.validChild();
                validArr.push(validChild);
            }
            return validArr.includes(false) === false;
        },
        // 切换回复 / 不回复
        checkSelectCondition(e) {
            if (e == 103) {
                this.$refs.stageSecondSet.ruleForm.performType = this.$refs.stageSecondSet.ruleForm.performType.filter(v => v != 5)
                this.$refs.stageSecondSet.taskDataList = this.$refs.stageSecondSet.taskDataList.filter(v => v.actionType != 5)
            }
            if (e == 102) {
                this.sopTaskNodeReqVO.sopConditionReqVOs.map(v => {
                    v[this.customerReplyList] = [{ specificContentList: [{ sopStageId: this.sopItemId }], anyContent: 0 }];
                });
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.stage-settings-frists ::v-deep {
    .limit-text {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        color: #B3B3B3;
        margin-left: 8px;
    }

    .el-form {
        padding: 30px 13px 0 40px;
    }

    .stage-tip {
        margin-bottom: 23px;
        height: 32px;
        opacity: 1;
        background: #F9F9F9;
        line-height: 32px;
        padding-left: 11px;
        margin-top: -8px;

        .el-icon-warning {
            color: #B7C1D7;
        }

        font-size: 14px;
        font-weight: normal;
        color: #999999;
    }

    .add-icon-texts {
        color: #1c53d9;
        font-size: 14px;
        cursor: pointer;
        border: none;

        .add-icon {
            color: #1c53d9;
            width: 15px;
            height: 15px;
            margin-left: 8px;
        }
    }

    .add-reply {
        margin-top: 18px;
        font-size: 14px;
        font-weight: normal;
        color: #333333;
        padding: 0 16px 16px 16px;
        border-radius: 2px;
        opacity: 1;
        background: #FFFFFF;
        border: 0.5px solid #C0CCDA;
        max-height: calc(100vh - 500px);
        overflow: auto;

        .add-reply-item {
            margin-top: 16px;
        }
    }

    .add-input-reply {
        width: 280px;
        margin: 0 8px;
    }
}

.sop-add-drawer ::v-deep {

    .line {
        width: calc(100% - 21px);
        height: 1px;
        background-color: #d8d8d8;
        margin-left: 21px;
    }

    .el-drawer__header {
        font-weight: 500;
        color: #000;
        font-size: 16px;
        margin-bottom: 27px;
    }

    .el-input--small .el-input__inner {
        height: 32px;
        line-height: 32px;
    }




    .foot {
        z-index: 9999;
        position: absolute;
        bottom: 0;
        height: 70px;
        width: 100%;
        padding: 15px 39px;
        vertical-align: middle;
        opacity: 1;
        background: #ffffff;
        box-shadow: 0px -3px 8px 0px rgba(202, 202, 202, 0.16);

        .prev {
            float: left;
        }

        .cancelClose {
            margin-right: 30px;
            background: #f6f6fa;
            float: right;
        }

        .next,
        .finish {
            float: right;
        }
    }
}
</style>